import React, { useEffect, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import './style.scss';

type Props = {
  state: boolean;
  termsAccepted: boolean;
  setTermsAccepted: Function;
  agreed: boolean;
};

export function TermsModal(props: Props) {
  const { state, termsAccepted, setTermsAccepted, agreed } = props;
  const [isOpen, setIsOpen] = useState(state);

  useEffect(() => {
    setIsOpen(true);
  }, [state]);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
              <section className="m-auto w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div
                  className="scrollbar mx-auto mt-10 h-[600px]  overflow-y-scroll dark:bg-slate-800 md:pr-10"
                  id="style-1"
                >
                  <section className="flex w-full ">
                    <div className="z-[10] w-full px-4 text-gray-500 dark:text-white md:w-[3/4]">
                      <h1 className="text-lg font-bold md:text-xl">
                        TERMS OF SERVICE
                      </h1>

                      <p className="mb-10">
                        <strong>
                          <time dateTime="2018-11-29" itemProp="datePublished">
                            Last updated on November 29, 2018
                          </time>
                        </strong>
                      </p>

                      <section className="terms">
                        <li>
                          <strong>
                            Agreement between You and Authentic; Changes
                          </strong>

                          <ol>
                            <li>
                              .1 Please read the following terms of service (the
                              &ldquo;<strong>Terms</strong>&rdquo;) carefully.
                              By clicking the &ldquo;I Accept&rdquo; box, you
                              acknowledge that you have read, understood and
                              agree to be bound by these Terms, and the terms
                              and conditions of the Privacy Policy (as defined
                              below), when using any service or services
                              (collectively, the &ldquo;<strong>Service</strong>
                              &rdquo;) offered by Authentic LLC, an Oregon
                              limited liability company, or its parents,
                              affiliates or subsidiaries (collectively, &ldquo;
                              <strong>Authentic</strong>&rdquo; or &ldquo;
                              <strong>we</strong>&rdquo;), on or through this or
                              any site made available by Authentic
                              (collectively, the &ldquo;<strong>Site</strong>
                              &rdquo;) or in connection with any mobile
                              application (a &ldquo;
                              <strong>Mobile App</strong>
                              &rdquo;) separately made available by Authentic.
                              The term &ldquo;you&rdquo; (and
                              &ldquo;your&rdquo;) for purposes of these Terms,
                              means both you in your individual capacity, and if
                              applicable, the company or other legal entity whom
                              you represent and on whose behalf you use the
                              Service.
                            </li>
                            <li>
                              .2 In order to use the Service you must agree to
                              these Terms. However, in addition to clicking the
                              “I Accept” box, you may also agree to the Terms by
                              actually using the Service. You acknowledge and
                              agree that Authentic will treat your use of the
                              Service as acceptance of these Terms from the time
                              you first use the Service.
                            </li>
                            <li>
                              .3&nbsp;
                              <strong>
                                Authentic reserves the right to change any of
                                the terms and conditions contained in these
                                Terms, including the Service and/or any policies
                                or guidelines governing the Service, at any time
                                and in its sole discretion. While we will
                                endeavor to provide direct notice to you of any
                                changes, you are responsible for periodically
                                checking the Site to determine if any changes
                                have been made and we are not liable for your
                                failure to do so or our failure to provide such
                                direct notice to you. Your continued use of the
                                Service following any revision to these Terms
                                will constitute your acceptance of the changes
                                or modifications to these Terms. If you do not
                                agree to any changes to these Terms, do not
                                continue to use the Service.
                              </strong>
                            </li>
                          </ol>
                        </li>

                        <li>
                          <strong>
                            User Accounts; User Types; Mobile Apps.{' '}
                          </strong>

                          <ol>
                            <li>
                              .1 In order to use the Service, you must register
                              with us to open an Authentic user account (&ldquo;
                              <strong>Account</strong>&rdquo;). By opening an
                              Account, you represent and warrant that: (a) all
                              information you submit in connection with your
                              opening and use of your Account is true, accurate,
                              current, and complete; (b) you will promptly
                              notify us if your information changes so that we
                              can update our records; (c) you are aged 18 or
                              older; the Service is not intended for persons
                              under 18; and (d) your use of the Service does not
                              violate any applicable law, rule or regulation.
                              You are responsible for maintaining this
                              information current.{' '}
                            </li>
                            <li>
                              .2 You are solely responsible for maintaining the
                              security and confidentiality of the information
                              you hold for your Account, including, without
                              limitation, your user name and password, and for
                              any and all activity that occurs through your
                              Account as a result of your failure to keep this
                              information secure and confidential. You hereby
                              agree to notify Authentic immediately if you
                              become aware of any unauthorized use of your
                              Account, user name or password, or any other
                              breach of security in connection therewith. You
                              may be held liable for losses incurred by
                              Authentic or any third party due to someone else
                              using your Account, user name or password as a
                              result of your failing to keep your Account
                              information secure and confidential. You are
                              strictly prohibited from using anyone else&rsquo;s
                              Account, user name or password at any time and for
                              any reason. Authentic is not liable to you or any
                              third party for your failure to comply with your
                              obligations under this paragraph.{' '}
                            </li>
                            <li>
                              .3 The Service is made available by Authentic to
                              the following two (2) user categories
                              (collectively, &ldquo;<strong>Users</strong>
                              &rdquo;): (a)&nbsp;individual Content (as defined
                              below) providers (each, a &ldquo;
                              <strong>Content Provider</strong>&rdquo;) for use
                              in connection with creating and potentially
                              selling certain custom Content (&ldquo;
                              <strong>Submitted Content</strong>&rdquo;); and
                              (b)&nbsp;individual brands or other parties (each,
                              a &ldquo;<strong>Client</strong>&rdquo;) for use
                              in connection with (i) providing creative briefs
                              that describe certain requested Submitted Content
                              and related requirements (each, a &ldquo;
                              <strong>Creative Brief</strong>&rdquo;),
                              (ii)&nbsp;selecting among available Content
                              Providers to create Submitted Content, (iii) if
                              applicable, providing certain Client products
                              (&ldquo;<strong>Client Products</strong>&rdquo;)
                              to Content Providers in connection with the
                              creation of such Submitted Content, and
                              (iv)&nbsp;reviewing available Submitted Content
                              within six (6) months following the initial
                              submission deadline set forth in the Creative
                              Brief (the &ldquo;<strong>Review Period</strong>
                              &rdquo;) to determine which such Submitted Content
                              Client elects to purchase (the &ldquo;
                              <strong>Purchased Content</strong>&rdquo;).
                            </li>
                            <li>
                              .4 You acknowledge and agree that all Mobile Apps
                              shall be made available solely in accordance with
                              the terms of the then-current end user license
                              agreement under which each such Mobile App is made
                              available (the &ldquo;
                              <strong>EULA</strong>
                              &rdquo;), which such EULA shall supersede any
                              conflicting terms or conditions of these Terms
                              with respect to your access to and use of the
                              Mobile Apps.
                            </li>
                          </ol>
                        </li>

                        <li>
                          <strong>Your Information and Privacy</strong>.
                          <ol>
                            <li>
                              .1 In order to use the Service you will be
                              required to provide certain (i) personally
                              identifiable information to Authentic (e.g., your
                              name, address, company information, email address,
                              phone number, and, if applicable, your credit card
                              information); and other information (collectively,
                              &ldquo;
                              <strong>Information</strong>&rdquo;). Authentic
                              will only use such personally identifiable
                              Information in accordance with the terms of our
                              privacy policy (the &ldquo;
                              <strong>Privacy Policy</strong>&rdquo;). In this
                              regard, you agree to the then-current version of
                              the Privacy Policy that you can find and read at{' '}
                              <a
                                href="https://protect-us.mimecast.com/s/sucTCmZ2YAcj4NGQiGtBMB?domain=authenticpx.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://authenticpx.com/privacy-policy.html
                              </a>
                              . You hereby grant Authentic a license to use all
                              other Information as necessary in connection with
                              the provision of the Service.{' '}
                            </li>
                          </ol>
                        </li>

                        <li>
                          <strong>Fees; Payments</strong>.
                          <ol>
                            <li>
                              .1 The fees that Clients are required to pay for
                              Purchased Content, and amounts to which Content
                              Providers are entitled for Purchased Content, are
                              set forth on the Site and may be updated by
                              Authentic at any time, in its sole discretion;
                              provided that, for purposes of clarification, no
                              amounts shall be payable by Clients, or to Content
                              Providers, in connection with Submitted Content.{' '}
                            </li>
                            <li>
                              .2 If applicable, the credit card Clients maintain
                              on file with Authentic in your Account will be
                              automatically charged in accordance with each item
                              of Purchased Content. Clients are responsible for
                              maintaining up to date credit card information in
                              your Account. Authentic reserves the right to
                              suspend or terminate a Client&rsquo;s use of the
                              Service (and access to your Content) if you fail
                              to pay any fees due in connection with your use of
                              the Service. Any outstanding fees are immediately
                              due and payable upon termination of your use of
                              the Service for any reason. To the greatest extent
                              permitted by applicable law, and without affecting
                              your credit card issuer&rsquo;s rights, each
                              Client hereby waives all claims relating to fees
                              unless you notify Authentic of any dispute or
                              other fee-related claim within thirty (30)
                              calendar days of the fees being charged to your
                              credit card.{' '}
                            </li>
                            <li>
                              .3 Within thirty (30) days following receipt of
                              fees from Clients for applicable Purchased
                              Content, Authentic will pay the applicable Content
                              Provider the fees set forth on the Site.
                            </li>
                            <li>
                              .4 To the greatest extent permitted by applicable
                              law, any refunds of fees are at the sole
                              discretion of Authentic, and refunds will only be
                              provided in the form of a credit against future
                              fees for the Service. Nothing in these Terms
                              obligates Authentic to extend credit to any
                              person. You acknowledge and agree that any credit
                              card and related billing and payment information
                              that you provide to Authentic may be shared by
                              Authentic with third party service providers who
                              work on Authentic&rsquo;s behalf, such as payment
                              processors and/or credit agencies, solely for the
                              purposes of checking credit, effecting payment to
                              Authentic and servicing your Account. Authentic
                              may also provide information in response to valid
                              legal processes, such as subpoenas, search
                              warrants and court orders, or to establish or
                              exercise its legal rights or defend against legal
                              claims. Authentic shall not be liable for any use
                              or disclosure of such information by such third
                              parties.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Authentic&rsquo;s Proprietary Rights</strong>.
                          <ol>
                            <li>
                              .1 You hereby acknowledge and agree that Authentic
                              (or its licensors) own all legal right, title and
                              interest in and to the Site and Service,
                              including, without limitation, any intellectual
                              property or other proprietary rights which subsist
                              in the Site and Service (whether such rights are
                              registered or unregistered, and wherever in the
                              world those rights may exist). As between you and
                              Authentic, all materials on the Site, including,
                              but not limited to, graphics, user and visual
                              interfaces, images, software, applications, and
                              text, as well as the design, structure, selection,
                              coordination, expression, &ldquo;look and
                              feel&rdquo;, and arrangement of the Site and its
                              Content (except for your Content), and the domain
                              names, trademarks, service marks, proprietary
                              logos and other distinctive brand features found
                              on the Site, are all owned by Authentic or
                              licensors.
                            </li>
                            <li>
                              .2 Authentic shall fully own and retain all rights
                              to anonymous usage data derived from your use of
                              the Service (&ldquo;
                              <strong>Usage Data</strong>
                              &rdquo;) as aggregated with usage data from
                              Authentic&rsquo;s other customers for its own
                              business purposes such as support, operational
                              planning, product innovation and sales and
                              marketing of Authentic&rsquo;s services. For
                              purposes of clarification, such Usage Data may not
                              include any data that could reasonably identify
                              you.
                            </li>
                            <li>
                              .3 Nothing in these Terms gives you any right to
                              use any of Authentic&rsquo;s trade names,
                              trademarks, service marks, logos, domain names,
                              and other distinctive brand features. All rights
                              not expressly granted by Authentic under these
                              Terms are reserved.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Service Use; Restrictions.</strong>
                          <ol>
                            <li>
                              .1 While these Terms are in effect, you may access
                              and use the Site and Services in accordance with
                              these Terms.
                            </li>
                            <li>
                              .2 You hereby represent and warrant that you will
                              not, and will not permit any third party to: (a)
                              attempt to disable or circumvent any security
                              mechanisms used by the Site or Service or
                              otherwise attempt to gain unauthorized access to
                              any portion or feature of the Site or Service, or
                              any other systems or networks connected to the
                              Site or Service, or to any Authentic server, by
                              hacking, password &ldquo;mining&rdquo;, or any
                              other illegal means; (b) use any
                              &ldquo;deep-link&rdquo;,
                              &ldquo;page-scrape&rdquo;, &ldquo;robot&rdquo;,
                              &ldquo;spider&rdquo; or other automatic device,
                              program, algorithm or methodology, or any
                              comparable manual process, to access, acquire,
                              copy, or monitor any portion of the Site or
                              Service; (c) use any device, software or routine
                              to interrupt or interfere, or attempt to interrupt
                              or interfere with, the proper operation and
                              working of the Site or Service or any transaction
                              being conducted on the Site or through the
                              Service, or with any other person&rsquo;s use of
                              the Site or Service; (d) breach any security
                              measures implemented on the Site or in the
                              Service; (e) track or seek to trace any
                              information on any other person who visits the
                              Site or uses the Service; (f) forge headers or
                              otherwise manipulate identifiers in order to
                              disguise your identity, or the origin of any
                              message or other communication that you send to
                              Authentic in connection with the Service; (g)
                              pretend that you are, or that you represent,
                              someone else, or impersonate any other person; (h)
                              use the Service in the design, development,
                              production, or use of missiles or the design,
                              development, production, stockpiling, or use of
                              chemical or biological weapons; (i) use the
                              Service or Site for any illegal purpose, for
                              soliciting the performance of any illegal
                              activity, or as otherwise prohibited by these
                              Terms or applicable laws, rules or regulations,
                              including, without limitation, laws applicable to
                              the export of software and data; (j) upload or
                              otherwise process any malicious content to, or
                              through, the Service; or (k) copy, modify, create
                              a derivative work of, reverse engineer, decompile
                              or otherwise attempt to extract the source code of
                              the any Authentic proprietary software used to
                              provide, maintain, or otherwise applicable to, the
                              Service, or made available to you in connection
                              with the Service.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Content; License and Ownership Rights</strong>
                          .
                          <ol>
                            <li>
                              .1 Each User hereby agrees that you are solely
                              responsible and liable for any and all
                              information, data files, written text, computer
                              software, music, audio files or other sounds,
                              photographs, videos, images, and other content,
                              including any of the foregoing provided in
                              connection with a Creative Brief and Submitted
                              Content, as applicable (collectively &ldquo;
                              <strong>Content</strong>&rdquo;), that you store,
                              transmit, display, or otherwise use in connection
                              with the Service, and for the consequences of your
                              actions in connection with such Content and your
                              use of the Service. You agree that Authentic has
                              no responsibility to you or to any third party in
                              connection with such Content. You are solely
                              responsible obtaining consent from applicable
                              third parties (i.e., obtaining releases for any
                              photograph content) in connection with Content and
                              for any losses or damage suffered by Authentic in
                              connection with your Content. You hereby represent
                              and warrant that you will not store or otherwise
                              use any Content in connection with the Service
                              that: (a) violates these Terms; (b) is defamatory,
                              libelous, abusive, illegal, profane, indecent,
                              pornographic, obscene, hateful, offensive,
                              harassing, or threatening in any way; (c)
                              constitutes or encourages conduct that would be
                              considered a criminal offense or give rise to
                              civil liability, or otherwise intentionally or
                              unintentionally violate any law, rule or
                              regulation; (d) violates any third party&rsquo;s
                              copyright, trademark, patent, trade secret, or
                              other personal or proprietary right; (e) invades
                              or interferes with the rights of privacy or
                              publicity of any person; or (f) contains a virus,
                              malicious code or any other harmful component.{' '}
                            </li>
                            <li>
                              .2 Authentic reserves the right, at any time in
                              its sole discretion and without notice to you, to
                              pre-screen, review, monitor, flag, filter, modify,
                              refuse or remove any or all Content from the
                              Service, but Authentic has no obligation to do so.
                              You agree to immediately take down, delete or
                              modify any Content that is stored by you through
                              the Service that violates these Terms or any
                              applicable laws, rules, or regulations, including
                              pursuant to a take down, deletion or modification
                              request from Authentic. In the event that you
                              elect not to comply with a request from Authentic
                              to take down, delete or modify certain Content,
                              Authentic reserves the right to directly take
                              down, delete or modify such Content. Authentic
                              assumes no liability or responsibility arising
                              from your activities in connection with the
                              Service, including, without limitation, Content
                              that you store or otherwise use in connection with
                              Service.{' '}
                            </li>
                            <li>
                              .3 Authentic claims no ownership or control over
                              your right, title and interest in your Content.
                              Except in connection with Purchased Content, you
                              retain copyright and any other intellectual
                              property and other proprietary rights you already
                              hold in your Content, and you are solely
                              responsible for protecting those rights, as you
                              deem appropriate. By submitting, storing,
                              displaying, or otherwise using your Content in
                              connection with the Service, you hereby grant
                              Authentic a worldwide, royalty-free,
                              fully-paid-up, non-exclusive license to reproduce,
                              perform, modifying display, distribute, and
                              otherwise use such Content for the purpose of
                              enabling Authentic to provide the Service,
                              including, without limitation, storing and
                              retrieving the Content, making the Content
                              available through the Service, adapting the
                              Content for technical display and transmission,
                              conforming the Content to the limitation and terms
                              of the Service, and any other use related to the
                              maintenance, provision and improvement of the
                              Service
                            </li>
                            <li>
                              .4 You hereby agree that Authentic, in its sole
                              discretion, may use your trade names, trademarks,
                              service marks, logos, domain names, and other
                              distinctive brand features, in presentations,
                              marketing materials, customer lists, financial
                              reports and web site listings (including links to
                              your web site) for the purpose of advertising or
                              publicizing the Service.
                            </li>
                            <li>
                              .5 Authentic will not disclose any of your
                              Content, except: (a) if you expressly authorize us
                              to do so in connection with your use of the
                              Service (i.e. to authorized Content Providers and
                              Clients); (b) as necessary to provide the Service
                              to you; or (c) to comply with the request of any
                              governmental or regulatory body, subpoenas or
                              court orders, or as otherwise required by
                              applicable law, rule or regulation. If we receive
                              a subpoena, court order, or other request from a
                              governmental or regulatory body requesting the
                              disclosure of any of your Content, we will use
                              good faith efforts to provide you with reasonable
                              notice to allow you to seek a protective order or
                              other appropriate remedy (except to the extent
                              Authentic&rsquo;s compliance with the foregoing
                              would cause it to violate a court order or other
                              legal requirement). You are required to respond to
                              third party requests about your Content, and we
                              may provide your contact information to third
                              parties, and suspend or terminate your access to
                              the Service and your Content, if you fail to
                              respond to such third party requests.{' '}
                            </li>
                            <li>
                              .6 Clients will own all right, title and interest
                              in and to all Purchased Content selected within
                              the Review Period, and Content Providers hereby
                              assign to each such Client all present and future
                              right, title and interest in and to the applicable
                              Purchased Content; provided that Content Providers
                              shall retain a limited right to display such
                              Purchased Content as part of its portfolio. Upon a
                              Client&rsquo;s request and at such Client&rsquo;s
                              cost, Content Providers will execute and deliver
                              to such Client all documents necessary to perfect
                              such Client&rsquo;s right, title and interest in
                              and to applicable Purchased Content. In the event
                              that Client does not elect to purchase Submitted
                              Content within the Review Period, (i) the
                              applicable Content Provider shall retain all
                              right, title and interest in and to such Submitted
                              Content; and (ii)&nbsp;such Client shall have no
                              right to access or use any Submitted Content that
                              is not Purchased Content within the Review Period;
                              provided that, in the event that such Submitted
                              Content includes any Client Product, Content
                              Provider may not publish, distribute or disclose
                              such Submitted Content to any third party within
                              the six (6) month period following the conclusion
                              of the applicable Review Period.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Feedback.</strong>
                          <ol>
                            <li>
                              .1 If you send or transmit any communications or
                              materials to Authentic by mail, electronic mail,
                              telephone, or otherwise (&ldquo;
                              <strong>Feedback</strong>&rdquo;), suggesting or
                              recommending changes to our Site or Service,
                              including without limitation, new features or
                              functionality relating thereto, any comments,
                              questions, suggestions, or the like, all such
                              Feedback are, and will be treated as,
                              non-confidential and non-proprietary. This means
                              that you give up any claim that the use of such
                              Feedback by Authentic or its agents, violates any
                              of your rights including moral rights, privacy
                              rights, proprietary or other property rights,
                              rights of publicity, rights to credit for material
                              or ideas, or any other right, including the right
                              to approve the way Authentic uses such Feedback.{' '}
                            </li>
                            <li>
                              .2 You hereby assign all right, title, and
                              interest in, and Authentic is free to use, without
                              any attribution or compensation to you, any ideas,
                              know-how, concepts, techniques, or other
                              intellectual property rights contained in the
                              Feedback, whether or not patentable, for any
                              purpose whatsoever, including but not limited to,
                              developing, manufacturing, having manufactured,
                              licensing, marketing, and selling, directly or
                              indirectly, products and services using such
                              Feedback. You agree and understand that Authentic
                              is not obligated to use, display, reproduce, or
                              distribute any such ideas, know-how, concepts, or
                              techniques contained in the Feedback, and you have
                              no right to compel such use, display,
                              reproduction, or distribution.{' '}
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Copyright Infringement. </strong>
                          <ol>
                            <li>
                              .1 Authentic respects the intellectual property
                              and other proprietary rights of others and has a
                              policy of removing Content from the Site that
                              infringes third party copyrights and suspending
                              and/or terminating the Account of any user who
                              uses the Site or Service in violation of copyright
                              law, and where appropriate, blocking such
                              user&rsquo;s access to the Site and Service.{' '}
                            </li>
                            <li>
                              .2 If you are a copyright owner, or an agent
                              thereof, and believe that your work is the subject
                              of copyright infringement and appears on our Site,
                              or is being used through the Service, you may
                              submit a notification pursuant to the Digital
                              Millennium Copyright Act (&ldquo;
                              <strong>DMCA</strong>
                              &rdquo;) by providing our copyright agent
                              (designated below) with the following information
                              in writing: (a) your physical or electronic
                              signature; (b) identification of the copyrighted
                              work claimed to have been infringed; (c)
                              identification of the material that is claimed to
                              be infringing or to be the subject of infringing
                              activity and that is to be removed or access to
                              which is to be disabled, and information
                              reasonably sufficient to permit Authentic to
                              locate such material; (d) information reasonably
                              sufficient to permit Authentic to contact you,
                              such as an address, telephone number, and, if
                              available, an e-mail address; (e) a statement that
                              you have a good faith belief that use of the
                              material in the manner complained of is not
                              authorized by the copyright owner, its agent, or
                              the law; and (f) a statement that the information
                              in the notification is accurate, and under penalty
                              of perjury, that you are, or that you are
                              authorized to act on behalf of, the owner of an
                              exclusive right that is allegedly infringed.
                              Authentic&rsquo;s designated copyright agent to
                              receive notifications of claimed infringement
                              under the DMCA is:{' '}
                              <blockquote>
                                Authentic LLC
                                <br />
                                Address: 3817 NE 19th Avenue, Portland, OR 97212
                                <br />
                                Tel: 847-207-1735
                                <br />
                                E-mail: erin@authenticpx.com
                              </blockquote>
                            </li>

                            <li>
                              .3 Only DMCA or other intellectual property
                              rights-related notices should be sent to the
                              copyright agent; any other communications sent to
                              the copyright agent will be destroyed.{' '}
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>
                            Client Products; Exclusion of Warranties.{' '}
                          </strong>
                          <ol>
                            <li>
                              .1 Content Providers acknowledge and agree that
                              NEITHER Authentic NOR CLIENTS ARE responsible FOR
                              in any way, OR SHALL HAVE ANY LIABILITY IN
                              CONNECTION WITH, THE QUALITY OF ANY CLIENT
                              PRODUCTS PROVIDED BY A CLIENT.{' '}
                            </li>
                            <li>
                              .2 YOUR USE OF THE SITE AND SERVICE IS ENTIRELY AT
                              YOUR OWN DISCRETION AND RISK. THE SITE AND SERVICE
                              ARE FURNISHED TO YOU &ldquo;AS IS&rdquo; AND
                              WITHOUT WARRANTIES OR CONDITIONS, STATUTORY OR
                              OTHERWISE, OF ANY KIND. Authentic, on behalf of
                              itself, and ON BEHALF OF ITS PARENTS, AFFILIATES,
                              SUBSIDIARIES, licensors AND third party SERVICE
                              PROVIDERS, AND AUTHENTIC&rsquo;S AND THEIR
                              DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS,
                              AGENTS, SUCCESSORS, AND ASSIGNS, (collectively,
                              the &ldquo;
                              <strong>AUTHENTIC parties</strong>
                              &rdquo;): (A) EXPRESSLY DISCLAIMs ALL WARRANTIES
                              AND CONDITIONS, WHETHER EXPRESS, IMPLIED OR
                              STATUTORY, INCLUDING, WITHOUT LIMITATION, THE
                              IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE,
                              MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
                              PURPOSE; (B) DOes NOT WARRANT THAT THE SERVICE, or
                              data provided through the service, WILL MEET YOUR
                              REQUIREMENTS, or that ITS OPERATION WILL BE
                              timely, UNINTERRUPTED, secure, OR ERROR-FREE OR
                              THAT ANY DEFECTS WILL BE CORRECTED; AND (C) DOes
                              NOT WARRANT OR MAKE ANY REPRESENTATIONS OR
                              CONDITIONS REGARDING THE USE OR THE RESULTS OF THE
                              USE OF THE SERVICE IN TERMS OF ITS ACCURACY,
                              RELIABILITY, timeliness, completeness, OR
                              OTHERWISE. YOU ASSUME TOTAL RESPONSIBILITY FOR
                              YOUR USE OF THE SERVICE.{' '}
                            </li>
                            <li>
                              .3 THIS LIMITATION OF REMEDIES IS A PART OF THE
                              BARGAIN BETWEEN YOU AND Authentic. NO ORAL OR
                              WRITTEN INFORMATION OR ADVICE GIVEN BY Authentic
                              or ANY PERSON ON BEHALF OF Authentic SHALL CREATE
                              A WARRANTY OR CONDITION, OR IN ANY WAY CHANGE THIS
                              EXCLUSION OF WARRANTY.{' '}
                            </li>
                            <li>
                              .4 NOTHING IN THESE TERMS, THIS SECTION 10, OR
                              SECTION 11 BELOW, SHALL EXCLUDE OR LIMIT
                              AUTHENTIC&rsquo;S WARRANTY OR LIABILITY FOR LOSSES
                              WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY
                              APPLICABLE LAW.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Limitation of Liability.</strong>
                          <strong> </strong>
                          <ol>
                            <li>
                              .1 SUBJECT TO SECTION 10.4 ABOVE, IN NO EVENT WILL
                              Authentic OR Authentic PARTIES BE LIABLE TO YOU,
                              OR ANY THIRD PARTY, FOR ANY SPECIAL, INDIRECT,
                              INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR
                              CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, BUT
                              NOT LIMITED TO, COMPENSATION, REIMBURSEMENT OR
                              DAMAGES IN CONNECTION WITH, ARISING OUT OF, OR
                              RELATING TO, THE USE, OR LOSS OF USE OF, THE
                              SERVICE, LOSS OF PROFITS, LOSS OF GOODWILL, LOSS
                              OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS
                              OR SERVICES, SUBSEQUENT OR OTHER COMMERCIAL LOSS,
                              OR FOR ANY OTHER REASON OF ANY KIND, WHETHER BASED
                              ON CONTRACT OR TORT (INCLUDING, WITHOUT
                              LIMITATION, NEGLIGENCE OR STRICT LIABILITY), EVEN
                              IF Authentic OR Authentic PARTIES HAVE BEEN
                              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.{' '}
                            </li>
                            <li>
                              .2 Authentic AND Authentic PARTIES WILL NOT BE
                              LIABLE TO YOU OR ANY THIRD PARTY FOR DAMAGES FOR
                              BREACH OF ANY EXPRESS OR IMPLIED WARRANTY OR
                              CONDITION, BREACH OF CONTRACT, NEGLIGENCE, STRICT
                              LIABILITY OR ANY OTHER LEGAL THEORY RELATED TO THE
                              SITE OR SERVICE. IF, NOTWITHSTANDING THE
                              FOREGOING, Authentic OR ONE OF Authentic PARTIES
                              IS FOUND TO BE LIABLE TO YOU OR ANY THIRD PARTY
                              FOR ANY DAMAGE OR LOSS WHICH ARISES UNDER OR IN
                              CONNECTION WITH THESE TERMS OR THE SERVICE,
                              Authentic OR THE RELEVANT Authentic PARTY&rsquo;S
                              TOTAL CUMULATIVE LIABILITY TO YOU OR ANY THIRD
                              PARTY SHALL IN NO EVENT EXCEED THE GREATER OF (I)
                              THE AMOUNT OF FEES ACTUALLY PAID BY OR TO YOU
                              DURING THE ONE (1) YEAR PERIOD PRECEDING THE DATE
                              OF THE EVENT GIVING RISE TO AUTHENTIC&rsquo;S
                              LIABILITY OR (II) $250.00.{' '}
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Indemnification &amp; Release</strong>.
                          <ol>
                            <li>
                              .1 You hereby agree to indemnify, defend and hold
                              harmless Authentic and Authentic Parties (each an
                              &ldquo;
                              <strong>Indemnified Party</strong>&rdquo;), from
                              and against any and all liability and costs
                              (including, without limitation, attorneys&rsquo;
                              fees and costs) incurred by the Indemnified
                              Party(s) in connection with any actual or alleged
                              claim arising out of: (a) your use of the Service;
                              (b) any breach or alleged breach by you of these
                              Terms; (c) any Feedback provided by you; (d) any
                              breach or alleged breach by you of a third
                              party&rsquo;s rights, including, without
                              limitation, any intellectual property, privacy or
                              publicity rights; or (e) any damage caused by or
                              alleged to have been caused by you to the Site or
                              Service.{' '}
                            </li>
                            <li>
                              .2 Counsel you select for defense or settlement of
                              a claim must be consented to by Authentic and/or
                              the Indemnified Party(s) prior to counsel being
                              engaged to represent you and Authentic and/or the
                              Indemnified Party(s). You and your counsel will
                              cooperate as fully as reasonably required, and
                              provide such information as reasonably requested,
                              by the Indemnified Party(s) in the defense or
                              settlement of any claim. Authentic and/or the
                              Indemnified Party(s) reserves the right, at its
                              own expense, to assume the exclusive defense or
                              settlement, and control of any matter otherwise
                              subject to indemnification by you. You shall not
                              in any event consent to any judgment, settlement,
                              attachment, or lien, or any other act adverse to
                              the interest of Authentic or any Indemnified
                              Party(s) without the prior written consent of
                              Authentic and/or the Indemnified Party(s).
                            </li>
                            <li>
                              .3 In the event that you have a dispute with any
                              Client, Content Provider or other third party,
                              including without limitation any other User,
                              arising from or in connection with the use of the
                              Site and/or Service, you hereby release, remise
                              and forever discharge Authentic and its agents,
                              directors, officers, employees, shareholders and
                              all other related persons or entities from any and
                              all manner of rights, complaints, demands, claims,
                              causes of action, proceedings, obligations,
                              liabilities, legal fees, costs, and disbursements
                              of any nature whatsoever, whether known or
                              unknown, which now or hereafter arise from, relate
                              to, or are connected with such dispute or your use
                              of the Site and/or Service. If you are a
                              California resident, you hereby waive California
                              civil code section 1542, which says: &ldquo;a
                              general release does not extend to claims which
                              the creditor does not know or suspect to exist in
                              his favor at the time of executing the release,
                              which, if known by him must have materially
                              affected his settlement with the debtor.&rdquo; If
                              you are a resident of a state other than
                              California, you explicitly waive the terms and
                              protections of any statute of your own state that
                              has a similar import or intent.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Changes to Service.</strong>
                          <p>
                            Authentic is constantly striving to provide the best
                            possible experience for its Service users. You
                            acknowledge and agree that the form and nature of
                            the Service which Authentic currently provides may
                            change from time-to-time without prior notice to
                            you. Changes to the form and nature of the Service
                            will be effective with respect to all versions of
                            the Service. Examples of changes to the form and
                            nature of the Service include, without limitation,
                            changes to fees and payment policies, security
                            patches, additional functionality, reduced
                            functionality, and other enhancements.
                          </p>
                        </li>
                        <li>
                          <strong>Termination of Service. </strong>
                          <ol>
                            <li>
                              .1 You may stop using the Service at any time by
                              closing your Account, or ceasing to use the
                              Service.{' '}
                            </li>
                            <li>
                              .2 Authentic reserves the right in its sole
                              discretion to cease or suspend providing all or
                              any part of the Service immediately without any
                              notice to you.{' '}
                            </li>
                            <li>
                              .3 Any of your obligations under these Terms which
                              by their nature are intended to survive the
                              termination of your use of the Service, shall
                              continue to apply to you after you cease to use
                              the Service.{' '}
                            </li>
                            <li>
                              .4 Authentic may notify the relevant law
                              enforcement authorities or other third parties, of
                              any illegal or other prohibited conduct by you,
                              including, without limitation, your violation of
                              these Terms or unauthorized use of the Site or
                              Service{' '}
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Governing Law and Venue. </strong>
                          <ol>
                            <li>
                              .1 These Terms will be construed and enforced in
                              all respects in accordance with the laws of the
                              state of Oregon, without reference to its choice
                              of law rules. Except as set forth below in Section
                              15.2, the federal and state courts seated in
                              Multnomah County, Oregon shall have sole and
                              exclusive jurisdiction for all purposes in
                              connection with any action or proceeding that
                              arises from, or relates to, these Terms and you
                              hereby irrevocably waive any objection to such
                              exclusive jurisdiction; provided however, that
                              Authentic may seek to enforce any judgment in its
                              favor in any court of competent jurisdiction.
                            </li>
                            <li>
                              .2 Notwithstanding the foregoing, Authentic may
                              seek injunctive or other equitable relief in any
                              court of competent jurisdiction to protect its
                              proprietary and other rights. You agree that your
                              breach of these Terms may result in immediate and
                              irreparable damage to Authentic for which there is
                              no adequate remedy at law.{' '}
                            </li>
                            <li>
                              .3 The United Nations Convention on Contracts for
                              the International Sale of Goods in its entirety is
                              expressly excluded from these Terms, including,
                              without limitation, application to the Site or
                              Service. Furthermore, these Terms (including
                              without limitation, the Site and Service) will not
                              be governed or interpreted in any way by referring
                              to any law based on the Uniform Computer
                              Information Transactions Act (UCITA) or any other
                              act derived from or related to UCITA.
                            </li>
                            <li>
                              .4 Any cause of action arising under these Terms
                              must be commenced by you within one (1) year after
                              the claim or cause of action arises.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <strong>Miscellaneous Legal Terms. </strong>
                          <ol>
                            <li>
                              .1 These Terms and the Privacy Policy, together
                              constitute the entire agreement between you and
                              Authentic with respect to the Service (excluding
                              any services which Authentic may provide to you
                              under a separate written agreement), and
                              completely supersedes, cancels and replaces any
                              and all other written or oral agreements or
                              understandings previously existing between you and
                              Authentic with respect to the Service.{' '}
                            </li>
                            <li>
                              .2 The failure of Authentic to exercise or enforce
                              any right or provision of these Terms shall not
                              constitute a waiver of such right or provision.{' '}
                            </li>
                            <li>
                              .3 If any part of these Terms is held invalid,
                              illegal or unenforceable, that provision shall be
                              enforced to the maximum extent permissible so as
                              to maintain the intent of these Terms, and the
                              other parts will remain in full force and effect.{' '}
                            </li>
                            <li>
                              .4 Any notice or other communications by Authentic
                              relating to the Service may be made by letter,
                              e-mail or posting on the Site, and you hereby
                              consent to receive notices and other
                              communications in electronic form to the extent
                              permitted by applicable law.{' '}
                            </li>
                            <li>
                              .5 These Terms shall not be interpreted or
                              construed to confer any rights or remedies on any
                              third parties, except that Clients shall be a
                              third party beneficiary hereunder and accordingly,
                              shall be entitled to directly enforce against
                              applicable Content Providers and rely upon any
                              provision of these Terms related to intellectual
                              property rights in any Purchased Content.{' '}
                            </li>
                            <li>
                              .6 Authentic may assign or transfer its rights, or
                              delegate any performance, under these Terms to a
                              third party in its sole discretion. You may not
                              assign or otherwise transfer your rights, or
                              delegate your performance, under these Terms to
                              any third party without in each and every case,
                              Authentic&rsquo;s express prior written consent.{' '}
                            </li>
                            <li>
                              .7 Authentic will not be liable for failing or
                              delaying performance of its obligations resulting
                              from any condition beyond its reasonable control,
                              including but not limited to, governmental action,
                              acts of the common enemy, earthquake, fire, flood
                              or other acts of God, the elements, epidemics,
                              labor conditions, power failures, and Internet
                              disturbances.
                            </li>
                            <li>
                              .8 Authentic may take any legal action against you
                              to enforce these Terms or to prevent the breach of
                              these Terms, including, without limitation,
                              seeking equitable remedies or using technical
                              means at its disposal.
                            </li>
                            <li>
                              .9 Authentic may add to, change or remove any
                              part, term or condition of these Terms or Privacy
                              Policy at any time without prior notice to you. It
                              is your responsibility to check these Terms and
                              Privacy Policy periodically for changes. By
                              continuing to use the Service, you are indicating
                              your acceptance of such changes. However, we will
                              provide written notice to you of any significant
                              changes to these Terms or Privacy Policy
                              (including notices posted on the Site or sent to
                              your registered e-mail address).{' '}
                            </li>
                            <li>
                              .10 You may not access the Service if you are a
                              direct competitor of Authentic&rsquo;s, except
                              with our prior written consent. In addition, you
                              may not access the Service for purposes of
                              monitoring its availability, performance or
                              functionality, or for any other benchmarking or
                              competitive purposes.{' '}
                            </li>
                          </ol>
                        </li>
                      </section>
                      <div className="inline-flex">
                        <label
                          htmlFor="Terms"
                          className="my-10 flex items-center text-gray-500 dark:text-white"
                        >
                          <input
                            id="Terms"
                            className="float-left mr-4 h-5 w-5 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition duration-200  checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none  dark:bg-slate-800 dark:checked:bg-sky-300"
                            type="checkbox"
                            value=""
                            disabled={agreed}
                            onChange={() => setTermsAccepted(!termsAccepted)}
                            defaultChecked={termsAccepted}
                          />
                          I Agree To Terms Of Use.
                        </label>
                        <Link
                          to="/authentic-Terms-of-Service.pdf"
                          target="_blank"
                          className="ml-10 self-center rounded-2xl border bg-sky-400 py-2 px-6 text-white transition-all duration-150 hover:opacity-75"
                          download
                        >
                          Click to download
                        </Link>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
