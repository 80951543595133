import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { myContext } from '../../components/login-detect/LoginDetect';

export default function Auth() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const { authenticated, setAuthenticated } = useContext(myContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.roles?.includes('ROLE_BRAND')) {
      setAuthenticated('brand');
      if (currentUser.onboardFinish) {
        navigate(searchParams.get('redirect') ?? '/dashboard');
      } else {
        navigate('/ProfileOnboardingBrand');
      }
    }

    if (currentUser?.roles?.includes('ROLE_CREATOR')) {
      setAuthenticated('creator');

      if (currentUser.onboardFinish) {
        navigate(searchParams.get('redirect') ?? '/Profile');
      } else {
        navigate('/ProfileOnboarding');
      }
    }

    if (currentUser?.roles?.includes('ROLE_ADMIN')) {
      setAuthenticated('admin');
      navigate('/admin');
    }
  }, [currentUser]);

  useEffect(() => {
    document.title = 'Authentic | Auth';
  }, []);

  return <div />;
}
