import React, { useCallback, useEffect, useState } from 'react';
import Masonry from 'react-responsive-masonry';
import axios from 'axios';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';
import { ModalPreviewAddCart } from '../modal-preview-add-cart/ModalPreviewAddCart';

type Props = {
  data: any;
  setProjectCart: any;
  projectCart: any;
  photoBuy: any;
  setPhotoBuy: any;
  // eslint-disable-next-line react/require-default-props
  projectId?:any;
};

export function MasonryPhotographers(props: Props) {
  const { data, projectCart, setProjectCart, setPhotoBuy, photoBuy, projectId } = props;
  let masonryCounter = 1;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [previewUrlIndex, setPreviewUrlIndex] = useState<number>(0);

  // For showing smaller images in center column of masonry
  function resetMasonryCounter() {
    if (masonryCounter >= 2) {
      masonryCounter = 1; // Reset !
    }
  }
  for (let i = 0; i < data?.length; i += 1) {
    if (window.innerWidth > 700) {
      if (masonryCounter === 0 || masonryCounter === 2) {
        data[i].masonryHeight = 'h-[37.5rem]';
        masonryCounter += 1;
        resetMasonryCounter();
      } else {
        // 480px was the default
        data[i].masonryHeight = 'h-[17.510rem]';
        masonryCounter += 1;
      }
    } else {
      data[i].masonryHeight = 'h-[27.625rem]';
    }
  }

  const [cols, setCols] = useState(3);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setCols(1);
    }
  }, []);

  const addToCart = useCallback(
    async (index: any) => {
      const route = `${SERVER_URL}addToCart`;

      await axios
        .post(route, {
          photo: data[index],
        })
        .then((res) => {
          if (res.status === 201) {
            setProjectCart(res.data);
          }
          return res.data;
        })
        .catch(() => { });
    },
    [data, setProjectCart],
  );

  function deleteFromShare(index: any) {
    const temp = [...photoBuy];
    temp[index].isShare = false;
    setPhotoBuy(temp);
    updateProjectShareFavoriteImages(temp);
  }

  function addToShare(index: any) {
    const temp = [...photoBuy];
    temp[index].isShare = true;
    setPhotoBuy(temp);
    updateProjectShareFavoriteImages(temp);
  }

  const updateProjectShareFavoriteImages = async (array: any) => {
    const information = {
      images: array,
      projectId
    };

    const route = `${SERVER_URL}updateProjectShareFavoriteImages`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        information,
      })
      .then((res) => {
        if (res.status === 201) {
          //
        }
        return res.data;
      })
      .catch(() => { });
  }

  return (
    <div>
      <ModalPreviewAddCart
        toggle={setIsOpenModal}
        resetIndex={setPreviewUrlIndex}
        isOpenModal={isOpenModal}
        previewUrlIndex={previewUrlIndex}
        data={data}
        addToCart={addToCart}
      />

      <Masonry columnsCount={cols} gutter="4.48vh">
        {data?.map((element: any, index: any) => (
          <div
            key={Math.floor(Math.random() * (56468151 - 2 + 1) + 2)}
            className={`relative rounded-2xl shadow hover:opacity-90 md:mb-0 ${element.masonryHeight} ${element.marginBottom} w-[21.875rem] md:w-full`}
          >
            <div className="absolute grid h-full w-full grid-cols-2 place-content-between">
              {element.isShare ? (
                <div
                  className="z-20"
                  role="button"
                  tabIndex={0}
                  onClick={() => deleteFromShare(index)}
                >
                  <img
                    src="./img/heartTrue.png"
                    alt=""
                    className="z-20 ml-5 mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80 md:h-[4.26vh] md:w-[2.08vw]"
                  />
                </div>
              ) : (
                <div
                  className="z-20"
                  role="button"
                  tabIndex={0}
                  onClick={() => addToShare(index)}
                >
                  <img
                    src="./img/heartFalse.png"
                    alt=""
                    className="z-20 ml-5 mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80 md:h-[4.26vh] md:w-[2.08vw]"
                  />
                </div>
              )}
              {!element.downloadable && element.inCart && (
                <div
                  className="z-20"
                // role="button"
                // tabIndex={0}
                // onClick={() => addToCart(index)}
                >
                  <img
                    src="./img/buyTrue.png"
                    alt=""
                    className="absolute right-5 z-10 mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80 md:ml-[6vw] md:h-[4.26vh] md:w-[2.08vw]"
                  />
                </div>
              )}
              {!element.downloadable && !element.inCart && (
                <div
                  className="z-20"
                  role="button"
                  tabIndex={0}
                  onClick={() => addToCart(index)}
                >
                  <img
                    src="./img/buyFalse.png"
                    alt=""
                    className="absolute right-5 z-10 mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80 md:ml-[6vw] md:h-[4.26vh] md:w-[2.08vw]"
                  />
                </div>
              )}
              <div />

              {element.downloadable && (
                <div
                  role="button"
                  tabIndex={0}
                  className="z-30"
                  onClick={() =>
                    window.open(
                      `${BUCKET_IMAGES}original/${element?.projectId}/${element?.imageUrl}`,
                    )
                  }
                >
                  <img
                    src="./img/downloadTrue.png"
                    alt=""
                    className="absolute bottom-0 right-5 ml-[6.25rem] mb-2 h-10 w-10 hover:cursor-pointer hover:opacity-80 md:ml-[6vw] md:h-[4.26vh] md:w-[2.08vw]"
                  />
                </div>
              )}

              {/* <img
                src="./img/downloadTrue.png"
                alt=""
                className="z-10 ml-[6.25rem] mb-2 h-10 w-10 hover:cursor-pointer hover:opacity-80 md:ml-[6vw] md:h-[4.26vh] md:w-[2.08vw]"
              /> */}
            </div>

            <div
              onClick={() => {
                setPreviewUrlIndex(index);
                setIsOpenModal(true);
              }}
              role="button"
              tabIndex={0}
              className="relative h-full w-full rounded-2xl hover:cursor-pointer hover:opacity-90"
            >
              <img
                src={`${BUCKET_IMAGES}${element.downloadable ? 'original' : 'watermark'
                  }/${element.projectId}/${element?.imageUrl}`}
                alt=""
                className="absolute h-full w-full rounded-2xl object-cover hover:opacity-90"
              />
            </div>
          </div>
        ))}
      </Masonry>
    </div>
  );
}
