import React, { useRef, useState, useEffect } from 'react';
import { TermsAndConditions } from 'components/terms-and-conditions';
import { BUCKET_IMAGES } from '../../common/CONFIG';

type PropsProfileBrand = {
  user: any;
  onChangeBrandProfileUserInfo: any;
  onChangeBrandProfileExtra: any;
  brandExtraInfo: any;
  userInfo: any;
  onChangeAvatar: any;
  avatarPhoto: any;
};

export function ProfileDetailsBrand(props: PropsProfileBrand) {
  const {
    user,
    onChangeBrandProfileUserInfo,
    onChangeBrandProfileExtra,
    userInfo,
    brandExtraInfo,
    onChangeAvatar,
    avatarPhoto,
  } = props;

  const focusNameInput = () => {
    nameInput!.current!.focus();
  };
  const focusAboutInput = () => {
    aboutInput!.current!.focus();
  };

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  const privacy: any = useRef();
  const terms: any = useRef();

  const nameInput = useRef<HTMLInputElement>(null);
  const aboutInput = useRef<HTMLTextAreaElement>(null);
  const fileUpload = useRef<HTMLInputElement>(null);
  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  return (
    <section className="bg-gray-100 text-gray-500 dark:bg-slate-900 dark:text-white">
      <div className="mx-auto w-10/12 pt-10">
        <h1 className="text-2xl font-semibold text-gray-600 dark:text-white ">
          Profile
        </h1>

        <section className="mx-auto flex w-full flex-col-reverse md:flex-row">
          <div className="grid w-9/12 ">
            <div className="mt-5 flex items-center">
              Company Name
              <img
                onClick={focusNameInput}
                onKeyDown={focusNameInput}
                src="./img/write.png"
                alt=""
                className="ml-3 h-5 w-6 place-self-center hover:cursor-pointer hover:opacity-80"
              />
            </div>
            <br />
            <input
              className="h-[70px] w-[75%] rounded-2xl border border-gray-300  p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
              // className={`my-1 mt-1 mr-2 block  h-12 w-80 bg-gray-100 py-2 text-3xl font-bold text-gray-600 placeholder:text-gray-600 hover:cursor-pointer focus:outline-none dark:bg-slate-900  dark:text-white dark:placeholder:text-white md:w-96 `}
              placeholder="Company Name"
              type="text"
              name="profileName"
              maxLength={50}
              ref={nameInput}
              value={brandExtraInfo?.companyName}
              onChange={(e) =>
                onChangeBrandProfileExtra('companyName', e.target.value)
              }
            />

            <div className="mt-5 flex items-center">
              Company Bio
              <img
                onClick={focusAboutInput}
                src="./img/write.png"
                alt=""
                className="ml-3 h-5 w-6 place-self-center hover:cursor-pointer hover:opacity-80"
              />
            </div>
            <br />
            <textarea
              className="h-[300px] rounded-2xl border border-gray-300 p-6  text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500 dark:bg-slate-400   dark:placeholder:text-white md:w-9/12"
              // className="mt-1  min-h-[200px] resize-none overflow-y-auto bg-gray-100 py-3 hover:cursor-pointer focus:outline-none dark:bg-slate-900 dark:placeholder:text-white"
              placeholder="Company Bio"
              ref={aboutInput}
              maxLength={2000}
              value={brandExtraInfo?.bio}
              onChange={(e) => onChangeBrandProfileExtra('bio', e.target.value)}
            />
          </div>
          <input
            onChange={(e) => onChangeAvatar(e)}
            type="file"
            ref={fileUpload}
            accept="image/*"
            className="hidden"
          />

          <div
            role="button"
            tabIndex={0}
            onClick={clickFileUpload}
            className="grid w-[250px] md:ml-20  md:w-[23.43vw]"
          >
            <img
              src={`${BUCKET_IMAGES}avatar/${userInfo?.username}/${brandExtraInfo?.avatarUrl}`}
              alt="profilePicture"
              className="ml-[40px] inline h-60 w-60 self-center rounded-full object-cover hover:cursor-pointer hover:border hover:shadow-md md:ml-0 md:h-[22vw] md:w-[25vw]"
            />
          </div>
        </section>
        <div className="pt-20">
          <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
            {' '}
            Company Information{' '}
          </h1>
          <section className="mt-10 grid h-20 w-full gap-5">
            <div className="grid gap-5 md:grid-cols-2">
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300  p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="Company web"
                value={brandExtraInfo?.companyWeb}
                onChange={(e) =>
                  onChangeBrandProfileExtra('companyWeb', e.target.value)
                }
              />
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="Company FaceBook"
                value={brandExtraInfo?.companyFB}
                onChange={(e) =>
                  onChangeBrandProfileExtra('companyFB', e.target.value)
                }
              />
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300  p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="Company Instagram"
                value={brandExtraInfo?.companyIG}
                onChange={(e) =>
                  onChangeBrandProfileExtra('companyIG', e.target.value)
                }
              />
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="Company E-Mail"
                value={userInfo?.email}
                onChange={(e) =>
                  onChangeBrandProfileUserInfo('email', e.target.value)
                }
              />
            </div>
          </section>
        </div>
        <div className="mt-20 pt-20">
          <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
            {' '}
            User Information{' '}
          </h1>
          <section className="mt-10 grid h-20 w-full gap-5">
            <div className="grid gap-5 md:grid-cols-2">
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300  p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="First Name"
                value={userInfo?.first_name}
                onChange={(e) =>
                  onChangeBrandProfileUserInfo('first_name', e.target.value)
                }
              />
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="Last Name"
                value={userInfo?.last_name}
                onChange={(e) =>
                  onChangeBrandProfileUserInfo('last_name', e.target.value)
                }
              />
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300  p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="Title"
                value={brandExtraInfo?.title}
                onChange={(e) =>
                  onChangeBrandProfileExtra('title', e.target.value)
                }
              />
              <input
                type="text"
                className="h-[70px] w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
                id=""
                placeholder="E-Mail"
                value={userInfo?.email}
                onChange={(e) =>
                  onChangeBrandProfileUserInfo('email', e.target.value)
                }
              />
            </div>
          </section>
        </div>
        <div className="mt-20 py-20 ">
          <hr className="my-10 border border-slate-300 dark:border-slate-100" />
          <TermsAndConditions
            agreed
            terms={terms}
            privacy={privacy}
            setAcceptTerms={setAcceptTerms}
            setAcceptPrivacy={setAcceptPrivacy}
          />
        </div>
      </div>
    </section>
  );
}
