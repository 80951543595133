import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { ImageUpload } from '../image-upload/ImageUpload';

type Props = {
  setBriefDetails: any;
  briefDetails: any;
  setProductImages: any;
  productImages: any;
  editMode: any;
  productName: any;
  productDesc: any;
  productImagesRef: any;
};

export function ProductDetailsAndInformation(props: Props) {
  const {
    briefDetails,
    setBriefDetails,
    setProductImages,
    productImages,
    editMode,
    productName,
    productDesc,
    productImagesRef,
  } = props;

  const onChangeBriefDetails = (concept: string, value: string) => {
    const tempstate = { ...briefDetails, [concept]: value };
    setBriefDetails(tempstate);
  };

  return (
    <section className="mx-auto w-10/12">
      <div className="flex align-middle">
        <h1 className="text-gray-700 dark:text-white">
          Product Details & Information
        </h1>
        <span
          data-tip
          data-for="homeToolTip"
          className="mx-2 cursor-pointer self-center text-xs text-gray-400 dark:text-white"
        >
          <FontAwesomeIcon className="" icon={faQuestionCircle} />
        </span>
        <ReactTooltip
          id="homeToolTip"
          type="info"
          effect="solid"
          className="tooltip"
          place="right"
          arrowColor="#54a3f5"
        >
          If you want multiple products featured, please provide details of each
          one
        </ReactTooltip>
      </div>
      <section className="grid md:flex">
        <div className="mr-6 w-[21.875rem]  md:w-7/12">
          <input
            ref={productName}
            type="text"
            className="my-3 w-full rounded-xl px-5 py-6 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-600 dark:text-white dark:placeholder:text-slate-300"
            placeholder="Product Name"
            value={briefDetails?.productName}
            onChange={(e) =>
              onChangeBriefDetails('productName', e.target.value)
            }
          />
          <input
            ref={productDesc}
            type="text"
            className="my-3 w-full rounded-xl px-5 py-6 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-600 dark:text-white dark:placeholder:text-slate-300"
            placeholder="Product Description and Color"
            value={briefDetails?.productDescription}
            onChange={(e) =>
              onChangeBriefDetails('productDescription', e.target.value)
            }
          />
        </div>
        <div className="min:h-72 w-[21.875rem]  md:w-[43.75rem]">
          <ImageUpload
            productImagesRef={productImagesRef}
            setProductImages={setProductImages}
            productImages={productImages}
            editMode={editMode}
          />
        </div>
      </section>
    </section>
  );
}
