const { REACT_APP_ENV } = process.env;

let serverUrl = 'https://api2.authenticpx.com/';

if (REACT_APP_ENV === 'local') {
  serverUrl = 'http://localhost:8080/';
} else if (REACT_APP_ENV === 'development') {
  serverUrl = 'https://dev2.authenticpx.com/';
}

export const SERVER_URL = serverUrl;
export const BUCKET_IMAGES =
  REACT_APP_ENV !== 'production'
    ? 'https://authentic-api-v2-dev.s3.amazonaws.com/'
    : 'https://authentic-api-v2.s3.amazonaws.com/';
export const USERS_IMAGES_URL =
  // eslint-disable-next-line no-nested-ternary
  REACT_APP_ENV === 'local'
    ? 'http://localhost:8080/uploads/'
    : REACT_APP_ENV === 'development'
    ? 'https://dev2.authenticpx.com/uploads/'
    : 'https://api2.authenticpx.com/uploads/';
export const PAYD_IMAGES = USERS_IMAGES_URL;
