import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { store } from 'common/redux';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
reportWebVitals();
