import React, { useEffect, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import './style.scss';

type Props = {
  state: boolean;
  privacyAccepted: boolean;
  setPrivacyAccepted: Function;
  agreed: boolean;
};

export function PrivacyPolicyModal(props: Props) {
  const { state, privacyAccepted, setPrivacyAccepted, agreed } = props;
  const [isOpen, setIsOpen] = useState(state);

  useEffect(() => {
    setIsOpen(true);
  }, [state]);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
              <section className="m-auto w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div
                  className=" scrollbar mx-auto mt-10 h-[600px] overflow-y-scroll dark:bg-slate-800 md:pr-10"
                  id="style-1"
                >
                  <section className="z-0 flex w-full ">
                    <div className="z-[10] w-full px-4 text-gray-500 dark:text-white md:w-[3/4]">
                      <h1 className="text-lg font-bold md:text-xl">
                        PRIVACY POLICY
                      </h1>
                      <br />
                      <p>
                        Your privacy is important to us and this policy
                        represents our commitment to safeguard and protect your
                        personal information. This policy governs use of the{' '}
                        <em>Authentic</em> website (the &ldquo;Site&rdquo;),
                        which is provided by Authentic LLC
                        (&ldquo;Company,&rdquo; &ldquo;we,&rdquo;
                        &ldquo;us,&rdquo; or &ldquo;our&rdquo;). This policy
                        (the &ldquo;Privacy Policy&rdquo;) governs our
                        collection of information from you (&ldquo;you,&rdquo;
                        &ldquo;your,&rdquo; or a &ldquo;user&rdquo;) through
                        your use of the Site. It does not apply to any
                        information you may provide to us through other means,
                        including via mail or telephone. Please read this
                        Privacy Policy carefully so that you understand our
                        online privacy practices.{' '}
                      </p>
                      <br />
                      <p>
                        By visiting and using the Site you agree that your
                        visit, and any dispute over our online privacy
                        practices, is governed by this Privacy Policy.
                      </p>
                      <br />
                      <p>
                        <strong>TYPES OF INFORMATION WE COLLECT.</strong>
                        &nbsp;We use the information gathered to provide the
                        services you request, to better understand your needs,
                        to improve the contents of our services, and to monitor
                        technical performance of our network. The information we
                        gather falls into two categories: (1) information
                        voluntarily supplied by users to the Site through
                        optional registration; and (2) other information
                        gathered as users navigate through the Site.{' '}
                      </p>
                      <br />
                      <p>
                        <u>Information You Provide To Us</u>. <br />
                        We may collect and store information, personal or
                        otherwise, that you voluntarily supply either while
                        using our Site or in responding (via email, messaging,
                        or other means of data transfer) in connection with a
                        feature provided on our Site. Some examples of this type
                        of information include demographic information and
                        personal information (such as your name, address, or
                        email address) that you electronically submit to us for
                        purposes such as registration as a user, accessing
                        certain features on our Site, signing up for optional
                        communications, providing feedback, or posting content
                        to various permissible areas on our Site. We may also
                        ask for information (including a credit card number)
                        from users who conduct transactions on our Site. The
                        information supplied helps us to provide the product or
                        service requested, offer you more personalized features,
                        and tailor the Site to your interests. Supplying such
                        information is entirely voluntary. However, if you
                        choose not to supply information requested, we may be
                        unable to provide you with services offered to users of
                        the Site.
                      </p>
                      <br />
                      <p>
                        <u>Other Information</u>.<br />
                        We also may collect and store information that is
                        generated automatically as you navigate through our
                        Site. For example, we employ a standard feature of
                        browser software called a &quot;cookie&quot; to assign
                        each user a unique, random number that resides on a
                        user`&apos;`s computer. The cookie does not personally
                        identify the user; it merely identifies the computer
                        with which the user accesses the Site. Unless a user
                        voluntarily identifies himself or herself (through user
                        registration, for example), the Site will not be able to
                        ascertain the identity of the user, even if a cookie is
                        assigned to a user`&apos;`s computer. The only personal
                        information a cookie can contain is information supplied
                        by a user. By showing how and when visitors use the
                        Site, cookies help track user trends and patterns. They
                        also prevent you from having to re-enter your
                        preferences on certain areas of the Site where you have
                        entered preference information previously. Of course,
                        you are free to set your web browser to disable cookies
                        or otherwise limit the information we collect; however,
                        if you do so it may render your computer unable to take
                        advantage of the personalized features enjoyed by other
                        users of the Site.
                      </p>
                      <p>
                        We also may use web beacons and other methods to access
                        cookies and to count users who visit our Site or open
                        HTML-formatted email messages. Our Site`&apos;`s servers
                        automatically collect limited information about your
                        computer`&apos;`s connection to the Internet, including
                        your IP address. The IP address is a number that lets
                        computers attached to the Internet know where to send
                        data such as the web pages users view. An IP address
                        does not personally identify a user. We use this
                        information to deliver the Site`&apos;`s content to you
                        upon request and to measure traffic within the Site.
                      </p>
                      <p>
                        We may use cookies, web beacons and similar technologies
                        to collect non-personally identifiable information about
                        users of our Site and about activity that occurs within
                        our Site, and we may use this information to, among
                        other things, serve targeted information including
                        offers on our Site. The information collected allows us
                        to analyze how users use the Site and to track user
                        interests, trends and patterns.{' '}
                      </p>
                      <p>
                        <strong>How we use and share your information</strong>
                        <strong>.</strong>&nbsp;We use the information we
                        collect in a variety of ways, including using the
                        information to customize features and advertising that
                        appear on the Site. We also may provide your information
                        to third parties, such as service providers,
                        contractors, and sponsors, for a variety of purposes.{' '}
                      </p>
                      <br />
                      <p>
                        <u>Our Use of Information</u>.
                      </p>
                      <br />
                      <p>
                        The information we gather and collect enables us to
                        provide the Site and to help you partake of our
                        services. You agree that we may use your information to:
                      </p>
                      <br />
                      <li>contact you for customer service purposes</li>
                      <li>
                        contact you to inform you of important changes or
                        additions to our Site or the services offered over our
                        Site
                      </li>
                      <li>
                        to send you administrative notices, offers and any other
                        communications that we believe may be of interest to you
                      </li>
                      <li>
                        operate, improve and maintain our products and services,
                        including analyzing user behavior, and
                      </li>
                      <li>
                        administer promotions, provide features and display
                        relevant content to you.
                      </li>

                      <p>&nbsp;</p>
                      <p>
                        You may withdraw your permission and prevent our use of
                        your information by contacting us using the contact
                        information below.{' '}
                      </p>
                      <br />
                      <p>
                        <u>Information We Share</u>.
                      </p>
                      <br />
                      <p>
                        You consent to our sharing of information for the
                        purposes described below:
                      </p>
                      <br />
                      <p>
                        1) Service Providers and Contractors:
                        <br />
                        We reserve the right to disclose to service providers
                        and contractors all of the information that we collect
                        online about you and other visitors to conduct business
                        on our behalf. These activities could include processing
                        payments, maintaining this Site, and providing business
                        services to us or to you. <br />
                        2) By Law or to Protect Rights:
                        <br />
                        We may disclose information when we believe that
                        disclosure is necessary to comply with the law, to
                        enforce our intellectual property rights, or to protect
                        the rights, property or safety of us and our employees,
                        and if necessary to defend against third-party claims.
                        We may also disclose information when requested to
                        comply with a court order, investigation, or
                        governmental request. <br />
                        3) Business Transfers:
                        <br />
                        If we go through a business transfer, such as
                        consolidation, merger, restructuring, acquisition, or
                        sale of part or all of our assets, you acknowledge and
                        consent to the transfer of your information. You further
                        acknowledge and consent to the continued use of your
                        information by the recipient so long as they comply with
                        this Privacy Policy.
                        <br />
                        4) With Your Consent:
                        <br />
                        We may share information when you provide us with your
                        consent to do so. This may occur when you consent
                        affirmatively or it may occur through your use of the
                        Site as provided in this Policy. For instance, when you
                        request information or services from our affiliates,
                        partners, service providers, or other third parties
                        through us, or when you post information to our Site,
                        you provide your consent for us to share that
                        information with others.
                        <br />
                        5) We Won&rsquo;t Sell your Information:
                        <br />
                        We will not share your personal information with any
                        third party who is not affiliated with us except as
                        described in this privacy policy. We don&rsquo;t like
                        spam any more than you do and we will not share your
                        personal information to be used for the purpose of
                        sending you unsolicited commercial offers.{' '}
                      </p>
                      <br />
                      <p>
                        Please bear in mind that whenever you voluntarily make
                        your personal information available for viewing by third
                        parties online - for example through comments and
                        participation in forums or through email - that
                        information can be seen, collected and used by others
                        besides us. We cannot be responsible for any
                        unauthorized third-party use of such information.
                      </p>
                      <br />
                      <p>
                        <strong>
                          MODIFYING YOUR INFORMATION AND OPTING OUT.
                        </strong>
                        &nbsp;If you share information with us, you will have
                        the opportunity to review, update, change or delete the
                        information you have provided to us (and that we
                        continue to store) at any time. Please contact us by
                        phone, email, or postal mail using the information below
                        to effect changes.{' '}
                      </p>
                      <br />
                      <p>
                        <strong>STORAGE OF INFORMATION.</strong>&nbsp;All
                        information gathered on our Site is stored within cloud
                        storage services, and we have exclusive access to the
                        databases containing your information. We will use
                        industry standard measures, including encryption where
                        appropriate. However, as effective as the reasonable
                        security measures we implement may be, no security
                        system is impenetrable. We cannot guarantee the security
                        of our database, nor can we guarantee that information
                        supplied will not be intercepted while being transmitted
                        over the Internet.
                      </p>
                      <br />
                      <p>
                        <strong>Additional Information About US</strong>
                        <strong>.</strong>&nbsp;We do not publish text, images,
                        or multimedia content that portrays nudity, foul
                        language, violence, or other information not suitable
                        for children. The Site is not directed to children under
                        the age of 13 and we will not knowingly maintain
                        personally identifiable information from or about anyone
                        under 13.
                        <strong />
                      </p>
                      <br />
                      <p>
                        <strong>CHANGES TO THIS POLICY.</strong>&nbsp;The
                        Internet and relevant state and federal law is always
                        evolving. If we need to change this Privacy Policy at
                        some point in the future, we`&apos;`ll post the modified
                        Privacy Policy on this Site and update the Effective
                        Date of the policy to reflect the date of the changes.
                        If you are concerned with how your information is used
                        you should check this webpage periodically. By
                        continuing to use the Site after we post any such
                        changes, you accept the Privacy Policy as modified.
                      </p>
                      <br />
                      <p>
                        <strong>Additional Privacy Questions?</strong>&nbsp;If
                        you have any questions or concerns about this Privacy
                        Policy, please contact us at{' '}
                        <a href="mailto:info@authenticpx.com">
                          info@authenticpx.com.
                        </a>
                      </p>

                      <div className="inline-flex">
                        <label
                          htmlFor="accept"
                          className="my-10 flex items-center text-gray-500 dark:text-white"
                        >
                          <input
                            className="float-left mr-4 h-5 w-5 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none  dark:bg-slate-800 dark:checked:bg-sky-300"
                            type="checkbox"
                            value=""
                            onChange={() =>
                              setPrivacyAccepted(!privacyAccepted)
                            }
                            defaultChecked={privacyAccepted}
                            id="accept"
                            disabled={agreed}
                          />
                          I Agree To Privacy Policy.
                        </label>
                        <Link
                          to="/authentic-Privacy-Policy.pdf"
                          target="_blank"
                          download
                          className="ml-10 self-center rounded-2xl border bg-sky-400 py-2 px-6 text-white transition-all duration-150 hover:opacity-75"
                        >
                          Click to download
                        </Link>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
