import React, { useState, useEffect } from 'react';
import { Loginform } from 'components';
import './style.scss';

export default function Login() {
  const [mobileBg, setMobileBg] = useState('');

  useEffect(() => {
    if (window.innerWidth < 700) {
      setMobileBg('background-mobile-login');
    }
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Login';
  }, []);

  return (
    <section
      className={`block justify-between bg-gray-100 dark:bg-slate-900 md:grid md:grid-cols-2 ${mobileBg}`}
    >
      <div className="hidden md:block ">
        <img alt="logo" src="./img/loginBackGound.png" />
      </div>
      <div className=" backdrop-blur-sm">
        <Loginform />
      </div>
    </section>
  );
}
