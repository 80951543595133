// FOR TESTING ONLY 😊

export const dataTestRecentProject = [
  {
    key: 1,
    pictureName: 'Outdoor Photography',
    pictureUrl:
      'https://wallpaperboat.com/wp-content/uploads/2020/10/26/58264/relaxing-02.jpg',
    photographer:
      'https://i0.wp.com/digital-photography-school.com/wp-content/uploads/2021/10/questions-to-ask-photographers-8.jpg?fit=1500%2C1000&ssl=1',
    totalPurchased: '4',
    totalPictures: '32',
  },
  {
    key: 2,
    pictureName: 'Amazing Photo',
    pictureUrl:
      'https://www.palco23.com/files/0002017/000redaccion/competiciones/titan%20desert/titan-desert-marruecos-2019-728.jpeg',
    photographer:
      'https://assets.wam.ae/uploads/2021/06/3415669390637760812.jpg',
    totalPurchased: '2',
    totalPictures: '64',
  },
  {
    key: 3,
    pictureName: 'Hiking Woman',
    pictureUrl:
      'https://www.planetware.com/wpimages/2019/10/montana-glacier-national-park-best-hiking-trails-swiftcurrent-pass.jpg',
    photographer:
      'https://images.ctfassets.net/u0haasspfa6q/3hT9hGg6MRP0UkcD5Rcd12/9afa2f6b2a2d77b5ff441288a300d3b2/noemi-macavei-katocz-zrk7WirlxgM-unsplash',
    totalPurchased: '2',
    totalPictures: '6',
  },
  {
    key: 4,
    pictureName: 'Forest Ambience',
    pictureUrl:
      'https://www.tiposdeguitarra.com/wp-content/uploads/2020/05/lp2-1024x768.jpg',
    photographer:
      'https://expertphotography.com/wp-content/uploads/2020/07/social-media-for-photographers-follow-1.jpg',
    totalPurchased: '1',
    totalPictures: '44',
  },
  {
    key: 5,
    pictureName: 'Best Friends Outdoor',
    pictureUrl:
      'https://www.nps.gov/es-es/mora/planyourvisit/images/Paradise-Wildflower-Meadow_NPSPhoto_webtop_1.jpeg?maxwidth=1200&autorotate=false&quality=78&format=webp',
    photographer:
      'https://www.shawacademy.com/static/9ff5b4b492502285d2b12dcbbce704c1/46604/photography-1.png',
    totalPurchased: '2',
    totalPictures: '5',
  },
  {
    key: 6,
    pictureName: 'Man and Mountains',
    pictureUrl:
      'https://i0.wp.com/lookaboutlindsey.com/wp-content/uploads/2020/08/DSC00083-2.jpg?resize=1160%2C770&ssl=1',
    photographer:
      'https://www.epa.eu/sites/epa/files/styles/pm_preview_7_5/public/uploads/2019-07/antonio-bat.jpg?itok=ZPRQRvJR',
    totalPurchased: '0',
    totalPictures: '3',
  },
];
