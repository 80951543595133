/* eslint-disable no-console */
import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ProjectDetails,
  ProjectDetailsPhotographers,
  ProjectAddedToCart,
} from 'components';
import { SERVER_URL } from '../../../common/CONFIG';

type Props = {
  setOpenChatShortcut: any;
  openChatShortcut: any;
};

export default function Project(props: Props) {
  const { setOpenChatShortcut, openChatShortcut } = props;

  const { user: currentUser } = useSelector((state: any) => state.auth);

  const query = new URLSearchParams(useLocation().search);
  const id = query.get('id');

  const [project, setProject] = useState<any>([]);
  const [creators, setCreators] = useState<any>([]);
  const [due, setDue] = useState<any>();
  const [projectId, setProjectId] = useState<any>(0);

  useEffect(() => {
    if (project?.project?.dueDate !== undefined) {
      const tempDue = project.project?.dueDate;
      const date = new Date(tempDue).toISOString().slice(0, 10);
      setDue(date);
    }

    setCreators(project?.creators);
    setProjectId(project?.project?.projectId);
    getProjectCart();
  }, [project]);

  const [acceptedCreatorId, setAcceptedCreatorId] = useState<any>();
  const [showNoMinPurchase, setShowNoMinPurchase] = useState<any>(false);

  useEffect(() => {
    // If theres a creator with state ACCEPTED for the project, then hide the others (with state FLOP)
    // Yes, is a for loop.

    let favCreators = project?.project?.favoritePhotographers;
    if (!favCreators) {
      return;
    }
    favCreators = JSON.parse(favCreators);

    for (let i = 0; i < favCreators.length; i += 1) {
      if (favCreators[i]?.state === 'ACCEPTED') {
        setAcceptedCreatorId(favCreators[i]?.creatorId);
      }
    }
  }, [project?.project?.favoritePhotographers]);

  useEffect(() => {
    getAllProjectInformation();
  }, []);

  async function getProjectCart() {
    const config = {
      method: 'get',
      url: `${SERVER_URL}getProjectCart/${project?.project?.projectId}`,
      // headers: authHeader(),
    };

    const res = await axios(config);
    setProjectCart(res.data);
  }

  const [projectCart, setProjectCart] = useState<any>([]);
  const [minPurchasedExpected, setMinPurchasedExpected] = useState<any>();

  const navigate = useNavigate();

  async function getAllProjectInformation() {
    await axios
      .post(`${SERVER_URL}getAllProjectInformation/${id}`, {
        user: currentUser,
      })
      .then((resp) => {
        if (resp.data === 'denied') {
          navigate('/404');
        }

        if (resp.status === 201) {
          setProject(resp.data);
        }
        return resp.data;
      })
      .catch(() => {});
  }

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function goToCheckout() {
    let favCreators = project?.project?.favoritePhotographers;
    if (!favCreators) {
      return;
    }
    favCreators = JSON.parse(favCreators);
    let currentCreatorOfProject: any;

    for (let i = 0; i < favCreators.length; i += 1) {
      if (favCreators[i]?.state === 'ACCEPTED') {
        currentCreatorOfProject = favCreators[i]?.creatorId;
      }
    }

    const searchCreatorInfo = creators.find(
      (element: any) => element?.userInfo.id === currentCreatorOfProject,
    );

    setMinPurchasedExpected({
      creator: searchCreatorInfo?.creatorInfo?.minPhotosRequest,
      project: projectCart.length.toString(),
      purchased: project.project.qtyPurchased.toString(),
    });

    if (
      !project.project.qtyPurchased &&
      projectCart.length <
        Number(searchCreatorInfo?.creatorInfo?.minPhotosRequest)
    ) {
      setShowNoMinPurchase(true);
      return;
    }

    if (projectCart.length > 0) {
      navigate(`/checkout?id=${project?.project?.projectId}`);
    }
  }

  useEffect(() => {
    document.title = 'Authentic | Project';
  }, []);
  return (
    <>
      <Transition appear show={showNoMinPurchase} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[101] overflow-y-auto"
          onClose={() => setShowNoMinPurchase(!showNoMinPurchase)}
        >
          <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
          <div className="px-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="mx-auto my-8 inline-block  w-full rounded-[2.5rem] bg-white p-1 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-[800px]">
                <section className="m-auto grid w-full">
                  <div className="confirm-background-small relative grid p-10 text-center ">
                    {minPurchasedExpected?.creator &&
                      minPurchasedExpected?.project && (
                        <strong className="self-center pb-10 pt-5 text-2xl font-bold text-gray-700 dark:text-white">
                          This does not meet the photographer minimum.
                          <br />
                          Please add{' '}
                          {minPurchasedExpected.creator -
                            minPurchasedExpected.project}{' '}
                          photos to your cart.
                        </strong>
                      )}

                    <div className="inline-flex w-full justify-center gap-6">
                      <div
                        onClick={() => setShowNoMinPurchase(!showNoMinPurchase)}
                        role="button"
                        tabIndex={0}
                        className="z-0 self-center rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-2 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                      >
                        Close
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <section className="w-full bg-gray-100 dark:bg-slate-900">
        <ProjectDetails project={project} due={due} />

        {creators?.map(
          (creator: any, index: number) =>
            acceptedCreatorId &&
            acceptedCreatorId === creator?.userInfo?.id && (
              <ProjectDetailsPhotographers
                creator={creator}
                projectCart={projectCart}
                setProjectCart={setProjectCart}
                projectId={projectId}
                project={project}
                setOpenChatShortcut={setOpenChatShortcut}
                openChatShortcut={openChatShortcut}
              />
            ),
        )}

        <ProjectAddedToCart
          projectCart={projectCart}
          setProjectCart={setProjectCart}
          projectId={project?.project?.projectId}
        />
        <div className="text-center md:w-11/12 md:text-right">
          <div
            role="button"
            tabIndex={0}
            onClick={() => goToCheckout()}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="brand-color mt-4 inline-block rounded-full border-white px-12 py-2 text-white duration-200 ease-in hover:bg-sky-300 lg:mt-0"
          >
            Check Out
          </div>
        </div>

        <br />
        <br />
      </section>
    </>
  );
}
