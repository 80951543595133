import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BUCKET_IMAGES } from '../../common/CONFIG';

type Props = {
  setIsOpen: any;
  isOpen: boolean;
  setInspirations: any;
  inspirations: any;
  setEditingIndex: any;
  editingIndex: any;
  isEdit: boolean;
  editingInfo: any;
  setIsEdit: any;
  editMode: any;
};

export function ModalInspiration(props: Props) {
  const {
    isOpen,
    setIsOpen,
    setInspirations,
    inspirations,
    setEditingIndex,
    editingIndex,
    isEdit,
    editingInfo,
    setIsEdit,
    editMode,
  } = props;

  // Photo
  const [photo, setPhoto] = useState<any | null>('');
  const [creatorId, setCreatorId] = useState<any | null>('');
  const [name, setName] = useState<any | null>('');
  const [description, setDescription] = useState<any | null>('');
  const [fileDataURL, setFileDataURL] = useState<any | null>('');

  const fileUpload = useRef<HTMLInputElement>(null);
  const onChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setPhoto(fileList[0]);
    showSelectedPhoto(fileList[0]);
  };

  const showSelectedPhoto = (input: any) => {
    let fileReader: any;
    if (input) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result) {
          setFileDataURL(e.target.result);
        }
      };
      fileReader.readAsDataURL(input);
    }
  };
  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  function closeModal() {
    setCreatorId('');
    setPhoto('');
    setFileDataURL('');
    setEditingIndex(-1);
    setFromBrand(false);
    setIsEdit(false);
    setIsOpen(false);
  }

  const [fromBrand, setFromBrand] = useState<any | null>(false);

  useEffect(() => {
    if (isEdit) {
      setCreatorId(editingInfo?.creatorId);
      setName(editingInfo?.name);
      setDescription(editingInfo?.description);
      setPhoto(editMode ? editingInfo?.photoUrl : editingInfo?.photo);
      setFileDataURL(editingInfo?.photo);

      if (editingInfo?.fromBrand) {
        setFromBrand(true);
      }
    }
  }, [isEdit]);

  function handleOnAddInspiration() {
    if (!photo) {
      closeModal();
      return;
    }

    let newInspiration;

    if (fromBrand) {
      newInspiration = {
        creatorId,
        photo,
        photoUrl: photo,
        name,
        description,
        fromBrand: true,
      };
    } else {
      newInspiration = {
        creatorId,
        photo,
        photoUrl: photo,
        name,
        description,
      };
    }

    const detectEditMode = [...inspirations];

    if (editMode && detectEditMode[editingIndex]?.projectId) {
      newInspiration = {
        creatorId: detectEditMode[editingIndex]?.creatorId,
        photo: detectEditMode[editingIndex]?.photoUrl,
        photoUrl: detectEditMode[editingIndex]?.photoUrl,
        name,
        description,
        projectId: detectEditMode[editingIndex]?.projectId,
        fromBrand: detectEditMode[editingIndex]?.fromBrand,
      };
    }

    if (isEdit) {
      const tempstate = [...inspirations];
      tempstate[editingIndex] = newInspiration;
      setInspirations(tempstate);
    } else {
      const tempstate = [...inspirations];
      tempstate.push(newInspiration);
      setInspirations(tempstate);
    }

    closeModal();
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="mx-auto my-8 inline-block  w-fit overflow-hidden rounded-2xl bg-white p-1 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:p-8">
              <section className="m-auto grid w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                    onKeyDown={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div className="grid md:inline-flex">
                  <div className="w-fit">
                    <h1 className="self-center text-2xl font-semibold text-gray-600 dark:text-white">
                      Photography Inspiration
                    </h1>
                  </div>
                </div>

                <div className="mx-auto mt-3 grid min-h-[64.03vh] w-full ">
                  <section className="grid text-gray-500 dark:text-white ">
                    <section className="z-10 mt-5">
                      <div
                        onClick={clickFileUpload}
                        role="button"
                        tabIndex={0}
                        className="relative mt-0 flex h-80 w-80 rounded-xl border-[0.125rem] border-dashed border-sky-300 bg-white hover:cursor-pointer hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800  dark:hover:bg-slate-500 md:h-[40vh] md:w-[26.04vw]"
                      >
                        {isEdit &&
                          !editingInfo.fromBrand &&
                          !editingInfo?.projectId && (
                            <img
                              src={URL.createObjectURL(editingInfo?.photo)}
                              alt="Preview 1"
                              className="w-full rounded-xl object-cover"
                            />
                          )}

                        {isEdit && !!editingInfo.fromBrand && (
                          <img
                            src={`${BUCKET_IMAGES}portfolio/${
                              editingInfo?.creatorId ||
                              editingInfo?.photoData.userId
                            }/${editingInfo?.photo || editingInfo?.photoUrl}`}
                            alt="Preview 2"
                            className="w-full rounded-xl object-cover"
                          />
                        )}

                        {fileDataURL && photo && !isEdit && (
                          <img
                            src={fileDataURL}
                            alt="Preview 3"
                            className="w-full rounded-xl object-cover"
                          />
                        )}

                        {isEdit &&
                          !editingInfo.fromBrand &&
                          editingInfo?.projectId && (
                            <img
                              src={`${BUCKET_IMAGES}inspiration/${editingInfo?.projectId}/${editingInfo?.photoUrl}`}
                              alt="Preview 4"
                              className="w-full rounded-xl object-cover"
                            />
                          )}

                        {!photo && !editingInfo.photoUrl && (
                          <div className="m-auto w-max content-center justify-center rounded-xl p-10 text-center align-middle md:inset-y-[30%]">
                            <img
                              src="./img/upload.png"
                              alt=""
                              className="inline h-10 w-10 self-center"
                            />
                            <h1 className="mt-5 text-lg font-semibold text-gray-600 dark:text-white">
                              Upload Inspiration Photo
                            </h1>
                          </div>
                        )}
                      </div>
                    </section>
                    <input
                      type="file"
                      ref={fileUpload}
                      multiple
                      name="uploadPhotosInspiration"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onChangePhoto(e)}
                    />
                    <section className="z-0 mt-5 grid p-5 md:p-0">
                      <div className="z-10 self-center">
                        {isEdit ? (
                          <input
                            onChange={(e) => setName(e.target.value)}
                            className="z-10 w-full border-b-2 py-3 text-2xl  font-semibold text-gray-600 transition duration-200 placeholder:text-gray-600  focus:border-sky-500 focus:outline-none focus:ring-0 focus:ring-sky-500 dark:bg-slate-800 dark:text-white"
                            placeholder="Name"
                            value={name}
                          />
                        ) : (
                          <input
                            onChange={(e) => setName(e.target.value)}
                            className="z-10 w-full border-b-2 py-3 text-2xl  font-semibold text-gray-600 transition duration-200 placeholder:text-gray-600  focus:border-sky-500 focus:outline-none focus:ring-0 focus:ring-sky-500 dark:bg-slate-800 dark:text-white"
                            placeholder="Name"
                          />
                        )}
                      </div>

                      {isEdit ? (
                        <textarea
                          rows={3}
                          className="z-0 my-5 h-[9.5rem] resize-none overflow-y-auto rounded-xl border p-3 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 dark:bg-slate-500 dark:placeholder:text-white md:mt-10 md:h-[16.22vh] md:w-full"
                          placeholder="Enter description"
                          onChange={(e) => setDescription(e.target.value)}
                          value={description}
                        />
                      ) : (
                        <textarea
                          rows={3}
                          className="z-0 my-5 h-[9.5rem] resize-none overflow-y-auto rounded-xl border p-3 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 dark:bg-slate-500 dark:placeholder:text-white md:mt-10 md:h-[16.22vh] md:w-full"
                          placeholder="Enter description"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      )}
                      <div className="text-right">
                        <div>
                          <div
                            onClick={() => handleOnAddInspiration()}
                            role="button"
                            tabIndex={0}
                            className="brand-color mt-8 inline-block rounded-full px-8 py-2 text-white duration-200 ease-in hover:cursor-pointer   hover:bg-sky-300 md:mt-4 lg:mt-0"
                          >
                            Submit
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
