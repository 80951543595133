import React, { useEffect, useState, Fragment, SetStateAction } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';
import './style.scss';

type PropsData = {
  id: number;
  pictureName: string;
  pictureUrl: string;
  photographer: string;
  totalPurchased: string;
  totalPictures: string;
  buy: boolean;
  favorite: boolean;
  download: boolean;
  ref?: number | string;
  resolution: string;
  price?: number;
};

type Props = {
  isOpenModal: SetStateAction<boolean | any>;
  previewUrlIndex: number;
  toggle: (open: boolean) => void;
  resetIndex: SetStateAction<number | any>;
  data: any;
  addToCart: Function;
};

export function ModalPreviewAddCart(props: Props) {
  const { isOpenModal, previewUrlIndex, toggle, resetIndex, data, addToCart } =
    props;
  const [currentImage, setCurrentImage] = useState(previewUrlIndex);

  useEffect(() => {
    if (previewUrlIndex) {
      toggle(true);
    }
    setCurrentImage(previewUrlIndex);
  }, [toggle, previewUrlIndex]);

  function closeModal() {
    resetIndex(null);
    toggle(false);
  }

  function nextImage() {
    if (currentImage + 1 < data.length) {
      setCurrentImage(currentImage + 1);
    } else {
      setCurrentImage(0);
    }
  }

  function previousImage() {
    if (currentImage - 1 >= 0) {
      setCurrentImage(currentImage - 1);
    } else {
      setCurrentImage(data.length - 1);
    }
  }

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 -z-[1]" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="landscape-preview inline-block h-[25rem] w-full overflow-hidden rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:h-full md:p-6">
              <section className="m-auto w-full">
                <div className="flex justify-end p-3 md:p-0">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                  >
                    <p className="mr-3 self-center md:text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-0 h-4 w-4 self-center align-middle md:mt-2 md:h-6 md:w-6"
                    />
                  </div>
                </div>

                <div className="mx-auto mt-0 h-[50rem] w-11/12 rounded-xl">
                  <div className="landscape-preview-text-hide mx-auto grid w-full text-center text-gray-500 dark:text-white">
                    <h1 className="landscape-preview-text text-lg font-bold md:text-3xl">
                      #REF {data[currentImage]?.ref}
                    </h1>
                    <h1 className="landscape-preview-text  text-xs">
                      Resolution {data[currentImage]?.resolution}
                    </h1>
                  </div>

                  <div className="m-auto flex h-[18.75rem] w-full content-center  justify-between  align-middle md:h-12 ">
                    <img
                      src="./img/arrowLeft.png"
                      alt=""
                      className="z-0 mr-2 mt-[40%] inline h-8 w-4 hover:cursor-pointer hover:opacity-75 md:mr-0 md:h-12 md:w-6  md:self-center"
                      onClick={previousImage}
                      onKeyDown={previousImage}
                    />

                    <div className="flex h-fit">
                      <div className="landscape-preview-img flex  content-center justify-center md:h-[43.75rem]">
                        <img
                          src={`${BUCKET_IMAGES}${
                            data[currentImage]?.downloadable
                              ? 'original'
                              : 'watermark'
                          }/${data[currentImage]?.projectId}/${
                            data[currentImage]?.imageUrl
                          }`}
                          alt=""
                          className="landscape-preview-img m-auto h-full w-[62.5rem] self-center	 rounded-[2.5rem] object-contain align-middle md:p-5"
                        />
                      </div>
                      {!data[currentImage]?.downloadable && (
                        <section className="ml-10 w-5/12 self-center">
                          <p className="mb-5 text-xl font-bold text-slate-800 dark:text-white">
                            Bundle Pricing
                          </p>

                          <div className="leading-9">
                            <li className="text-slate-800 dark:text-white">
                              Buy any 4-9 photos and get a 10% discount
                            </li>
                            <li className="text-slate-800 dark:text-white">
                              Buy any 10-15 photos and get a 15% discount
                            </li>
                            <li className="text-slate-800 dark:text-white">
                              Buy any 16+ photos and get a 20% discount
                            </li>
                          </div>

                          <div className="mt-5 flex justify-between">
                            <p className="mb-5 text-xl font-bold text-slate-800 dark:text-white">
                              Price
                            </p>
                            <p className="mb-5 mr-2 text-xl font-bold text-slate-800 dark:text-white">
                              ${data[currentImage]?.price}
                            </p>
                          </div>

                          {!data[currentImage]?.inCart && (
                            <div
                              tabIndex={0}
                              role="button"
                              onClick={() => addToCart(currentImage)}
                              className="brand-color z-0 mb-5 mt-3 block w-full rounded-full px-8 py-2 text-center text-white duration-200 ease-in hover:cursor-pointer hover:bg-sky-300 "
                            >
                              Add to Cart
                            </div>
                          )}
                          {data[currentImage]?.inCart && (
                            <div className="z-0 mt-3 block w-full rounded-full border-2 border-sky-500 px-8 py-2 text-center text-sky-500 hover:cursor-default">
                              Added in the Cart
                            </div>
                          )}
                        </section>
                      )}
                    </div>

                    <img
                      src="./img/arrowRight.png"
                      alt=""
                      className="z-0 ml-2 mt-[40%] inline h-8 w-4 hover:cursor-pointer hover:opacity-75 md:ml-0 md:h-12 md:w-6  md:self-center"
                      onClick={nextImage}
                      onKeyDown={nextImage}
                    />
                  </div>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
