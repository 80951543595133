import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  ProjectDetailPage,
  PhotographyInspirations,
  SupportingDocuments,
  SupportingDocumentsUpload,
} from 'components';

import { SERVER_URL } from '../../../common/CONFIG';

export default function ProjectDetails() {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get('id');

  const [project, setProject] = useState<any>([]);
  const [projectId, setProjectId] = useState<any>(0);
  const [photosAlreadyUploaded, setPhotosAlreadyUploaded] = useState<any>([]);

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllProjectInformation();
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Project Details';
  }, []);

  useEffect(() => {
    setProjectId(project?.project?.projectId);
  }, [project?.project?.projectId]);

  const { user: currentUser } = useSelector((state: any) => state.auth);

  const navigate = useNavigate();

  async function getAllProjectInformation() {
    await axios
      .post(`${SERVER_URL}getAllProjectInformationCreator/${id}`, {
        user: currentUser,
      })
      .then((resp) => {
        if (resp.data === 'denied') {
          navigate('/404');
        }

        if (resp.status === 201) {
          setProject(resp.data);
        }
        return resp.data;
      })
      .catch(() => {});
  }

  useEffect(() => {
    setPhotosAlreadyUploaded(project?.photosBuy);
  }, [project]);
  const [receivedProduct, setReceivedProduct] = useState(false);
  const [status, setStatus] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [statusChangedByUpload, setStatusChangedByUpload] = useState('');

  useEffect(() => {
    let statusValue;

    if (statusChangedByUpload.length > 0) {
      statusValue = statusChangedByUpload;
    } else {
      statusValue = project?.project?.status;
    }

    switch (statusValue) {
      case 'ASKING':
        setStatus('INVITED');
        setCurrentStep(0);
        break;
      case 'ACCEPTED':
        setStatus('ACCEPTED');
        setCurrentStep(1);
        break;
      case 'RECIVED_PRODUCT':
        setStatus('RECIVED PRODUCT');
        setCurrentStep(2);
        break;
      case 'UPLOAD_PHOTOS':
        setStatus('UPLOAD IMAGES');
        setCurrentStep(3);
        break;
      case 'PHOTOS_PURCHASED':
        setStatus('PHOTOS PURCHASED');
        setCurrentStep(4);
        break;
      case 'REJECTED':
        setStatus('REJECTED');
        setCurrentStep(0);
        break;
      default:
        setStatus('READY');
    }
  }, [project?.project?.status, statusChangedByUpload]);

  return (
    <section className="bg-gray-100 dark:bg-slate-900 ">
      <ProjectDetailPage
        project={project}
        status={status}
        setStatus={setStatus}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        receivedProduct={receivedProduct}
        setReceivedProduct={setReceivedProduct}
      />
      <PhotographyInspirations data={project?.inspirations} />
      <SupportingDocuments data={project?.documents} />

      {(project?.project?.status === 'UPLOAD_PHOTOS' ||
        project?.project?.status === 'RECIVED_PRODUCT' ||
        project?.project?.status === 'PHOTOS_PURCHASED' ||
        project?.project?.status === 'FINISHED' ||
        receivedProduct) && (
        <SupportingDocumentsUpload
          projectId={projectId}
          photosAlreadyUploaded={photosAlreadyUploaded}
          setStatusChangedByUpload={setStatusChangedByUpload}
          setPhotosAlreadyUploaded={setPhotosAlreadyUploaded}
        />
      )}

      <br />
      <br />
    </section>
  );
}
