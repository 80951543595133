/* eslint-disable tailwindcss/no-custom-classname */
import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SERVER_URL } from '../../common/CONFIG';
import { login } from '../../actions/auth';
import './style.scss';

export function Loginform() {
  type State = {
    emailText: String;
    passwordText: String;
  };

  // If not, when dispatching email and pass can't use "THEN" !
  type AppDispatch = ThunkDispatch<State, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userRef: any = useRef();
  const passRef: any = useRef();

  const userRefCurrent = userRef.current;
  const passRefCurrent = passRef.current;

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    let errorEmptyUser = true;
    let errorEmptyPass = true;

    // name check
    if (userRefCurrent?.value.length === 0) {
      userRefCurrent?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    } else {
      errorEmptyUser = false;
      userRefCurrent?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    // pass check
    if (passRefCurrent?.value.length === 0) {
      passRefCurrent?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    } else {
      errorEmptyPass = false;
      passRefCurrent?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    if (!errorEmptyUser && !errorEmptyPass) {
      dispatch(login(emailText, passwordText))
        // eslint-disable-next-line promise/always-return
        .then(() => {
          navigate(`/auth?redirect=${location.pathname + location.search}`);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          //  console.log("ERROR");
        });
    }
  };

  const { message } = useSelector((state: any) => state.message);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [resetPasswordSent, setResetPasswordSent] = useState(false);

  function emailFieldChange(e: string) {
    setEmailText(e);
  }
  function passwordFieldChange(e: string) {
    setPasswordText(e);
  }
  const { user: currentUser } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (currentUser) {
      navigate('/auth');
    }
  }, [currentUser]);

  const showForgotPassword = () => {
    setForgotPasswordMode(!forgotPasswordMode);
  };

  async function handleForgotPasswordForm() {
    if (!emailText) {
      return;
    }
    console.log('handleForgotPasswordForm', emailText);
    await axios
      .post(`${SERVER_URL}forgotPassword`, {
        email: emailText,
      })
      .then((resp) => {
        if (resp.status === 201) {
          console.log('oko !');
          setResetPasswordSent(true);
        }
        return resp.data;
      })
      .catch(() => {});
  }

  return (
    <div className="mx-auto w-4/5 md:mt-52 md:bg-gray-100 md:dark:bg-slate-900">
      {resetPasswordSent ? (
        <>
          <div className="mb-20 justify-center pt-10 text-center  md:pt-20 ">
            <h1 className="text-4xl font-semibold text-white dark:text-white md:text-gray-600">
              Email sent
            </h1>
            <h1 className="pt-10 text-xl text-white dark:text-white md:text-gray-600">
              Recovery instructions where sent to {emailText}, <br /> please
              check your email to reset your credentials.
            </h1>
          </div>

          <button
            type="button"
            className="brand-color font-display focus:shadow-outline w-full rounded-2xl p-6 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
            onClick={() => {
              setResetPasswordSent(!resetPasswordSent);
              setForgotPasswordMode(!forgotPasswordMode);
            }}
          >
            Go back to Login
          </button>
        </>
      ) : (
        <form onSubmit={handleFormSubmit} className="pb-20 md:pb-0 ">
          {message && (
            <div className="mb-5 animate-pulse text-center">
              <div className="text-red-500" role="alert">
                {message}
              </div>
            </div>
          )}
          <div className="mb-20 justify-center pt-10 text-center font-semibold md:pt-0 ">
            <h1 className="text-4xl text-white dark:text-white md:text-gray-600">
              Welcome
            </h1>
          </div>

          <input
            type="email"
            className={`${
              validEmail
                ? 'border-white hover:border-sky-500'
                : 'border-red-500 hover:border-red-300'
            }
          mb-4 w-full rounded-2xl border p-6 text-sm outline-none transition duration-500 ease-in hover:border dark:bg-slate-200`}
            id="email"
            placeholder={
              forgotPasswordMode
                ? 'Type Email account you wish to recover'
                : 'Email'
            }
            onChange={(e) => emailFieldChange(e.target.value)}
            ref={userRef}
          />
          <span
            className={`${
              validEmail ? 'mandatory-asterisk' : 'mandatory-required-text'
            }`}
          />

          {!forgotPasswordMode && (
            <>
              <input
                ref={passRef}
                type="password"
                className={`${
                  validPassword
                    ? 'border-white hover:border-sky-500'
                    : 'border-red-500 hover:border-red-300'
                }
          mb-4 w-full rounded-2xl border p-6 text-sm outline-none transition duration-500 ease-in hover:border dark:bg-slate-200`}
                id="password"
                placeholder="Password"
                onChange={(e) => passwordFieldChange(e.target.value)}
              />
              <span
                className={`${
                  validPassword
                    ? 'mandatory-asterisk'
                    : 'mandatory-required-text'
                }`}
              />
            </>
          )}

          {forgotPasswordMode ? (
            <div className="mt-6 inline-flex w-full gap-2">
              <button
                type="button"
                className="brand-color font-display focus:shadow-outline w-full rounded-2xl p-6 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
                onClick={() => setForgotPasswordMode(!forgotPasswordMode)}
              >
                Cancel
              </button>

              <button
                type="button"
                className="brand-color font-display focus:shadow-outline w-full rounded-2xl p-6 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
                onClick={handleForgotPasswordForm}
              >
                Reset Password
              </button>
            </div>
          ) : (
            <div className="mt-6 flex items-center justify-center">
              <button
                type="submit"
                className="brand-color font-display focus:shadow-outline w-full rounded-2xl p-6 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
                onClick={handleFormSubmit}
              >
                Login
              </button>
            </div>
          )}
          <div className="mt-10 flex justify-between">
            <p className="text-xs text-white hover:cursor-pointer hover:text-sky-500 hover:opacity-40 dark:text-white md:text-sm md:text-gray-600">
              <a
                href="https://www.authenticpx.com/contact/"
                target="_blank"
                rel="noreferrer"
              >
                Don´t have an account? Get started.
              </a>
            </p>
            {!forgotPasswordMode && (
              <div
                role="button"
                tabIndex={0}
                onClick={() => showForgotPassword()}
              >
                <p className="text-xs text-white hover:cursor-pointer hover:text-sky-500 hover:opacity-40 dark:text-white md:text-sm md:text-gray-600">
                  Forgot Password?
                </p>
              </div>
            )}
          </div>
          {/* <div className="test-background m-auto mt-10 w-full overflow-hidden rounded-md border border-dashed border-sky-300 p-5  text-center">
          <div className=" flex justify-around">
            <div
              className="z-10 rounded-md border border-dashed border-sky-300 bg-sky-100 p-3 hover:bg-sky-200 dark:bg-sky-300 dark:text-white dark:hover:bg-sky-600"
              onClick={() => handleIsbrand()}
              role="button"
              tabIndex={0}
            >
              Enter as Brand
            </div>

            <div
              className="z-10 rounded-md border border-dashed border-sky-300 bg-sky-100 p-3 hover:bg-sky-200 dark:bg-sky-300 dark:text-white dark:hover:bg-sky-600"
              onClick={() => handleIsCreator()}
              role="button"
              tabIndex={0}
            >
              Enter as Photographer
            </div>
          </div>
        </div> */}
        </form>
      )}
    </div>
  );
}
