import React, { useEffect } from 'react';
import { FavoritePhotographersGrid } from 'components';
// import FavoritePhotographersGrid from '../../../components/favorite-photographers-grid/FavoritePhotographersGrid';

export default function FavoritePhotographers() {
  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = 'Authentic | Favourite Photographers';
  }, []);
  return (
    <section className="m-auto w-full bg-gray-100 dark:bg-slate-900">
      <FavoritePhotographersGrid />

      <section className="mx-auto w-10/12 items-center py-16 text-center align-middle">
        <div className="mb-16 px-0 pt-10 md:px-0">
          <div className="mx-auto flex content-center items-center justify-center self-center text-center">
            <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
              Find Photographers
            </h1>
          </div>
          <p className="mt-2 text-lg font-normal text-gray-600 dark:text-white">
            Our talented team of photographers are just a click away.
          </p>
        </div>

        <div className="mx-auto w-fit ">
          <figure className="z-0 m-5 h-[20rem] w-[20rem] content-center items-center justify-center rounded-full bg-white text-center align-middle shadow-[0_1.25rem_3.75rem_0.625rem_rgba(63,127,191,0.12)] dark:bg-slate-700 md:h-[30rem] md:w-[30rem]">
            <figure className=" relative top-12 left-12 z-10 h-[14rem] w-[14rem] content-center items-center justify-center rounded-full bg-white text-center align-middle shadow-[0_1.25rem_3.75rem_0.625rem_rgba(45,51,46,0.05)] dark:bg-slate-600 md:h-[24rem] md:w-[24rem]">
              <figure className=" relative top-12 left-12  z-20 grid h-[8rem] w-[8rem] content-center items-center justify-center rounded-full bg-white text-center align-middle shadow-[0_1.25rem_3.75rem_0.625rem_rgba(45,51,46,0.05)] duration-200 ease-in hover:top-[2.5rem] hover:left-[2.5rem] hover:h-[9rem] hover:w-[9rem] hover:bg-sky-50 hover:shadow-[0_1.25rem_3.75rem_0.625rem_rgba(45,51,46,0.15)] dark:bg-slate-500 dark:hover:bg-slate-400  md:flex md:h-[18rem] md:w-[18rem] md:hover:top-[2.375rem] md:hover:left-[2.5rem]  md:hover:h-[19rem] md:hover:w-[19rem]">
                <div className="z-30 -mt-[1.875rem] shrink-0 align-middle leading-tight hover:cursor-pointer">
                  <h1 className="text-[4.25rem] font-thin text-sky-500  md:text-[6.25rem]">
                    +
                  </h1>
                  <h1 className="text-[0.625rem] font-normal text-sky-500 md:text-lg">
                    Add your favorites
                  </h1>
                </div>
              </figure>
            </figure>
          </figure>
        </div>
      </section>
    </section>
  );
}
