import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Masonry from 'react-responsive-masonry';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';
import authHeader from '../../common/services/auth-header';

type DataPhotographersType = {
  id: number | string;
  pictureName: string;
  imageUrl: string;
  photographer: string;
  totalPurchased: string;
  totalPictures: string;
  buy: boolean;
  favorite: boolean;
  download: boolean;
  resolution: string;
  masonryHeight?: string;
  marginBottom?: string;
  refPosition?: string;
  ref?: number;
  notes?: string;
  cartAdded?: boolean;
  cartBought?: boolean;
  documentName?: string;
  extension?: string;
  type?: string;
  deleted?: boolean;
};
type Props = {
  data: DataPhotographersType[];
};

export function ProfileMasonry(props: Props) {
  const { data } = props;
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [cols, setCols] = useState(3);
  const [photosShow, setPhotosShow] = useState<any>(data);

  useEffect(() => {
    let masonryCounter = 1;

    const tempData = [...data];

    for (let i = 0; i < tempData.length; i += 1) {
      if (!tempData[i].deleted) {
        if (window.innerWidth > 700) {
          if (masonryCounter === 0 || masonryCounter === 2) {
            tempData[i].masonryHeight = 'h-[37.5rem]';
            masonryCounter += 1;
            if (masonryCounter >= 2) {
              masonryCounter = 1; // Reset !
            }
          } else {
            tempData[i].masonryHeight = 'h-[27.625rem]';
            masonryCounter += 1;
          }
        } else {
          tempData[i].masonryHeight = 'h-[27.625rem]';
        }
      }
    }

    setPhotosShow(tempData);
  }, [data]);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setCols(1);
    }
  }, []);

  async function deletePortfolioPhoto(indexPhotoInDB: any) {
    const updatedInfo = {
      type: 'creator',
      photoIdToDelete: indexPhotoInDB,
    };

    try {
      const res = await axios.post(`${SERVER_URL}delete-photo/`, updatedInfo);
      if (res.status === 201) {
        getUserInformation();
      }
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  async function getUserInformation() {
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getUserInfo/${username}`,
      headers: authHeader(),
    };

    try {
      const res = await axios(config);

      setPhotosShow(res?.data.creatorPhotos);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  return (
    <div className="mx-auto mt-20 w-10/12 pb-20">
      <Masonry columnsCount={cols} gutter="1.625rem ">
        {photosShow?.map(
          (element: any) =>
            !element.deleted && (
              <div
                key={element.id}
                className={`relative mb-6 rounded-2xl hover:opacity-90 md:mb-0 md:shadow ${element.masonryHeight}  w-[22rem] md:w-[100%]`}
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => deletePortfolioPhoto(element.id)}
                >
                  <img
                    src="./img/deleteCircleBlue.png"
                    alt=""
                    className="absolute top-3 right-5 z-[80] mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80"
                  />
                </div>
                <img
                  src={`${BUCKET_IMAGES}portfolio/thumbnails/${currentUser?.username}/${element?.thumbnailUrl}`}
                  alt=""
                  className="z-[70] h-full  w-[350px] rounded-2xl object-cover hover:opacity-90 md:w-full"
                />
              </div>
            ),
        )}
        {/* </section> */}
      </Masonry>
    </div>
  );
}
