import React, { useState, useEffect } from 'react';
import { RecentProjectCardCreator } from '../recent-project-card-creator/RecentProjectCardCreator';
import { dataTestRecentProject } from '../../common/services/mocks/dataCurrentProject';

type Props = {
  currentProjects: any
}

export function RecentProjectsCreator(props: Props) {
  const { currentProjects } = props
  const [OpenDropdown, setOpenDropdown] = useState(false);
  const [search, setSearch] = useState('All');
  const [projects, setProjects] = useState(currentProjects);

  useEffect(() => {
    setOpenDropdown(false);

    if (search === 'All' || search === 'Latest') {
      setProjects(currentProjects);
    } else {
      const temp: { photoBuyLength: number; }[] = [];
      currentProjects.forEach((element: { photoBuyLength: number; }) => {
        if (element?.photoBuyLength > 0) {
          temp.push(element);
        }
        setProjects(temp);
      });
    }
  }, [search, currentProjects]);

  return (
    <div>
      <section className="m-auto mt-24 grid items-center justify-between align-middle md:flex      ">
        <div className="flex items-center align-middle">
          <h1 className="mr-8 text-2xl font-semibold text-gray-600 dark:text-white">
            Recent Projects
          </h1>
        </div>

        <div className="mt-5 md:absolute md:right-[8.33vw] md:mt-0">
          <div
            role="button"
            tabIndex={0}
            onClick={() => setOpenDropdown(!OpenDropdown)}
            onKeyDown={() => setOpenDropdown(!OpenDropdown)}
            className="m-auto inline-flex w-[21.875rem] items-center justify-between rounded-xl bg-white px-8 py-5 text-center text-sm text-gray-500 duration-200 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 md:w-[10.625rem]"
          >
            {search}{' '}
            <svg
              className="ml-2 h-8 w-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          {OpenDropdown && (
            // This could be made with a "const" for the options. Then mapping it, and a onClick function to setSearch.
            // BUT the custom style was easier to make this way :
            <div
              onMouseLeave={() => setOpenDropdown(!OpenDropdown)}
              className="absolute z-30  mt-2 w-[21.875rem] list-none divide-y divide-gray-100 rounded-xl bg-white text-base shadow dark:bg-slate-800 md:w-[10.625rem]"
            >
              <span
                onClick={() => setSearch('All')}
                key="All"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-t-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                All
              </span>
              <span
                onClick={() => setSearch('Latest')}
                key="Latest"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                Latest
              </span>

              <span
                onClick={() => setSearch('Purchased')}
                key="Purchased"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-b-xl hover:bg-sky-300 hover:text-white dark:text-white  dark:hover:bg-slate-500"
              >
                Purchased
              </span>
            </div>
          )}
        </div>
      </section>
      <section className="mx-auto mt-10 place-items-center content-around items-center justify-center text-center md:grid md:grid-cols-3 md:gap-y-8 md:gap-x-6">
        {projects?.map((element: any) => (
          <RecentProjectCardCreator
            data={element}
          />
        ))}
      </section>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
