import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CartDropdown } from 'components/cart-dropdown/CartDropdown';
import useDarkMode from '../theme/useDarkMode';
import { myContext } from '../login-detect/LoginDetect';
import { history } from '../../helpers/history';

import './style.scss';

type Props = {
  logOut: any;
};

export function Navbar(props: Props) {
  const navigate = useNavigate();

  const { logOut } = props;

  const { colorTheme, setTheme } = useDarkMode();
  const [cartOpen, setCartOpen] = useState<boolean>(false);

  const [offset, setOffset] = useState(0);
  const [upButton, setUpButton] = useState('opacity-0 hidden');

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isBrand, setIsBrand] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const { authenticated, setAuthenticated } = useContext(myContext);

  const [currentLocation, setCurrentLocation] = useState<string>('');

  useEffect(() => {
    const currentUrl = window.location.pathname;

    if (authenticated === 'brand' && currentUrl !== '/share') {
      setIsBrand(true);
      setIsLoggedIn(true);
      setIsAdmin(false);
      setCurrentLocation('/brand-profile');
    }
    if (authenticated === 'creator' && currentUrl !== '/share') {
      setIsBrand(false);
      setIsAdmin(false);
      setIsLoggedIn(true);
      setCurrentLocation('/Profile');
    }

    if (authenticated === 'admin' && currentUrl !== '/share') {
      setIsAdmin(true);
      setIsLoggedIn(true);
      navigate('/admin');
    }

    if (
      !authenticated &&
      currentUrl !== '/share' &&
      currentUrl !== '/Profile' &&
      currentUrl !== '/projectCreator' &&
      currentUrl !== '/purchasedphotos' &&
      currentUrl !== '/dashboard' &&
      currentUrl !== '/photographer' &&
      currentUrl !== '/brief' &&
      currentUrl !== '/brand-profile' &&
      currentUrl !== '/forgot' &&
      currentUrl !== '/welcome' &&
      !currentUrl.includes('/project')
    ) {
      navigate('/');
    }
  }, [authenticated]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (offset >= 200) {
      setUpButton('opacity-90');
    } else {
      setUpButton('opacity-0 hidden');
    }
  }, [offset]);

  function goUp() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  // Icons and controlling mobile hamburguer
  const hamburguerClosed = './img/hamburguer.png';
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [iconMobileMenu, setIconMobileMenu] = useState(hamburguerClosed);
  const [toggleIconMobile, setToggleIconMobile] = useState(false);
  const [toggleAnimationType, setToggleAnimationType] = useState(
    'animation-menu-toggle-none',
  );

  function toggleMenuMobile() {
    setToggleIconMobile(!toggleIconMobile);
    if (toggleIconMobile) {
      setIconMobileMenu('./img/hamburguer.png');
    } else {
      setIconMobileMenu('./img/xMenu.png');
    }
  }
  // Detect mobile and user type, at least for testing
  useEffect(() => {
    if (window.innerWidth > 700) {
      setOpenMobileMenu(true);
    }
    if (isBrand) {
      setToggleAnimationType('animation-menu-toggle-brand');
    } else {
      setToggleAnimationType(
        isLoggedIn
          ? 'animation-menu-toggle-photographer'
          : 'animation-menu-toggle-brand',
      );
    }
  }, [isBrand, isLoggedIn]);

  const { user: currentUser } = useSelector((state: any) => state.auth);

  const location = useLocation();

  const [showNavbar, setShowNavBar] = useState<boolean>(true);

  useEffect(() => {
    setCurrentLocation(location.pathname);
    if (
      location.pathname !== '/ProfileOnboarding' &&
      location.pathname !== '/ProfileOnboardingBrand' &&
      location.pathname !== '/'
    ) {
      setShowNavBar(true);
    } else {
      setShowNavBar(false);
    }
    if (location.pathname === undefined) {
      setShowNavBar(true);
    }
  }, [location]);
  useEffect(() => {
    setCurrentLocation(location.pathname);
    if (
      location.pathname !== '/ProfileOnboarding' &&
      location.pathname !== '/ProfileOnboardingBrand' &&
      location.pathname !== '/'
    ) {
      let userauthenticated: any =
        localStorage.getItem('userAuthenticApp') || '';

      if (!userauthenticated) {
        return;
      }
      userauthenticated = JSON.parse(userauthenticated);

      if (userauthenticated.roles[0] === 'ROLE_BRAND') {
        setShowNavBar(true);
        setIsBrand(true);
        setIsLoggedIn(true);
      }
      if (userauthenticated.roles[0] === 'ROLE_CREATOR') {
        setShowNavBar(true);
        setIsBrand(false);
        setIsLoggedIn(true);
      }

      if (userauthenticated.roles[0] === 'ROLE_ADMIN') {
        setShowNavBar(true);
        setIsBrand(false);
        setIsLoggedIn(true);
        setIsAdmin(true);
      }

      setShowNavBar(true);
    } else {
      setShowNavBar(false);
    }
  }, []);

  const handleLogout = () => {
    setAuthenticated(null);
    logOut();
  };

  return (
    <div className=" sticky top-0 z-[100]">
      <div
        role="button"
        tabIndex={0}
        onClick={() => goUp()}
        onKeyDown={() => goUp()}
        className={`${upButton} font-white fixed bottom-20 right-10 z-[205] flex h-10 w-10 place-items-center items-center justify-center rounded-full bg-gray-200 text-center text-4xl font-semibold transition duration-100 ease-in-out hover:cursor-pointer hover:bg-gray-300 hover:opacity-100 hover:shadow dark:bg-slate-700 md:bottom-10 md:right-10 md:h-20 md:w-20`}
      >
        <i className="arrow arrow-up mt-2 self-center md:mt-1" />
      </div>

      <nav className=" sticky top-0 z-[100] flex flex-wrap items-center justify-between bg-white p-6 drop-shadow-sm dark:bg-slate-800  dark:drop-shadow-lg">
        <Link to="/">
          {colorTheme === 'light' ? (
            <img
              alt="logo"
              src="./img/brandLogoDark.png"
              className="w-48  md:ml-[6.66vw]"
            />
          ) : (
            <img
              alt="logo"
              src="./img/brandLogo.png"
              className="w-48 md:ml-[6.66vw]"
            />
          )}
        </Link>
        {window.innerWidth < 700 && (
          <img
            alt="hamburguer"
            src={iconMobileMenu}
            className="h-8 w-8 opacity-30 hover:cursor-pointer hover:opacity-10"
            onClick={() => {
              setOpenMobileMenu(!openMobileMenu);
              toggleMenuMobile();
            }}
            onKeyDown={() => {
              setOpenMobileMenu(!openMobileMenu);
              toggleMenuMobile();
            }}
          />
        )}
        {openMobileMenu && (
          <div
            className={`m-auto grid w-[18.75rem] text-center md:contents md:w-full md:text-right ${toggleAnimationType}`}
          >
            {isLoggedIn && showNavbar ? (
              <div>
                {isBrand ? (
                  <div className="grid md:contents">
                    <Link
                      to="/purchasedphotos"
                      className={`${
                        currentLocation === '/purchasedphotos'
                          ? 'text-sky-400 underline '
                          : ' text-gray-500 '
                      }transition-all mt-8 inline-block px-8 py-2 text-xl decoration-4  underline-offset-[2.2625rem] duration-100 hover:text-sky-400  hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                    >
                      Purchases
                    </Link>
                    <Link
                      to="/dashboard"
                      className={`${
                        currentLocation === '/dashboard'
                          ? 'text-sky-400 underline '
                          : ' text-gray-500 '
                      }transition-all mt-8 inline-block px-8 py-2 text-xl decoration-4  underline-offset-[2.2625rem] duration-100 hover:text-sky-400  hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                    >
                      Dashboard
                    </Link>

                    <Link
                      to="/photographer"
                      className={`${
                        currentLocation === '/photographer'
                          ? 'text-sky-400 underline '
                          : ' text-gray-500 '
                      }transition-all mt-8 inline-block px-8 py-2 text-xl decoration-4  underline-offset-[2.2625rem] duration-100  hover:text-sky-400  hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                    >
                      Photographers
                    </Link>

                    <Link
                      to="/brief"
                      className={`${
                        currentLocation === '/brief'
                          ? 'text-sky-400 underline '
                          : ' text-gray-500 '
                      }transition-all mt-8 inline-block  px-8 py-2 text-xl decoration-4  underline-offset-[2.2625rem] duration-100 hover:text-sky-400 hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                    >
                      Create Brief
                    </Link>

                    <Link
                      to="/brand-profile"
                      className={`${
                        currentLocation === '/brand-profile'
                          ? 'text-sky-400 underline '
                          : ' text-gray-500 '
                      }transition-all mt-8 inline-block  px-8 py-2 text-xl decoration-4  underline-offset-[2.2625rem] duration-100 hover:text-sky-400 hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                    >
                      Profile
                    </Link>

                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => handleLogout()}
                      className="mt-8 inline-block px-8 py-2 text-xl text-gray-500 decoration-4 underline-offset-[2.2625rem]  duration-100 hover:text-sky-400 hover:underline  dark:text-white dark:hover:text-sky-400 md:mr-10 md:mt-4 lg:mt-0"
                    >
                      Logout
                    </div>

                    {/* <div
                      role="button"
                      tabIndex={0}
                      onClick={() => setCartOpen(!cartOpen)}
                      className="mt-10 flex justify-self-center md:mt-0 md:contents"
                    >
                      <span className="brand-color-text mr-1 text-xl">4</span>

                      <img
                        src="./img/shop.png"
                        className="inline-block justify-self-center align-middle hover:cursor-pointer hover:opacity-50 md:mr-10"
                        alt=""
                      />
                    </div> */}

                    {colorTheme === 'light' ? (
                      <img
                        onClick={() => setTheme(colorTheme)}
                        className="mt-10 inline-block h-10 w-10 place-self-center opacity-40 transition-all duration-100 hover:cursor-pointer hover:opacity-70 md:mr-32 md:mt-0"
                        src="./img/light.png"
                        alt="Sun And Moon"
                      />
                    ) : (
                      <img
                        onClick={() => setTheme(colorTheme)}
                        className="mt-10 inline-block h-10 w-10 place-self-center opacity-40 transition-all duration-100 hover:cursor-pointer hover:opacity-70 md:mr-32 md:mt-0"
                        src="./img/dark.png"
                        alt="Sun And Moon"
                      />
                    )}
                  </div>
                ) : (
                  !isAdmin && (
                    <div className="grid md:contents">
                      <Link
                        to="/projectCreator"
                        className={`${
                          currentLocation === '/projectCreator'
                            ? 'text-sky-400 underline '
                            : ' text-gray-500 '
                        }transition-all mt-8 inline-block px-8 py-2 text-xl decoration-4  underline-offset-[2.2625rem]   duration-100 hover:text-sky-400 hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                      >
                        Projects
                      </Link>

                      {/* <Link
                      to="/projectdetails"
                      className={`${
                        currentLocation === '/projectdetails'
                          ? 'text-sky-400 underline '
                          : ' text-gray-500 '
                      }transition-all duration-100 mt-8 inline-block px-8 py-2 text-xl  decoration-4 underline-offset-[2.2625rem]  hover:text-sky-400  hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                    >
                      Projects (delete)
                    </Link> */}

                      <Link
                        to="/Profile"
                        className={`${
                          currentLocation === '/Profile'
                            ? 'text-sky-400 underline '
                            : ' text-gray-500 '
                        }transition-all mt-8 inline-block  px-8 py-2 text-xl decoration-4  underline-offset-[2.2625rem] duration-100 hover:text-sky-400 hover:underline dark:text-white dark:hover:text-sky-400 md:mt-4 lg:mt-0`}
                      >
                        Profile
                      </Link>

                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => handleLogout()}
                        className="mt-8 inline-block px-8 py-2 text-xl text-gray-500 decoration-4 underline-offset-[2.2625rem]  duration-100 hover:text-sky-400 hover:underline  dark:text-white dark:hover:text-sky-400 md:mr-10 md:mt-4 lg:mt-0"
                      >
                        Logout
                      </div>

                      {colorTheme === 'light' ? (
                        <img
                          onClick={() => setTheme(colorTheme)}
                          className="mt-10 inline-block h-10 w-10 place-self-center opacity-40 hover:cursor-pointer hover:opacity-70 md:mr-32 md:mt-0"
                          src="./img/light.png"
                          alt="Sun And Moon"
                        />
                      ) : (
                        <img
                          onClick={() => setTheme(colorTheme)}
                          className="mt-10 inline-block h-10 w-10 place-self-center opacity-40 hover:cursor-pointer hover:opacity-70 md:mr-[6.66vw] md:mt-0"
                          src="./img/dark.png"
                          alt="Sun And Moon"
                        />
                      )}
                    </div>
                  )
                )}

                {isAdmin && (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleLogout()}
                    className="mt-8 inline-block px-8 py-2 text-xl text-gray-500 decoration-4 underline-offset-[2.2625rem]  duration-100 hover:text-sky-400 hover:underline  dark:text-white dark:hover:text-sky-400 md:mr-10 md:mt-4 lg:mt-0"
                  >
                    Logout
                  </div>
                )}
              </div>
            ) : (
              // <Link
              //   to="/admin"
              //   className="brand-color mt-8 inline-block rounded-full border border-white px-8 py-2 text-white hover:border   hover:border-sky-400 hover:bg-transparent hover:text-sky-400 md:mr-32 md:mt-4 lg:mt-0"
              // >
              //   Register
              // </Link>
              <div>
                {colorTheme === 'light' ? (
                  <img
                    onClick={() => setTheme(colorTheme)}
                    className="mt-10 inline-block h-10 w-10 place-self-center opacity-40 transition-all duration-100 hover:cursor-pointer hover:opacity-70 md:mr-32 md:mt-0"
                    src="./img/light.png"
                    alt="Sun And Moon"
                  />
                ) : (
                  <img
                    onClick={() => setTheme(colorTheme)}
                    className="mt-10 inline-block h-10 w-10 place-self-center opacity-40 transition-all duration-100 hover:cursor-pointer hover:opacity-70 md:mr-[6.66vw] md:mt-0"
                    src="./img/dark.png"
                    alt="Sun And Moon"
                  />
                )}
              </div>
            )}
          </div>
        )}
      </nav>
      {cartOpen && <CartDropdown setCartOpen={setCartOpen} />}
    </div>
  );
}
