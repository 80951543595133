export default function menuDrag(elmnt:any) {
    let pos1 = 0
    let  pos2 = 0
    let  pos3 = 0
    let  pos4 = 0
    if (document.getElementById(`${elmnt.id  }header`)) {

      /* if present, the header is where you move the DIV from: */

      document.getElementById(`${elmnt.id  }header`)!.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV: */
      // eslint-disable-next-line no-param-reassign
      elmnt.onmousedown = dragMouseDown;
    }
  
    function dragMouseDown(e:any) {
      // eslint-disable-next-line no-param-reassign
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e:any) {
      // eslint-disable-next-line no-param-reassign
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      // eslint-disable-next-line no-param-reassign
      elmnt.style.top = `${elmnt.offsetTop - pos2  }px`;
      // eslint-disable-next-line no-param-reassign
      elmnt.style.left = `${elmnt.offsetLeft - pos1  }px`;
    }
  
    function closeDragElement() {
      /* stop moving when mouse button is released: */
      document.onmouseup = null;
      document.onmousemove = null;
    }
  } 