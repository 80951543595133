import React, { useRef, useState, useEffect } from 'react';
import { USERS_IMAGES_URL } from '../../common/CONFIG';

type Props = {
  setProductImages: any;
  productImages: any;
  editMode: any;
  productImagesRef: any;
};
export function ImageUpload(props: Props) {
  const { setProductImages, productImages, editMode, productImagesRef } = props;

  const fileUpload = useRef<HTMLInputElement | any>(null);
  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  const handleMultipleImages = (evnt: any) => {
    const targetFiles = evnt.target.files;
    if (!targetFiles) return;
    if (targetFiles?.length !== 0) {
      const temp = [...productImages];
      temp.push(targetFiles[0]);
      setProductImages(temp);
    }
  };

  function handleRemoveImage(value: any) {
    const result = [...productImages];
    result.splice(value, 1);
    setProductImages(result);
  }

  // useEffect(() => {
  //    // eslint-disable-next-line no-console
  //   //  console.log("productImages", productImages);
  // }, [productImages])

  return (
    <section>
      <input
        onChange={handleMultipleImages}
        type="file"
        ref={fileUpload}
        accept="image/*"
        multiple
        className="hidden"
      />
      <div
        onClick={clickFileUpload}
        role="button"
        tabIndex={0}
        ref={productImagesRef}
        className="mt-2 flex h-44 rounded-xl border-[0.125rem] border-sky-300 bg-white transition-all duration-300 hover:cursor-pointer hover:border-sky-400 hover:bg-sky-50 hover:opacity-80 dark:bg-slate-800  dark:hover:bg-slate-500"
      >
        <div className="m-auto content-center justify-center text-center align-middle">
          <img
            src="./img/upload.png"
            alt=""
            className="inline h-10 w-10 self-center"
          />
          <p className="mt-2 text-base font-semibold text-gray-600 dark:text-white">
            Upload Image of Product
          </p>
        </div>
      </div>
      {/* Images list ! */}

      <div className="mt-5 flex w-full ">
        {productImages?.map((image: any, index: number) => (
          <div className="relative mr-5 text-center"   key={`prodimg + ${index.toString()}`}>
            <div
              onClick={() => handleRemoveImage(index)}
              role="button"
              tabIndex={0}
              className="absolute -right-[6px] -top-[6px] h-8 w-8 justify-center rounded-full bg-white pt-[3px] text-center text-xl font-semibold text-sky-500 opacity-80 transition-all duration-200 hover:bg-sky-100 hover:opacity-100 hover:shadow-md  hover:shadow-md dark:bg-slate-600"
            >
              X
            </div>

            {editMode && image?.projectId ? (
              <img
                alt="img"
                src={`${USERS_IMAGES_URL}${image?.photoUrl}`}
                className="h-20 w-20 rounded-xl bg-contain object-cover"
              />
            ) : (
              <img
                alt="img"
                src={URL.createObjectURL(image)}
                className="h-20 w-20 rounded-xl bg-contain object-cover"
              />
            )}

            <p className="max-h-[20px] max-w-[5rem] overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-white">
              {image?.name}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
