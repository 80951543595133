import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  ONBOARDING_FINISHED,
} from './types';

import AuthService from '../common/services/auth.service';

export const register =
  (
    username: string,
    email: string,
    password: string,
    roles: any,
    firstName: any,
    lastName: any,
    type: any,
    checkedStateActivity: any,
    checkedShotLocation: any,
    checkedSpecializationCategories: any,
    contactPhone: any,
    creatorInfoExtra: any,
    brandInfoExtra: any,
    avatarPhoto: any,
    onboarding: boolean,
    enabled: boolean,
  ) =>
  (dispatch: (any: any) => any) =>
    AuthService.register(
      username,
      email,
      password,
      roles,
      firstName,
      lastName,
      type,
      checkedStateActivity,
      checkedShotLocation,
      checkedSpecializationCategories,
      contactPhone,
      creatorInfoExtra,
      brandInfoExtra,
      avatarPhoto,
      onboarding,
      enabled,
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        // eslint-disable-next-line promise/no-return-wrap
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        // eslint-disable-next-line promise/no-return-wrap
        return Promise.reject();
      },
    );

export const login =
  (username: string, password: string) => (dispatch: (any: any) => any) =>
    AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        // eslint-disable-next-line promise/no-return-wrap
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        // eslint-disable-next-line promise/no-return-wrap
        return Promise.reject();
      },
    );

export const logout = () => (dispatch: (any: any) => any) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const notifyOnboardingFinished = () => (dispatch: (any: any) => any) => {
  dispatch({
    type: ONBOARDING_FINISHED,
  });
};
