import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import authHeader from '../../common/services/auth-header';
import { SERVER_URL } from '../../common/CONFIG';
import EditUserModal from './EditUserModal';

export default function Stats() {
  const [topCreators, setTopCreators] = useState<any>([]);
  const [userToEdit, setUserToEdit] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [access, setAccess] = useState<any>([]);
  const [shoot, setShoot] = useState<any>([]);
  const [special, setSpecial] = useState<any>([]);

  async function getStatsInfo() {
    const config = {
      method: 'get',
      url: `${SERVER_URL}statsInfo`,
      headers: authHeader(),
    };

    const res = await axios(config);
    setTopCreators(res.data.favCreators);

    setAccess(res.data.access);
    setShoot(res.data.shoot);
    setSpecial(res.data.special);
  }

  useEffect(() => {
    getStatsInfo();
  }, []);

  const [activiNames, setActiviNames] = useState<any>([]);
  const [activiCount, setActiviCount] = useState<any>([]);

  useEffect(() => {
    const temp1Act: any[] = [];
    const temp2ActCount: any[] = [];

    access.forEach((element: { activity: any; count: any }) => {
      temp1Act.push(element.activity);
      temp2ActCount.push(element.count);
    });

    setActiviNames(temp1Act);
    setActiviCount(temp2ActCount);
  }, [access]);

  const [shootNames, setShootNames] = useState<any>([]);
  const [shootCount, setShootCount] = useState<any>([]);

  useEffect(() => {
    const temp1Act: any[] = [];
    const temp2ActCount: any[] = [];

    shoot.forEach((element: { activity: any; count: any }) => {
      temp1Act.push(element.activity);
      temp2ActCount.push(element.count);
    });

    setShootNames(temp1Act);
    setShootCount(temp2ActCount);
  }, [shoot]);

  const [specialNames, setSpecialNames] = useState<any>([]);
  const [specialCount, setSpecialCount] = useState<any>([]);

  useEffect(() => {
    const temp1Act: any[] = [];
    const temp2ActCount: any[] = [];

    special.forEach((element: { activity: any; count: any }) => {
      temp1Act.push(element.activity);
      temp2ActCount.push(element.count);
    });

    setSpecialNames(temp1Act);
    setSpecialCount(temp2ActCount);
  }, [special]);

  ChartJS.register(ArcElement, Tooltip, Legend);

  const dataActivities = {
    labels: activiNames,
    datasets: [
      {
        label: 'Activities',
        data: activiCount,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(185, 109, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(185, 109, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataShoot = {
    labels: shootNames,
    datasets: [
      {
        label: 'Shoot Loction',
        data: shootCount,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(185, 109, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(185, 109, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataSpecial = {
    labels: specialNames,
    datasets: [
      {
        label: 'Specialization Categories',
        data: specialCount,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(185, 109, 64, 0.2)',
          'rgba(175, 92, 92, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(185, 109, 64, 1)',
          'rgba(175, 92, 92, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  async function handleEditUser(e: any) {
    const username = e;
    const config = {
      method: 'get',
      url: `${SERVER_URL}editUser/${username}`,
      headers: authHeader(),
    };

    try {
      const res = await axios(config);
      setUserToEdit(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
    setOpenModal(!openModal);
  }

  return (
    <div className="bg-gray-100 text-center text-gray-500 dark:bg-slate-900 dark:text-white">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1 className="mb-5 text-center text-2xl font-semibold text-gray-600 dark:text-white">
        General Stats
      </h1>
      <br />
      <br />

      <section className="m-auto grid w-10/12 grid-cols-3 place-items-center content-between items-center justify-between gap-4">
        <div className="grid w-[25rem] text-center dark:text-white">
          <h1 className="my-5 text-xl text-slate-500 dark:text-white">
            Creators Activities
          </h1>
          <Pie data={dataActivities} />
        </div>

        <div className="grid w-[25rem] text-center dark:text-white">
          <h1 className="my-5 text-xl text-slate-500 dark:text-white">
            Creators Shoot Location
          </h1>
          <Pie data={dataShoot} />
        </div>

        <div className="grid w-[25rem] text-center dark:text-white">
          <h1 className="my-5 text-xl text-slate-500 dark:text-white">
            Creators Specialization Categories
          </h1>
          <Pie data={dataSpecial} />
        </div>
      </section>

      <br />
      <br />
      <br />

      <br />
      <hr className="m-auto w-6/12 border" />
      <br />

      <br />
      <h1 className="mb-5 text-center text-2xl font-semibold text-gray-600 dark:text-white">
        Top Favorited Creators
      </h1>
      <br />

      <div className="relative z-[10] m-auto overflow-x-auto shadow-md sm:rounded-lg md:w-9/12">
        <table className="w-full  text-left text-gray-500 dark:text-gray-400">
          <thead className=" bg-gray-50 uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Times Faved
              </th>
              <th scope="col" className="py-3 px-6">
                Username
              </th>
              <th scope="col" className="py-3 px-6">
                E-Mail
              </th>
              <th scope="col" className="py-3 px-6">
                View
              </th>
            </tr>
          </thead>
          <tbody>
            {topCreators?.map((element: any, index: number) => (
              <tr
                key={element?.creator?.id}
                className="border-b bg-white hover:bg-slate-50 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-800"
              >
                <td className="py-4 px-6 text-center">
                  <div className="h-10 w-10 rounded-full bg-sky-500 py-3 text-center text-xs font-bold text-white">
                    {element?.timesFaved}
                  </div>
                </td>
                <td className="py-4 px-6">{element?.creator?.username}</td>
                <td className="py-4 px-6">{element?.creator?.email}</td>
                <td className="py-4 px-6">
                  {element.type !== 'admin' && (
                    <div
                      onClick={() => handleEditUser(element?.creator?.username)}
                      role="button"
                      tabIndex={0}
                      className="brand-color font-display focus:shadow-outline m-auto w-[5rem]  rounded-xl p-2 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
                    >
                      View
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <EditUserModal userToEdit={userToEdit} state={openModal} />
    </div>
  );
}
