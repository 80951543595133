/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { MyCartStep } from 'components';
import { SERVER_URL } from '../../../common/CONFIG';

export default function Checkout() {
  const query = new URLSearchParams(useLocation().search);
  const projectId = query.get('id');
  const [photosPrice, setPhotosPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);

  const getProjectCart = useCallback(async () => {
    const config = {
      method: 'get',
      url: `${SERVER_URL}getProjectCart/${projectId}`,
      // headers: authHeader(),
    };

    const res = await axios(config);
    setProjectCart(res.data);
  }, [projectId]);

  useEffect(() => {
    document.title = 'Authentic | My Cart';
    window.scrollTo(0, 0);
    getProjectCart();
  }, [getProjectCart]);

  const calcCartAmount = useCallback(
    (amount, photoPrice) =>
      amount *
      photoPrice *
      (amount > 5 && amount <= 10
        ? 0.9
        : amount > 10 && amount <= 15
        ? 0.85
        : amount > 15
        ? 0.8
        : 1),
    [],
  );

  const [projectCart, setProjectCart] = useState<any>([]);

  useEffect(() => {
    const qtyPurchased = projectCart[0]?.qtyPurchased;
    const modelExtra =
      !qtyPurchased && projectCart[0]?.professionalModel ? 80 : 0;
    const videographyExtra =
      !qtyPurchased && projectCart[0]?.videography ? 500 : 0;
    const photoPrice = projectCart[0]?.photoPrice ?? 0;
    const photosLength = projectCart?.length ?? 0;

    const basePrice = photosLength * photoPrice + modelExtra + videographyExtra;

    setPhotosPrice(photosLength * photoPrice);
    setTotalPrice(basePrice);
    setDiscountedPrice(
      calcCartAmount(photosLength, photoPrice) + modelExtra + videographyExtra,
    );
  }, [projectCart, setTotalPrice, setDiscountedPrice]);

  return (
    <div className="bg-gray-100 dark:bg-slate-900">
      <section className="m-auto  w-11/12 bg-gray-100 py-10 dark:bg-slate-900 md:w-10/12">
        <div className="h-fit rounded-2xl bg-white px-5 py-10 dark:bg-slate-800 md:px-20">
          <section className="m-auto w-40 text-center md:w-96">
            <p className=" text-xl font-bold text-gray-700 dark:text-white md:text-2xl">
              My Cart
            </p>
          </section>
          <MyCartStep
            total={totalPrice}
            discountedPrice={discountedPrice}
            photosPrice={photosPrice}
            projectCart={projectCart}
            projectId={projectId}
          />
        </div>
      </section>

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
