import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

type PropsCategory = {
  activityAccess: any;
  handleOnChangeActivity: any;
  handleOnChangeShotLocation: any;
  shotLocation: any;
  specializationCategories: any;
  handleOnChangeSpecializationCategories: any;
  stateActivityRef: any;
  specializationCategoriesRef: any;
  shotLocationRef: any;
};

export function SelectCategory(props: PropsCategory) {
  const {
    activityAccess,
    handleOnChangeActivity,
    handleOnChangeShotLocation,
    shotLocation,
    specializationCategories,
    handleOnChangeSpecializationCategories,
    stateActivityRef,
    specializationCategoriesRef,
    shotLocationRef,
  } = props;

  return (
    <div className="text-gray-600 dark:text-white      ">
      <h1 className="text-2xl font-semibold"> Select Category </h1>

      <div className="mt-10 grid w-full md:flex">
        <div className="border-gray-300 px-14 md:w-1/2 md:border-r-2 md:px-0">
          <h1 className="text-xl font-semibold" ref={shotLocationRef}>
            Shoot Location Access
          </h1>

          <div className="mt-10 grid grid-cols-1 gap-5">
            {shotLocation?.map((element: any, index: number) => (
              <div
                key={`shot + ${index.toString()}`}
                className="flex items-center"
              >
                <input
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={index}
                  type="checkbox"
                  id={`checkbox1-${index}`}
                  name={element.location}
                  onChange={() => handleOnChangeShotLocation(element.location)}
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow  dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
                <label
                  className="ml-1 inline-flex place-self-center md:text-[0.83vw]"
                  htmlFor={`checkbox1-${index}`}
                >
                  {element.location}
                </label>
              </div>
            ))}
          </div>
        </div>
        <hr className="m-auto my-10 block w-3/4 md:hidden" />
        <div className="w-full border-gray-300 px-14 md:border-r-2 md:px-[2.91vw]">
          <h1 className="text-xl font-semibold " ref={stateActivityRef}>
            Activity Access
          </h1>
          <div className="mt-10 grid w-10/12 gap-5 md:grid-cols-2">
            {activityAccess?.map((element: any, index: number) => (
              <div
                key={`act + ${index.toString()}`}
                className="flex items-center"
              >
                <input
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={20 + index}
                  type="checkbox"
                  id={`checkbox2-${index}`}
                  name={element.location}
                  onChange={() => handleOnChangeActivity(element.location)}
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow  dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
                <label
                  className="ml-1 inline-flex place-self-center md:text-[0.83vw]"
                  htmlFor={`checkbox2-${index}`}
                >
                  {element.location}
                </label>
                <span
                  data-tip
                  data-for={element.location}
                  className="mx-2 cursor-pointer text-xs text-gray-400 dark:text-white"
                >
                  <FontAwesomeIcon className="" icon={faQuestionCircle} />
                </span>
                <ReactTooltip
                  id={element.location}
                  type="info"
                  effect="solid"
                  className="tooltip"
                  place="right"
                  arrowColor="#54a3f5"
                >
                  {element.tooltip}
                </ReactTooltip>
              </div>
            ))}
          </div>
        </div>
        <hr className="m-auto my-10 block w-3/4 md:hidden" />
        <div className="w-full px-14 md:px-[2.91vw]">
          <h1
            className="text-xl font-semibold "
            ref={specializationCategoriesRef}
          >
            Specialization Categories
          </h1>
          <div className="mt-10 grid w-8/12 gap-5 md:w-9/12 md:grid-cols-2">
            {specializationCategories.map((element: any, index: number) => (
              <div
                key={`spec + ${index.toString()}`}
                className="flex items-center"
              >
                <input
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={30 + index}
                  type="checkbox"
                  id={`checkbox3-${index}`}
                  name={element.location}
                  onChange={() =>
                    handleOnChangeSpecializationCategories(element.location)
                  }
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow  dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
                <label
                  className="ml-1 inline-flex place-self-center  md:text-[0.83vw]"
                  htmlFor={`checkbox3-${index}`}
                >
                  {element.location}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
