import React, { useEffect, useState } from 'react';
import Masonry from 'react-responsive-masonry';
import { BUCKET_IMAGES, USERS_IMAGES_URL } from '../../common/CONFIG';

type DataPhotographersType = {
  id: number | string;
  pictureName?: string;
  pictureUrl?: string;
  photographer?: string;
  totalPurchased?: string;
  totalPictures?: string;
  buy?: boolean;
  favorite?: boolean;
  download?: boolean;
  resolution: string;
  masonryHeight?: string;
  marginBottom?: string;
  refPosition?: string;
  ref?: number;
  notes?: string;
  cartAdded?: boolean;
  cartBought?: boolean;
  documentName?: string;
  extension?: string;
  type?: string;
};
type Props = {
  data: any;
};
export function PhotographyInspirations(props: Props) {
  let masonryCounter = 1;
  const { data } = props;
  // For showing smaller images in center column of masonry
  function resetMasonryCounter() {
    if (masonryCounter >= 2) {
      masonryCounter = 1; // Reset !
    }
  }

  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      if (window.innerWidth > 700) {
        if (masonryCounter === 0 || masonryCounter === 2) {
          data[i].masonryHeight = 'h-[37.5rem]';
          masonryCounter += 1;
          resetMasonryCounter();
        } else {
          // 480px was the default
          data[i].masonryHeight = 'h-[17.510rem]';
          masonryCounter += 1;
        }
      } else {
        data[i].masonryHeight = 'h-[27.625rem]';
      }
    }
  }

  const [cols, setCols] = useState(3);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setCols(1);
    }
  }, []);
  return (
    <section className="m-auto mt-20 mb-5 w-10/12">
      {data?.length > 0 && (
        <>
          <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
            Photography Inspiration
          </h1>

          <Masonry columnsCount={cols} gutter="2.625rem" className="mt-10">
            {data?.map((element: any) => (
              <div
                key={element.id}
                onClick={() =>
                  window.open(
                    `${BUCKET_IMAGES}${
                      element?.fromBrand
                        ? `portfolio/${element?.creatorId}`
                        : `inspiration/${element?.projectId}`
                    }/${element?.photoUrl}`,
                  )
                }
                role="button"
                tabIndex={0}
                className={` rounded-2xl hover:opacity-90 hover:shadow md:mb-0 ${element?.masonryHeight} ${element?.marginBottom} w-[21.875rem] md:w-[26.09vw]`}
              >
                <div
                  className={`flex-end absolute flex w-[22rem] place-items-end rounded-2xl opacity-0 transition-all duration-300 hover:opacity-80 md:w-[26.09vw] ${element?.masonryHeight} hover:cursor-pointer hover:bg-sky-400 `}
                >
                  <div className="grid h-fit p-5 text-white">
                    <h1 className="text-xl ">Notes:</h1>
                    <p className="mt-3 text-xs md:text-base">
                      {element?.name}
                      <br />
                      {element?.description}
                    </p>
                  </div>
                </div>

                <img
                  src={`${BUCKET_IMAGES}${
                    element?.fromBrand
                      ? `portfolio/${element?.creatorId}`
                      : `inspiration/${element?.projectId}`
                  }/${element?.photoUrl}`}
                  alt=""
                  className="h-full w-full rounded-2xl object-cover hover:opacity-90"
                />
              </div>
            ))}
          </Masonry>
        </>
      )}
    </section>
  );
}
