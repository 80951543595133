import React, { useRef, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import { ModalInspiration } from 'components';
import { InspirationPhotoCard } from '../inspiration-photo-card/InspirationPhotoCard';
import { dataTestRecentProject } from '../../common/services/mocks/dataDashboard';

type Props = {
  setInspirations: any;
  inspirations: any;
  editMode: any;
};

export function InspirationPhotos(props: Props) {
  const { setInspirations, inspirations, editMode } = props;

  const fileUpload = useRef<HTMLInputElement>(null);

  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  const [isOpen, setIsOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<any>(-1);
  const [editingInfo, setEditingInfo] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<any>(false);

  useEffect(() => {
    if (editingIndex !== -1) {
      const tempInspiration = [...inspirations];
      setEditingInfo(tempInspiration[editingIndex]);
      setIsEdit(true);
      setIsOpen(true);
    }
  }, [editingIndex, isEdit]);

  //   useEffect(() => {
  //  // eslint-disable-next-line no-console
  // //  console.log("inspirations", inspirations);
  //   }, [inspirations])

  return (
    <>
      <ModalInspiration
        setInspirations={setInspirations}
        inspirations={inspirations}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setEditingIndex={setEditingIndex}
        editingIndex={editingIndex}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        editingInfo={editingInfo}
        editMode={editMode}
      />
      <section className="mx-auto mt-44 mb-10 w-[22.5rem] justify-center text-center md:w-10/12">
        <div>
          <div className="mx-auto flex content-center items-center justify-center self-center text-center">
            <img
              src="./img/inspirationStar.png"
              alt=""
              className="mr-4 inline h-7 w-7 self-center text-center align-middle"
            />
            <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
              Inpiration Photos
            </h1>
          </div>
          <p className="m-auto  mt-2 w-[21.125rem] font-normal text-gray-600 dark:text-white md:w-full">
            Photos you liked that match the feel, style, subject or color you
            are wanting for this projects
          </p>
        </div>
        <section
          className="mx-auto mt-20 grid w-[22.5rem] justify-center md:w-full md:grid-cols-4 md:gap-10"
          role="button"
          tabIndex={0}
        >
          <input
            type="file"
            ref={fileUpload}
            accept="image/*"
            className="hidden"
          />
          <div
            onClick={() => setIsOpen(!isOpen)}
            role="button"
            tabIndex={0}
            className="mb-10 flex h-[41.62wh] min-h-[23.5rem] w-[20.625rem] rounded-xl border-[0.125rem] border-dashed border-sky-300 bg-white transition-all duration-200 hover:cursor-pointer hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800  dark:hover:bg-slate-500 md:mb-0 md:w-[18.75vw]"
          >
            <div className="m-auto content-center justify-center p-10 text-center align-middle">
              <img
                src="./img/upload.png"
                alt=""
                className="inline h-16 w-16 self-center"
              />
              <p className="mt-5 font-normal text-gray-600 dark:text-white">
                Add Photo Inspiration
              </p>
            </div>
          </div>
          {inspirations?.map((element: any, index: number) => (
            <InspirationPhotoCard
              key={`insp + ${index.toString()}`}
              index={index}
              inspirationInfo={element}
              setInspirations={setInspirations}
              inspirations={inspirations}
              setEditingIndex={setEditingIndex}
              editingIndex={editingIndex}
              editMode={editMode}
            />
          ))}
        </section>
      </section>
    </>
  );
}
