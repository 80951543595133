/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AddNewProject, RecentProjects } from 'components';
import { SERVER_URL } from '../../../common/CONFIG';
import authHeader from '../../../common/services/auth-header';

export default function Dashboard() {
  const [projects, setProjects] = useState<any | null>(null);
  const [lastProject, setLastProject] = useState<any | null>(null);
  const [featuredCreators, setFeaturedCreators] = useState<any | null>();

  const [lastProjectPhotosDownload, setLastProjectPhotosDownload] = useState<
    any | null
  >();

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = 'Authentic | Dashboard';
  }, []);

  const { user: currentUser } = useSelector((state: any) => state.auth);

  useEffect(() => {
    getProjects();
    getGlobalFeaturedCreators();
  }, []);

  useEffect(() => {
    if (projects) {
      const temp = [...projects];
      const last = temp.slice(-1).pop();
      setLastProject(last);
    }
  }, [projects]);

  useEffect(() => {
    if (lastProject) {
      getLastProjectBoughtPhotos();
    }
  }, [lastProject]);

  async function getLastProjectBoughtPhotos() {
    const route = `${SERVER_URL}getLastProjectBoughtPhotos/${lastProject.project.projectId}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        user: currentUser?.id,
      })
      .then((resp) => {
        if (resp.status === 201) {
          setLastProjectPhotosDownload(resp.data);
        }
        return resp.data;
      })
      .catch(() => {});
  }

  async function getProjects() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getBrandsProjects/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setProjects(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  async function getGlobalFeaturedCreators() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getGlobalFeaturedCreators/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setFeaturedCreators(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  return (
    <section className="bg-gray-100 dark:bg-slate-900">
      <AddNewProject
        lastProject={lastProject}
        featuredCreators={featuredCreators}
        lastProjectPhotosDownload={lastProjectPhotosDownload}
      />

      {/* <div className='align-center text-center my-20 self-center m-auto w-full'>

      <Link
        to="/purchasedphotos"
        className="mt-3 rounded-3xl bg-sky-500 py-3 text-center text-white shadow-lg shadow-sky-500/50 duration-200 ease-in hover:cursor-pointer hover:bg-sky-400 dark:hover:bg-sky-300 md:mr-8 md:mt-0 md:pl-10 md:pr-6">
        View All Your Purchased Photos
      </Link>
          </div> */}

      <RecentProjects projects={projects} getProjects={getProjects} />
      <br />
      <br />
      <br />
    </section>
  );
}
