/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { ChatModal } from 'components/chat-modal/ChatModal';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { SERVER_URL } from '../../common/CONFIG';
import './style.scss';

type Props = {
  numberMessages: any;
  setNumberMessages: any;
  setOpenChatShortcut: any;
  openChatShortcut: any;
};

export function MessagesBar(props: Props) {
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [recipients, setRecipients] = useState<undefined | any>([]);
  const [isBrand, setIsBrand] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [unreadContacts, setUnreadContacts] = useState<any>([]);



  const { setNumberMessages, openChatShortcut, setOpenChatShortcut } = props;

  useEffect(() => {
    if (currentUser?.roles.includes('ROLE_BRAND')) {
      setIsBrand(true);
    } else {
      setIsBrand(false);
    }
  }, [currentUser?.roles]);

  const location = useLocation();

  useEffect(() => {
    const loc = location.pathname;
    let isShare = false;
    if (loc.includes('share')) {
      isShare = true;
    } else {
      isShare = false;
    }

    if (
      location.pathname !== '/' &&
      location.pathname !== '/admin' &&
      location.pathname !== '/ProfileOnboarding' &&
      location.pathname !== '/ProfileOnboardingBrand' &&
      !isShare
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [location]);

  const { numberMessages } = props;
  const [chatOpen, setChatOpen] = useState(false);


  async function getRecipients() {

    let res;
    // const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}posibleRecipients/${currentUser?.id}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setRecipients(res.data?.userContacts);
      setNumberMessages(res.data?.unread);
      setUnreadContacts(res.data?.unreadInfo);

    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }

  }

  function openTheModal() {
    setChatOpen(!chatOpen);
    setOpenChatShortcut(!chatOpen);
  }

  useEffect(() => {
    if (openChatShortcut) {
      getRecipients();
      setChatOpen(!chatOpen);
    }
  }, [openChatShortcut]);

  useEffect(() => {
    getRecipients();
  }, [currentUser?.id, chatOpen, location]);

  return (
    <div>
      {showButton && (
        <>
          <ChatModal
            state={chatOpen}
            recipients={recipients}
            setRecipients={setRecipients}
            unreadContacts={unreadContacts}
            // eslint-disable-next-line react/jsx-no-bind
            getRecipients={getRecipients}
          />

          <div className="absolute right-[0rem] top-[0rem]">
            <div
              role="button"
              tabIndex={0}
              onClick={() => openTheModal()}
              className="right-[9.375rem] top-[9.0625rem] mt-5 inline-block rounded-full bg-gray-600 px-5 py-2 text-white   duration-200 ease-in  hover:cursor-pointer hover:bg-gray-500 dark:text-white md:absolute md:ml-5  md:mt-0  "
            >
              Messages
              <span className="background-message p-4 text-xs">
                {numberMessages}
              </span>
            </div>
          </div>
          {isBrand && (
            <div className="-mb-[15px] h-[8rem] bg-gray-100 dark:bg-slate-900" />
          )}
        </>
      )}
    </div>
  );
}
