// export const shotLocation = ["Home","Urban", "Outdoor"];

export const shotLocation = [
  {
    location: 'Home',
    selected: false,
  },
  {
    location: 'Urban',
    selected: false,
  },
  {
    location: 'Outdoor',
    selected: false,
  },
];

export const activityAccess = [
  {
    location: 'Winter',
    selected: false,
    tooltip: 'skiing, snowboarding, sledding, snow shoeing...',
  },
  {
    location: 'Coastal',
    selected: false,
    tooltip: 'swimming, surfing, tide pools, sandcastles...',
  },
  {
    location: 'Camping',
    selected: false,
    tooltip: 'backcountry and car camping...',
  },
  {
    location: 'Farm',
    selected: false,
    tooltip: 'livestock, farm equipment, agriculture...',
  },
  {
    location: 'Team Sports',
    selected: false,
    tooltip: 'soccer, basketball, baseball...',
  },
  {
    location: 'Hunting And Fishing',
    selected: false,
    tooltip: 'fresh & saltwater fishing, fowl hunting, big game...',
  },
  {
    location: 'Health',
    selected: false,
    tooltip: 'running, biking, fitness, cardio...',
  },
  {
    location: 'Physical Work',
    selected: false,
    tooltip: 'construction, woodworking, industrial...',
  },
];

export const specializationCategories = [
  {
    location: 'Food',
    selected: false,
  },
  {
    location: 'Architecture',
    selected: false,
  },
  {
    location: 'Fashion',
    selected: false,
  },
  {
    location: 'Baby',
    selected: false,
  },
  {
    location: 'Wildlife',
    selected: false,
  },
  {
    location: 'Landscapes',
    selected: false,
  },
  {
    location: 'Sports',
    selected: false,
  },
  {
    location: 'Video',
    selected: false,
  },
  {
    location: 'Interior',
    selected: false,
  },
  {
    location: 'Portraits',
    selected: false,
  },
  {
    location: 'Pet',
    selected: false,
  },
  {
    location: 'Product',
    selected: false,
  },
];
