/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isEqual } from 'lodash';
import { ActionInformation } from 'components/action-information';
import {
  ProfileDetails,
  AddTalentModels,
  PortfolioPhotography,
  ProfileMasonry,
} from 'components';
import {
  shotLocation,
  activityAccess,
  specializationCategories,
} from './SelectCategoryData';

import authHeader from '../../../common/services/auth-header';
import { SERVER_URL } from '../../../common/CONFIG';

export default function Profile() {
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [creatorTalents, setCreatorTalents] = useState(['']);
  const [creatorPhotos, setCreatorPhotos] = useState([]);
  const [creatorInfoExtra, setCreatorInfoExtra] = useState<any>(null);
  const creatorInfoExtraRef = useRef(null);
  const [avatarPhoto, setAvatarPhoto] = useState<any | null>('');
  const [text, setText] = useState('Save');
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [flagPhone, setFlagPhone] = useState<boolean>(false);
  const [checkedStateActivity, setCheckedStateActivity] = useState<any>(['']);
  const [checkedShotLocation, setCheckedShotLocation] = useState<any>(['']);
  const [checkedSpecializationCategories, setCheckedSpecializationCategories] =
    useState<any>(['']);
  const [creatorPortfolioPhotos, setCreatorPortfolioPhotos] = useState<any>([
    '',
  ]);
  const [afterOnBoarding, setAfterOnBoarding] = useState<boolean>(true);
  const [addNewTalent, setAddNewTalent] = useState<any>('');
  const [editingTalentFromProfile, setEditingTalentFromProfile] =
    useState<boolean>(false);
  const [contactViaPhone, setContactViaPhone] = useState(
    creatorInfoExtra?.contactViaPhone,
  );

  useEffect(() => {
    document.title = 'Authentic | Profile';
    window.scrollTo(0, 0);
    setAfterOnBoarding(true);
    getUserInformation();
  }, []);

  async function uploadNewPhotos() {
    if (creatorPortfolioPhotos) {
      setIsUploading(true);

      const uploadPortfolioPhotos = new FormData();
      const temp = [...creatorPortfolioPhotos];
      Object.values(temp).forEach((element) => {
        uploadPortfolioPhotos.append('uploadPhotos', element);
      });
      uploadPortfolioPhotos.append('type', 'creator');

      try {
        const res = await axios.post(
          `${SERVER_URL}upload-photos/${currentUser?.username}`,
          uploadPortfolioPhotos,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        );
        if (res.status === 201) {
          setIsUploading(false);
          getUserInformation();
        }
        // eslint-disable-next-line no-console
        //  console.log(res);
      } catch (err: any) {
        if (err.response.status === 500) {
          // eslint-disable-next-line no-console
          //  console.log(err);
        } else {
          // eslint-disable-next-line no-console
          //  console.log(err.response.data.msg);
        }
      }
    }
  }

  async function getUserInformation() {
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getUserInfo/${username}`,
      headers: authHeader(),
    };

    try {
      const res = await axios(config);

      setCreatorPhotos(res?.data.creatorPhotos);
      setCreatorInfoExtra(res?.data.creatorExtraInfo);

      creatorInfoExtraRef.current = res?.data.creatorExtraInfo;

      setContactViaPhone(res?.data.creatorExtraInfo.contactViaPhone);
      setCreatorTalents(res?.data.creatorTalents);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  const onChangeCreatorProfileExtra = (concept: string, value: string) => {
    const tempstate = { ...creatorInfoExtra, [concept]: value };
    setCreatorInfoExtra(tempstate);
  };

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setAvatarPhoto(fileList[0]);
  };

  async function uploadAvatar() {
    if (avatarPhoto) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('uploadImages', avatarPhoto);
      formData.append('type', 'creator');

      const route = `${SERVER_URL}upload-avatar/${currentUser?.username}`;
      // eslint-disable-next-line promise/no-nesting
      await axios
        .post(route, formData, config)
        // eslint-disable-next-line promise/always-return
        .then(() => {
          getUserInformation();
        })
        .catch(() => {});
    }
  }

  async function uploadExtraFields() {
    const route = `${SERVER_URL}upload-creator-profile/${currentUser?.username}`;
    try {
      const res = await axios.post(route, {
        creatorInfoExtra: JSON.stringify(creatorInfoExtra),
        userType: 'creator',
      });

      if (res.status === 201) {
        getUserInformation();
      }
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  async function uploadExtraFieldsActivities() {
    const route = `${SERVER_URL}upload-creator-profile/${currentUser?.username}`;

    try {
      await axios.post(route, {
        checkedStateActivity: JSON.stringify(checkedStateActivity),
        checkedShotLocation: JSON.stringify(checkedShotLocation),
        checkedSpecializationCategories: JSON.stringify(
          checkedSpecializationCategories,
        ),
        userType: 'creator',
      });
    } catch (err: any) {
      if (err.response.status === 500) {
        console.log(err);
      }
    }
  }

  const handleOnChangeActivity = (activity: string) => {
    const tempstate = [...checkedStateActivity];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = checkedStateActivity[index];
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedStateActivity(tempstate);
  };

  const handleOnChangeSpecializationCategories = (activity: string) => {
    const tempstate = [...checkedSpecializationCategories];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = checkedSpecializationCategories[index];
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;

    setCheckedSpecializationCategories(tempstate);
  };

  const handleOnChangeShotLocation = (activity: string) => {
    const tempstate = [...checkedShotLocation];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = checkedShotLocation[index];
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;

    setCheckedShotLocation(tempstate);
  };

  useEffect(() => {
    const tempShot = [...shotLocation];
    const tempActivi = [...activityAccess];
    const tempSpecial = [...specializationCategories];

    if (
      !creatorInfoExtra?.shotLocation ||
      !creatorInfoExtra?.activityAccess ||
      !creatorInfoExtra?.specializationCategories
    ) {
      return;
    }

    shotLocation?.map((element, index) => {
      if (creatorInfoExtra?.shotLocation.includes(element.location)) {
        tempShot[index].selected = true;
      }
      return null;
    });

    activityAccess?.map((element, index) => {
      if (creatorInfoExtra?.activityAccess.includes(element.location)) {
        tempActivi[index].selected = true;
      }
      return null;
    });

    specializationCategories?.map((element, index) => {
      if (
        creatorInfoExtra?.specializationCategories.includes(element.location)
      ) {
        tempSpecial[index].selected = true;
      }
      return null;
    });
    setCheckedStateActivity(tempActivi);
    setCheckedShotLocation(tempShot);
    setCheckedSpecializationCategories(tempSpecial);
  }, [
    creatorInfoExtra?.specializationCategories,
    creatorInfoExtra?.activityAccess,
    creatorInfoExtra?.shotLocation,
  ]);

  const onChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setCreatorPortfolioPhotos(fileList);
  };

  async function uploadTalents() {
    if (addNewTalent) {
      const uploadTalentsPhotos = new FormData();
      uploadTalentsPhotos.append('uploadImages', addNewTalent?.photoTalent);
      uploadTalentsPhotos.append(
        'creatorTalents',
        JSON.stringify(addNewTalent),
      );
      uploadTalentsPhotos.append('type', 'creator');

      try {
        const res = await axios.post(
          `${SERVER_URL}upload-talent/${currentUser?.username}`,
          uploadTalentsPhotos,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        );

        if (res.status === 201) {
          setIsUploading(false);
          getUserInformation();
        }
      } catch (err: any) {
        if (err.response.status === 500) {
          // eslint-disable-next-line no-console
          //  console.log(err);
        } else {
          // eslint-disable-next-line no-console
          //  console.log(err.response.data.msg);
        }
      }
    }
  }

  useEffect(() => {
    if (
      isEqual(creatorInfoExtraRef.current, creatorInfoExtra) ||
      !creatorInfoExtra
    ) {
      return;
    }
    setShowSave(true);
  }, [creatorInfoExtra]);

  useEffect(() => {
    if (
      checkedStateActivity.length < 2 &&
      checkedShotLocation.length < 2 &&
      checkedSpecializationCategories.length < 2
    ) {
      return;
    }
    uploadExtraFieldsActivities();
  }, [
    checkedStateActivity,
    checkedShotLocation,
    checkedSpecializationCategories,
  ]);

  useEffect(() => {
    uploadAvatar();
  }, [avatarPhoto]);

  useEffect(() => {
    if (addNewTalent) {
      uploadTalents();
    }
  }, [addNewTalent]);

  useEffect(() => {
    uploadNewPhotos();
  }, [creatorPortfolioPhotos]);

  async function handleUploadChanges() {
    setLoadingIcon(true);
    setText('Saving');

    await uploadExtraFields();

    setLoadingIcon(false);
    setText('Done');

    setTimeout(() => {
      setShowSave(false);
      setText('Save');
    }, 500);
  }

  return (
    <section className="bg-gray-100 text-gray-500 dark:bg-slate-900 dark:text-white">
      <div onClick={handleUploadChanges} role="button" tabIndex={0}>
        <ActionInformation
          showActionInfo={showSave}
          text={text}
          loadingIcon={loadingIcon}
        />
      </div>
      <ProfileDetails
        creatorUsername={currentUser?.username}
        setFlagPhone={setFlagPhone}
        flagPhone={flagPhone}
        creatorExtraInfo={creatorInfoExtra}
        onChangeCreatorProfileExtra={onChangeCreatorProfileExtra}
        onChangeAvatar={onChangeAvatar}
        activityAccessSelected={checkedStateActivity}
        shotLocationSelected={checkedShotLocation}
        specializationCategoriesSelected={checkedSpecializationCategories}
        handleOnChangeActivity={handleOnChangeActivity}
        handleOnChangeShotLocation={handleOnChangeShotLocation}
        handleOnChangeSpecializationCategories={
          handleOnChangeSpecializationCategories
        }
        contactViaPhone={contactViaPhone}
        setContactViaPhone={setContactViaPhone}
      />
      <div className="md:m-auto md:w-10/12">
        <AddTalentModels
          setEditingTalentFromProfile={setEditingTalentFromProfile}
          setCreatorTalents={setCreatorTalents}
          creatorTalents={creatorTalents}
          setAddNewTalent={setAddNewTalent}
          afterOnBoarding={afterOnBoarding}
        />
      </div>
      <PortfolioPhotography
        onChangePhoto={onChangePhoto}
        creatorPortfolioPhotos={creatorPortfolioPhotos}
        setCreatorPortfolioPhotos={setCreatorPortfolioPhotos}
        isUploading={isUploading}
      />
      <ProfileMasonry data={creatorPhotos} />
    </section>
  );
}
