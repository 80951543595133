import React, { useState, useEffect } from 'react';
import './style.scss';

type Props = {
  currentStep: number;
};

export function StepsProject(props: Props) {
  const [stepOne, setStepOne] = useState('grayscale');
  const [stepTwo, setStepTwo] = useState('grayscale');
  const [stepThree, setStepThree] = useState('grayscale');
  const [stepFour, setStepFour] = useState('grayscale');

  const { currentStep } = props;

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setStepOne('');
        break;
      case 2:
        setStepOne('');
        setStepTwo('');
        break;
      case 3:
        setStepOne('');
        setStepTwo('');
        setStepThree('');
        break;
      case 4:
        setStepOne('');
        setStepTwo('');
        setStepThree('');
        setStepFour('');
        break;
      default:
      // code default block
    }
  }, [currentStep]);
  return (
    <section className="m-auto my-20 h-40 md:my-40 md:mt-20 md:h-40 md:w-full">
      <div className="m-auto md:w-10/12">
        <div className="mt-10 flex justify-between text-center font-bold text-sky-400">
          <div className={`${stepOne}`}>
            <section className="relative grid justify-items-center">
              <p className="absolute bottom-[70px] text-sm md:w-[125px] md:text-base">
                Invite Accepted
              </p>
              <div className="step-background mt-3 self-center text-xl leading-[51px]">
                <p>1</p>
              </div>
            </section>
          </div>
          <div className={`${stepTwo} flex w-full`}>
            <hr className="step-hr" />

            <section className="relative grid justify-items-center">
              <p className="absolute bottom-[70px] text-sm md:w-[155px] md:text-base">
                Product Received
              </p>
              <div className="step-background mt-3 self-center text-xl leading-[51px]">
                <p>2</p>
              </div>
            </section>
          </div>
          <div className={`${stepThree} flex w-full`}>
            <hr className="step-hr" />

            <section className="relative grid justify-items-center">
              <p className="absolute bottom-[70px] text-sm md:w-[125px] md:text-base">
                Images Uploaded
              </p>
              <div className="step-background mt-3 self-center text-xl leading-[51px]">
                <p>3</p>
              </div>
            </section>
          </div>
          <div className={`${stepFour} flex w-full`}>
            <hr className="step-hr" />

            <section className="relative grid justify-items-center">
              <p className="absolute bottom-[70px] text-sm md:w-[155px] md:text-base">
                Photos Purchased
              </p>
              <div className="step-background mt-3 self-center text-xl leading-[51px]">
                <p>4</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}
