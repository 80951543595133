import React, { LegacyRef, useState, useEffect } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { Modal } from 'components';
import axios from 'axios';
import { SERVER_URL, BUCKET_IMAGES } from '../../common/CONFIG';
import './style.scss';

type Props = {
  photographer: any;
  prefPosition: number;
  provided: DraggableProvided;
  innerRef: LegacyRef<HTMLDivElement>;
  setFavDelete: any;
  setPreferedPhotographerOrder: any;
  preferedPhotographerOrder: any;
  setForRefresh: any;
  editMode: any;
};

export function FavoritePhotographersCardBrief(props: Props) {
  const {
    prefPosition,
    photographer,
    setFavDelete,
    setPreferedPhotographerOrder,
    preferedPhotographerOrder,
    setForRefresh,
    editMode,
  } = props;
  const { provided, innerRef } = props;
  const { user: currentUser } = useSelector((state: any) => state.auth);

  const [openModal, setOpenModal] = useState(false);

  const [currentCreator, setCurrentCreator] = useState(['']);

  const [currentInspirations, setCurrentInspirations] = useState<any>([]);
  const [hideExtraButtons, setHideExtraButtons] = useState(false);
  const [inspirations, setInspirations] = useState([]);

  const handleAdd = () => {
    const photographerTemp = photographer;
    const photographerId = photographer?.user?.id;
    const orderTemp = [...preferedPhotographerOrder];

    let foundIndex;
    if (editMode) {
      foundIndex = orderTemp.findIndex(
        (x) => x?.userInfo?.id === photographer?.userInfo?.id,
      );
    } else {
      foundIndex = orderTemp.findIndex((x) => x?.user?.id === photographerId);
    }

    if (photographerTemp?.added === undefined) {
      orderTemp[foundIndex].added = true;
      setPreferedPhotographerOrder(orderTemp);
    } else {
      const opposite = orderTemp[foundIndex]?.added;
      orderTemp[foundIndex].added = !opposite;
      setPreferedPhotographerOrder(orderTemp);
    }
  };

  async function removeFavCreator(creatorId: number) {
    // const dt = new Date();

    const route = `${SERVER_URL}deleteCreatorFromBrandsFav/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        deleteCreatorFromBrandsFav: creatorId,
      })
      .then((res) => {
        if (res.status === 201) {
          // setFavDelete(creatorId + dt.getMilliseconds());
          setFavDelete(creatorId);
        }
        return res.data;
      })
      .catch(() => {});
  }
  const [isBrandFav, setIsBrandFav] = useState(false);

  function handleShowCreatorModal(creator: any) {
    setCurrentCreator(creator);
    setOpenModal(true);
  }

  useEffect(() => {
    if(!editMode) {
      getBrandsInspirations();
    }
  }, [editMode]);

  async function getBrandsInspirations() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getBrandsInspirationPhotos/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setInspirations(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    setCurrentInspirations(inspirations);
  }, [inspirations]);

  return (
    <>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={currentCreator}
        isBrandFav={isBrandFav}
        setIsBrandFav={setIsBrandFav}
        hideExtraButtons={hideExtraButtons}
        currentInspirations={currentInspirations}
        setCurrentInspirations={setCurrentInspirations}
        setForRefresh={setForRefresh}
      />

      <div
        key={photographer.creatorExtraInfo?.id}
        ref={innerRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...provided.draggableProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...provided.dragHandleProps}
        className="mb-5 h-[25.625rem] w-[21.25rem] rounded-2xl bg-white opacity-90 transition-all duration-300 hover:bg-sky-50 hover:opacity-100 hover:shadow dark:bg-slate-800 dark:hover:bg-slate-500 md:relative md:mb-0 md:h-[43.75vh] md:w-full md:min-w-[16.14vw]"
      >
        <div className="favorite-photographers-card-number relative left-2 top-2 mb-5 flex h-12 w-12 content-center items-center justify-center text-center align-middle md:h-[5vh] md:w-[3vw]">
          <p className="ml-1 mt-1 text-xs text-sky-500">{prefPosition + 1}</p>
        </div>
        <div className="flex content-center items-center justify-center text-center align-middle">
          <div className="m-auto flex h-44  w-44 content-center items-center justify-center self-center rounded-full border-2 border-dashed  border-gray-300 align-middle md:h-[18.78vh] md:w-[9.16vw]">
            {photographer.creatorExtraInfo?.avatarUrl ? (
              <img
                src={`${BUCKET_IMAGES}avatar/${photographer.user?.username}/${photographer.creatorExtraInfo?.avatarUrl}`}
                alt=""
                className="m-auto flex h-36 w-36 content-center items-center justify-center self-center rounded-full object-cover align-middle md:h-[15.36vh] md:w-[7.5vw]"
              />
            ) : (
              <img
                src={`${BUCKET_IMAGES}avatar/${photographer.userInfo?.username}/${photographer.creatorInfo?.avatarUrl}`}
                alt=""
                className="m-auto flex h-36 w-36 content-center items-center justify-center self-center rounded-full object-cover align-middle md:h-[15.36vh] md:w-[7.5vw]"
              />
            )}
          </div>
        </div>
        <img
          onClick={() => removeFavCreator(photographer.user?.id)}
          src="./img/delete.png"
          alt=""
          className="relative left-[18.5rem] top-[7.5rem] h-[24px] w-[22px] opacity-80 transition-all duration-200 hover:h-[26px] hover:w-[24px] hover:cursor-pointer hover:opacity-100 md:absolute md:left-[15vw] md:top-[39vh] md:h-[2.56vh]  md:w-[1.14vw] hover:md:left-[14.9vw]"
        />
        <div className="mt-7 md:mt-[4vh]">
          {!photographer?.added ? (
            <div
              role="button"
              tabIndex={0}
              onClick={handleAdd}
              className="m-auto w-3/5  rounded-3xl border-2  border-gray-300 px-[0.05vw] py-[0.85vh] text-center text-gray-500 transition duration-200 ease-in hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400 md:text-[1.5vh]"
            >
              Add Photographer
            </div>
          ) : (
            <div
              role="button"
              tabIndex={0}
              onClick={handleAdd}
              className="m-auto mt-7 w-3/5 rounded-3xl border-2 border-sky-500 bg-sky-500  py-[0.85vh] px-[0.05vw] text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:border-sky-400 hover:bg-sky-400  dark:hover:bg-sky-400 md:mt-0 md:text-[1.5vh]"
            >
              Added!
            </div>
          )}
          <div className="m-auto mt-7 flex w-full content-center justify-center self-center text-center md:mt-[2vh] ">
            <img
              src="./img/arrows.png"
              alt=""
              className="mr-2 h-3 w-3 self-center opacity-60 dark:invert"
            />
            <div
              role="button"
              tabIndex={0}
              onClick={() => handleShowCreatorModal(photographer)}
            >
              <p className="text-xs font-normal text-gray-600 hover:cursor-pointer hover:font-bold hover:text-sky-500 dark:text-white dark:hover:text-sky-500  md:text-[1.5vh]">
                View Portfolio
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
