import React, { useEffect, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type Props = {
  state: boolean;
};

export function CouponModal(props: Props) {
  const { state } = props;
  const [isOpen, setIsOpen] = useState(state);

  const [couponCode, setCouponCode] = useState('');

  const [validCoupon, setValidCoupon] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, [state]);

  useEffect(() => {
    setIsOpen(false);
    setValidCoupon(false);
  }, []);

  function closeModal() {
    setValidCoupon(false);
    setIsOpen(false);
  }

  function onChangeCouponField(e: string) {
    setValidCoupon(false);
    setCouponCode(e);
  }

  function validateCoupon() {
    if (couponCode === 'cookie') {
      setValidCoupon(true);
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-6/12">
              <section className="m-auto w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                    onKeyDown={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div className="z-0 mx-auto mt-10 md:w-8/12">
                  <section className="z-0 flex w-full">
                    <div className="z-0 w-full text-center text-gray-500 dark:text-white md:w-[3/4]">
                      <h1 className="ml-5 text-2xl font-semibold text-gray-900 dark:text-white ">
                        Enter Coupon Code
                      </h1>
                      <input
                        type="text"
                        className={`${
                          validCoupon
                            ? 'animate-pulse border-green-500 text-green-500 hover:border-green-500'
                            : 'border-white hover:border-sky-500'
                        }
                        z-0 my-10 w-full self-center rounded-2xl border border-gray-300 p-6 text-center text-4xl font-extrabold text-slate-800 outline-none transition duration-300 ease-in hover:border hover:border-sky-500 dark:bg-slate-400 dark:text-white dark:placeholder:text-white`}
                        id=""
                        onChange={(e) => onChangeCouponField(e.target.value)}
                      />
                      <div
                        onClick={() => validateCoupon()}
                        role="button"
                        tabIndex={0}
                        className="brand-color mb-5 block w-full rounded-full px-8 py-2 text-center text-white duration-200 ease-in hover:cursor-pointer hover:bg-sky-300"
                      >
                        Validate
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
