import React from 'react';
import { Link } from 'react-router-dom';
import { USERS_IMAGES_URL, SERVER_URL } from '../../common/CONFIG';

type Props = {
  data: any;
};

export function RecentProjectCardCreator(props: Props) {
  const { data } = props;

  return (
    <Link to={`/projectdetails?id=${data?.project?.projectId}`}>
      <section
        key={data.project?.id}
        className="relative z-20 ml-[0rem] mb-5 h-fit w-[350px] rounded-3xl bg-white pb-5 pt-2 opacity-90 duration-200 ease-in hover:cursor-pointer hover:opacity-100 hover:shadow dark:bg-slate-800 md:my-0 md:ml-0 md:h-[52.29vh] md:w-[26.04vw] md:pt-0"
      >
        <img
          src={`${USERS_IMAGES_URL}${data?.project?.projectImage}`}
          alt=""
          className="relative z-0 mx-6  mt-6 w-[305px] rounded-[1rem] object-cover md:absolute md:mx-[1.25vw] md:mt-[2.56vh] md:h-[37.35vh] md:w-[23.59vw]"
        />
        <div className="relative mt-10 flex w-full items-center justify-between md:absolute md:bottom-5 md:mt-0">
          <h1 className="ml-8   text-left font-semibold text-gray-600 dark:text-white md:ml-[1.66vw] md:py-[4.26vh] md:text-[1.04vw]">
            {data?.project?.name}
          </h1>
          <div className="flex  w-[152px] md:w-[7.91vw]">
            <img
              alt="logo"
              src="./img/dashboardFrame.png"
              className="h-5 w-5 md:h-[2.1vh] md:w-[1.0vw]"
            />
            <p className="ml-2 mr-8 text-sm text-sky-400 md:ml-[0.41vw] md:mr-[1.66vw] md:text-[0.72vw]">
              {data?.photoBuyLength} Photos
            </p>
          </div>
        </div>

        <div className="relative mt-3 flex w-full items-center justify-between md:absolute md:-bottom-4 md:mt-0">
          <h1 className="ml-8 text-left text-sm text-gray-600 dark:text-white md:ml-[1.66vw] md:py-[4.26vh] md:text-[0.72vw]">
            {data?.project?.productName}
          </h1>
          <div className="flex w-[152px] md:w-[7.91vw]">
            <img
              alt="logo"
              src="./img/download.png"
              className="h-5 w-5 md:h-[2.1vh] md:w-[1.0vw]"
            />
            <p className="ml-2 mr-8 text-sm text-gray-500 dark:text-white md:ml-[0.41vw] md:mr-[1.66vw] md:text-[0.72vw]">
              {data?.project?.qtyPurchased} Purchased
            </p>
          </div>
        </div>
      </section>
    </Link>
  );
}
