import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ProfileDetailsBrand } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ActionInformation } from 'components/action-information';
import { SERVER_URL } from '../../../common/CONFIG';
import authHeader from '../../../common/services/auth-header';

export default function BrandProfile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Profile';
  }, []);

  const [avatarPhoto, setAvatarPhoto] = useState<any | null>();
  const [showActionInfo, setShowActionInfo] = useState<boolean>(false);
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [type, setType] = useState('brand');
  const [user, setUser] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<any>([]);
  const [brandExtraInfo, setBrandExtraInfo] = useState<any>([]);
  const [text, setText] = useState('Save');
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [flag, setFlag] = useState<boolean>(false);

  async function getInfo() {
    // eslint-disable-next-line no-console
    //  //  console.log("currentUser?.username", currentUser?.username);
    const config = {
      method: 'get',
      url: `${SERVER_URL}getUserInfo/${currentUser?.username}`,
      headers: authHeader(),
    };
    try {
      const res = await axios(config);
      setUser(res.data);
      setShowActionInfo(false);
      setFlag(true);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
    setShowActionInfo(false);
  }
  useEffect(() => {
    setUserInfo(user?.userInfo);
    setBrandExtraInfo(user?.brandExtraInfo);
  }, [user]);

  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const state = query.get('state');

  useEffect(() => {
    if (state === 'welcome') {
      navigate('/brand-profile');
    }
  }, []);

  useEffect(() => {
    setShowActionInfo(false);
    getInfo();
  }, []);

  const onChangeBrandProfileUserInfo = (concept: string, value: string) => {
    const tempstate = { ...userInfo, [concept]: value };
    setUserInfo(tempstate);
  };

  const onChangeBrandProfileExtra = (concept: string, value: string) => {
    const tempstate = { ...brandExtraInfo, [concept]: value };
    setBrandExtraInfo(tempstate);
  };

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setAvatarPhoto(fileList[0]);
  };

  async function uploadAvatar() {
    if (avatarPhoto) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('uploadImages', avatarPhoto);
      formData.append('type', type);

      const route = `${SERVER_URL}upload-avatar/${currentUser?.username}`;
      // eslint-disable-next-line promise/no-nesting
      await axios
        .post(route, formData, config)
        // eslint-disable-next-line promise/always-return
        .then(() => {
          getInfo();
          setShowActionInfo(false);
        })
        .catch(() => {});
    }
    setShowActionInfo(false);
  }
  useEffect(() => {
    if (flag) {
      setShowActionInfo(true);
    }
  }, [brandExtraInfo, userInfo]);

  useEffect(() => {
    uploadAvatar();
  }, [avatarPhoto]);

  function handleUploadChanges() {
    setTimeout(() => {
      setLoadingIcon(false);
      setText('Done');
    }, 1800);
    setTimeout(() => {
      setShowActionInfo(false);
      setLoadingIcon(false);
      setText('Save');
    }, 1900);
    uploadExtraFields();
  }

  async function uploadExtraFields() {
    setLoadingIcon(true);
    setText('Saving');
    const route = `${SERVER_URL}upload-brand-profile/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        brandExtraInfo: JSON.stringify(brandExtraInfo),
        userInfo: JSON.stringify(userInfo),
        userType: type,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        getInfo();
        setShowActionInfo(false);
      })
      .catch(() => {});
    setShowActionInfo(false);
  }

  return (
    <section className="relative bg-gray-100 text-gray-500 dark:bg-slate-900 dark:text-white">
      <div onClick={handleUploadChanges} role="button" tabIndex={0}>
        <ActionInformation
          showActionInfo={showActionInfo}
          text={text}
          loadingIcon={loadingIcon}
        />
      </div>
      <ProfileDetailsBrand
        user={user}
        onChangeBrandProfileUserInfo={onChangeBrandProfileUserInfo}
        onChangeBrandProfileExtra={onChangeBrandProfileExtra}
        userInfo={userInfo}
        brandExtraInfo={brandExtraInfo}
        onChangeAvatar={onChangeAvatar}
        avatarPhoto={avatarPhoto}
      />
    </section>
  );
}
