import React from 'react';
import { BUCKET_IMAGES } from '../../common/CONFIG';

type Props = {
  inspirationInfo: any;
  setInspirations: any;
  inspirations: any;
  index: number;
  setEditingIndex: any;
  editingIndex: any;
  editMode: any;
};

export function InspirationPhotoCard(props: Props) {
  const {
    inspirationInfo,
    setInspirations,
    inspirations,
    index,
    setEditingIndex,
    editingIndex,
    editMode,
  } = props;

  function deleteInspiration() {
    const tempInspirations = [...inspirations];
    tempInspirations.splice(index, 1);
    setInspirations(tempInspirations);
  }

  function editInspiration() {
    setEditingIndex(index);
  }

  return (
    <section>
      <div className="mb-10 flex  h-[23.4375rem] w-[20.625rem] rounded-xl bg-white p-5 transition-all duration-200 hover:shadow dark:bg-slate-800 md:relative md:mb-0 md:w-[18.75vw]">
        <div className="w-[18.75rem] overflow-hidden text-center md:w-full">
          {inspirationInfo?.projectId && editMode && (
            <img
              src={`${BUCKET_IMAGES}${
                inspirationInfo?.fromBrand
                  ? `portfolio/${inspirationInfo?.creatorId}`
                  : `inspiration/${inspirationInfo?.projectId}`
              }/${inspirationInfo?.photoUrl}`}
              alt=""
              className="m-auto h-[15.625rem] w-full rounded-xl object-cover "
            />
          )}

          {inspirationInfo?.photo &&
            !inspirationInfo.fromBrand &&
            !inspirationInfo?.projectId && (
              <img
                src={URL.createObjectURL(inspirationInfo?.photo)}
                alt=""
                className="m-auto h-[15.625rem] w-full rounded-xl object-cover "
              />
            )}

          {!!inspirationInfo?.photo &&
            !!inspirationInfo.fromBrand &&
            !inspirationInfo?.projectId && (
              <img
                src={`${BUCKET_IMAGES}portfolio/${inspirationInfo?.creatorId}/${inspirationInfo?.photo}`}
                alt=""
                className="m-auto h-[15.625rem] w-full rounded-xl object-cover "
              />
            )}

          {!inspirationInfo?.photo &&
            !inspirationInfo.fromBrand &&
            !inspirationInfo?.projectId && (
              <div className="m-auto h-[15.625rem] w-full rounded-xl bg-slate-100 object-cover " />
            )}

          <div className="mt-[2.98vh] w-48 max-w-[17.5rem] flex-wrap overflow-hidden text-ellipsis text-left text-gray-600 dark:text-white  md:text-[0.83vw]">
            <h1 className="truncate font-semibold">{inspirationInfo?.name}</h1>
            <p className="truncate">{inspirationInfo?.description}</p>
          </div>
          <div className="relative left-[16.6rem] bottom-[3.4375rem] grid w-6 md:absolute md:left-[16.10vw]  md:bottom-[3vh] md:w-[1.25vw]">
            <div role="button" tabIndex={0} onClick={() => deleteInspiration()}>
              <img
                src="./img/delete.png"
                alt=""
                className="h-6 hover:cursor-pointer hover:opacity-80 md:h-[2.56vh] md:w-[2vw]"
              />
            </div>
            <div role="button" tabIndex={0} onClick={() => editInspiration()}>
              <img
                src="./img/edit.png"
                alt=""
                className="mt-2 h-6 hover:cursor-pointer hover:opacity-80 md:h-[2.56vh] md:w-[2vw]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
