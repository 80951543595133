import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SERVER_URL, BUCKET_IMAGES } from '../../../common/CONFIG';

export default function Purchasedphotos() {
  const [photos, setPhotos] = useState<any | null>();
  const [search, setSearch] = useState<any | null>();

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = 'Authentic | Purchased Photos';
  }, []);

  const { user: currentUser } = useSelector((state: any) => state.auth);

  useEffect(() => {
    getPhotos();
  }, []);

  async function getPhotos() {
    let res;
    const id = currentUser?.id;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getBrandsPurchasedPhotos/${id}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setPhotos(res.data);
      setSearch(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  function searchPhotosHandler(e: string) {
    if (e.length > 2) {
      const temp: any = [...photos];
      const result = temp?.filter(
        (element: any) =>
          element.creator?.includes(e) || element.project?.name?.includes(e),
      );
      setSearch(result);
    } else {
      setSearch(photos);
    }
  }

  return (
    <section className="min-h-[50vw] bg-gray-100 pb-20  dark:bg-slate-900">
      <h1 className="px-5 text-center text-3xl font-bold text-gray-700 dark:text-white md:px-0">
        All Your Purchased Photos
      </h1>

      <br />
      <br />

      <div className="flex justify-center">
        <div className="mb-3 w-full xl:w-96">
          <div className="input-group relative mb-4 flex w-full  items-stretch rounded">
            <input
              onChange={(e) => searchPhotosHandler(e.target.value)}
              type="search"
              className=" relative my-3 block w-full flex-auto rounded-xl bg-clip-padding px-4 py-4 text-base font-normal transition ease-in-out focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 dark:bg-slate-600 dark:text-white dark:placeholder:text-slate-300"
              placeholder="Search by Project or Photographer"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
            <span
              className="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-gray-700"
              id="basic-addon2"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="search"
                className="w-8"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <section className="m-auto gap-y-8 md:grid md:w-10/12 md:grid-cols-4 md:gap-x-5 md:gap-y-8">
        {search?.map((element: any, index: number) => (
          <div
            key={`pics + ${index.toString()}`}
            className="my-5 rounded-2xl border bg-white p-5 hover:bg-slate-100 dark:border-gray-800 dark:bg-gray-900 dark:hover:bg-gray-800 md:my-0 md:w-96"
          >
            <img
              src={`${BUCKET_IMAGES}original/${element?.project?.projectId}/${element?.imageUrl}`}
              alt=""
              className=" max-h-[200px] w-full rounded-xl object-cover"
            />

            <section className="mt-2 inline-flex w-full justify-between">
              <div className="grid">
                <p className="text-20 mt-2 font-bold text-gray-700 dark:text-white">
                  Project: {element?.project?.name}
                </p>
                <p className="text-[12px] text-gray-700 dark:text-white">
                  Photographer: {element?.creator}
                </p>
              </div>

              <a
                target="_blank"
                download
                href={`${BUCKET_IMAGES}original/${element?.project?.projectId}/${element?.imageUrl}`}
                className="grid cursor-pointer self-center hover:opacity-80"
                rel="noreferrer"
              >
                <img
                  alt="logo"
                  src="./img/download.png"
                  className="mt-2 h-5 w-5 md:h-7 md:w-7"
                />
              </a>
            </section>
          </div>
        ))}
      </section>
      {!search?.length && (
        <div className="m-auto mb-[600px] text-center">
          <p className="animate-pulse p-10 text-2xl font-extrabold text-gray-400 dark:text-gray-300">
            No Results
          </p>
        </div>
      )}
    </section>
  );
}
