import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export function Footer() {
  const instagram = faInstagram as IconProp;
  const linkedin = faLinkedin as IconProp;

  return (
    <div className=":bg-slate-800 z-10 m-auto grid w-full bg-white p-10 text-center dark:bg-slate-800 md:flex md:h-fit md:place-content-between md:items-center md:p-5 md:px-[7.81vw]">
      <div className="grid md:flex">
        <p className="text-gray-500 dark:text-gray-400">© Copyright</p>
      </div>

      <br />

      <div className="m-5 flex justify-between text-center md:m-0">
        <p className="mt-2 text-gray-500 hover:cursor-pointer hover:text-sky-400 dark:text-gray-400 dark:hover:text-sky-400 md:mt-0 md:ml-5">
          <FontAwesomeIcon icon={faGlobe} className="h-5 w-5" />
        </p>
        <p className="text-gray-500 hover:cursor-pointer hover:text-sky-400 dark:text-gray-400 dark:hover:text-sky-400 md:ml-5">
          <FontAwesomeIcon icon={instagram} className="h-5 w-5" />
        </p>
        <p className="text-gray-500 hover:cursor-pointer hover:text-sky-400 dark:text-gray-400 dark:hover:text-sky-400 md:ml-5">
          <FontAwesomeIcon icon={linkedin} className="h-5 w-5" />
        </p>
      </div>
    </div>
  );
}
