import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { StepsProject } from '../steps-project/StepsProject';

type PropscurrentProject = {
  currentProject: any;
};

export function CurrentProject(props: PropscurrentProject) {
  const { currentProject } = props;

  const [state, setState] = useState(0);
  const [dueDate, setDueDate] = useState('');
  const [remainingDays, setRemainingDays] = useState(0);
  const [status, setStatus] = useState('');

  useEffect(() => {
    switch (currentProject?.project?.status) {
      case 'WAITING':
      case 'BRIEF':
      case 'ASKING':
        setState(0);
        setStatus('INVITED');
        break;
      case 'ACCEPTED':
        setState(1);
        setStatus('ACCEPTED');
        break;
      case 'RECIVED_PRODUCT':
        setState(2);
        setStatus('RECIVED PRODUCT');
        break;
      case 'UPLOAD_PHOTOS':
        setState(3);
        setStatus('UPLOAD IMAGES');
        break;
      case 'PHOTOS_PURCHASED':
        setState(4);
        setStatus('PHOTOS PURCHASED');
        break;
      case 'REJECTED':
        setState(0);
        setStatus('REJECTED');
        break;

      default:
        setState(0);
        setStatus('INVITED');
        break;
    }
  }, [currentProject]);

  useEffect(() => {
    const today = new Date();
    const date = new Date(currentProject?.project?.dueDate);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = new Date(currentProject?.project?.dueDate).getDate();
    const dateDueFinal = `${month.toUpperCase()} ${day}`;
    const difTime = date.getTime() - today.getTime();
    const difDays = difTime / (1000 * 3600 * 24);
    setDueDate(dateDueFinal);
    setRemainingDays(Math.round(difDays));
  }, [currentProject?.project?.dueDate]);

  return (
    <section>
      <div className="grid pt-5 text-gray-500 dark:text-white md:flex md:justify-between">
        <h1 className="self-center text-xl font-bold" />

        <div className="flex">
          {/* <div className="mt-8 rounded-full border border-gray-400 bg-white px-8 py-2 text-center text-gray-400 duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-white hover:text-sky-400 dark:bg-slate-800 dark:text-white   dark:hover:bg-slate-500 dark:hover:text-sky-400 md:mt-4 lg:mt-0">
            Received Product
          </div> */}
          <Link to={`/projectdetails?id=${currentProject?.project?.projectId}`}>
            <div className="brand-color mt-8 ml-3 rounded-full px-8 py-2 text-center text-white duration-200 ease-in   hover:bg-sky-300 md:mt-4 lg:mt-0">
              View Brief
            </div>
          </Link>
        </div>
      </div>
      <Link to={`/projectdetails?id=${currentProject?.project?.projectId}`}>
        <div className="my-10 h-[450px] rounded-2xl bg-white px-10 py-8 opacity-90 transition-all duration-150 hover:cursor-pointer hover:bg-sky-50 hover:opacity-100 hover:shadow-sm dark:bg-slate-800 hover:dark:bg-slate-700 md:h-80">
          <div className="grid md:flex md:justify-between ">
            <div className="grid">
              <h1 className="self-center text-4xl font-bold text-gray-500 dark:text-white">
                {currentProject?.project?.name}
              </h1>
              <h1 className="mt-2 self-center text-lg font-semibold uppercase text-sky-500 ">
                Status: {status}
              </h1>
            </div>

            <div className="mt-10 grid md:mt-0">
              <div className="flex">
                <img
                  src="./img/calendar.png"
                  alt=""
                  className="mr-3 inline h-6 w-6 self-center"
                />
                <h1 className="self-center text-2xl font-semibold text-gray-500 dark:text-white">
                  DUE {dueDate}
                </h1>
              </div>
              <h1 className="self-center text-left font-normal text-gray-500 dark:text-white  md:text-right">
                {remainingDays > 0
                  ? `${Math.abs(remainingDays)} Day(s) Left`
                  : `${Math.abs(remainingDays)} Day(s) Overdue`}
              </h1>
            </div>
          </div>

          <StepsProject currentStep={state} />
        </div>
      </Link>
    </section>
  );
}
