import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { SERVER_URL } from '../../common/CONFIG';
// eslint-disable-next-line import/no-cycle
import { FavoritePhotographersCardBrief } from '../favorite-photographers-card-brief/FavoritePhotographersCardBrief';

type Props = {
  setBriefDetails: any;
  briefDetails: any;
  setForRefresh: any;
  editMode: any;
  project: any;
  creatorsRef: any;
  isDuplicate: any;
};

export function FavoritePhotographers(props: Props) {
  const {
    briefDetails,
    setBriefDetails,
    setForRefresh,
    editMode,
    project,
    creatorsRef,
    isDuplicate,
  } = props;

  const [preferedPhotographerOrder, setPreferedPhotographerOrder] =
    useState<any>([]);

  const [showNoFavsWarning, setShowNoFavsWarning] = useState(false);

  const onChangeBriefDetails = (concept: string, value: string) => {
    const tempstate = { ...briefDetails, [concept]: value };
    setBriefDetails(tempstate);
  };

  const [showDiscardChanges, setShowDiscardChanges] = useState(false);
  const [infoReceived, setInfoReceived] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const editId = query.get('edit');

  useEffect(() => {
    if (editMode) {
      onChangeBriefDetails('favoritePhotographers', project?.creators);

      const tempPrefered = project?.creators;
      tempPrefered?.forEach((element: { added: boolean }) => {
        // eslint-disable-next-line no-param-reassign
        element.added = true;
      });

      setPreferedPhotographerOrder(tempPrefered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, project?.creators]);

  useEffect(() => {
    onChangeBriefDetails('favoritePhotographers', preferedPhotographerOrder);
  }, [preferedPhotographerOrder]);

  useEffect(() => {
    if (!editMode && preferedPhotographerOrder.length < 1 && infoReceived) {
      setShowNoFavsWarning(true);
    } else {
      setShowNoFavsWarning(false);
    }
  }, [editMode, preferedPhotographerOrder, infoReceived]);

  // For reorder when drag n' drop the favourite cards:
  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const [favDelete, setFavDelete] = useState<any>(-1);

  const { user: currentUser } = useSelector((state: any) => state.auth);

  async function getUserInformation() {
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}brandFavoriteCreators/${username}`,
      // headers: authHeader(),
    };

    try {
      const res = await axios(config);
      if (res.status === 201) {
        setPreferedPhotographerOrder(res.data);
        setInfoReceived(true);
      }
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }


  useEffect(() => {
    if (editId === null || isDuplicate) {
      getUserInformation();
    }
  }, [editMode, editId, isDuplicate]);

  useEffect(() => {
    if (favDelete !== -1) {
      const tempArr = [...preferedPhotographerOrder];
      const result = tempArr.filter((item) => item?.user?.id !== favDelete);
      setPreferedPhotographerOrder(result);

      //  getUserInformation();
    }
  }, [favDelete]);

  return (
    <>
      <Transition appear show={showNoFavsWarning} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[101] overflow-y-auto"
          onClose={() => setShowNoFavsWarning(!showNoFavsWarning)}
        >
          <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
          <div className="px-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="mx-auto my-8 inline-block  w-full rounded-[2.5rem] bg-white p-1 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-[800px]">
                <section className="m-auto grid w-full">
                  <div className="confirm-background-small relative grid p-10 text-center ">
                    <strong className="self-center pb-10 pt-5 text-3xl font-bold text-gray-700 dark:text-white">
                      You need to have at least one favorite photographer to
                      create a brief.
                    </strong>

                    <div className="inline-flex w-full justify-center gap-6">
                      <Link
                        to="/photographer"
                        className="z-0 self-center rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-2 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                      >
                        Search for Photographers
                      </Link>
                    </div>
                  </div>
                </section>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={showDiscardChanges} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[101] overflow-y-auto"
          onClose={() => setShowDiscardChanges(!showDiscardChanges)}
        >
          <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
          <div className="px-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="mx-auto my-8 inline-block  w-full rounded-[2.5rem] bg-white p-1 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-[800px]">
                <section className="m-auto grid w-full">
                  <div className="confirm-background-small relative grid p-10 text-center ">
                    <strong className="self-center pb-10 pt-5 text-3xl font-bold text-gray-700 dark:text-white">
                      Discard changes ?
                    </strong>

                    <div className="inline-flex w-full justify-center gap-6">
                      <Link
                        to="/photographer"
                        className="z-0 self-center rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-2 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                      >
                        Discard Changes
                      </Link>
                      <div
                        onClick={() =>
                          setShowDiscardChanges(!showDiscardChanges)
                        }
                        onKeyDown={() =>
                          setShowDiscardChanges(!showDiscardChanges)
                        }
                        role="button"
                        tabIndex={0}
                        className="z-0 self-center rounded-full border-2 border-gray-300 px-16 py-2 text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400"
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result;
          if (!destination) {
            return;
          }
          if (
            source.index === destination.index &&
            source.droppableId === destination.droppableId
          ) {
            return;
          }
          setPreferedPhotographerOrder((oldPrefs: any) =>
            reorder(oldPrefs, source.index, destination.index),
          );
        }}
      >
        <section className="mx-auto w-10/12 text-center" ref={creatorsRef}>
          <div className="my-16  px-0 md:px-0">
            <div className="mx-auto flex content-center items-center justify-center self-center text-center">
              <img
                src="./img/bookmarkFav.png"
                alt=""
                className="mr-4 inline h-7 w-5 self-center text-center align-middle"
              />
              <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
                Favorite Photographers
              </h1>
            </div>
            <p className="mt-2 text-lg  font-normal text-gray-600 dark:text-white">
              Talented creators you liked for this project. Drag them in the
              order of priority.
            </p>
          </div>

          <Droppable
            droppableId="photographerCard"
            direction={window.innerWidth > 700 ? 'horizontal' : 'vertical'}
          >
            {(droppableProvided) => (
              <section
                className="m-auto grid justify-center overflow-hidden md:grid-cols-4 md:place-content-stretch md:gap-[4.16vw] md:p-5 "
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {preferedPhotographerOrder?.map((element: any, index: any) => (
                  <Draggable
                    key={`dragg + ${index.toString()}`}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(draggableProvided) => (
                      <FavoritePhotographersCardBrief
                        innerRef={draggableProvided.innerRef}
                        provided={draggableProvided}
                        photographer={element}
                        prefPosition={index}
                        setFavDelete={setFavDelete}
                        setPreferedPhotographerOrder={
                          setPreferedPhotographerOrder
                        }
                        preferedPhotographerOrder={preferedPhotographerOrder}
                        setForRefresh={setForRefresh}
                        editMode={editMode}
                      />
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </section>
            )}
          </Droppable>

          {!preferedPhotographerOrder?.length && (
            <h1 className="mb-24 mt-10 animate-pulse text-xl font-bold text-gray-500">
              You don´t have any favorite Photographers!{' '}
            </h1>
          )}

          <div
            role="button"
            tabIndex={0}
            onClick={() => setShowDiscardChanges(true)}
          >
            <p className="mt-8 mr-7 text-right font-bold text-gray-500 transition ease-in hover:translate-x-2 hover:cursor-pointer hover:text-sky-500 hover:transition  hover:duration-100 hover:ease-in dark:text-white dark:hover:text-sky-500 md:mr-16 ">
              Find Other Photographers &gt;
            </p>
          </div>
        </section>
      </DragDropContext>
    </>
  );
}
