import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { RegionModal } from '../region-modal/RegionModal';
import './style.scss';

const shotLocation = [
  {
    location: 'Home',
    selected: false,
    tooltip: "kitchen, backyard/garden, home office..."
  },
  {
    location: 'Urban',
    selected: false,
    tooltip: "city, sidewalks, retail, parks..."
  },
  {
    location: 'Outdoor',
    selected: false,
    tooltip: "forest, beach, mountains, lake..."
  },
];

const age = [
  {
    concept: 'Child',
    selected: false,
  },
  {
    concept: 'Teen',
    selected: false,
  }, 
  {
    concept: 'Adult',
    selected: false,
  },
  {
    concept: 'Senior',
    selected: false,
  },
  {
    concept: 'N/A',
    selected: false,
  },
];

const activityAccess = [
  {
    location: 'Winter',
    selected: false,
    tooltip: "skiing, snowboarding, sledding, snow shoeing..."
  },
  {
    location: 'Coastal',
    selected: false,
    tooltip: "swimming, surfing, tide pools, sandcastles..."
  },
  {
    location: 'Camping',
    selected: false,
    tooltip: "backcountry and car camping..."
  },
  {
    location: 'Farm',
    selected: false,
    tooltip: "livestock, farm equipment, agriculture..."
  },
  {
    location: 'Team Sports',
    selected: false,
    tooltip: "soccer, basketball, baseball..."
  },
  {
    location: 'Hunting And Fishing',
    selected: false,
    tooltip: "fresh & saltwater fishing, fowl hunting, big game..."
  },
  {
    location: 'Health',
    selected: false,
    tooltip: "running, biking, fitness, cardio..."
  },
  {
    location: 'Physical Work',
    selected: false,
    tooltip: "construction, woodworking, industrial..."
  },
];

type Props = {
  setSearchLocation: any,
  setSearchActivity: any,
  setSearchAge: any,
  setEnabledFavourite : any,
  enabledFavourite : any,
}

export function FilterCreators(props : Props) {

const {setSearchLocation, setSearchActivity, setSearchAge, enabledFavourite, setEnabledFavourite} = props

  const [isRegionModalOpen, setRegionModalIsOpen] = useState(false);

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 700) {
      setOpen(true);
    }
  }, []);

const [checkedShotLocation, setCheckedShotLocation] = useState<any>(shotLocation);
const [checkedActivities, setCheckedActivities] = useState<any>(activityAccess);
const [checkedAge, setCheckedAge] = useState<any>(age);

const handleOnChangeLocation = (activity: string) => {
  const tempstate = [...checkedShotLocation];
  const index = tempstate.findIndex(
    (activities) => activities.location === activity,
  );
  const tempelement = { ...checkedShotLocation[index] };
  tempelement.selected = !tempelement.selected;
  tempstate[index] = tempelement;

  setCheckedShotLocation(tempstate);
  setSearchLocation(tempstate)

};

const handleOnChangeAge = (selectedAge: string) => {
  const tempstate = [...checkedAge];
  const index = tempstate.findIndex(
    (ages) => ages.concept === selectedAge,
  );
  const tempelement = { ...checkedAge[index] };
  tempelement.selected = !tempelement.selected;
  tempstate[index] = tempelement;

  setCheckedAge(tempstate);
  setSearchAge(tempstate)

};

const handleOnChangeActivity = (activity: string) => {
  const tempstate = [...checkedActivities];
  const index = tempstate.findIndex(
    (activities) => activities.location === activity,
  );
  const tempelement = { ...checkedActivities[index] };
  tempelement.selected = !tempelement.selected;
  tempstate[index] = tempelement;

  setCheckedActivities(tempstate);
  setSearchActivity(tempstate)

};

  return (
    <section className="mx-auto h-fit w-[21.875rem] rounded-2xl bg-white p-6 dark:bg-slate-800 md:w-72">
      <div
        className="flex hover:cursor-pointer"
        onClick={() => setOpen(!isOpen)}
        onKeyDown={() => setOpen(!isOpen)}
        role="button"
        tabIndex={0}
      >
        <img src="./img/filter.png" alt="" className="h-6 w-5 dark:invert" />
        <h1 className="ml-5 text-xl font-semibold text-gray-900 dark:text-white">
          Filter Creators
        </h1>
      </div>

      {isOpen && (
        <section className="filter-creators">
          <hr className="my-5" />
          <h1 className="text-lg text-gray-700 dark:text-white">Location</h1>
          <div className="grid py-4 ">

          {checkedShotLocation.map((element: any, index: Number) => (
            <label
              htmlFor={element.location}
              className="my-1 flex items-center text-gray-700 dark:text-white"
              key={`shot + ${index.toString()}`}
            >
              <input
                className="float-left mr-4 h-5 w-5 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:border-sky-500 checked:bg-sky-300 focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300"
                type="checkbox"
                value=""
                id={`checkbox-${index}`}
                name={element.location}
                checked={element.selected}
                onChange={() =>  handleOnChangeLocation(element.location)}
              />
              {element.location}
              <span
                data-tip
                data-for={element.location}
                className="mx-2 cursor-pointer text-xs text-gray-400 dark:text-white"
              >
                <FontAwesomeIcon className="" icon={faQuestionCircle} />
              </span>
              <ReactTooltip
                id={element.location}
                type="info"
                effect="solid"
                className="tooltip"
                place="right"
                arrowColor="#54a3f5"
              >
                 {element.tooltip}
              </ReactTooltip>
            </label>

          ))}


          </div>
          <h1 className="text-lg text-gray-700 dark:text-white">Ages</h1>
          <div className="grid py-4 ">
          {checkedAge.map((element: any, index: Number) => (
            
                <div
                key={`age + ${index.toString()}`}      
                  className="my-1 flex items-center text-gray-700 dark:text-white"                 
                >
                  <input
                    className="float-left mr-4 h-5 w-5 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:border-sky-500 checked:bg-sky-300 focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300"
                    type="checkbox"
                    value=""
                    id={`checkboxAges-${index}`}
                    name={element.concept}
                    checked={element.selected}
                    onChange={() =>  handleOnChangeAge(element.concept)}
                  />
                  {element.concept}
        
                </div>
      
          ))}
          </div>


          <h1 className="text-lg text-gray-700 dark:text-white">Activities</h1>
          <div className="grid py-4 ">

          {checkedActivities.map((element: any, index: Number) => (
            <label
              htmlFor={element.location}
              className="my-1 flex items-center text-gray-700 dark:text-white"
              key={`act + ${index.toString()}`}
            >
              <input
                className="float-left mr-4 h-5 w-5 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:border-sky-500 checked:bg-sky-300 focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300"
                type="checkbox"
                value=""
                id={`checkbox-${index}`}
                name={element.location}
                checked={element.selected}
                onChange={() =>  handleOnChangeActivity(element.location)}
              />
              {element.location}
              <span
                data-tip
                data-for={element.location}
                className="mx-2 cursor-pointer text-xs text-gray-400 dark:text-white"
              >
                <FontAwesomeIcon className="" icon={faQuestionCircle} />
              </span>
              <ReactTooltip
                id={element.location}
                type="info"
                effect="solid"
                className="tooltip"
                place="right"
                arrowColor="#54a3f5"
              >
                 {element.tooltip}
              </ReactTooltip>
            </label>

          ))}

          </div>
          <div className="grid text-left">
            <h1 className="mb-5 text-xl font-normal text-gray-700 dark:text-white">
              Filter by favorite photographers only.
            </h1>
            <Switch
              checked={enabledFavourite}
              onChange={()=>setEnabledFavourite(!enabledFavourite)}
              className={`${enabledFavourite ? 'bg-sky-300' : 'bg-gray-300'}
          duration-400 relative inline-flex h-[1.813rem] w-[4.063rem] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span
                aria-hidden="true"
                className={`${enabledFavourite ? 'translate-x-9' : 'translate-x-0'}
            duration-400 pointer-events-none inline-block h-[1.563rem] w-[1.563rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out dark:bg-slate-800`}
              />
            </Switch>
            {/* <div
              role="button"
              tabIndex={0}
              onKeyDown={() => setRegionModalIsOpen(!isRegionModalOpen)}
              onClick={() => setRegionModalIsOpen(!isRegionModalOpen)}
              className="mt-7 w-full place-self-center self-center rounded-3xl border-2 border-gray-300 px-5 py-2 text-center text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400"
            >
              Filter By Region
            </div> */}
            <Link to="/brief">
              <div className="my-10 rounded-3xl bg-sky-500 px-6 py-2 text-center text-white shadow-lg shadow-sky-500/50 duration-200 ease-in hover:bg-sky-400 dark:hover:bg-sky-300">
                Add Brief Details
              </div>
            </Link>
          </div>
        </section>
      )}

      <RegionModal state={isRegionModalOpen} />
    </section>
  );
}
