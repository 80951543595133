import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
  SERVER_URL,
  USERS_IMAGES_URL,
  BUCKET_IMAGES,
} from '../../common/CONFIG';

type PropsBrandUser = {
  onChangeAvatar: any;
  onChangeExtraBrand: any;
  brandInfoExtra: any;
  editMode: boolean;
};

export default function BrandFields(props: PropsBrandUser) {
  const { onChangeAvatar, onChangeExtraBrand, brandInfoExtra, editMode } =
    props;
  const [favorites, setFavorites] = useState<any>([]);
  const [inspirations, setInspirations] = useState<any>([]);

  async function getUserInformation() {
    const username = brandInfoExtra?.userId;
    const config = {
      method: 'get',
      url: `${SERVER_URL}brandFavoriteCreatorsForAdmin/${username}`,
      // headers: authHeader(),
    };

    try {
      const res = await axios(config);
      if (res.status === 201) {
        setFavorites(res.data?.creators);
        setInspirations(res.data?.inspirations);
      }
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    getUserInformation();
  }, [brandInfoExtra]);

  return (
    <div>
      <h1 className="text-xl font-semibold ">Extra brand fields</h1>
      <br />
      {!editMode && (
        <>
          <p>Avatar</p>
          <input
            onChange={(e) => onChangeAvatar(e)}
            type="file"
            name="uploadImages"
            accept="image/*"
            className=""
          />
          <br />
          <br />
        </>
      )}

      <section className="m-auto grid w-6/12 gap-3 md:grid-cols-2">
        <div className="">
          <p>Title</p>
          <input
            value={brandInfoExtra?.title}
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="title"
            onChange={(e) => onChangeExtraBrand('title', e.target.value)}
          />
        </div>

        <div className="">
          <p>Company Name</p>
          <input
            value={brandInfoExtra?.companyName}
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="companyName"
            onChange={(e) => onChangeExtraBrand('companyName', e.target.value)}
          />
        </div>

        <div className="">
          <p>Company WEB</p>
          <input
            value={brandInfoExtra?.companyWeb}
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="companyWeb"
            onChange={(e) => onChangeExtraBrand('companyWeb', e.target.value)}
          />
        </div>

        <div className="">
          <p>Company FaceBook</p>
          <input
            value={brandInfoExtra?.companyFB}
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="companyFB"
            onChange={(e) => onChangeExtraBrand('companyFB', e.target.value)}
          />
        </div>

        <div className="">
          <p>Company Instagram</p>
          <input
            value={brandInfoExtra?.companyIG}
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="companyIG"
            onChange={(e) => onChangeExtraBrand('companyIG', e.target.value)}
          />
        </div>
      </section>

      <p>Bio</p>
      <textarea
        value={brandInfoExtra?.bio}
        rows={3}
        className="mb-5 w-6/12 rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
        name="bio"
        onChange={(e) => onChangeExtraBrand('bio', e.target.value)}
      />

      <br />
      <br />
      <h1 className="text-xl font-semibold ">
        Favorite creators of {brandInfoExtra?.companyName}
      </h1>
      <br />
      <br />
      <div className="grid grid-cols-4 gap-4">
        {favorites?.map((element: any, index: number) => (
          <div className="mb-5 rounded-2xl bg-slate-200 p-5 opacity-90 transition-all duration-300 hover:bg-sky-50 hover:opacity-100 hover:shadow dark:bg-slate-700 dark:hover:bg-slate-500 md:relative md:mb-0 md:h-[23.75vh]  md:w-fit">
            <div className="flex content-center items-center justify-center text-center align-middle">
              <img
                src={`${BUCKET_IMAGES}avatar/${element.user?.username}/${element.creatorExtraInfo?.avatarUrl}`}
                alt=""
                className="m-auto flex h-16 w-16 content-center items-center justify-center self-center rounded-full object-cover align-middle"
              />
            </div>
            <div className="mt-7 md:mt-[4vh]">
              <p className="text-xs font-normal text-gray-600   dark:text-white  md:text-[1.5vh]">
                {element.creatorExtraInfo?.nickname}
              </p>
              <br />
              <p className="text-xs font-normal text-gray-600   dark:text-white   md:text-[1.5vh]">
                {element.user?.email}
              </p>
            </div>
          </div>
        ))}
      </div>

      <br />
      <br />
      <h1 className="text-xl font-semibold ">Brand´s Inspiration photos</h1>
      <br />
      <br />
      <div className="grid grid-cols-6 gap-2">
        {inspirations?.map((element: any, index: number) => (
          <div className="mb-3 rounded-2xl bg-slate-200 p-3 opacity-90 transition-all duration-300 hover:bg-sky-50 hover:opacity-100 hover:shadow dark:bg-slate-700 dark:hover:bg-slate-500 md:relative md:mb-0  md:w-[100%]">
            <div className="flex content-center items-center justify-center text-center align-middle">
              <img
                src={`${USERS_IMAGES_URL}${element?.photo}`}
                alt=""
                className="m-auto flex h-20 w-20 content-center items-center justify-center self-center rounded-xl object-cover align-middle"
              />
            </div>
            <div className="mt-7 md:mt-[4vh]">
              <p className="text-xs font-normal text-gray-600   dark:text-white  md:text-[1.5vh]">
                by: {element?.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
