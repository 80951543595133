import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BUCKET_IMAGES } from '../../common/CONFIG';
import './style.scss';

type Props = {
  setIsOpen: any;
  isOpen: boolean;
  data: any;
  setSubmit: any;
  submit: any;
};

export function ModalConfirm(props: Props) {
  const { isOpen, setIsOpen, data, submit, setSubmit } = props;

  const [submiting, setSubmiting] = useState<any>(false);

  function closeModal() {
    setSubmiting(false);
    setIsOpen(false);
  }
  function submitMe() {
    setSubmiting(true);
    setSubmit(true);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="mx-auto my-8 inline-block  w-full rounded-[2.5rem] bg-white p-1 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-[800px]">
              <section className="m-auto grid w-full">
                {submiting ? (
                  <div className="h-[300px] items-center place-self-center self-center p-[15%] text-center">
                    <svg
                      className="-ml-1 mr-3 h-20 w-20 animate-spin text-sky-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className=" confirm-background relative grid p-10 text-center ">
                    <div className="container-confirm-brief absolute -top-[4.8125rem] inline-flex items-center justify-center justify-self-center">
                      {data?.map(
                        (creator: any, index: any) =>
                          creator?.added &&
                          (creator.creatorExtraInfo?.avatarUrl ? (
                            <img
                              src={`${BUCKET_IMAGES}avatar/${creator?.user?.username}/${creator.creatorExtraInfo?.avatarUrl}`}
                              alt=""
                              className="image-confirm-brief h-[210px] w-[210px] rounded-full object-cover"
                            />
                          ) : (
                            <img
                              src={`${BUCKET_IMAGES}avatar/${creator?.userInfo?.username}/${creator.creatorInfo?.avatarUrl}`}
                              alt=""
                              className="image-confirm-brief h-[210px] w-[210px] rounded-full object-cover"
                            />
                          )),
                      )}
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <strong className="self-center text-3xl font-bold text-gray-900 dark:text-white">
                      Please Confirm Your Minimum Order
                    </strong>

                    <br />

                    {data?.map(
                      (creator: any) =>
                        creator?.added &&
                        (creator.creatorExtraInfo?.nickname ? (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-white">
                              {creator.creatorExtraInfo?.nickname} has a
                              requested minimum purchase of{' '}
                              <strong>
                                {creator.creatorExtraInfo?.minPhotosRequest}{' '}
                                images.
                              </strong>
                              <br />
                              my intention is to meet this request.
                            </p>
                            <br />
                          </div>
                        ) : (
                          <div>
                            <p className="text-sm text-gray-500 dark:text-white">
                              {creator.creatorInfo?.nickname} has a requested
                              minimum purchase of{' '}
                              <strong>
                                {creator.creatorInfo?.minPhotosRequest} images.
                              </strong>
                              <br />
                              my intention is to meet this request.
                            </p>
                            <br />
                          </div>
                        )),
                    )}

                    <br />

                    <div className="inline-flex w-full justify-center gap-6">
                      <div
                        onClick={submitMe}
                        onKeyDown={submitMe}
                        role="button"
                        tabIndex={0}
                        className="z-0 self-center rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-2 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                      >
                        Accept & Submit
                      </div>
                      <div
                        onClick={closeModal}
                        onKeyDown={closeModal}
                        role="button"
                        tabIndex={0}
                        className="z-0 self-center rounded-full border-2 border-gray-300 px-16 py-2 text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400"
                      >
                        Cancel
                      </div>
                    </div>
                    <br />
                  </div>
                )}
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
