/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../common/CONFIG';
import './style.scss';

type Props = {
  numberMessages: any;
  print: boolean;
  invitations: any;
};

export function MessagesBarCreator(props: Props) {
  const { numberMessages, print, invitations } = props;
  const { user: currentUser } = useSelector((state: any) => state.auth);

  const navigate = useNavigate();

  function reviewNewInvitation() {
    if (invitations?.projectId !== undefined) {
      navigate(`/projectdetails?id=${invitations?.projectId}`);
    }
  }

  const [currentLocation, setCurrentLocation] = useState<any>();
  const [isAnNewInvite, setIsAnNewInvite] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [decision, setDecision] = useState();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get('id');

  useEffect(() => {
    setCurrentLocation(id);
  }, [id]);

  useEffect(() => {
    if (invitations?.projectId) {
      if (
        invitations?.projectId.toString() === currentLocation &&
        invitations?.status === 'ASKING'
      ) {
        setIsAnNewInvite(true);
        setShowToast(true);
      } else {
        setIsAnNewInvite(false);
        setShowToast(true);
      }
    } else {
      setShowToast(false);
    }
  }, [currentLocation, invitations]);

  function acceptProject() {
    sendResponseForNewProject(true);
  }

  function declineProject() {
    sendResponseForNewProject(false);
  }

  async function sendResponseForNewProject(answer: boolean) {
    const route = `${SERVER_URL}acceptOrDeclineProject/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        projectId: invitations.projectId,
        creator: currentUser.id,
        decision: answer,
      })
      // eslint-disable-next-line promise/always-return
      .then((res) => setDecision(res?.data))
      .catch(() => {});
  }

  useEffect(() => {
    if (decision === true || decision === false) {
      navigate(`/projectCreator`);
    }
  }, [decision]);

  return (
    <>
      <div className="top-15 absolute z-[90] grid w-full  justify-center pt-8 text-center md:flex md:w-full">
        <section className="inline-flex">
          {showToast && (
            <div className="mx-auto grid w-11/12 animate-pulse justify-center rounded-2xl bg-white p-5 text-center align-middle dark:bg-slate-800 md:flex md:w-[35.45vw]">
              {isAnNewInvite ? (
                <div className="md:flex">
                  <p className="self-center align-middle text-gray-500 dark:text-white md:text-[1.7vh]">
                    Are you available for this project?
                  </p>
                  <div
                    onClick={() => acceptProject()}
                    role="button"
                    tabIndex={0}
                    className="mt-5 inline-block rounded-full bg-slate-400 px-5 py-2 text-white duration-200 ease-in hover:cursor-pointer hover:bg-slate-300 md:mt-0 md:ml-5   md:px-[1.04vw] md:py-[0.85vh] md:text-[1.7vh] "
                  >
                    Available
                  </div>
                  <div
                    onClick={() => declineProject()}
                    role="button"
                    tabIndex={0}
                    className="mt-5 inline-block rounded-full bg-slate-400 px-5 py-2 text-white duration-200 ease-in hover:cursor-pointer hover:bg-slate-300 md:mt-0 md:ml-5   md:px-[1.04vw] md:py-[0.85vh] md:text-[1.7vh] "
                  >
                    Unavailable
                  </div>
                </div>
              ) : (
                <div className="md:flex">
                  <p className="self-center align-middle text-gray-500 dark:text-white md:text-[1.7vh]">
                    You have been invited to a new project
                  </p>
                  <div
                    onClick={() => reviewNewInvitation()}
                    role="button"
                    tabIndex={0}
                    className="mt-5 inline-block rounded-full bg-slate-400 px-5 py-2 text-white duration-200 ease-in hover:cursor-pointer hover:bg-slate-300 md:mt-0 md:ml-5   md:px-[1.04vw] md:py-[0.85vh] md:text-[1.7vh] "
                  >
                    Review / Accept
                  </div>
                </div>
              )}
            </div>
          )}

          {/* <div className="top-[9.0625rem] right-[9.375rem] mt-5 ">
          <div className="inline-block rounded-full bg-gray-600 px-5 py-[8px]   text-white  duration-200 ease-in hover:cursor-pointer hover:bg-gray-500 dark:text-white md:ml-[5rem] md:px-[1.04vw]  md:py-[0.85vh] md:text-[1.7vh]">
            Messages
            <span className="background-message p-4 text-xs">
              {numberMessages}
            </span>
          </div>

          {print && (
            <div className="ml-5 inline-block rounded-full bg-white px-5   py-2 text-gray-500 duration-200   ease-in  hover:cursor-pointer  hover:bg-sky-50 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 md:ml-[1.04vw] md:px-[1.04vw] md:py-[0.85vh]   md:text-[1.7vh]  ">
              Print Brief
              <span className="print-brief ml-3 p-4 text-xs" />
            </div>
          )}
        </div> */}
        </section>
      </div>
      {currentUser && (
        <div className="-mb-[20px] h-32 bg-gray-100 dark:bg-slate-900" />
      )}
    </>
  );
}
