import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  UserInformationOnboarding,
  SelectCategory,
  AddTalentModels,
  UploadPortfolio,
} from 'components';
// borrar componente: AddTalentModelsOnboarding

import { notifyOnboardingFinished } from 'actions/auth';
import {
  shotLocation,
  activityAccess,
  specializationCategories,
} from '../../../components/select-category/SelectCategoryData';
import authHeader from '../../../common/services/auth-header';
import { SERVER_URL } from '../../../common/CONFIG';

export default function ProfileOnboarding() {
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [user, setUser] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const creatorInfoExtraItems = {
    first_name: '',
    last_name: '',
    address: '',
    contactViaPhone: true,
    phone: '',
    city: '',
    zipCode: '',
    state: '',
    bio: '',
  };

  const [userData, setUserData] = useState<any>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [creatorInfoExtra, setCreatorInfoExtra] = useState<any>(
    creatorInfoExtraItems,
  );

  const onChangeExtraCreator = (concept: string, value: string) => {
    const tempstate = { ...creatorInfoExtra, [concept]: value };
    setCreatorInfoExtra(tempstate);
  };

  const [avatarPhoto, setAvatarPhoto] = useState<any | null>();
  // Only for profile
  const [addNewTalent, setAddNewTalent] = useState<any>();
  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setAvatarPhoto(fileList[0]);
  };

  const [checkedStateActivity, setCheckedStateActivity] =
    useState<any>(activityAccess);
  const handleOnChangeActivity = (activity: string) => {
    const tempstate = [...checkedStateActivity];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedStateActivity[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedStateActivity(tempstate);
  };

  const [checkedSpecializationCategories, setCheckedSpecializationCategories] =
    useState<any>(specializationCategories);
  const handleOnChangeSpecializationCategories = (activity: string) => {
    const tempstate = [...checkedSpecializationCategories];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedSpecializationCategories[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedSpecializationCategories(tempstate);
  };

  const [checkedShotLocation, setCheckedShotLocation] =
    useState<any>(shotLocation);
  const handleOnChangeShotLocation = (activity: string) => {
    const tempstate = [...checkedShotLocation];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedShotLocation[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedShotLocation(tempstate);
  };

  const [creatorTalents, setCreatorTalents] = useState<any>([]);
  const [creatorPortfolioPhotos, setCreatorPortfolioPhotos] = useState<any>([]);
  const [portfolioPhotosSizes, setPortfolioPhotosSizes] = useState<any>([]);

  useEffect(() => {
    preparePhotosDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorPortfolioPhotos]);

  function preparePhotosDimensions() {
    setPortfolioPhotosSizes([]);

    for (const results of creatorPortfolioPhotos) {
      const img = document.createElement('img');
      const blob = URL.createObjectURL(results);

      img.src = blob;
      img.onload = function getSizes() {
        const w = img.width;
        const h = img.height;
        const dimensions = `${w}X${h}`;
        setPortfolioPhotosSizes((prevArray: any) => [...prevArray, dimensions]);
      };
    }
  }

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Onboarding';
  }, []);

  const [currentStep, setCurrentStep] = useState(1);

  function handlePreviousStep() {
    setCurrentStep(currentStep - 1);
  }

  const creatorfirstname: any = useRef();
  const creatorlastname: any = useRef();
  const creatoraddress: any = useRef();
  const creatorphone: any = useRef();
  const creatorcity: any = useRef();
  const creatorzip: any = useRef();
  const creatorstate: any = useRef();
  const creatorbio: any = useRef();
  const creatoravatar: any = useRef();
  const paypalEmail: any = useRef();

  const stateActivityRef: any = useRef();
  const specializationCategoriesRef: any = useRef();
  const shotLocationRef: any = useRef();

  function handleNextStep() {
    if (currentStep === 1) {
      let wrongValidation = false;

      if (creatorfirstname.current.value.length === 0) {
        creatorfirstname.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatorfirstname.current?.classList.remove('border-red-500');
      }

      if (creatorlastname.current.value.length === 0) {
        creatorlastname.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatorlastname.current?.classList.remove('border-red-500');
      }

      if (creatoraddress.current.value.length === 0) {
        creatoraddress.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatoraddress.current?.classList.remove('border-red-500');
      }

      if (creatorphone.current.value.length === 0) {
        creatorphone.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatorphone.current?.classList.remove('border-red-500');
      }

      if (creatorcity.current.value.length === 0) {
        creatorcity.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatorcity.current?.classList.remove('border-red-500');
      }

      if (creatorzip.current.value.length === 0) {
        creatorzip.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatorzip.current?.classList.remove('border-red-500');
      }

      if (creatorstate.current.value.length === 0) {
        creatorstate.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatorstate.current?.classList.remove('border-red-500');
      }

      if (creatorbio.current.value.length === 0) {
        creatorbio.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatorbio.current?.classList.remove('border-red-500');
      }

      if (paypalEmail.current.value.length === 0) {
        paypalEmail.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        paypalEmail.current?.classList.remove('border-red-500');
      }

      if (!avatarPhoto) {
        creatoravatar.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        creatoravatar.current?.classList.remove('border-red-500');
      }

      if (!wrongValidation) {
        setCurrentStep(currentStep + 1);
      }
    }

    if (currentStep === 2) {
      let flagStateActivity = false;
      let flagSpecializationCategories = false;
      let flagShotLocation = false;

      checkedStateActivity.forEach((element: { selected: any }) => {
        if (element.selected) {
          flagStateActivity = true;
        }
      });

      checkedSpecializationCategories.forEach((element: { selected: any }) => {
        if (element.selected) {
          flagSpecializationCategories = true;
        }
      });

      checkedShotLocation.forEach((element: { selected: any }) => {
        if (element.selected) {
          flagShotLocation = true;
        }
      });

      if (!flagStateActivity) {
        stateActivityRef.current?.classList.add(
          'text-red-500',
          'animate-pulse',
        );
      } else {
        stateActivityRef.current?.classList.remove(
          'text-red-500',
          'animate-pulse',
        );
      }

      if (!flagSpecializationCategories) {
        specializationCategoriesRef.current?.classList.add(
          'text-red-500',
          'animate-pulse',
        );
      } else {
        specializationCategoriesRef.current?.classList.remove(
          'text-red-500',
          'animate-pulse',
        );
      }

      if (!flagShotLocation) {
        shotLocationRef.current?.classList.add('text-red-500', 'animate-pulse');
      } else {
        shotLocationRef.current?.classList.remove(
          'text-red-500',
          'animate-pulse',
        );
      }

      if (
        flagStateActivity &&
        flagSpecializationCategories &&
        flagShotLocation
      ) {
        setCurrentStep(currentStep + 1);
      }
    }

    if (currentStep === 3 || currentStep === 4) {
      setCurrentStep(currentStep + 1);
    }
  }

  const [afterOnBoarding, setAfterOnBoarding] = useState<boolean>(false);
  const [stepOne, setStepOne] = useState('grayscale');
  const [stepTwo, setStepTwo] = useState('grayscale');
  const [stepThree, setStepThree] = useState('grayscale');
  const [stepFour, setStepFour] = useState('grayscale');

  useEffect(() => {
    if (currentStep < 1) {
      setCurrentStep(1);
    }
    if (currentStep > 4) {
      setCurrentStep(4);
    }
    switch (currentStep) {
      case 1:
        setStepOne('');
        setStepTwo('grayscale');
        setStepThree('grayscale');
        setStepFour('grayscale');
        break;
      case 2:
        setStepOne('');
        setStepTwo('');
        setStepThree('grayscale');
        setStepFour('grayscale');
        break;
      case 3:
        setStepOne('');
        setStepTwo('');
        setStepThree('');
        setStepFour('grayscale');
        break;
      case 4:
        setStepOne('');
        setStepTwo('');
        setStepThree('');
        setStepFour('');
        break;
      default:
      // code default block
    }
  }, [currentStep]);

  const navigate = useNavigate();

  async function handleSubmitEverything(e: any) {
    e.preventDefault();
    setIsUploading(true);

    try {
      await uploadExtraFields();
      await uploadTalents();
      await uploadPortfolio();

      // update onboardFinish flag
      dispatch(notifyOnboardingFinished());
      const storedUser = localStorage.getItem('userAuthenticApp') || '';
      const userInfo = JSON.parse(storedUser);
      if (!userInfo.onboardFinish) {
        userInfo.onboardFinish = true;
      }
      localStorage.setItem('userAuthenticApp', JSON.stringify(userInfo));
      setIsUploading(false);
      navigate('/profile');
    } catch (err) {
      console.log('Error while submitting everything', err);
    }
  }

  const [type, setType] = useState('creator');

  async function uploadAvatar() {
    if (avatarPhoto) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('uploadImages', avatarPhoto);
      formData.append('type', type);

      const route = `${SERVER_URL}upload-avatar/${currentUser?.username}`;
      // eslint-disable-next-line promise/no-nesting
      await axios
        .post(route, formData, config)
        .then((resp) => resp.data)
        .catch(() => {});
    }
  }

  useEffect(() => {
    setAfterOnBoarding(false);
  }, []);

  async function uploadExtraFields() {
    const route = `${SERVER_URL}create-creator-extra/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        checkedStateActivity: JSON.stringify(checkedStateActivity),
        checkedShotLocation: JSON.stringify(checkedShotLocation),
        checkedSpecializationCategories: JSON.stringify(
          checkedSpecializationCategories,
        ),
        creatorInfoExtra: JSON.stringify(creatorInfoExtra),
        first_name: creatorInfoExtra?.first_name,
        last_name: creatorInfoExtra?.last_name,
        userType: type,
      })
      .then((resp) => {
        if (resp.status === 201) {
          uploadAvatar();
        }
        return resp.data;
      })
      .catch(() => {});
  }

  async function uploadTalents() {
    const uploadTalentsPhotos = new FormData();
    const temp = [...creatorTalents];
    Object.values(temp).forEach((talent) => {
      uploadTalentsPhotos.append('uploadPhotos', talent.photoTalent);
    });
    uploadTalentsPhotos.append(
      'creatorTalents',
      JSON.stringify(creatorTalents),
    );
    uploadTalentsPhotos.append('type', type);

    try {
      const res = await axios.post(
        `${SERVER_URL}upload-talents/${currentUser?.username}`,
        uploadTalentsPhotos,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );
      // eslint-disable-next-line no-console
      //  console.log(res);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  async function uploadPortfolio() {
    // upload all photos

    const uploadPortfolioPhotos = new FormData();
    const temp = [...creatorPortfolioPhotos];
    Object.values(temp).forEach((element) => {
      uploadPortfolioPhotos.append('uploadPhotos', element);
    });

    uploadPortfolioPhotos.append(
      'uploadPhotosSizes',
      JSON.stringify(portfolioPhotosSizes),
    );

    uploadPortfolioPhotos.append('type', type);

    const res: any = await axios
      .post(
        `${SERVER_URL}upload-photos/${currentUser?.username}`,
        uploadPortfolioPhotos,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
      .then((resp) => {
        if (resp.status === 201) {
          //
        }
        return res.data;
      })
      .catch(() => {});
  }

  return (
    <div className="bg-gray-100 dark:bg-slate-900      ">
      <section className="m-auto min-h-[772px] w-11/12 bg-gray-100 py-10 dark:bg-slate-900 md:w-11/12">
        <div className="h-fit rounded-2xl bg-white px-3 py-10 dark:bg-slate-800 md:px-20">
          {/* STEPS START */}
          <section className="my-5 h-40 ">
            <div className="m-auto my-20 w-10/12">
              <div className="project-step mt-10 flex justify-between text-center font-bold text-sky-400">
                <div className={`${stepOne}`}>
                  <section className="relative grid justify-items-center">
                    <p className="absolute bottom-[70px] text-sm md:w-[125px] md:text-base">
                      Information
                    </p>
                    <div className="step-background mt-3 self-center text-xl leading-[51px]">
                      <p>1</p>
                    </div>
                  </section>
                </div>
                <div className={`${stepTwo} flex w-full`}>
                  <hr className="step-hr" />

                  <section className="relative grid justify-items-center">
                    <p className="absolute bottom-[70px] text-sm md:w-[155px] md:text-base">
                      Categories
                    </p>
                    <div className="step-background mt-3 self-center text-xl leading-[51px]">
                      <p>2</p>
                    </div>
                  </section>
                </div>
                <div className={`${stepThree} flex w-full`}>
                  <hr className="step-hr" />

                  <section className="relative grid justify-items-center">
                    <p className="absolute bottom-[70px] text-sm md:w-[125px] md:text-base">
                      {' '}
                      Talent
                    </p>
                    <div className="step-background mt-3 self-center text-xl leading-[51px]">
                      <p>3</p>
                    </div>
                  </section>
                </div>
                <div className={`${stepFour} flex w-full`}>
                  <hr className="step-hr" />

                  <section className="relative grid justify-items-center">
                    <p className="absolute bottom-[70px] text-sm md:w-[155px] md:text-base">
                      Portfolio
                    </p>
                    <div className="step-background mt-3 self-center text-xl leading-[51px]">
                      <p>4</p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* STEPS END */}

          {currentStep === 1 && (
            <UserInformationOnboarding
              userData={userData}
              onChangeExtraCreator={onChangeExtraCreator}
              onChangeAvatar={onChangeAvatar}
              avatarPhoto={avatarPhoto}
              creatorInfoExtra={creatorInfoExtra}
              creatoravatar={creatoravatar}
              creatorfirstname={creatorfirstname}
              creatorlastname={creatorlastname}
              creatoraddress={creatoraddress}
              creatorphone={creatorphone}
              creatorcity={creatorcity}
              creatorzip={creatorzip}
              creatorstate={creatorstate}
              creatorbio={creatorbio}
              paypalEmail={paypalEmail}
            />
          )}
          {currentStep === 2 && (
            <SelectCategory
              activityAccess={activityAccess}
              shotLocation={shotLocation}
              specializationCategories={specializationCategories}
              handleOnChangeActivity={handleOnChangeActivity}
              handleOnChangeShotLocation={handleOnChangeShotLocation}
              handleOnChangeSpecializationCategories={
                handleOnChangeSpecializationCategories
              }
              stateActivityRef={stateActivityRef}
              specializationCategoriesRef={specializationCategoriesRef}
              shotLocationRef={shotLocationRef}
            />
          )}
          {currentStep === 3 && (
            <>
              <h1 className="text-2xl font-semibold text-gray-500 dark:text-white">
                {' '}
                Add Talent & Models{' '}
              </h1>
              <AddTalentModels
                setCreatorTalents={setCreatorTalents}
                creatorTalents={creatorTalents}
                setAddNewTalent={setAddNewTalent}
                setEditingTalentFromProfile={false}
                afterOnBoarding={afterOnBoarding}
              />
            </>
          )}
          {currentStep === 4 && (
            <UploadPortfolio
              setCreatorPortfolioPhotos={setCreatorPortfolioPhotos}
              creatorPortfolioPhotos={creatorPortfolioPhotos}
              isUploading={isUploading}
            />
          )}

          <div className="m-auto mt-10 flex w-full justify-between">
            {currentStep === 1 ? (
              <div />
            ) : (
              <div
                role="button"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={199}
                onClick={handlePreviousStep}
                onKeyDown={handlePreviousStep}
                className="brand-color mt-8 inline-block rounded-full px-8 py-2 text-white duration-100 ease-in hover:cursor-pointer   hover:bg-sky-300 md:mt-4 lg:mt-0"
              >
                Previous
              </div>
            )}

            {currentStep === 4 ? (
              <div
                role="button"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={201}
                onClick={handleSubmitEverything}
                className="mt-8 inline-block rounded-full bg-green-400 px-8 py-2 text-white duration-100 ease-in hover:cursor-pointer   hover:bg-green-300 md:mt-4 lg:mt-0"
              >
                Finish
              </div>
            ) : (
              <div
                role="button"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={200}
                onClick={handleNextStep}
                onKeyDown={handleNextStep}
                className="brand-color mt-8 inline-block rounded-full px-8 py-2 text-white duration-100 ease-in hover:cursor-pointer   hover:bg-sky-300 md:mt-4 lg:mt-0"
              >
                Next
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
