import React, { useRef } from 'react';
import { USERS_IMAGES_URL } from '../../common/CONFIG';
import './style.scss';

type DataSupporting = {
  id: string;
  documentName: string;
  extension: string;
  pictureUrl: string;
  type: string;
};
type Props = {
  data: any;
};

export function SupportingDocuments(props: Props) {
  const { data } = props;
  const supportingScrollBar = useRef<HTMLDivElement>(null);

  function scrollLeft() {
    supportingScrollBar!.current!.scrollBy({ left: -255, behavior: 'smooth' });
  }

  function scrollRight() {
    supportingScrollBar!.current!.scrollBy({ left: 255, behavior: 'smooth' });
  }

  return (
    <section className="m-auto mt-20 w-10/12">
      {data?.length > 0 && (
        <>
          <h1 className="text-2xl font-semibold text-gray-600 dark:text-white      ">
            Supporting Documents
          </h1>
          <div className="mt-5 flex align-middle">
            <img
              src="./img/scrollLeft.png"
              alt=""
              className="mb-20 h-10 place-self-center rounded-xl hover:cursor-pointer hover:opacity-80"
              onClick={() => scrollLeft()}
              onKeyDown={() => scrollLeft()}
            />
            <div
              ref={supportingScrollBar}
              id="supportingScrollBar"
              className="scrollbar-hide flex h-96 w-full touch-auto place-content-start content-start overflow-auto md:ml-3"
            >
              {data?.map((element: any) =>
                element?.fileExtension === 'jpg' ||
                element?.fileExtension === 'png' ||
                element?.fileExtension === 'bmp' ? (
                  <div
                    key={element?.id}
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      window.open(`${USERS_IMAGES_URL}${element?.fileUrl}`)
                    }
                  >
                    <div className="mr-6 grid h-[300px] w-[500px] touch-auto place-items-center rounded-2xl bg-white text-center hover:cursor-pointer hover:opacity-80  dark:bg-slate-800">
                      <img
                        src={`${USERS_IMAGES_URL}${element?.fileUrl}`}
                        alt=""
                        className="h-[300px] w-[500px] rounded-xl object-cover hover:cursor-pointer hover:opacity-80 "
                      />
                    </div>
                    <div className="mt-2 text-gray-500 dark:text-white ">
                      <p>{element?.fileOriginalName}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    key={element?.id}
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      window.open(`${USERS_IMAGES_URL}${element?.fileUrl}`)
                    }
                  >
                    <div className="relative mr-6 grid  h-[300px] w-[235px] touch-auto place-items-center rounded-2xl bg-sky-500 text-center hover:cursor-pointer  hover:opacity-80">
                      <img
                        src="./img/file.png"
                        alt=""
                        className="h-[11rem] rounded-xl hover:cursor-pointer hover:opacity-80 "
                      />
                      <p className="absolute bottom-[80px] left-[65px] text-4xl font-extrabold uppercase text-white">
                        {element?.fileExtension}
                      </p>
                    </div>
                    <div className="mt-2 text-left text-gray-500 dark:text-white ">
                      <p>{element?.fileOriginalName}</p>
                    </div>
                  </div>
                ),
              )}
            </div>
            <img
              src="./img/scrollRight.png"
              alt=""
              className="ml-5 mb-20 h-10 place-self-center rounded-xl hover:cursor-pointer hover:opacity-80"
              onClick={() => scrollRight()}
              onKeyDown={() => scrollRight()}
            />
          </div>
        </>
      )}
    </section>
  );
}
