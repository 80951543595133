import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { BUCKET_IMAGES } from '../../common/CONFIG';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Props = {
  data: any;
  creatorUsername: string;
};
export function FriendsAndFamily(props: Props) {
  const { data, creatorUsername } = props;
  const [slides, setSlides] = useState(5);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setSlides(1);
    }
  }, []);

  return (
    <div className="mx-auto mt-10 w-full ">
      <h1 className="text-2xl font-semibold text-gray-500 dark:text-white      ">
        {' '}
        Friend and Family Talent{' '}
      </h1>

      <Slider
        dots
        infinite={false}
        speed={500}
        slidesToShow={slides}
        slidesToScroll={4}
        className="m-auto mb-20 mt-10 h-[22.875rem] w-[20.625rem] md:h-[21.875rem] md:w-full"
      >
        {data?.map((element: any, index: any) => (
          <section
            key={`friend + ${index.toString()}`}
            className="h-[21.875rem] w-[21.875rem] p-2 md:w-[23.75rem] md:pr-3"
          >
            <div className="my-0 mb-5 grid h-[18.75rem] place-items-center rounded-2xl bg-white text-center hover:opacity-90 dark:bg-slate-800">
              <img
                src={`${BUCKET_IMAGES}talent/${creatorUsername}/${element?.imageUrl}`}
                alt=""
                className="h-[18.75rem] w-full rounded-2xl object-cover hover:opacity-90"
              />
              <div className="mt-2 h-8 w-full self-center text-gray-500 hover:cursor-pointer hover:opacity-80 dark:text-white">
                <p>{element?.name}</p>
              </div>
            </div>
          </section>
        ))}
      </Slider>
    </div>
  );
}
