import React from 'react';

type Props = {
  salesInfo:any,
}

export function Sales(props: Props) {

const {salesInfo} = props

  return (
    <section className="">
      <h1 className="text-2xl font-semibold text-gray-600 dark:text-white      ">
        Sales
      </h1>

      <div className="m-auto mt-10 grid gap-5 pb-20 md:flex md:w-full md:justify-between md:gap-0">
        <div className="flex h-[150px] w-[350px] items-center rounded-2xl  bg-white py-3 px-10 hover:shadow dark:bg-slate-800 md:w-[26.04vw]">
          <div>
            <h1 className="text-[50px] font-semibold text-gray-600  dark:text-white">
            {salesInfo?.sales}
            </h1>
            <h1 className="mt-1 text-gray-600 dark:text-white">Total Sales</h1>
          </div>
        </div>

        <div className="flex h-[150px] w-[350px] items-center rounded-2xl  bg-white py-3 px-10 hover:shadow dark:bg-slate-800 md:w-[26.04vw]">
          <div>
            <h1 className="text-[50px] font-semibold text-gray-600  dark:text-white">
              ${salesInfo?.earnings}
            </h1>
            <h1 className="mt-1 text-gray-600 dark:text-white">Earnings</h1>
          </div>
        </div>

        <div className="flex h-[150px] w-[350px] items-center rounded-2xl  bg-white py-3 px-10 hover:shadow dark:bg-slate-800 md:w-[26.04vw]">
          <div>
            <h1 className="text-[50px] font-semibold text-gray-600  dark:text-white">
            {salesInfo?.projectsCompleted}
            </h1>
            <h1 className="mt-1 text-gray-600 dark:text-white">
              Projects Completed
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}
