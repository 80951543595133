import React, { useRef } from 'react';

type PortfolioPhotography = {
  setCreatorPortfolioPhotos: any;
  creatorPortfolioPhotos: any;
  onChangePhoto: any;
  isUploading: any;
};

export function PortfolioPhotography(props: PortfolioPhotography) {
  const {
    setCreatorPortfolioPhotos,
    creatorPortfolioPhotos,
    onChangePhoto,
    isUploading,
  } = props;

  const fileUpload = useRef<HTMLInputElement>(null);
  const clickFileUpload = () => {
    if (isUploading) {
      return;
    }

    fileUpload!.current!.click();
  };

  return (
    <div className="mx-auto mt-20 w-10/12 text-gray-500 dark:text-white md:mt-10">
      <input type="file" ref={fileUpload} accept="image/*" className="hidden" />
      <div className="grid md:flex">
        <h1 className="font-semibold text-gray-700 dark:text-white">
          {' '}
          Portfolio Photography /
        </h1>
        <p className="mt-3 text-gray-500 dark:text-white md:ml-2 md:mt-0">
          Show A Wide Range Of Work That Highlights Your Capability And Range.
        </p>
      </div>
      <input
        type="file"
        ref={fileUpload}
        accept="image/*"
        className="hidden"
        name="uploadPhotos"
        multiple
        onChange={(e) => onChangePhoto(e)}
      />

      <div
        onClick={clickFileUpload}
        role="button"
        tabIndex={0}
        className="mt-5 flex h-96 rounded-xl border-[0.125rem] border-sky-300 bg-white hover:cursor-pointer  hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500"
      >
        {isUploading ? (
          <div className="m-auto h-[300px] place-items-center content-center items-center justify-center justify-items-center place-self-center self-center justify-self-center py-28 px-[15%] text-center">
            <svg
              className="-ml-1 mr-3 h-20 w-20 animate-spin text-sky-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>
        ) : (
          <div className="m-auto content-center justify-center p-10 text-center align-middle">
            <img
              src="./img/upload.png"
              alt=""
              className="inline h-[70px] w-[70px] self-center"
            />
            <h1 className="mt-5 text-3xl font-semibold text-gray-600 dark:text-white">
              Upload Portfolio Photographs
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}
