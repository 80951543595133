export default function authHeader() {
  let user: any = localStorage.getItem('userAuthenticApp') || '';

  if (user) {
    user = JSON.parse(user);
  }

  if (user && user?.accessToken) {
    // For Spring Boot back-end
    // return { Authorization: `Bearer ${  user.accessToken}` };
    // for Node.js Express back-end
  }
  return { 'x-access-token': user.accessToken };
}
