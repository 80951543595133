/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { RecentProjectCard } from '../recent-project-card/RecentProjectCard';
import { dataTestRecentProject } from '../../common/services/mocks/dataDashboard';

type Props = {
  projects: any;
  getProjects: any;
};

export function RecentProjects(props: Props) {
  const { projects, getProjects } = props;

  const [OpenDropdown, setOpenDropdown] = useState(false);
  const [search, setSearch] = useState('All');

  const [currentProjectFilter, setCurrentProjectFilter]: any = useState([]);

  useEffect(() => {
    setOpenDropdown(false);

    if (!projects) {
      return;
    }

    if (search === 'All') {
      setCurrentProjectFilter(projects);
    }

    if (search === 'Completed') {
      const temp = [];
      for (let i = 0; i < projects.length; i += 1) {
        if (projects[i]?.project?.completed === true) {
          temp.push(projects[i]);
        }
      }
      setCurrentProjectFilter(temp);
    }

    if (search === 'In Progress') {
      const temp2 = [];
      for (let i = 0; i < projects.length; i += 1) {
        if (projects[i]?.project?.completed !== true) {
          temp2.push(projects[i]);
        }
      }
      setCurrentProjectFilter(temp2);
    }
  }, [search]);

  useEffect(() => {
    setCurrentProjectFilter(projects);
  }, [projects]);

  return (
    <div>
      <section className="m-auto mt-24 grid w-10/12 items-center justify-between align-middle md:flex      ">
        <div className="flex items-center align-middle">
          <img alt="icon" src="./img/photo.png" className="h-5 w-5" />
          <h1 className="ml-3 mr-8 text-lg font-semibold text-gray-600 dark:text-white">
            Projects
          </h1>
        </div>

        <div className="mt-5 md:absolute md:right-[8.33vw] md:mt-0">
          <div
            onClick={() => setOpenDropdown(!OpenDropdown)}
            onKeyDown={() => setOpenDropdown(!OpenDropdown)}
            className="m-auto inline-flex w-[21.875rem] items-center justify-between rounded-xl bg-white px-8 py-5 text-center text-sm text-gray-500 duration-200 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 md:w-[12.625rem]"
            role="button"
            tabIndex={0}
          >
            {search}{' '}
            <svg
              className="ml-2 h-8 w-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          {OpenDropdown && (
            // This could be made with a "const" for the options. Then mapping it, and a onClick function to setSearch.
            // BUT the custom style was easier to make this way :
            <div
              onMouseLeave={() => setOpenDropdown(!OpenDropdown)}
              className="absolute z-30  mt-2 w-[21.875rem] list-none divide-y divide-gray-100 rounded-xl bg-white text-base shadow dark:bg-slate-800 md:w-[12.625rem]"
            >
              <span
                role='button'
                tabIndex={0}
                onClick={() => setSearch('All')}
                key="All"
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-t-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                All
              </span>
              <span
                role='button'
                tabIndex={0}
                onClick={() => setSearch('In Progress')}
                key="In Progress"
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                In Progress
              </span>
              {/* <option
                onClick={() => setSearch('Purchased')}
                key="Purchased"
                value="Purchased"
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                Purchased
              </option> */}
              <span
                role='button'
                tabIndex={0}
                onClick={() => setSearch('Completed')}
                key="Completed"
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-b-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                Completed
              </span>
            </div>
          )}
        </div>
      </section>
      <section className="mx-auto  mt-24 place-items-center content-around items-center justify-center text-center md:grid  md:w-[85.93vw] md:grid-cols-3 md:gap-x-1 md:gap-y-8">
        {currentProjectFilter?.map(
          (element: any) => (
            // element?.product?.length > 0 && (
            <RecentProjectCard
              key={element.key}
              projectId={element?.project?.projectId}
              projectName={element?.project?.name}
              description={element?.project?.description}
              pictureUrl={element?.project?.projectImage}
              totalPurchased={element?.project?.qtyPurchased}
              totalPictures={element?.photoBuyLength}
              favoritePhotographers={element?.project?.favoritePhotographers}
              getProjects={getProjects}
              status={element?.project?.status}
            />
          ),
          // ),
        )}
        <br />
        <br />
      </section>
    </div>
  );
}
