/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import { USERS_IMAGES_URL } from '../../common/CONFIG';
import './style.scss';

type Props = {
  setBriefDetails: any;
  briefDetails: any;
  editMode: any;
  project: any;
  onChangeProjectImage: any;
  projectImage: any;
  inputProjectName: any;
  inputProjectDescription: any;
  dueDateInput: any;
  imageCropRef: any;
  noProjectImageNoEditMode: any;
};

export function BriefDetails(props: Props) {
  const {
    briefDetails,
    setBriefDetails,
    editMode,
    project,
    onChangeProjectImage,
    projectImage,
    inputProjectName,
    inputProjectDescription,
    dueDateInput,
    imageCropRef,
    noProjectImageNoEditMode,
  } = props;
  const cropImageBottons = [
    {
      id: 1,
      value: 'Wide',
      checked: false,
      class: 'wide',
    },
    {
      id: 2,
      value: 'Med',
      checked: false,
      class: 'med',
    },
    {
      id: 3,
      value: 'Tight',
      checked: false,
      class: 'tight',
    },
  ];

  const [startDateDueDate, setStartDateDateDueDate] = useState<any>();
  const [preferencesCropImage, setPreferencesCropImage] =
    useState(cropImageBottons);

  useEffect(() => {
    if (!editMode) {
      const monthAhead = dayjs().add(1, 'month').toDate();
      setStartDateDateDueDate(monthAhead);
      onChangeBriefDetails('dueDate', monthAhead.toDateString());
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      onChangeBriefDetails('dueDate', briefDetails?.dueDate);
    }
  }, [briefDetails?.dueDate]);

  useEffect(() => {
    onChangeBriefDetails('dueDate', startDateDueDate);
  }, [startDateDueDate]);

  useEffect(() => {
    onChangeBriefDetails('imageCrop', JSON.stringify(preferencesCropImage));
  }, [preferencesCropImage]);

  const handleClickCropImage = (index: any) => {
    const tempstate = [...preferencesCropImage];
    const tempelement = { ...preferencesCropImage[index] };
    tempelement.checked = !tempelement.checked;
    tempstate[index] = tempelement;
    setPreferencesCropImage(tempstate);
  };

  const onChangeBriefDetails = (concept: string, value: string) => {
    const tempstate = { ...briefDetails, [concept]: value };
    setBriefDetails(tempstate);
  };

  useEffect(() => {
    if (editMode) {
      const cropvalues = [
        {
          id: 1,
          value: 'Wide',
          checked: project?.imageCropWide,
          class: 'wide',
        },
        {
          id: 2,
          value: 'Med',
          checked: project?.imageCropMed,
          class: 'med',
        },
        {
          id: 3,
          value: 'Tight',
          checked: project?.imageCropTight,
          class: 'tight',
        },
      ];

      const tempstate = { ...briefDetails, imageCrop: cropvalues };
      setBriefDetails(tempstate);
      setPreferencesCropImage(cropvalues);
      const tempDate = project?.project?.dueDate;
      setStartDateDateDueDate(Date.parse(tempDate));
    }
  }, [editMode, project?.imageCropTight]);

  const fileUpload = useRef<HTMLInputElement>(null);
  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  return (
    <section className="mx-auto w-10/12">
      <div className="w-full pt-10 md:inline-flex md:place-content-between md:items-center md:justify-between">
        <div className="md:w-1/2">
          {editMode ? (
            <h1 className="mb-3 text-3xl font-bold text-gray-700 dark:text-white">
              Brief Edit
            </h1>
          ) : (
            <h1 className="mb-3 text-3xl font-bold text-gray-700 dark:text-white">
              Brief
            </h1>
          )}

          <p className="my-3 text-gray-700 dark:text-white">Project Name</p>
          <input
            ref={inputProjectName}
            onChange={(e) => onChangeBriefDetails('name', e.target.value)}
            value={briefDetails?.name}
            type="text"
            className="my-3 w-full rounded-xl px-5 py-6 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 dark:bg-slate-600 dark:text-white dark:placeholder:text-slate-300"
            placeholder="Enter Name"
          />
        </div>
        <div className="center-div md:w-1/2">
          <input
            onChange={(e) => onChangeProjectImage(e)}
            type="file"
            name="uploadImages"
            ref={fileUpload}
            accept="image/*"
            className="hidden"
          />

          {projectImage && !editMode && (
            <div
              role="button"
              tabIndex={0}
              onClick={clickFileUpload}
              className="grid w-[250px] md:ml-20  md:w-[23.43vw]"
            >
              <img
                src={URL.createObjectURL(projectImage)}
                alt="projectPicture"
                className="ml-[40px] inline h-60 w-60 self-center rounded-full object-cover hover:cursor-pointer hover:border hover:shadow-md md:ml-0 md:h-[13vw] md:w-[13vw]"
              />
            </div>
          )}

          {!projectImage && !editMode && (
            <div
              role="button"
              tabIndex={0}
              onClick={clickFileUpload}
              className="grid w-[250px] md:ml-20  md:w-[23.43vw]"
            >
              <div
                ref={noProjectImageNoEditMode}
                className="ml-[40px] inline h-60 w-60 self-center rounded-full border bg-slate-100 object-cover pt-[112px] text-center text-slate-700 hover:cursor-pointer hover:shadow-md dark:bg-slate-600 md:ml-0 md:h-[13vw] md:w-[13vw]"
              >
                <h1 className="text-slate-700  dark:text-white">
                  Add Project Image
                </h1>
              </div>
            </div>
          )}

          {!projectImage && editMode && (
            <div
              role="button"
              tabIndex={0}
              onClick={clickFileUpload}
              className="grid w-[250px] md:ml-20  md:w-[23.43vw]"
            >
              <img
                src={`${USERS_IMAGES_URL}${briefDetails?.projectImage}`}
                alt="projectPicture"
                className="ml-[40px] inline h-60 w-60 self-center rounded-full object-cover hover:cursor-pointer hover:border hover:shadow-md md:ml-0 md:h-[13vw] md:w-[13vw]"
              />
            </div>
          )}

          {projectImage && editMode && (
            <div
              role="button"
              tabIndex={0}
              onClick={clickFileUpload}
              className="grid w-[250px] md:ml-20  md:w-[23.43vw]"
            >
              <img
                src={URL.createObjectURL(projectImage)}
                alt="projectPicture"
                className="ml-[40px] inline h-60 w-60 self-center rounded-full object-cover hover:cursor-pointer hover:border hover:shadow-md md:ml-0 md:h-[13vw] md:w-[13vw]"
              />
            </div>
          )}
        </div>
      </div>
      <div className="grid md:flex">
        <div className="md:h-96 md:w-7/12">
          <p className="my-3 text-gray-700 dark:text-white">
            Short description & details
          </p>
          <div className="h-full">
            <textarea
              maxLength={2000}
              rows={3}
              ref={inputProjectDescription}
              value={briefDetails?.description}
              className="mt-4 h-[16.875rem] w-[21.875rem] resize-none overflow-y-auto rounded-xl p-5 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 dark:bg-slate-600 dark:text-white dark:placeholder:text-slate-300 md:my-3 md:w-full"
              placeholder="Enter Description"
              onChange={(e) =>
                onChangeBriefDetails('description', e.target.value)
              }
            />
             <span className='block pt-[1px] text-[9px] text-right text-slate-600 dark:text-white'>Max 2000 chars.</span>
          </div>
        </div>
        <div className="mt-5 w-[22.5rem] md:ml-6 md:mt-16 md:w-[43.75rem] ">
          {/* npm install react-datepicker --save */}
          <p className="mb-3 text-gray-700 dark:text-white">Due Date</p>
          <div ref={dueDateInput} className="grid w-full">
            <DatePicker
              selected={startDateDueDate}
              onChange={(date: any) => setStartDateDateDueDate(date)}
              className="background-date-picker h-20 w-[21.875rem] rounded-xl p-5 transition-all duration-200 hover:cursor-pointer hover:bg-slate-200 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-700 dark:text-white hover:dark:bg-slate-600 md:w-full"
            />
          </div>
          <p className="mt-5 text-gray-700 dark:text-white md:my-3">
            Image Crop
          </p>
          <div className="flex" ref={imageCropRef}>
            <div className="crop-image-selector crop-image-selector-2 mt-5 mb-10 flex md:my-0">
              {preferencesCropImage.map((element, index) => (
                <div
                  key={element.id}
                  className="mr-5 grid w-[4.375rem] justify-center bg-center text-center"
                >
                  <label
                    htmlFor={`Crop ${element.class}`}
                    className={`image-crop transition-all duration-200 ${
                      element.class
                    } bg-center ${
                      element.checked ? 'grayscale-0' : 'grayscale'
                    }`}
                  >
                    <input
                      id={`Crop ${element.class}`}
                      type="checkbox"
                      name="image-crop-select"
                      value={element.class}
                      defaultChecked={element.checked}
                      onClick={() => handleClickCropImage(index)}
                    />
                  </label>

                  <p className="my-3 text-gray-700 dark:text-white ">
                    {element.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
