import React, { useEffect, useState } from 'react';
import { RecentProjectsCreator, CurrentProject, Sales } from 'components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from '../../../common/CONFIG';

type Props = {
  setInvitations: any;
};

export default function ProjectCreator(props: Props) {
  const { setInvitations } = props;

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Project';
    getInvitations();
    getCurrentProjectsCreator();
  }, []);

  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [currentProjects, setCurrentProjects] = useState([]);
  const [salesInfo, setSalesInfo] = useState([]);

  async function getInvitations() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getInvitations/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setInvitations(res.data);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      //  console.log(err);
    }
  }

  async function getCurrentProjectsCreator() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getCurrentProjectsCreator/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setCurrentProjects(res.data.projects);
      setSalesInfo(res.data);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      //  console.log(err);
    }
  }

  return (
    <section className="bg-gray-100 dark:bg-slate-900 min-h-[1200px]">
      <div className="m-auto w-10/12">
        <br />
        <Sales salesInfo={salesInfo} />
        {currentProjects?.length > 1 ? (
          <h1 className="self-center text-xl font-bold text-gray-500 dark:text-white">
            Current Projects
          </h1>
        ) : (
          <h1 className="self-center text-xl font-bold text-gray-500 dark:text-white">
            Current Project
          </h1>
        )}
        {currentProjects?.map((element: any, index: any) => (
          <CurrentProject currentProject={element} />
        ))}

        <RecentProjectsCreator currentProjects={currentProjects} />
      </div>
    </section>
  );
}
