import React, { useEffect } from 'react';
import { Switch } from '@headlessui/react';

type PropsCreateUser = {
  onChangeAvatar: any;
  onChangeExtraCreator: any;
  contactPhone: any;
  handleContactViaPhone: any;
  activityAccess: any;
  handleOnChangeActivity: any;
  handleOnChangeShotLocation: any;
  shotLocation: any;
  specializationCategories: any;
  handleOnChangeSpecializationCategories: any;
  editMode: boolean;
  creatorInfoExtra: any;
};

export default function CreatorFields(props: PropsCreateUser) {
  const {
    onChangeAvatar,
    onChangeExtraCreator,
    contactPhone,
    handleContactViaPhone,
    activityAccess,
    handleOnChangeActivity,
    handleOnChangeShotLocation,
    shotLocation,
    specializationCategories,
    handleOnChangeSpecializationCategories,
    editMode,
    creatorInfoExtra,
  } = props;

  return (
    <>
      <br />
      <h1 className="text-xl font-semibold ">Extra creator fields</h1>
      <br />
      {!editMode && (
        <>
          <p>Avatar</p>
          <input
            onChange={(e) => onChangeAvatar(e)}
            type="file"
            name="uploadImages"
            accept="image/*"
            className=""
          />
          <br />
          <br />
        </>
      )}

      <section className="m-auto grid w-6/12 gap-3 md:grid-cols-2">
        <div className="">
          <p>Address</p>
          <input
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="address"
            onChange={(e) => onChangeExtraCreator('address', e.target.value)}
            value={creatorInfoExtra?.address}
          />
        </div>

        <div className="">
          <p>Phone</p>
          <input
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="phone"
            onChange={(e) => onChangeExtraCreator('phone', e.target.value)}
            value={creatorInfoExtra?.phone}
          />
        </div>

        <div className="">
          <p>City</p>
          <input
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="city"
            onChange={(e) => onChangeExtraCreator('city', e.target.value)}
            value={creatorInfoExtra?.city}
          />
        </div>

        <div className="">
          <p>State</p>
          <input
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="state"
            onChange={(e) => onChangeExtraCreator('state', e.target.value)}
            value={creatorInfoExtra?.state}
          />
        </div>

        <div className="">
          <p>Zip Code</p>
          <input
            className="mb-5 w-full  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="zipCode"
            onChange={(e) => onChangeExtraCreator('zipCode', e.target.value)}
            value={creatorInfoExtra?.zipCode}
          />
        </div>
      </section>
      <div className="">
        <p>Bio</p>
        <textarea
          rows={3}
          className="mb-5 w-6/12 rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
          name="bio"
          onChange={(e) => onChangeExtraCreator('bio', e.target.value)}
          value={creatorInfoExtra?.bio}
        />
      </div>

      <p>Allow Brand Contact</p>
      <Switch
        checked={contactPhone}
        onChange={handleContactViaPhone}
        className={`${contactPhone ? 'bg-sky-300' : 'bg-gray-300'}
                      duration-400 relative mx-5 
                      inline-flex h-[1.8125rem] w-[4.0625rem] 
                      shrink-0 cursor-pointer 
                      place-self-center rounded-full 
                      border-2 border-transparent transition-colors 
                      ease-in-out focus:outline-none focus-visible:ring-2  
                      focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden="true"
          className={`${contactPhone ? 'translate-x-9' : 'translate-x-0'}
                      duration-400 pointer-events-none inline-block h-[1.5625rem] 
                      w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out 
                      dark:bg-slate-800`}
        />
      </Switch>
      <br />
      <br />

      <div className="m-auto w-6/12 rounded-2xl border border-gray-300 p-10">
        <h1 className="text-xl font-semibold ">Activity Access</h1>
        <div className="m-auto mt-10 grid w-10/12 gap-5 md:grid-cols-2">
          {activityAccess?.map((element: any, index: Number) => (
            <div   key={`act + ${index.toString()}`} className="flex items-center">
              {editMode && (
                <input
                  type="checkbox"
                  id={`checkboxAccess-${index}`}
                  name={element.location}
                  checked={element.selected}
                  onChange={() => handleOnChangeActivity(element.location)}
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
              )}
              {!editMode && (
                <input
                  type="checkbox"
                  id={`checkboxAccess-${index}`}
                  name={element.location}
                  onChange={() => handleOnChangeActivity(element.location)}
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
              )}
              <label
                className="ml-1 inline-flex place-self-center md:text-[0.83vw]"
                htmlFor={`checkboxAccess-${index}`}
              >
                {element.location}
              </label>
            </div>
          ))}
        </div>
      </div>

      <br />

      <div className="m-auto w-6/12 rounded-2xl border border-gray-300 p-10">
        <h1 className="text-xl font-semibold ">Shoot Location Access</h1>
        <div className="m-auto mt-10 grid w-10/12 gap-5 md:grid-cols-2">
          {shotLocation?.map((element: any, index: Number) => (
            <div   key={`shotloc + ${index.toString()}`} className="flex items-center">
              {editMode && (
                <input
                  checked={element.selected}
                  type="checkbox"
                  id={`checkbox-${index}`}
                  name={element.location}
                  onChange={() => handleOnChangeShotLocation(element.location)}
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
              )}
              {!editMode && (
                <input
                  type="checkbox"
                  id={`checkbox-${index}`}
                  name={element.location}
                  onChange={() => handleOnChangeShotLocation(element.location)}
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
              )}
              <label
                className="ml-1 inline-flex place-self-center md:text-[0.83vw]"
                htmlFor={`checkbox-${index}`}
              >
                {element.location}
              </label>
            </div>
          ))}
        </div>
      </div>

      <br />

      <div className="m-auto w-6/12 rounded-2xl border border-gray-300 p-10">
        <h1 className="text-xl font-semibold ">Specialization Categories</h1>
        <div className="m-auto mt-10 grid w-10/12 gap-5 md:grid-cols-2">
          {specializationCategories?.map((element: any, index: Number) => (
            <div   key={`spectcat + ${index.toString()}`} className="flex items-center">
              {editMode && (
                <input
                  checked={element.selected}
                  type="checkbox"
                  id={`checkbox-special${index}`}
                  name={element.location}
                  onChange={() =>
                    handleOnChangeSpecializationCategories(element.location)
                  }
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
              )}
              {!editMode && (
                <input
                  type="checkbox"
                  id={`checkbox-special${index}`}
                  name={element.location}
                  onChange={() =>
                    handleOnChangeSpecializationCategories(element.location)
                  }
                  className="float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:mr-[0.83vw] md:h-[2.56vh] md:w-[1.25vw]"
                />
              )}
              <label
                className="ml-1 inline-flex place-self-center md:text-[0.83vw]"
                htmlFor={`checkbox-special${index}`}
              >
                {element.location}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
