import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { notifyOnboardingFinished } from 'actions/auth';
import {
  UserInformation,
  CompanyInformation,
  TermsAndConditions,
} from 'components';
import { SERVER_URL } from '../../../common/CONFIG';

export default function ProfileOnboarding() {
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [user, setUser] = useState<any>([]);
  const [type, setType] = useState('brand');
  const dispatch = useDispatch();

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Onboarding';
  }, []);

  const [currentStep, setCurrentStep] = useState(1);

  const brandfirstname: any = useRef();
  const brandlastname: any = useRef();
  const brandemail: any = useRef();
  const brandtitle: any = useRef();

  const brandcompany: any = useRef();
  const brandweb: any = useRef();
  const brandface: any = useRef();
  const brandinsta: any = useRef();
  const brandbio: any = useRef();
  const brandlogo: any = useRef();

  const privacy: any = useRef();
  const terms: any = useRef();

  function handlePreviousStep() {
    setCurrentStep(currentStep - 1);
  }

  function handleNextStep() {
    if (currentStep === 1) {
      let wrongValidation = false;

      if (brandfirstname.current.value.length === 0) {
        brandfirstname.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        brandfirstname.current?.classList.remove('border-red-500');
      }

      if (brandlastname.current.value.length === 0) {
        brandlastname.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        brandlastname.current?.classList.remove('border-red-500');
      }

      if (
        brandemail.current.value.length < 7 ||
        !brandemail.current.value.includes('@')
      ) {
        brandemail.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        brandemail.current?.classList.remove('border-red-500');
      }

      if (brandtitle.current.value.length === 0) {
        brandtitle.current?.classList.add('border-red-500');
        wrongValidation = true;
      } else {
        brandtitle.current?.classList.remove('border-red-500');
      }

      if (!wrongValidation) {
        setCurrentStep(currentStep + 1);
      }
    }

    if (currentStep === 2) {
      let wrongValidation2 = false;

      if (brandcompany.current.value.length === 0) {
        brandcompany.current?.classList.add('border-red-500');
        wrongValidation2 = true;
      } else {
        brandcompany.current?.classList.remove('border-red-500');
      }

      if (brandweb.current.value.length === 0) {
        brandweb.current?.classList.add('border-red-500');
        wrongValidation2 = true;
      } else {
        brandweb.current?.classList.remove('border-red-500');
      }

      if (brandface.current.value.length === 0) {
        brandface.current?.classList.add('border-red-500');
        wrongValidation2 = true;
      } else {
        brandface.current?.classList.remove('border-red-500');
      }

      if (brandinsta.current.value.length === 0) {
        brandinsta.current?.classList.add('border-red-500');
        wrongValidation2 = true;
      } else {
        brandinsta.current?.classList.remove('border-red-500');
      }

      if (brandbio.current.value.length === 0) {
        brandbio.current?.classList.add('border-red-500');
        wrongValidation2 = true;
      } else {
        brandbio.current?.classList.remove('border-red-500');
      }

      if (!avatarPhoto) {
        brandlogo.current?.classList.add('border-red-500');
        wrongValidation2 = true;
      } else {
        brandlogo.current?.classList.remove('border-red-500');
      }

      if (!wrongValidation2) {
        setCurrentStep(currentStep + 1);
      }
    }
  }

  const [stepOne, setStepOne] = useState('grayscale');
  const [stepTwo, setStepTwo] = useState('grayscale');
  const [stepThree, setStepThree] = useState('grayscale');

  useEffect(() => {
    if (currentStep < 1) {
      setCurrentStep(1);
    }
    if (currentStep > 3) {
      setCurrentStep(3);
    }
    switch (currentStep) {
      case 1:
        setStepOne('');
        setStepTwo('grayscale');
        setStepThree('grayscale');

        break;
      case 2:
        setStepOne('');
        setStepTwo('');
        setStepThree('grayscale');

        break;
      case 3:
        setStepOne('');
        setStepTwo('');
        setStepThree('');

        break;
      default:
      // code default block
    }
  }, [currentStep]);

  const brandInformation = {
    first_name: '',
    last_name: '',
    email: '',
    title: '',
    companyName: '',
    companyWeb: '',
    companyFB: '',
    companyIG: '',
    bio: '',
  };

  const [brandInfo, setBrandInfo] = useState<any>(brandInformation);

  const onChangeExtraBrand = (concept: string, value: string) => {
    const tempstate = { ...brandInfo, [concept]: value };
    setBrandInfo(tempstate);
  };

  const [avatarPhoto, setAvatarPhoto] = useState<any | null>();

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setAvatarPhoto(fileList[0]);
  };
  const navigate = useNavigate();

  async function handleSubmitEverything(e: any) {
    e.preventDefault();

    if (acceptTerms && acceptPrivacy) {
      await uploadExtraFields();
      await uploadAvatar();

      // update onboardFinish flag
      dispatch(notifyOnboardingFinished());
      const storedUser = localStorage.getItem('userAuthenticApp') || '';
      const userInfo = JSON.parse(storedUser);
      if (!userInfo.onboardFinish) {
        userInfo.onboardFinish = true;
      }
      localStorage.setItem('userAuthenticApp', JSON.stringify(userInfo));

      navigate('/brand-profile?state=welcome');
    }
    if (!acceptPrivacy) {
      privacy.current?.classList.add('text-red-500');
    }
    if (!acceptTerms) {
      terms.current?.classList.add('text-red-500');
    }
  }

  async function uploadAvatar() {
    if (avatarPhoto) {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('uploadImages', avatarPhoto);
      formData.append('type', type);

      const route = `${SERVER_URL}upload-avatar/${currentUser?.username}`;
      // eslint-disable-next-line promise/no-nesting
      await axios
        .post(route, formData, config)
        .then(
          () => null,
          // all good !
        )
        .catch(() => {});
    }
  }

  async function uploadExtraFields() {
    const route = `${SERVER_URL}upload-brand-extra/${currentUser?.username}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        brandInfo: JSON.stringify(brandInfo),
        userType: type,
      })
      .then(
        () => null,
        // all good !
      )
      .catch(() => {});
  }

  return (
    <div className="bg-gray-100 dark:bg-slate-900 ">
      <section className="m-auto min-h-[772px] w-11/12 bg-gray-100 py-10 dark:bg-slate-900 md:w-10/12">
        <div className="h-fit rounded-2xl bg-white px-5 py-10 dark:bg-slate-800 md:px-20">
          {/* STEPS START */}
          <section className="h-32 ">
            <div className="m-auto mt-10 mb-0 md:w-11/12">
              <div className="project-step flex justify-between text-center font-bold text-sky-400">
                <div className={`${stepOne}`}>
                  <section className="relative grid justify-items-center">
                    <p className="absolute bottom-[70px] text-xs md:w-[135px] md:text-base">
                      User Information
                    </p>
                    <div className="step-background mt-3 self-center text-xl leading-[51px]">
                      <p>1</p>
                    </div>
                  </section>
                </div>
                <div className={`${stepTwo} flex w-full`}>
                  <hr className="step-hr" />

                  <section className="relative grid justify-items-center">
                    <p className="absolute bottom-[70px] text-xs md:w-[175px] md:text-base">
                      Company Information
                    </p>
                    <div className="step-background mt-3 self-center text-xl leading-[51px]">
                      <p>2</p>
                    </div>
                  </section>
                </div>
                <div className={`${stepThree} flex w-full`}>
                  <hr className="step-hr" />

                  <section className="relative grid justify-items-center">
                    <p className="absolute bottom-[70px] text-xs md:w-[155px] md:text-base">
                      Terms & Conditions
                    </p>
                    <div className="step-background mt-3 self-center text-xl leading-[51px]">
                      <p>3</p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* STEPS END */}

          {currentStep === 1 && (
            <UserInformation
              onChangeExtraBrand={onChangeExtraBrand}
              brandInfo={brandInfo}
              brandfirstname={brandfirstname}
              brandlastname={brandlastname}
              brandemail={brandemail}
              brandtitle={brandtitle}
            />
          )}
          {currentStep === 2 && (
            <CompanyInformation
              onChangeExtraBrand={onChangeExtraBrand}
              brandInfo={brandInfo}
              onChangeAvatar={onChangeAvatar}
              avatarPhoto={avatarPhoto}
              brandcompany={brandcompany}
              brandweb={brandweb}
              brandface={brandface}
              brandinsta={brandinsta}
              brandbio={brandbio}
              brandlogo={brandlogo}
            />
          )}
          {currentStep === 3 && (
            <TermsAndConditions
              agreed={false}
              terms={terms}
              privacy={privacy}
              setAcceptTerms={setAcceptTerms}
              setAcceptPrivacy={setAcceptPrivacy}
            />
          )}

          <div className="m-auto mt-10 flex w-full justify-between">
            {currentStep === 1 ? (
              <div />
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={handlePreviousStep}
                onKeyDown={handlePreviousStep}
                className="brand-color mt-8 inline-block rounded-full px-8 py-2 text-white duration-100 ease-in hover:cursor-pointer   hover:bg-sky-300 md:mt-4 lg:mt-0"
              >
                Previous
              </div>
            )}

            {currentStep === 3 ? (
              <Link to="/dashboard">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleSubmitEverything}
                  className="mt-8 inline-block rounded-full bg-green-400 px-8 py-2 text-white duration-200 ease-in hover:cursor-pointer   hover:bg-green-300 md:mt-4 lg:mt-0"
                >
                  Finish
                </div>
              </Link>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={handleNextStep}
                onKeyDown={handleNextStep}
                className="brand-color mt-8 inline-block rounded-full px-8 py-2 text-white duration-200 ease-in hover:cursor-pointer   hover:bg-sky-300 md:mt-4 lg:mt-0"
              >
                Next
              </div>
            )}
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
    </div>
  );
}
