import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  USERS_IMAGES_URL,
  SERVER_URL,
  BUCKET_IMAGES,
} from '../../common/CONFIG';

type Props = {
  project: any;
  due: any;
};

export function ProjectDetails(props: Props) {
  const { project, due } = props;
  const [sizeProjectNameText, setSizeProjectNameText] = useState(13);
  const projectNameInput = useRef<HTMLInputElement>(null);
  const focusProjectNameInput = () => {
    (projectNameInput.current as HTMLElement).focus();
  };

  useEffect(() => {
    if (window.innerWidth < 700) {
      setSizeProjectNameText(22);
    }
  }, []);

  const resizeProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (window.innerWidth > 700) {
      const numberCharacters = event.target.value;
      const newWidth = numberCharacters.length;
      setSizeProjectNameText(newWidth + 2);
      if (newWidth < 10) {
        setSizeProjectNameText(13);
      }
      if (newWidth === 0) {
        setSizeProjectNameText(13);
      }
    }
  };

  const [status, setStatus] = useState('');
  useEffect(() => {
    switch (project?.project?.status) {
      case 'ASKING':
        setStatus('INVITED');
        break;
      case 'ACCEPTED':
        setStatus('ACCEPTED');
        break;
      case 'RECIVED_PRODUCT':
        setStatus('RECIVED PRODUCT');
        break;
      case 'UPLOAD_PHOTOS':
        setStatus('UPLOAD IMAGES');
        break;
      case 'PHOTOS_PURCHASED':
        setStatus('PHOTOS PURCHASED');
        break;
      case 'REJECTED':
        setStatus('REJECTED');

        break;
      default:
        setStatus('READY');
    }
  }, [project?.project?.status]);

  const navigate = useNavigate();

  async function duplicateProject(projectId: any) {
    const route = `${SERVER_URL}duplicateProject/${projectId}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route)
      .then((resp) => {
        if (resp.status === 201) {
          navigate(`/brief?edit=${resp.data}`);
        }
        return resp.data;
      })
      .catch(() => {});
  }

  return (
    <section className="m-auto w-10/12 py-10      ">
      <div className="grid">
        <p className="text-xs text-gray-500 dark:text-white">
          STATUS: {status}
        </p>
        <div className="grid place-items-center items-center md:flex md:w-full md:justify-between">
          <div className="mt-5 flex md:mt-0">
            <p className="my-1 mr-2 block h-12  rounded-md bg-gray-100 py-2 text-[1.375rem] font-bold text-gray-600 placeholder:italic placeholder:text-gray-400     dark:bg-slate-900 dark:text-white dark:placeholder:text-white">
              {project?.project?.name}
            </p>
            {/* <input
              className="my-1 mr-2 block h-12  rounded-md bg-gray-100 py-2 text-[1.375rem] font-bold text-gray-600 placeholder:italic placeholder:text-gray-400 hover:border  hover:border-gray-300 hover:bg-white hover:px-3 hover:shadow-sm focus:border-sky-500 focus:bg-white focus:px-3 focus:outline-none focus:ring-1  focus:ring-sky-500  dark:bg-slate-900 dark:text-white dark:placeholder:text-white"
              placeholder="Project Name"
              type="text"
              name="projectName"
              maxLength={50}
              onChange={resizeProjectName}
              style={{ width: `${sizeProjectNameText} ch` }}
              ref={projectNameInput}
            />

            <img
              onClick={focusProjectNameInput}
              src="./img/write.png"
              alt=""
              className="mr-4 inline h-5 w-5 self-center text-center align-middle hover:cursor-pointer hover:opacity-90"
            /> */}
          </div>
          <div className="inline-flex">
            <div
              role="button"
              tabIndex={0}
              onClick={() => duplicateProject(project?.project?.projectId)}
              className="mt-5 rounded-3xl border-2 border-gray-300 bg-white px-5 py-1 text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:bg-slate-800 dark:text-white dark:hover:text-sky-400 md:ml-3 md:mt-0"
            >
              Duplicate Project
            </div>
            <Link
              to={`/brief?edit=${project?.project?.projectId}`}
              className="mt-5 rounded-3xl border-2 border-gray-300 bg-white px-5 py-1 text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:bg-slate-800 dark:text-white dark:hover:text-sky-400 md:ml-3 md:mt-0"
            >
              Edit Project
            </Link>
          </div>
        </div>
      </div>

      <section className="m-auto py-10 ">
        <h1 className="mb-4 text-gray-700 dark:text-white">
          Short Description & Details
        </h1>
        <div className="grid md:flex">
          <div className="w-[21.25rem] md:w-8/12">
            <div className="h-[12.5rem] w-full resize-none overflow-y-auto rounded-xl bg-white p-5 text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-600 dark:text-white dark:placeholder:text-white">
              {project?.project?.description}
            </div>
            <section className="mt-8 grid w-full items-start md:mt-5 md:flex">
              <div className="grid w-11/12 text-gray-500 dark:text-white md:w-1/2">
                <p>Supporting Documents</p>
                <div className="mt-8 grid grid-cols-3 items-center gap-2 py-5 md:mt-0">
                  {project?.documents?.map((file: any, index: number) =>
                    file.type === 'image/png' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/bmp' ? (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() =>
                          window.open(`${USERS_IMAGES_URL}${file?.fileUrl}`)
                        }
                        className="relative mr-5 text-center"
                        key={`imgurl + ${index.toString()}`}
                      >
                        <img
                          alt="img"
                          src={`${USERS_IMAGES_URL}${file?.fileUrl}`}
                          className="h-20 w-20 rounded-xl bg-contain"
                        />
                        <p className="max-h-[20px] max-w-[5rem] overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-white">
                          {file?.fileOriginalName}
                        </p>
                      </div>
                    ) : (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() =>
                          window.open(`${USERS_IMAGES_URL}${file?.fileUrl}`)
                        }
                        className="relative mr-5 text-center"
                        key={`supdoc + ${index.toString()}`}
                      >
                        <div className="relative mr-6 grid h-20 w-20 touch-auto place-items-center rounded-2xl bg-sky-500 text-center hover:cursor-pointer  hover:opacity-80">
                          <img
                            src="./img/file.png"
                            alt=""
                            className="h-[54px] hover:cursor-pointer hover:opacity-80 "
                          />
                          <p className="absolute text-sm font-extrabold uppercase text-white">
                            {file?.fileExtension}
                          </p>
                        </div>

                        <p className="max-h-[20px] max-w-[5rem] overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-white">
                          {file?.fileOriginalName}
                        </p>
                      </div>
                    ),
                  )}
                </div>
              </div>
              <div className="mt-8 grid items-center text-gray-500 dark:text-white md:mt-0">
                <p>Inspiration Photos</p>
                <div className="mt-8 grid grid-cols-3 items-center  gap-2 py-5 md:mt-0">
                  {project?.inspirations?.map((file: any, index: number) => (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        window.open(
                          `${BUCKET_IMAGES}${
                            file?.fromBrand
                              ? `portfolio/${file?.creatorId}`
                              : `inspiration/${file?.projectId}`
                          }/${file?.photoUrl}`,
                        )
                      }
                      className="relative mr-5 text-center justify-items-center grid items-center"
                      key={`insp + ${index.toString()}`}
                    >
                      <img
                        alt="img"
                        src={`${BUCKET_IMAGES}${
                          file?.fromBrand
                            ? `portfolio/${file?.creatorId}`
                            : `inspiration/${file?.projectId}`
                        }/${file?.photoUrl}`}
                        className="h-20 w-20 rounded-xl bg-contain object-cover"
                      />
                      <p className="max-w-[15rem] overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-white">
                        {file?.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>

          <div className="mt-8  w-[21.25rem] md:ml-8 md:mt-0 md:w-1/2">
            {/* <img
                      src={`${USERS_IMAGES_URL}${project?.project?.projectImage}`}
                      alt=""
                      className="inline self-center text-center max-h-[122px] mb-[8px]"
                    /> */}
            <div className=" flex h-[4.375rem] w-full items-center  justify-between rounded-xl bg-white p-3 text-gray-500 dark:bg-slate-800 dark:text-white">
              <p>Estimated Delivery {due}</p>
              <img
                src="./img/calendar.png"
                alt=""
                className="mr-4 inline h-6 w-6 self-center text-center align-middle"
              />
            </div>
            <div className="mt-5">
              <section className="inline-flex gap-3">
                {project?.imageCropWide && (
                  <div>
                    <img
                      src="./img/wide.png"
                      alt=""
                      className="inline self-center text-center"
                    />
                    <p className="ml-4 text-gray-500 dark:text-white">Wide</p>
                  </div>
                )}
                {project?.imageCropMed && (
                  <div>
                    <img
                      src="./img/med.png"
                      alt=""
                      className="inline self-center text-center"
                    />
                    <p className="ml-4 text-gray-500 dark:text-white">Med</p>
                  </div>
                )}
                {project?.imageCropTight && (
                  <div>
                    <img
                      src="./img/tight.png"
                      alt=""
                      className="inline self-center text-center"
                    />
                    <p className="ml-4 text-gray-500 dark:text-white">Tight</p>
                  </div>
                )}
              </section>

              <div className="mt-5 text-gray-500 dark:text-white">
                <p>Additional Requirements</p>

                {project?.project?.gear && <li>{project?.project?.gear}</li>}

                {project?.project?.wardrobe && (
                  <li>{project?.project?.wardrobe}</li>
                )}

                {project?.project?.enviroments && (
                  <li>{project?.project?.enviroments}</li>
                )}

                {project?.project?.animals && (
                  <li>{project?.project?.animals}</li>
                )}

                {project?.project?.videography && <li>Videography</li>}
                {project?.project?.professionalModel && (
                  <li>Professional Model</li>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
