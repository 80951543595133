import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation } from 'react-router-dom';
import { PhotographyInspirationModal } from '../photography-inspiration-modal/PhotographyInspirationModal';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';
import authHeader from '../../common/services/auth-header';

type CreatorTalents = {
  setCreatorTalents: any;
  creatorTalents: any;
  setAddNewTalent: any;
  setEditingTalentFromProfile: any;
  afterOnBoarding: boolean;
};

export function AddTalentModels(props: CreatorTalents) {
  const {
    setCreatorTalents,
    creatorTalents,
    setAddNewTalent,
    setEditingTalentFromProfile,
    afterOnBoarding,
  } = props;
  // Visual stuff
  const [slides, setSlides] = useState(5);
  const [slidesRows, setSlidesRows] = useState(2);
  const [slidesRowsScroll, setSlidesRowsScroll] = useState(4);
  // Open Talent Modal
  const [isOpen, setIsOpen] = useState(false);
  // New Talent Model
  const [newTalent, setNewTalent] = useState<any | null>(null);
  // EDIT Talent Model
  const [editTalent, setEditTalent] = useState<any | null>(null);
  // EDIT Talent Model FLAG and Index
  const [isEdit, setIsEdit] = useState(false);
  const [editTalentIndex, setEditTalentIndex] = useState(-1);
  // Talent Models Array
  const [allTalents, setAllTalents] = useState<any | null>([]);
  // To check if user is admin, that means "user doesn't exist yet"
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [talentId, setTalentId] = useState<any | null>();
  const [uploadAvailable, setUploadAvailable] = useState<boolean>(true);

  const [finishOnboard, setFinishOnboard] = useState<boolean>(false);
  const [creatorUsername, setCreatorUsername] = useState('');

  useEffect(() => {
    if (currentUser?.onboardFinish) {
      setFinishOnboard(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser.onboardFinish === false && !afterOnBoarding) {
      setCreatorTalents(allTalents);
    }
  }, [allTalents]);

  useEffect(() => {
    if (
      currentUser?.roles[0] === 'ROLE_ADMIN' ||
      currentUser?.onboardFinish === false
    ) {
      const temp = [...allTalents];
      if (newTalent && editTalentIndex === -1) {
        temp.push(newTalent);
        setAllTalents(temp);
      }
      if (newTalent && editTalentIndex !== -1) {
        temp[editTalentIndex] = newTalent;
        setAllTalents(temp);
        setEditTalentIndex(-1);
      }
    } else {
      // PROFILE MODE !
      setEditingTalentFromProfile(true);
      if (talentId) {
        updateTalent();
      } else {
        setAddNewTalent(newTalent);
      }
    }
  }, [currentUser?.roles, newTalent]);

  useEffect(() => {
    getUserInformation();
  }, [currentUser]);

  useEffect(() => {
    getUserInformation();
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (
      currentUser.roles[0] === 'ROLE_CREATOR' &&
      location.pathname !== '/ProfileOnboarding'
    ) {
      setEditingTalentFromProfile(true);
      setAddNewTalent(newTalent);
      getUserInformation();
    }
  }, [newTalent]);

  async function updateTalent() {
    const updatedInfo = {
      creatorTalents: JSON.stringify(newTalent),
      type: 'creator',
      talentIdToUpdate: talentId,
    };

    try {
      await axios.post(`${SERVER_URL}update-talent/`, updatedInfo);
      getUserInformation();
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
        // setEditingTalentFromProfile(false);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    if (currentUser.roles[0] !== 'ROLE_CREATOR') {
      setProfileMode(false);
      setCreatorTalents(allTalents);
    }
  }, [allTalents]);

  const [profileMode, setProfileMode] = useState(false);
  useEffect(() => {
    if (
      currentUser.roles[0] === 'ROLE_CREATOR' &&
      currentUser.onboardFinish === true
    ) {
      setProfileMode(true);
      setAllTalents(creatorTalents);
    }
  }, [creatorTalents]);

  useEffect(() => {
    if (afterOnBoarding) {
      setProfileMode(true);
      setAllTalents(creatorTalents);
    }
  }, [creatorTalents]);

  useEffect(() => {
    if (
      currentUser.roles[0] === 'ROLE_CREATOR' &&
      currentUser.onboardFinish === true
    ) {
      setProfileMode(true);
      setAllTalents(creatorTalents);
    }
  }, []);

  useEffect(() => {
    if (!currentUser.onboardFinish && !afterOnBoarding) {
      setProfileMode(false);
    }
  }, [profileMode]);

  // Delete a Talent
  function deleteTalent(index: number, element: number) {
    if (
      currentUser.roles[0] === 'ROLE_ADMIN' ||
      currentUser.onboardFinish === false
    ) {
      const temp = [...allTalents];
      temp.splice(index, 1);
      setAllTalents(temp);
    } else {
      deleteFromDb(element);
    }
  }

  async function deleteFromDb(talentIdToDel: number) {
    const updatedInfo = {
      type: 'creator',
      talentIdToDelete: talentIdToDel,
    };

    try {
      const res = await axios.post(`${SERVER_URL}delete-talent/`, updatedInfo);
      // eslint-disable-next-line no-console
      //  console.log(res);
      getUserInformation();
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
    // setEditingTalentFromProfile(false);
    getUserInformation();
  }

  async function getUserInformation() {
    let res;
    const username = currentUser?.username;

    const config = {
      method: 'get',
      url: `${SERVER_URL}getUserInfo/${username}`,
      headers: authHeader(),
    };

    try {
      res = await axios(config);

      setCreatorUsername(res?.data.userInfo.username);
      setCreatorTalents(res?.data.creatorTalents);
      setFinishOnboard(currentUser?.onboardFinish);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  // Edit a Talent
  function editExistingTalent(index: number, element: number) {
    setTalentId(element);

    if (
      currentUser.roles[0] === 'ROLE_ADMIN' ||
      currentUser.onboardFinish === false
    ) {
      const temp = [...allTalents];
      const toEdit = temp[index];
      setEditTalent(toEdit);
      setEditTalentIndex(index);
      setIsEdit(true);
      setIsOpen(!isOpen);
    } else {
      setUploadAvailable(false);

      const temp = [...allTalents];
      const toEdit = temp[index];

      setEditTalent(toEdit);
      setEditTalentIndex(index);
      setIsEdit(true);
      setIsOpen(!isOpen);
    }
  }

  function openModal() {
    setEditTalentIndex(-1);
    setIsEdit(false);
    setIsOpen(!isOpen);
  }
  useEffect(() => {
    if (window.innerWidth < 700) {
      setSlides(1);
      setSlidesRows(1);
      setSlidesRowsScroll(1);
    }
  }, []);
  return (
    <div>
      <PhotographyInspirationModal
        state={isOpen}
        setNewTalent={setNewTalent}
        newTalent={newTalent}
        editTalent={editTalent}
        setEditTalent={setEditTalent}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setEditTalentIndex={setEditTalentIndex}
        profileMode={profileMode}
        uploadAvailable={uploadAvailable}
        setUploadAvailable={setUploadAvailable}
        creatorUsername={creatorUsername}
      />
      <div className="w-12/12 md:w-12/12 mx-auto text-gray-500 dark:text-white">
        <div className="mt-10">
          <div className="ml-5 md:ml-0">
            <p className="font-semibold">
              Available Talent / Family, Friends, Pet, Neighbors, Etc
            </p>
          </div>
          <div className="mt-5 place-content-between content-between md:flex ">
            <section role="button" tabIndex={0} onClick={() => openModal()}>
              <div className="m-auto grid h-[340px] w-[340px] content-center  justify-center  rounded-xl border border-dashed border-sky-300 bg-white p-10 text-center align-middle leading-none transition ease-in-out hover:cursor-pointer hover:bg-sky-50 hover:opacity-75 dark:bg-slate-800 dark:hover:bg-slate-500 md:h-[48.02vh] md:w-[20.83vw] ">
                <div className="mb-[45px]">
                  <h1 className="text-[4.25rem] font-[100] text-sky-500  md:text-[10.25rem]">
                    +
                  </h1>
                  <p className="mt-0 font-normal text-gray-600 dark:text-white">
                    Add Model & Talent
                  </p>
                </div>
              </div>
            </section>

            <section className="mt-10 md:mt-0 md:w-9/12">
              <Slider
                dots
                infinite={false}
                arrows={false}
                speed={500}
                slidesToShow={slides}
                centerPadding="80px"
                slidesToScroll={slidesRowsScroll}
                rows={slidesRows}
                className="m-auto h-fit w-full md:w-full"
              >
                {allTalents?.map(
                  (element: any, index: number) =>
                    !element.deleted && (
                      <section
                        className="ml-0 mb-3 h-[215px] w-[350px] px-3  md:h-[22.94vh] md:w-[11.19vw] "
                        key={Math.floor(Math.random() * (1500 - 1) + 1)}
                      >
                        <div className=" relative h-[215px] rounded-2xl bg-white hover:opacity-90 dark:bg-slate-800 md:h-[22.94vh] md:w-[11.19vw]">
                          {profileMode ? (
                            <div>
                              <img
                                src={`${BUCKET_IMAGES}talent/${creatorUsername}/${element?.imageUrl}`}
                                alt=""
                                className="h-[215px] w-[350px] rounded-2xl object-cover hover:opacity-90 md:h-[22.94vh] md:w-full"
                              />
                              <div
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                  editExistingTalent(index, element?.id)
                                }
                              >
                                <img
                                  src="./img/editCircleBlue.png"
                                  alt=""
                                  className="absolute left-[19rem] top-[0.3125rem]  z-10 mt-2 h-8 w-8 hover:cursor-pointer hover:opacity-80 md:left-[8.75vw] md:top-[0.53vh] md:h-[2vw] md:w-[2vw]"
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={URL.createObjectURL(element?.photoTalent)}
                                alt=""
                                className="h-[215px] w-[350px] rounded-2xl object-cover hover:opacity-90 md:h-[22.94vh] md:w-full"
                              />
                              <div
                                role="button"
                                tabIndex={0}
                                onClick={() => editExistingTalent(index, 0)}
                              >
                                <img
                                  src="./img/editCircleBlue.png"
                                  alt=""
                                  className="absolute left-[19rem] top-[0.3125rem]  z-10 mt-2 h-8 w-8 hover:cursor-pointer hover:opacity-80 md:left-[8.75vw] md:top-[0.53vh] md:h-[2vw] md:w-[2vw]"
                                />
                              </div>
                            </div>
                          )}

                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => deleteTalent(index, element?.id)}
                          >
                            <img
                              src="./img/deleteCircleBlue.png"
                              alt=""
                              className="absolute  left-[19rem] top-[2.8rem]  z-10 mt-2 h-8 w-8 hover:cursor-pointer hover:opacity-80 md:left-[8.75vw] md:top-[4.78vh] md:h-[2vw] md:w-[2vw]"
                            />
                          </div>
                        </div>
                      </section>
                    ),
                )}
              </Slider>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
