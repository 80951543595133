import React from 'react';

type PropsBrand = {
  onChangeExtraBrand: any;
  brandInfo: any;
  brandfirstname: any;
  brandlastname: any;
  brandemail: any;
  brandtitle: any;
};

export function UserInformation(props: PropsBrand) {
  const {
    onChangeExtraBrand,
    brandInfo,
    brandfirstname,
    brandlastname,
    brandemail,
    brandtitle,
  } = props;

  return (
    <div className="text-gray-600 dark:text-white">
      <div className="mb-10 flex w-full place-content-between">
        <h1 className="text-2xl font-semibold"> User Information </h1>
      </div>

      <div className="m-auto grid w-full gap-5 md:flex">
        <section className="grid w-full gap-5">
          <div className="grid w-full gap-5 md:grid-cols-2">
            <input
              type="text"
              className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandfirstname}
              placeholder="First Name"
              value={brandInfo?.first_name}
              onChange={(e) => onChangeExtraBrand('first_name', e.target.value)}
            />
            <input
              type="text"
              className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandlastname}
              placeholder="Last Name"
              value={brandInfo?.last_name}
              onChange={(e) => onChangeExtraBrand('last_name', e.target.value)}
            />
            <input
              type="email"
              className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandemail}
              placeholder="Email"
              value={brandInfo?.email}
              onChange={(e) => onChangeExtraBrand('email', e.target.value)}
            />
            <input
              type="text"
              className="w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandtitle}
              placeholder="Title"
              value={brandInfo?.title}
              onChange={(e) => onChangeExtraBrand('title', e.target.value)}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
