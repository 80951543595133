import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { MessagesBarCreator } from '../messages-bar-creator/MessagesBarCreator';
import { Steps } from '../steps-detail/StepsDetail';
import { USERS_IMAGES_URL, SERVER_URL } from '../../common/CONFIG';
import './style.scss';

type Props = {
  project: any;
  status: any;
  setStatus: any;
  currentStep: any;
  setCurrentStep: any;
  setReceivedProduct: any;
  receivedProduct: any;
};

export function ProjectDetailPage(props: Props) {
  const {
    project,
    status,
    setStatus,
    currentStep,
    setCurrentStep,
    setReceivedProduct,
    receivedProduct,
  } = props;
  const [dueDate, setDueDate] = useState('');
  const [remainingDays, setRemainingDays] = useState(0);

  useEffect(() => {
    setReceivedProduct(project?.project?.receivedProduct);
  }, [project?.project?.receivedProduct]);

  function receivedProductHandle() {
    if (currentStep > 0 && !receivedProduct) {
      setReceivedProduct(true);
      receivedProductFromCreator();
      setStatus('RECIVED PRODUCT');
      setCurrentStep(2);
    }
  }

  async function receivedProductFromCreator() {
    const route = `${SERVER_URL}receivedProductFromCreator/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        projectId: project?.project?.projectId,
        receivedProduct: true,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => { })
      .catch(() => { });
  }

  async function updatePhotographerNotes(notes: string) {
    const route = `${SERVER_URL}updatePhotographerNotes/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        projectId: project?.project?.projectId,
        notes,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => { })
      .catch(() => { });
  }

  useEffect(() => {
    const today = new Date();
    const date = new Date(project?.project?.dueDate);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = new Date(project?.project?.dueDate).getDate();
    const dateDueFinal = `${month.toUpperCase()} ${day}`;
    const difTime = date.getTime() - today.getTime();
    const difDays = difTime / (1000 * 3600 * 24);
    setDueDate(dateDueFinal);
    setRemainingDays(Math.round(difDays));
  }, [project?.project?.dueDate]);

  function addPhotographerNotes() {
    const text = notesInput!.current!.value;
    updatePhotographerNotes(text);
  }

  const notesInput = React.useRef<HTMLTextAreaElement>(null);

  return (
    <section className="m-auto w-10/12">
      {/* <MessagesBarCreator numberMessages={numberMessagesBar} print /> */}
      <div className="mt-10 grid md:mt-2 md:flex md:justify-between">
        <div className="grid">
          <p className="text-lg font-semibold text-sky-400">STATUS: {status}</p>
          <h1 className=" text-[2.8125rem] font-semibold text-gray-600 dark:text-white">
            Project Detail Page
          </h1>
          <div className="my-5 grid md:my-0 md:flex">
            <p className="text-lg font-semibold text-gray-500 dark:text-white">
              {project?.project?.name}
            </p>
            <p className="hidden text-lg font-semibold text-gray-500 dark:text-white md:mx-2 md:block">
              /
            </p>
            <div className="flex">
              <p className="text-lg font-semibold text-sky-400">
                <u>Product Received</u>
              </p>
              <input
                checked={receivedProduct}
                className="float-left mt-1 ml-2 h-5 w-5 cursor-pointer rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:border-sky-500 checked:bg-sky-300 focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300"
                type="checkbox"
                value=""
                onClick={() => receivedProductHandle()}
              />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="flex self-end">
            <img
              src="./img/calendar.png"
              alt=""
              className="mr-3 inline h-6 w-6 self-center"
            />
            <p className="text-[1.5rem] font-semibold text-gray-600 dark:text-white">
              DUE {dueDate}
            </p>
          </div>
          <p className="text-left text-gray-500 dark:text-white md:text-right">
            {remainingDays > 0
              ? `${Math.abs(remainingDays)} Day(s) Left`
              : `${Math.abs(remainingDays)} Day(s) Overdue`}
          </p>
        </div>
      </div>

      <Steps currentStep={currentStep} />

      <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
        Shoot Description & Details
      </h1>

      <section className="mt-5 grid md:flex">
        <div className="grid h-fit min-h-[49.09vh] w-[360px] rounded-2xl bg-white p-5 text-gray-500 dark:bg-slate-800 dark:text-white md:w-full ">
          <p>{project?.project?.description}</p>
          <hr className="my-5 border-[1px] font-bold" />

          <h1 className="font-semibold text-gray-600 dark:text-white">
            MY NOTES:
          </h1>
          <textarea
            maxLength={2000}
            ref={notesInput}
            id="style-1"
            className="mt-2 inline-block h-40 w-full rounded  border-2 border-gray-200 p-3 text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-600 dark:text-white dark:placeholder:text-white"
            defaultValue={project?.project?.photographerNotes}
          />

          <div
            role="button"
            tabIndex={0}
            onClick={() => addPhotographerNotes()}
            className="brand-color mt-10 mb-5 inline-block h-fit rounded-full px-8 py-2 text-center text-white duration-200 ease-in hover:cursor-pointer   hover:bg-sky-300 "
          >
            Add Photographer Notes +
          </div>
        </div>

        <div className="mt-10 max-w-[400px]  text-gray-600 dark:text-white md:mt-0 md:min-w-[33.85vw] md:px-10">
          <p>Photo Framing</p>

          <section className="mt-5 flex place-content-around md:w-2/5">
            {project?.imageCropWide && (
              <div className="grid text-center">
                <img
                  src="./img/wide.png"
                  alt=""
                  className="inline h-16 w-16 self-center text-center"
                />
                <p className="text-gray-500 dark:text-white">Wide</p>
              </div>
            )}

            {project?.imageCropMed && (
              <div className="grid text-center">
                <img
                  src="./img/med.png"
                  alt=""
                  className="inline h-16 w-16 self-center text-center"
                />
                <p className="text-gray-500 dark:text-white">Med</p>
              </div>
            )}
            {project?.imageCropTight && (
              <div className="grid text-center ">
                <img
                  src="./img/tight.png"
                  alt=""
                  className="inline h-16 w-16 self-center text-center"
                />
                <p className="text-gray-500 dark:text-white">Tight</p>
              </div>
            )}
          </section>

          <hr className="my-5 w-full border-[1px] font-bold md:w-3/5" />

          <p>Additional Requirements</p>
          {project?.project?.gear && <li>{project?.project?.gear}</li>}

          {project?.project?.wardrobe && <li>{project?.project?.wardrobe}</li>}

          {project?.project?.enviroments && (
            <li>{project?.project?.enviroments}</li>
          )}

          {project?.project?.animals && <li>{project?.project?.animals}</li>}

          {project?.project?.videography && <li>Videography</li>}
          {project?.project?.professionalModel && <li>Professional Model</li>}

          <hr className="my-5 w-full border-[1px] font-bold md:w-3/5" />
          <p>Product</p>

          <div className="mt-3 flex">
            {project?.product && (
              <img
                alt="img"
                src={`${USERS_IMAGES_URL}${project?.product[0]?.photoUrl}`}
                className="inline h-28 w-28 self-center rounded-2xl bg-white object-cover dark:bg-slate-800"
              />
            )}

            <div className="ml-5 grid">
              <p>Brand Site</p>
              <p className="text-xs">{project?.brand?.companyWeb}</p>

              <p>Contact</p>
              <p className="text-xs">
                {project?.brandUser?.first_name} {project?.brandUser?.last_name}
              </p>
              <p className="text-xs">{project?.brandUser?.email}</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
