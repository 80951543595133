import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import {
  age,
  gender,
  type,
  modelActivities,
} from '../../common/services/mocks/dataPhotographyInspirationModal';
import { BUCKET_IMAGES } from '../../common/CONFIG';

type Props = {
  setNewTalent: any;
  setEditTalent: any;
  setIsEdit: any;
  state: boolean;
  newTalent: string;
  editTalent: any;
  isEdit: boolean;
  setEditTalentIndex: any;
  profileMode: boolean;
  uploadAvailable: boolean;
  setUploadAvailable: any;
  creatorUsername: string;
};

export function PhotographyInspirationModal(props: Props) {
  const {
    profileMode,
    state,
    setNewTalent,
    newTalent,
    editTalent,
    setEditTalent,
    isEdit,
    setIsEdit,
    setEditTalentIndex,
    uploadAvailable,
    setUploadAvailable,
    creatorUsername,
  } = props;

  const [isOpen, setIsOpen] = useState(state);

  // For checkboxes
  const [checkedAge, setCheckedage] = useState('');
  const [checkedGender, setCheckedGender] = useState('');
  const [checkedType, setCheckedType] = useState('');
  const [checkedModelActivities, setCheckedModelActivities] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [breed, setBreed] = useState('');

  useEffect(() => {
    if (isEdit && !profileMode) {
      setBreed(editTalent.breedTalent);
      setDescription(editTalent.descriptionTalent);
      setName(editTalent.nameTalent);
      setCheckedModelActivities(editTalent.activityTalent);
      setCheckedType(editTalent.typeTalent);
      setCheckedGender(editTalent.genderTalent);
      setCheckedage(editTalent.ageTalent);
      setPhoto(editTalent.photoTalent);
      setFileDataURL(URL.createObjectURL(editTalent.photoTalent));
    }
    if (isEdit && profileMode) {
      setBreed(editTalent.animalBreed);
      setDescription(editTalent.information);
      setName(editTalent.name);
      setCheckedModelActivities(editTalent.modelActivities);
      setCheckedType(editTalent.type);
      setCheckedGender(editTalent.gender);
      setCheckedage(editTalent.age);
      setPhoto(editTalent.imageUrl);
      setFileDataURL(editTalent.photoTalent);
    }
    if (!isEdit) {
      setBreed('');
      setName('');
      setDescription('');
      setCheckedModelActivities('');
      setCheckedType('');
      setCheckedGender('');
      setCheckedage('');
      setPhoto('');
      setFileDataURL('');
    }
  }, [isEdit, isOpen]);

  const handleOnChangeAge = (e: any) => {
    setCheckedage(e.target.value);
  };
  const handleOnChangeGender = (e: any) => {
    setCheckedGender(e.target.value);
  };
  const handleOnChangeType = (e: any) => {
    setCheckedType(e.target.value);
  };
  const handleOnChangeModelActivities = (e: any) => {
    const checkedActivity = e.target.value;
    const activities = checkedModelActivities
      .split(',')
      .filter((activity) => !!activity);
    const activityIndex = activities.indexOf(checkedActivity);
    if (activityIndex > -1) {
      setCheckedModelActivities(
        activities.filter((activity) => activity !== checkedActivity).join(','),
      );
    } else {
      setCheckedModelActivities(activities.concat(checkedActivity).join(','));
    }
  };
  const onChangeDescription = (e: any) => {
    setDescription(e);
  };
  const onChangeName = (e: any) => {
    setName(e);
  };
  const onChangeBreed = (e: any) => {
    setBreed(e);
  };

  // Photo
  const [photo, setPhoto] = useState<any | null>('');
  const [fileDataURL, setFileDataURL] = useState<any | null>('');

  const fileUpload = useRef<HTMLInputElement>(null);
  const onChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    if (fileList[0] !== undefined) {
      setPhoto(fileList[0]);
    }

    showSelectedPhoto(fileList[0]);
  };

  const showSelectedPhoto = (input: any) => {
    let fileReader: any;
    if (input) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result) {
          setFileDataURL(e.target.result);
        }
      };
      fileReader.readAsDataURL(input);
    }
  };
  const clickFileUpload = () => {
    if (uploadAvailable) {
      fileUpload!.current!.click();
    }
  };

  const checkedAgeRef: any = useRef();
  const checkedModelActivitiesRef: any = useRef();
  const photoRef: any = useRef();
  const typeRef: any = useRef();



  function addNewModelToGrid() {

    if (!checkedAge) {
      checkedAgeRef.current?.classList.add('text-red-500', 'animate-pulse');
      return;
    }
    checkedAgeRef.current?.classList.remove('text-red-500', 'animate-pulse');

    if (!checkedType) {
      typeRef.current?.classList.add('text-red-500', 'animate-pulse');
      return;
    }
    typeRef.current?.classList.remove('text-red-500', 'animate-pulse');

    if (!checkedModelActivities) {
      checkedModelActivitiesRef.current?.classList.add('text-red-500', 'animate-pulse');
      return;
    }
    checkedModelActivitiesRef.current?.classList.remove('text-red-500', 'animate-pulse');

    if (!photo) {
      photoRef.current?.classList.remove('border-sky-300');
      photoRef.current?.classList.add('border-red-500', 'animate-pulse', 'border');
      return;
    }
    photoRef.current?.classList.add('border-sky-300');
    photoRef.current?.classList.remove('border-red-500', 'animate-pulse', 'border');


    if (isEdit && !profileMode) {
      const talentEditedObj = {
        ageTalent: checkedAge,
        typeTalent: checkedType,
        activityTalent: checkedModelActivities,
        genderTalent: checkedGender,
        descriptionTalent: description,
        breedTalent: breed,
        photoTalent: photo,
      };

      setNewTalent(talentEditedObj);
      closeModal();
    }
    if (!isEdit) {
      const talentObj = {
        ageTalent: checkedAge,
        typeTalent: checkedType,
        activityTalent: checkedModelActivities,
        genderTalent: checkedGender,
        descriptionTalent: description,
        name,
        breedTalent: breed,
        photoTalent: photo,
      };

      if (photo && fileDataURL) {
        setNewTalent(talentObj);
        setEditTalent(null);
        setIsEdit(false);
      }
      closeModal();
    }

    if (profileMode && isEdit) {
      const talentEditedObjFromProfile = {
        ageTalent: checkedAge,
        typeTalent: checkedType,
        activityTalent: checkedModelActivities,
        genderTalent: checkedGender,
        descriptionTalent: description,
        breedTalent: breed,
        photoTalent: photo,
        name,
      };

      setNewTalent(talentEditedObjFromProfile);
      closeModal();
    }
  }

  function showTooltip(element: any) {
    // I know it's not ideal, and worst thing to do in React... but there was no other way
    // Tooltips DONT work on modals according to official documentation.
    const elementTooltip = document.getElementById(element);
    elementTooltip?.classList.remove('hidden');
  }

  function hideTooltip(element: any) {
    const elementTooltip = document.getElementById(element);
    elementTooltip?.classList.add('hidden');
  }

  useEffect(() => {
    setIsOpen(true);
  }, [state]);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  function closeModal() {
    setEditTalent(null);
    setIsEdit(false);
    setIsOpen(false);
    setUploadAvailable(true);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="mx-auto my-8 inline-block w-11/12 overflow-hidden rounded-2xl bg-white p-3 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-10/12 md:p-12">
              <section className="m-auto grid w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mr-2 mt-1 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                    onKeyDown={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div className="grid md:inline-flex">
                  <div className="md:w-[37%]">
                    <h1 className="self-center text-2xl font-semibold text-gray-600 dark:text-white">
                      Add Talent
                    </h1>
                  </div>
                  <div className="z-10 self-center md:w-7/12">
                    <input
                      onChange={(e) => onChangeName(e.target.value)}
                      value={name}
                      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                      tabIndex={-1}
                      className="z-10 w-full border-b-2 py-3 text-2xl  font-semibold text-gray-600 transition duration-200 placeholder:text-gray-600  focus:border-sky-500 focus:outline-none focus:ring-0 focus:ring-sky-500 dark:bg-slate-800 dark:text-white"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div className="mx-auto mt-3 grid min-h-[600px] w-full md:flex md:min-h-[64.03vh] ">
                  <section className="grid text-gray-500 dark:text-white md:flex">
                    <section className="z-10">
                      <div
                        onClick={clickFileUpload}
                        role="button"
                        tabIndex={0}
                        ref={photoRef}
                        className="relative mt-0 flex h-80 w-80 rounded-xl border-[0.125rem] border-dashed border-sky-300 bg-white hover:cursor-pointer hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800  dark:hover:bg-slate-500 md:h-[76.84vh] md:w-[26.04vw]"
                      >
                        {fileDataURL && (
                          <img
                            src={fileDataURL}
                            alt="Preview 1"
                            className="object-cover"
                          />
                        )}

                        {photo && profileMode && !fileDataURL && (
                          <img
                            src={`${BUCKET_IMAGES}talent/${creatorUsername}/${photo}`}
                            alt="Preview 2"
                            className="object-cover"
                          />
                        )}
                        {!fileDataURL && !photo && (
                          <div className="absolute m-auto w-max content-center justify-center p-10 text-center align-middle md:inset-x-[8%] md:inset-y-[30%]">
                            <img
                              src="./img/upload.png"
                              alt=""
                              className="inline h-12 w-12 self-center"
                            />
                            <h1 className="mt-5 text-2xl font-semibold text-gray-600 dark:text-white">
                              Upload Model Photo
                            </h1>
                          </div>
                        )}
                      </div>
                    </section>
                    <input
                      type="file"
                      ref={fileUpload}
                      multiple
                      name="uploadPhotos"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onChangePhoto(e)}
                    />
                    <section className="z-0 mt-10 grid p-5 md:ml-[2.08vw] md:mt-12 md:p-0">
                      <div className="grid gap-1 md:w-[47.91vw]  md:grid-cols-3">
                        <div className="" ref={checkedAgeRef}>
                          <p>Age</p>
                          {age.map((element, index) => (
                            <div
                              key={`age + ${index.toString()}`}
                              className="mt-6 flex items-center"
                            >
                              <label
                                className="ml-1 inline-flex place-self-center"
                                htmlFor={`radio-${index}`}
                              >
                                <input
                                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                                  tabIndex={index}
                                  value={element.concept}
                                  type="radio"
                                  id={`radio-${index}`}
                                  name={element.concept}
                                  checked={checkedAge === element.concept}
                                  onChange={(e) => handleOnChangeAge(e)}
                                  className="z-0 float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow  dark:bg-slate-800 dark:checked:bg-sky-300 md:h-[2.56vh] md:w-[1.25vw]"
                                />

                                {element.concept}
                              </label>
                            </div>
                          ))}
                        </div>
                        {/* <hr className="m-auto my-10 block w-3/4 md:hidden" /> */}
                        {/* <div className="">
                          <p>Gender</p>
                          {gender.map((element, index) => (
                            <div
                              key={index.toString()}
                              className="mt-6 flex items-center"
                            >
                              <label
                                className="ml-1 inline-flex place-self-center"
                                htmlFor={`radio-${index}`}
                              >
                                <input
                                  value={element.concept}
                                  type="radio"
                                  id={`radio-${index}`}
                                  name={element.concept}
                                  checked={checkedGender === element.concept}
                                  onChange={(e) => handleOnChangeGender(e)}
                                  className="z-0 float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300 md:h-[2.56vh] md:w-[1.25vw]"
                                />

                                {element.concept}
                              </label>
                            </div>
                          ))}
                        </div> */}
                        <hr className="m-auto my-10 block w-3/4 md:hidden" />
                        <div className="" ref={typeRef}>
                          <p>Type</p>
                          {type.map((element, index) => (
                            <div
                              key={`type + ${index.toString()}`}
                              className="mt-6 flex items-center"
                            >
                              <label
                                className="ml-1 inline-flex place-self-center"
                                htmlFor={`radioType-${index}`}
                              >
                                <input
                                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                                  tabIndex={20 + index}
                                  value={element.concept}
                                  type="radio"
                                  id={`radioType-${index}`}
                                  name={element.concept}
                                  checked={checkedType === element.concept}
                                  onChange={(e) => handleOnChangeType(e)}
                                  className=" z-0 float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow dark:bg-slate-800 dark:checked:bg-sky-300 md:h-[2.56vh] md:w-[1.25vw]"
                                />

                                {element.concept}
                              </label>
                            </div>
                          ))}
                          {checkedType === 'Animal' && (
                            <input
                              type="text"
                              className="z-0 my-5 rounded-xl border p-3 focus:border-sky-500  focus:ring-1 focus:ring-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                              placeholder="Add Breed"
                              onChange={(e) => onChangeBreed(e.target.value)}
                              value={breed}
                            />
                          )}
                        </div>
                      </div>
                      <hr className="m-auto my-10 block w-3/4 md:hidden" />
                      <div className="md:mt-10">
                        <p>Model Activities</p>
                        <div className="grid w-full gap-0 md:h-[16vh] md:grid-cols-3" ref={checkedModelActivitiesRef}>
                          {modelActivities.map(
                            (element: any, index: number) => (
                              <div
                                key={`act + ${index.toString()}`}
                                className="relative mt-5 flex items-center"
                              >
                                <label
                                  className="ml-1 inline-flex place-self-center"
                                  htmlFor={element.concept}
                                >
                                  <input
                                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                                    tabIndex={30 + index}
                                    value={element.concept}
                                    type="checkbox"
                                    id={`checkboxActivities-${index}`}
                                    name={element.concept}
                                    checked={
                                      checkedModelActivities.indexOf(
                                        element.concept,
                                      ) > -1
                                    }
                                    onChange={(e) =>
                                      handleOnChangeModelActivities(e)
                                    }
                                    className="z-0 float-left mr-4 h-6 w-6 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition  duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow  dark:bg-slate-800 dark:checked:bg-sky-300 md:h-[2.56vh] md:w-[1.25vw]"
                                  />

                                  {element.concept}
                                  <span
                                    data-tip
                                    data-for={element.concept}
                                    className="z-[102] mx-2 cursor-pointer text-xs text-gray-400 dark:text-white"
                                  >
                                    <FontAwesomeIcon
                                      className=""
                                      icon={faQuestionCircle}
                                      onMouseOver={() =>
                                        showTooltip(element.concept)
                                      }
                                      onMouseLeave={() =>
                                        hideTooltip(element.concept)
                                      }
                                    />
                                  </span>
                                  <div
                                    id={element.concept}
                                    className="tooltip absolute left-[5px] top-[40px] z-[900] hidden w-fit px-4 py-2 text-center text-xs leading-normal text-white transition-all duration-200"
                                  >
                                    {element.tooltip}
                                  </div>
                                </label>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                      <textarea
                        rows={3}
                        className="z-0 my-5 h-[9.5rem] resize-none overflow-y-auto rounded-xl border p-3 focus:border-sky-500  focus:ring-1 focus:ring-sky-500 dark:bg-slate-400 dark:placeholder:text-white md:mt-10 md:h-[16.22vh] md:w-full"
                        placeholder="Enter Information"
                        onChange={(e) => onChangeDescription(e.target.value)}
                        value={description}
                        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                        tabIndex={150}
                      />

                      <div className="text-right">
                        <div>
                          <div
                            onClick={() => addNewModelToGrid()}
                            role="button"
                            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                            tabIndex={151}
                            className="brand-color mt-8 inline-block rounded-full px-8 py-2 text-white duration-200 ease-in hover:cursor-pointer   hover:bg-sky-300 md:mt-4 lg:mt-0"
                          >
                            Save
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
