import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CartItem } from 'components/cart-item/CartItem';
import { CouponModal } from 'components/coupon-modal/CouponModal';
import { SERVER_URL } from '../../common/CONFIG';

type Props = {
  photosPrice: number;
  total: number;
  discountedPrice: number;
  projectCart: any;
  projectId: any;
};

export function MyCartStep(props: Props) {
  const { projectId, projectCart, total, discountedPrice, photosPrice } = props;
  const [isCouponOpen, setIsCouponOpen] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [load, setLoad] = useState(false);
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    setDiscount(total - discountedPrice);
  }, [total, discountedPrice, projectCart]);

  useEffect(() => {
    if (discountedPrice > 0) {
      setLoad(true);
    }
  }, [discountedPrice]);

  const initialOptions = useMemo(
    () => ({
      'client-id':
        'AVyIjzLGY638SIipGbBtekV7QO9LBTKEkHNeg2ll387NPpzP8-2ZwjT2s_bamT7S01bbz-cFbhBKhA4J',
      currency: 'USD',
      intent: 'capture',
    }),
    [],
  );

  const buy = useCallback(
    async (orderId) => {
      const route = `${SERVER_URL}buy`;
      const res = await axios.post(route, {
        projectId,
        brandId: currentUser.id,
        projectCart,
        orderId,
      });
      if (res.status === 201) {
        navigate('/dashboard');
      }
    },
    [currentUser.id, navigate, projectCart, projectId],
  );

  return (
    <div>
      <CouponModal state={isCouponOpen} />

      <div className="mt-16 text-gray-600 dark:text-white">
        <section className="m-auto grid w-full md:flex md:gap-10">
          <section className="h-fit w-full border-b-2 border-slate-200 dark:border-slate-400">
            {projectCart?.map((element: any) => (
              <CartItem item={element} />
            ))}
          </section>

          <section className="m-auto mt-10 md:mt-0 md:w-1/2">
            <div className="h-fit rounded-2xl border border-gray-300 p-6 text-sm outline-none  dark:bg-slate-700  ">
              <p className="text-center text-xl font-extrabold text-gray-800 dark:text-white">
                Your Order Summary
              </p>
              <hr className="my-5" />

              {!projectCart[0]?.qtyPurchased &&
                !!projectCart[0]?.professionalModel && (
                  <div className="my-3 flex justify-between text-base  text-gray-800 dark:text-white">
                    <p>Professional Model Fee</p>
                    <p>$80</p>
                  </div>
                )}

              {!projectCart[0]?.qtyPurchased && !!projectCart[0]?.videography && (
                <div className="my-3 flex justify-between text-base  text-gray-800 dark:text-white">
                  <p>Videography</p>
                  <p>$500</p>
                </div>
              )}

              <div className="my-3 flex justify-between text-base  text-gray-800 dark:text-white">
                <p>Subtotal</p>
                <p>${total}</p>
              </div>

              {discount > 0 && (
                <div className="my-10 flex justify-between text-base  text-gray-800 dark:text-white">
                  <p>
                    Discount (${((discount * 100) / photosPrice).toFixed(2)}%)
                  </p>
                  <p>${discount}</p>
                </div>
              )}
              <hr />

              <div className="mt-5 flex justify-between">
                <p className="text-lg font-extrabold text-gray-700 dark:text-white">
                  Total
                </p>
                <p className="text-xl font-extrabold text-sky-500">
                  ${discountedPrice}
                </p>
              </div>

              <hr className="my-10" />

              <div className="m-auto text-center">
                {load && (
                  <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                      fundingSource="paypal"
                      style={{
                        layout: 'vertical',
                        shape: 'rect',
                        color: 'black',
                        label: 'checkout',
                        tagline: false,
                      }}
                      createOrder={(data, actions) =>
                        actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: discountedPrice.toString(),
                              },
                            },
                          ],
                        })
                      }
                      onApprove={(data, actions): any =>
                        actions.order?.capture().then((details) => {
                          // console.log('data', data, 'actions', actions);
                          const { orderID } = data;
                          if (orderID) {
                            buy(orderID);
                          }
                          return true;
                        })
                      }
                    />
                  </PayPalScriptProvider>
                )}
              </div>

              <p
                onClick={() => {
                  window.history.back();
                }}
                className="mt-10 text-center font-extrabold text-sky-500 hover:cursor-pointer hover:text-sky-300"
              >
                Continue Shopping
              </p>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}
