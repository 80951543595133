/* eslint-disable no-console */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FilterCreators, PhotographerCard } from 'components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import authHeader from '../../../common/services/auth-header';
import { USERS_IMAGES_URL, SERVER_URL } from '../../../common/CONFIG';

const shotLocation = [
  {
    location: 'Home',
    selected: false,
    tooltip: 'Home information',
  },
  {
    location: 'Urban',
    selected: false,
    tooltip: 'Urban information',
  },
  {
    location: 'Outdoor',
    selected: false,
    tooltip: 'Outdoor information',
  },
];

const activityAccess = [
  {
    location: 'Winter',
    selected: false,
    tooltip: 'Activity information',
  },
  {
    location: 'Coastal',
    selected: false,
    tooltip: 'Activity information',
  },
  {
    location: 'Camping',
    selected: false,
    tooltip: 'Activity information',
  },
  {
    location: 'Hunting & Fishing',
    selected: false,
    tooltip: 'Activity information',
  },
  {
    location: 'Farm',
    selected: false,
    tooltip: 'Activity information',
  },
  {
    location: 'Team Sports',
    selected: false,
    tooltip: 'Activity information',
  },
  {
    location: 'Health',
    selected: false,
    tooltip: 'Activity information',
  },

  {
    location: 'Physical Work',
    selected: false,
    tooltip: 'Activity information',
  },
];

const age = [
  {
    concept: 'Child',
    selected: false,
  },
  {
    concept: 'Teen',
    selected: false,
  },
  {
    concept: 'Adult',
    selected: false,
  },
  {
    concept: 'Senior',
    selected: false,
  },
  {
    concept: 'N/A',
    selected: false,
  },
];

export default function Photographers() {
  useEffect(() => {
    document.title = 'Authentic | Photographers';
  }, []);

  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [creators, setCreators] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [inspirations, setInspirations] = useState([]);
  const [search, setSearch] = useState([]);
  const [searchBackUp, setSearchBackUp] = useState([]);

  useEffect(() => {
    async function getUsersInformation() {
      const config = {
        method: 'get',
        url: `${SERVER_URL}getAllCreators/${currentUser?.id}`,
        headers: authHeader(),
      };

      try {
        const res = await axios(config);
        setCreators(res?.data);
      } catch (err: any) {
        if (err.response.status === 500) {
          // eslint-disable-next-line no-console
          //  console.log(err);
        } else {
          // eslint-disable-next-line no-console
          //  console.log(err.response.data.msg);
        }
      }
    }
    getUsersInformation();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSearch(creators);
    setSearchBackUp(creators);
  }, [creators]);

  const [searchLocation, setSearchLocation] = useState(shotLocation);

  const [searchActivity, setSearchActivity] = useState(activityAccess);
  const [enabledFavourite, setEnabledFavourite] = useState(false);
  const [searchAge, setSearchAge] = useState(age);

  const [refreshFavCreatorState, setRefreshFavCreatorState] = useState(-1);

  useEffect(() => {
    if (refreshFavCreatorState !== -1) {
      const temp: any = [...search];
      const tempStateFav = temp[refreshFavCreatorState]?.isBrandFav;

      if (tempStateFav) {
        temp[refreshFavCreatorState].isBrandFav = !tempStateFav;
      } else {
        temp[refreshFavCreatorState].isBrandFav = true;
      }

      setSearch(temp);
      setSearchBackUp(temp);
    }
  }, [refreshFavCreatorState]);

  useEffect(() => {
    const searchResultTempLocation: any = [];
    const searchResultTempActivity: any = [];
    const searchResultTempAge: any = [];
    const temp: any = [...creators];

    let flagLoc = false;
    let flagAct = false;
    let flagAge = false;

    searchLocation.forEach((location) => {
      if (location.selected) {
        flagLoc = true;
        temp.forEach((creator: any) => {
          if (creator.creatorExtraInfo?.shotLocation) {
            const shotLocationElements = JSON.parse(
              creator.creatorExtraInfo?.shotLocation,
            );
            shotLocationElements.forEach((element: any) => {
              const result = element.includes(location.location);
              if (result) {
                searchResultTempLocation.push(creator);
              }
            });
          }
        });
      }
    });

    searchActivity.forEach((location) => {
      if (location.selected) {
        flagAct = true;
        temp.forEach((creator: any) => {
          const currentCreatorTalents = creator.creatorTalents;
          currentCreatorTalents.forEach((talent: any) => {
            const result = talent.modelActivities?.includes(location.location);
            if (result) {
              searchResultTempActivity.push(creator);
            }
          });
        });

        temp.forEach((creator: any) => {
          if (creator.creatorExtraInfo?.activityAccess) {
            const searchActivityElements = JSON.parse(
              creator.creatorExtraInfo?.activityAccess,
            );
            searchActivityElements.forEach((element: any) => {
              const result = element.includes(location.location);
              if (result) {
                searchResultTempActivity.push(creator);
              }
            });
          }
        });
      }
    });

    searchAge.forEach((ageSel) => {
      if (ageSel.selected) {
        flagAge = true;
        temp.forEach((creator: any) => {
          const currentCreatorTalents = creator.creatorTalents;
          currentCreatorTalents.forEach((talent: any) => {
            const result = talent.age.includes(ageSel.concept);
            if (result) {
              searchResultTempAge.push(creator);
            }
          });
        });
      }
    });

    const finalArray: any = [];

    if (flagLoc && flagAct && flagAge) {
      searchResultTempLocation?.forEach((e1: any) =>
        searchResultTempActivity?.forEach((e2: any) => {
          searchResultTempAge?.forEach((e3: any) => {
            if (
              e1.creatorExtraInfo?.id === e2.creatorExtraInfo?.id &&
              e2.creatorExtraInfo?.id === e3.creatorExtraInfo?.id
            ) {
              finalArray.push(e1);
            }
          });
        }),
      );

      const unique = finalArray.filter(
        (a: any, i: any) =>
          finalArray.findIndex((s: any) => a.user?.id === s.user?.id) === i,
      );
      setSearch(unique);
      setSearchBackUp(unique);
    }

    if (flagLoc && flagAct && !flagAge) {
      searchResultTempLocation?.forEach((e1: any) =>
        searchResultTempActivity?.forEach((e2: any) => {
          if (e1.creatorExtraInfo?.id === e2.creatorExtraInfo?.id) {
            finalArray.push(e1);
          }
        }),
      );

      const unique = finalArray.filter(
        (a: any, i: any) =>
          finalArray.findIndex((s: any) => a.user?.id === s.user?.id) === i,
      );

      setSearch(unique);
      setSearchBackUp(unique);
    }

    if (flagLoc && !flagAct && flagAge) {
      searchResultTempLocation?.forEach((e1: any) =>
        searchResultTempAge?.forEach((e2: any) => {
          if (e1.creatorExtraInfo?.id === e2.creatorExtraInfo?.id) {
            finalArray.push(e1);
          }
        }),
      );

      const unique = finalArray.filter(
        (a: any, i: any) =>
          finalArray.findIndex((s: any) => a.user?.id === s.user?.id) === i,
      );

      setSearch(unique);
      setSearchBackUp(unique);
    }

    if (!flagLoc && flagAct && flagAge) {
      searchResultTempActivity?.forEach((e1: any) =>
        searchResultTempAge?.forEach((e2: any) => {
          if (e1.creatorExtraInfo?.id === e2.creatorExtraInfo?.id) {
            finalArray.push(e1);
          }
        }),
      );

      const unique = finalArray.filter(
        (a: any, i: any) =>
          finalArray.findIndex((s: any) => a.user?.id === s.user?.id) === i,
      );

      setSearch(unique);
      setSearchBackUp(unique);
    }

    if (flagLoc && !flagAct && !flagAge) {
      const unique = searchResultTempLocation.filter(
        (a: any, i: any) =>
          searchResultTempLocation.findIndex(
            (s: any) => a.user?.id === s.user?.id,
          ) === i,
      );

      setSearch(unique);
      setSearchBackUp(unique);
    }

    if (!flagLoc && flagAct && !flagAge) {
      const unique = searchResultTempActivity.filter(
        (a: any, i: any) =>
          searchResultTempActivity.findIndex(
            (s: any) => a.user?.id === s.user?.id,
          ) === i,
      );

      setSearch(unique);
      setSearchBackUp(unique);
    }
    if (!flagLoc && !flagAct && flagAge) {
      const unique = searchResultTempAge.filter(
        (a: any, i: any) =>
          searchResultTempAge.findIndex(
            (s: any) => a.user?.id === s.user?.id,
          ) === i,
      );

      setSearch(unique);
      setSearchBackUp(unique);
    }

    if (!flagLoc && !flagAct && !flagAge) {
      setSearch(creators);
      setSearchBackUp(creators);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLocation, searchActivity, searchAge]);

  useEffect(() => {
    if (search === undefined) {
      setSearch(creators);
      setSearchBackUp(creators);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  async function getBrandsFavoriteCreators() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getBrandsFavoriteCreators/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setFavorites(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  async function getBrandsInspirations() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getBrandsInspirationPhotos/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setInspirations(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    if (enabledFavourite) {
      getBrandsFavoriteCreators();
    }
  }, [enabledFavourite, search]);

  useEffect(() => {
    getBrandsInspirations();
  }, []);

  useEffect(() => {
    if (!enabledFavourite) {
      setSearch(searchBackUp);
    }
  }, [enabledFavourite]);

  useEffect(() => {
    if (enabledFavourite) {
      const tempSearch = [...search];

      const filteredSearch: any = [];

      favorites?.forEach((favs: any) => {
        tempSearch?.forEach((creator: any) => {
          const usertemp = creator?.user?.id;

          if (usertemp === favs) {
            filteredSearch.push(creator);
          }
        });
      });

      setSearch(filteredSearch);
    }
  }, [favorites]);

  return (
    <section className="bg-gray-100 dark:bg-slate-900">
      <div className="m-auto grid w-full md:flex md:w-10/12">
        <section className={`my-16 `}>
          <FilterCreators
            setSearchLocation={setSearchLocation}
            setSearchActivity={setSearchActivity}
            setSearchAge={setSearchAge}
            enabledFavourite={enabledFavourite}
            setEnabledFavourite={setEnabledFavourite}
          />
        </section>

        <section className="mb-2 md:my-16 md:ml-[4.16vw] w-full">
          <h1 className="ml-8 text-3xl font-bold text-gray-700 dark:text-white md:ml-0">
            Photographers
          </h1>
          <div className="md:my-6">
            {search?.map((element: any, index: number) => (
              <PhotographerCard
                key={`photcard + ${index.toString()}`}
                data={element}
                creatorIndex={index}
                search={search}
                setRefreshFavCreatorState={setRefreshFavCreatorState}
                inspirations={inspirations}
              />
            ))}
          </div>
          {creators?.length === 0 && (
            <div className="m-auto w-full text-center flex justify-center pt-32">
              <svg
                className="-ml-1 mr-3 h-20 w-20 animate-spin text-sky-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          )}
        </section>

        {search?.length === 0 && creators?.length !== 0 && (
          <div className="m-auto w-full text-center">
            <p className="animate-pulse p-10 text-2xl font-extrabold text-gray-400 dark:text-gray-300">
              No photographers match your search,
              <br />
              please reduce selected filters to see more results
            </p>
          </div>
        )}

      </div>
    </section>
  );
}
