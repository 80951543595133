import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../common/CONFIG';
import { dataTestRecentProject } from '../../common/services/mocks/dataDashboard';
// eslint-disable-next-line import/no-cycle
import { FavoritePhotographersCardBrief } from '../favorite-photographers-card-brief/FavoritePhotographersCardBrief';

export function FavoritePhotographersGrid() {
  const [preferedPhotographerOrder, setPreferedPhotographerOrder] = useState(
    dataTestRecentProject,
  );

  // For reorder when drag n' drop the favourite cards:
  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const { user: currentUser } = useSelector((state: any) => state.auth);

  const [favDelete, setFavDelete] = useState<any>(0);

  const [briefDetails, setBriefDetails] = useState<any>([]);
  const [forRefresh, setForRefresh] = useState<any>();

  async function getUserInformation() {
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}brandFavoriteCreators/${username}`,
      // headers: authHeader(),
    };

    try {
      const res = await axios(config);

      // eslint-disable-next-line no-console
      //  console.log(res);
      setPreferedPhotographerOrder(res.data);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { source, destination } = result;
        if (!destination) {
          return;
        }
        if (
          source.index === destination.index &&
          source.droppableId === destination.droppableId
        ) {
          return;
        }
        setPreferedPhotographerOrder((oldPrefs) =>
          reorder(oldPrefs, source.index, destination.index),
        );
      }}
    >
      <section className="mx-auto w-10/12 text-center      ">
        <div className="mb-20 px-0 pt-10 md:px-0">
          <div className="mx-auto flex content-center items-center justify-center self-center text-center">
            <h1 className="text-2xl font-semibold text-gray-600 dark:text-white">
              Previous Photographers, You´ve Worked With
            </h1>
          </div>
          <p className="mt-2 text-lg font-normal text-gray-600 dark:text-white">
            Talented Photographers, you´ve worked with in the past. Drag them in
            the order of priority.
          </p>
        </div>
        {/* Iterate array of photographers only 4! */}
        <Droppable
          droppableId="photographerCard"
          direction={window.innerWidth > 700 ? 'horizontal' : 'vertical'}
        >
          {(droppableProvided) => (
            <section
              className="m-auto grid justify-center overflow-hidden md:min-h-[430px] md:grid-cols-4 md:place-content-stretch md:gap-20 "
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {preferedPhotographerOrder.map(
                (element, index) =>
                  index < 4 && (
                    <Draggable
                      key={element.key}
                      draggableId={element.pictureUrl}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <FavoritePhotographersCardBrief
                          // {...draggableProvided.draggableProps}
                          // {...draggableProvided.dragHandleProps}
                          innerRef={draggableProvided.innerRef}
                          provided={draggableProvided}
                          photographer={element.photographer}
                          prefPosition={index}
                          setFavDelete={setFavDelete}
                          preferedPhotographerOrder={preferedPhotographerOrder}
                          setPreferedPhotographerOrder={
                            setPreferedPhotographerOrder
                          }
                          setForRefresh={setForRefresh}
                          editMode={false}
                        />
                      )}
                    </Draggable>
                  ),
              )}
              {droppableProvided.placeholder}
            </section>
          )}
        </Droppable>
        <Link to="/photographer">
          <p className="mt-8 mr-7 text-right font-bold text-gray-500 transition ease-in hover:translate-x-2 hover:cursor-pointer hover:text-sky-500 hover:transition hover:duration-100 hover:ease-in dark:text-white dark:hover:text-sky-500 md:mr-16 ">
            Find Other Photographers &gt;
          </p>
        </Link>
      </section>
    </DragDropContext>
  );
}
