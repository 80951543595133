import React from 'react';
import axios from 'axios';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';

type DataPhotographersType = {
  id: number | string;
  pictureName?: string;
  pictureUrl?: string;
  photographer?: string;
  totalPurchased?: string;
  totalPictures?: string;
  buy?: boolean;
  favorite?: boolean;
  download?: boolean;
  resolution?: string;
  masonryHeight?: string;
  marginBottom?: string;
  refPosition?: string;
  ref?: number;
  notes?: string;
  cartAdded?: boolean;
  cartBought?: boolean;
  documentName?: string;
  extension?: string;
  type?: string;
};
type Props = {
  projectId: any;
  data: any;
  setImagesFromCreator: any;
};

export function SupportingDocumentsUploadCard(props: Props) {
  const { projectId, data, setImagesFromCreator } = props;

  function deletePhoto(index: any) {
    const result = [...data];
    const photoId = result[index].id;
    result.splice(index, 1);
    setImagesFromCreator(result);
    deletePhotoBuy(photoId);
  }

  async function deletePhotoBuy(photoId: number) {
    const route = `${SERVER_URL}deletePhotoBuy`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photoId,
      })
      .then((res: any) => {
        if (res.status === 201) {
          //
        }
        return res.data;
      })
      .catch(() => {});
  }

  return (
    <div className="mx-auto mt-20 mb-10 grid h-fit w-10/12 grid-cols-1 gap-6 md:grid-cols-6">
      {data?.map(
        (element: any, index: any) =>
          !element.deleted && (
            <div
              className="relative h-[240px] w-full rounded-2xl md:w-[12.5vw]"
              key={element.id}
            >
              {element.cartBought && (
                <img
                  src="./img/cartBought.png"
                  alt=""
                  className="absolute top-3 right-3 h-9 w-9 transition-all duration-300 hover:cursor-pointer hover:opacity-70 md:right-[0.625vw]"
                />
              )}

              {element?.resolution ? (
                <img
                  src={`${BUCKET_IMAGES}original/${projectId}/${element?.imageUrl}`}
                  alt=""
                  className="inline h-[240px] w-full self-center rounded-2xl object-cover md:w-[12.5vw]"
                />
              ) : (
                <img
                  src={URL.createObjectURL(element)}
                  alt=""
                  className="inline h-[240px] w-full self-center rounded-2xl object-cover md:w-[12.5vw]"
                />
              )}

              <div className="absolute top-0 flex h-[240px] w-full place-items-end rounded-2xl bg-sky-500 opacity-0 transition-all duration-300 hover:cursor-pointer hover:opacity-80 md:w-[12.5vw]">
                <div className="m-3 grid text-white">
                  <div
                    className="flex"
                    role="button"
                    tabIndex={0}
                    onClick={() => deletePhoto(index)}
                  >
                    <img
                      src="./img/deleteCard.png"
                      alt=""
                      className="mr-3 inline h-7 w-6 self-center transition-all duration-300 hover:cursor-pointer hover:opacity-70"
                    />
                    <h1 className="md:mt-1">Delete</h1>
                  </div>
                  {/* <div className="mt-3 flex ">
                    <img
                      src="./img/addCard.png"
                      alt=""
                      className="mr-3 inline h-6 w-6 self-center transition-all duration-300 hover:cursor-pointer hover:opacity-70"
                    />
                    <h1>Add To Portfolio</h1>
                  </div> */}
                </div>
              </div>
            </div>
          ),
      )}
    </div>
  );
}
