/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../common/CONFIG';


export default function ForgotPassword() {

  const navigate = useNavigate();

  const userRef: any = useRef();
  const passRef: any = useRef();
  const passRef2: any = useRef();
  const tokenRef: any = useRef();



  const userRefCurrent = userRef.current;
  const passRefCurrent = passRef.current;
  const passRefCurrent2 = passRef2.current;

  const tokenRefCurrent = tokenRef.current;

  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validPassword2, setValidPassword2] = useState(true);

  const [validToken, setValidToken] = useState(true);

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('Error');
  const [changeSuccess, setChangeSuccess] = useState(false);





  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const [passwordText2, setPasswordText2] = useState('');
  const [token, setToken] = useState('');



const goToLogIn= () => {
  navigate('/');
}


  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    let errorEmptyUser = true;
    let errorEmptyPass = true;
    let errorEmptyPass2 = true;

    let errorEmptyToken = true;


    // name check
    if (userRefCurrent?.value.length === 0) {
      userRefCurrent?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    } else {
      errorEmptyUser = false;
      userRefCurrent?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    // pass check
    if (passRefCurrent?.value.length === 0) {
      passRefCurrent?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    } else {
      errorEmptyPass = false;
      passRefCurrent?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    if (passRefCurrent2?.value.length === 0) {
      passRefCurrent2?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    } else {
      errorEmptyPass2 = false;
      passRefCurrent2?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    // token check

    if (tokenRefCurrent?.value.length === 0) {
      tokenRefCurrent?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    } else {
      errorEmptyToken = false;
      tokenRefCurrent?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    if (passRefCurrent2?.value !== passRefCurrent?.value) {
      showErrorMessage("Password do not match.");
      return;
    }

    if (tokenRefCurrent?.value.length !== 8) {
      showErrorMessage("Wrong token.");
      return;
    }

    if (passRefCurrent?.value.length < 3) {
      showErrorMessage("Password too short.");
      return;
    }

    if (!errorEmptyUser && !errorEmptyPass && !errorEmptyToken) {

      resetPasswordSubmit();

    }
  };

  function emailFieldChange(e: string) {
    setEmailText(e);
  }
  function passwordFieldChange(e: string) {
    setPasswordText(e);
  }

  function passwordFieldChange2(e: string) {
    setPasswordText2(e);
  }

  function tokenFieldChange(e: string) {
    setToken(e);
  }

  function showErrorMessage(text: string) {
    setErrorText(text);
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }




  async function resetPasswordSubmit() {
    await axios
      .post(`${SERVER_URL}resetPassword`, {
        email: emailText,
        password: passwordText,
        token,

      })
      .then((resp) => {



        if (resp.status === 201 && resp.data === 'expired') {
          showErrorMessage("Expired token.");
        }

        if (resp.status === 201 && resp.data === 'inncorrect') {
          showErrorMessage("Invalid or incorrect token.");
        }

        if (resp.status === 201 && resp.data === 'done') {
          // successsssssssss
          setChangeSuccess(true);
        }

        return resp.data;
      })
      .catch(() => { });
  }



  return (
    <div className="mx-auto  w-full h-full  md:bg-gray-100 md:dark:bg-slate-900">

      {!changeSuccess && (


        <form onSubmit={handleFormSubmit} className="md:pb-0 md:w-1/6 w-full mx-auto py-40 min-h-[1000px]">
          <div className="mb-5 justify-center pt-10 text-center font-semibold md:pt-0 ">
            <h1 className="text-4xl text-white dark:text-white md:text-gray-600">
              Forgot Password
            </h1>

            {showError ? (
              <h1 className="text-xl text-white dark:text-white md:text-gray-600 animate-pulse mt-5 h-10">
                {errorText}
              </h1>
            ) : (
              <h1 className="text-xl text-white dark:text-white md:text-gray-600 mt-5 animate-pulse h-10" />

            )}

          </div>

          <input
            type="email"
            className={`${validEmail
              ? 'border-white hover:border-sky-500'
              : 'border-red-500 hover:border-red-300'
              }
          mb-4 w-full rounded-2xl border p-6 text-sm outline-none transition duration-500 ease-in hover:border dark:bg-slate-200`}
            id="email"
            placeholder="Email"
            onChange={(e) => emailFieldChange(e.target.value)}
            ref={userRef}
          />
          <span
            className={`${validEmail ? 'mandatory-asterisk' : 'mandatory-required-text'
              }`}
          />


          <input
            ref={tokenRef}
            type="text"
            className={`${validToken
              ? 'border-white hover:border-sky-500'
              : 'border-red-500 hover:border-red-300'
              }
          font-bold mb-4 w-full rounded-2xl border p-6 text-sm outline-none transition duration-500 ease-in hover:border dark:bg-slate-200`}
            id="token"
            placeholder="Token"
            onChange={(e) => tokenFieldChange(e.target.value)}
          />
          <span
            className={`${validToken ? 'mandatory-asterisk' : 'mandatory-required-text'
              }`}
          />


          <input
            ref={passRef}
            type="password"
            className={`${validPassword
              ? 'border-white hover:border-sky-500'
              : 'border-red-500 hover:border-red-300'
              }
          mb-4 w-full rounded-2xl border p-6 text-sm outline-none transition duration-500 ease-in hover:border dark:bg-slate-200`}
            id="password"
            placeholder="Password"
            onChange={(e) => passwordFieldChange(e.target.value)}
          />
          <span
            className={`${validPassword ? 'mandatory-asterisk' : 'mandatory-required-text'
              }`}
          />

          <input
            ref={passRef2}
            type="password"
            className={`${validPassword2
              ? 'border-white hover:border-sky-500'
              : 'border-red-500 hover:border-red-300'
              }
          mb-4 w-full rounded-2xl border p-6 text-sm outline-none transition duration-500 ease-in hover:border dark:bg-slate-200`}
            id="password2"
            placeholder="Repeat Password"
            onChange={(e) => passwordFieldChange2(e.target.value)}
          />
          <span
            className={`${validPassword2 ? 'mandatory-asterisk' : 'mandatory-required-text'
              }`}
          />




          <div className="mt-6 flex items-center justify-center">
            <button
              type="submit"
              className="brand-color font-display focus:shadow-outline w-full rounded-2xl p-6 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
              onClick={handleFormSubmit}
            >
              Reset my password
            </button>
          </div>

        </form>
      )}

      {changeSuccess && (
        <section className="md:pb-0 md:w-1/6 w-full mx-auto py-40 min-h-[1000px]">
          <div className="mb-5 justify-center pt-10 text-center font-semibold md:pt-0 ">
            <h1 className="text-3xl text-white dark:text-white md:text-gray-600">
              Your password has been successfully changed
            </h1>

            <div className="mt-6 flex items-center justify-center">
            <button
              type="submit"
              className="mt-5 brand-color font-display focus:shadow-outline w-full rounded-2xl p-6 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
              onClick={goToLogIn}
            >
              Login
            </button>
          </div>
          </div>
        </section>

      )}

    </div>
  );
}
