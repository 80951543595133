import React from 'react';
import { BUCKET_IMAGES } from '../../common/CONFIG';

type Props = {
  item: any;
};

export function CartItem(props: Props) {
  const { item } = props;

  return (
    <section className="w-full border-t-2 border-slate-200 py-5 hover:opacity-90 dark:border-slate-400">
      <div className="flex justify-between">
        <div className="flex">
          <img
            className="h-28 w-28 rounded-xl object-cover"
            alt="cartItem"
            src={`${BUCKET_IMAGES}watermark/${item?.projectId}/${item?.watermarkUrl}`}
          />

          <div className="ml-5">
            <p className="m-0 font-bold text-gray-800 dark:text-white">
              REF# {item?.ref}
            </p>
            <p className="m-0 font-bold text-gray-800 dark:text-white">
              ${item?.photoPrice}
            </p>
          </div>
        </div>

        {/* <img
          src="./img/x.png"
          alt=""
          className="mt-0 h-4 w-4 self-center align-middle hover:cursor-pointer hover:opacity-60 dark:invert md:mt-2 md:h-5 md:w-5"
        /> */}
      </div>
    </section>
  );
}
