import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../common/CONFIG';

export default function Transactions() {
  const [allTransactions, setAllTransactions] = useState([]);
  const [search, setSearch] = useState([]);

  async function getAllTransactions() {
    const config = {
      method: 'get',
      url: `${SERVER_URL}getAllTransactions`,
      // headers: authHeader(),
    };

    const res = await axios(config);
    const response = JSON.parse(res.request.response);
    setAllTransactions(response);
    setSearch(response);
  }

  useEffect(() => {
    getAllTransactions();
  }, []);

  function searchTransactions(e: string) {
    if (e.length > 2) {
      const temp: any = [...allTransactions];
      const result = temp?.filter(
        (element: any) =>
          element.orderId?.includes(e) ||
          element.brandName?.includes(e) ||
          element.projectId?.includes(e) ||
          element.projectName?.includes(e) ||
          element.projectProduct?.includes(e) ||
          element.date?.includes(e),
      );
      setSearch(result);
    } else {
      setSearch(allTransactions);
    }
  }

  return (
    <div className="h-full bg-gray-100 text-center text-gray-500 dark:bg-slate-900 dark:text-white">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1 className="mb-5 text-center text-2xl font-semibold text-gray-600 dark:text-white">
        All Transactions
      </h1>

      <br />
      <br />
      <hr className="m-auto w-6/12 border" />
      <br />

      <div className="flex justify-center">
        <div className="mb-3 xl:w-96">
          <div className="input-group relative mb-4 flex w-full  items-stretch rounded">
            <input
              onChange={(e) => searchTransactions(e.target.value)}
              type="search"
              className="form-control relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
            <span
              className="input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal text-gray-700"
              id="basic-addon2"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="search"
                className="w-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className="relative z-[10] m-auto overflow-x-auto shadow-md sm:rounded-lg md:w-11/12">
        <table className="w-full  text-left text-gray-500 dark:text-gray-400">
          <thead className=" bg-gray-50 uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Order Id
              </th>
              <th scope="col" className="py-3 px-6">
                Total Paid
              </th>
              <th scope="col" className="py-3 px-6">
                Brand Name
              </th>
              <th scope="col" className="py-3 px-6">
                QTY
              </th>

              <th scope="col" className="py-3 px-6">
                Project Id
              </th>

              <th scope="col" className="py-3 px-6">
                Project Name
              </th>
              <th scope="col" className="py-3 px-6">
                Project Product
              </th>

              <th scope="col" className="py-3 px-6">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {search?.map((element: any, index: number) => (
              <tr
                key={element.id}
                className="border-b bg-white hover:bg-slate-50 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-800"
              >
                <td className="py-4 px-6">{element.orderId}</td>
                <td className="py-4 px-6">{element.totalPaid}</td>
                <td className="py-4 px-6">{element.brandName}</td>
                <td className="py-4 px-6">{element.qtyPurchased}</td>
                <td className="py-4 px-6">{element.projectId}</td>
                <td className="py-4 px-6">{element.projectName}</td>
                <td className="py-4 px-6">{element.projectProduct}</td>
                <td className="py-4 px-6">{element.date.split('T')[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
