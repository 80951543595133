import React, { useState, useEffect } from 'react';
import CreateUser from './CreateUser';
import Stats from './Stats';
import AllUsers from './AllUsers';
import AllProjects from './AllProjects';
import Transactions from './Transactions';
import Payments from './Payments';
import menuDrag from './menuDrag';

export default function Admin() {
  useEffect(() => {
    document.title = 'Authentic | ADMIN 😎';
  }, []);

  const [menu, setMenu] = useState('stats');
  const [openMenu, setOpenMenu] = useState(true);

  const showMenu = (e: string) => {
    setMenu(e);
  };

  useEffect(() => {
    menuDrag(document.getElementById('menu'));
  }, []);

  const handleShowMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className="flex min-h-screen w-full grid-cols-2 bg-gray-100 ">
      <section
        id="menu"
        className="fixed top-40 left-10 z-[999] mx-auto h-fit w-[21.875rem] rounded-2xl bg-white p-3 shadow hover:cursor-grab dark:bg-slate-800 md:w-72"
      >
        <div
          onClick={handleShowMenu}
          className="rounded-2xl p-3 transition duration-200 ease-in hover:cursor-pointer hover:bg-slate-50 hover:dark:bg-slate-700"
          role="button"
          tabIndex={0}
        >
          <h1 className="text-center text-2xl font-semibold text-gray-600 dark:text-white">
            Menu
          </h1>
        </div>

        {openMenu && (
          <>
            <hr className="my-5" />
            <div
              onClick={() => showMenu('stats')}
              role="button"
              tabIndex={0}
              className={`${
                menu === 'stats' ? 'text-sky-500 dark:text-sky-500' : ''
              }  p-3 text-left font-semibold tracking-wide  text-gray-600 outline-none duration-200 ease-in hover:text-sky-500 focus:outline-none  dark:hover:text-sky-500`}
            >
              General Stats
            </div>
            <div
              onClick={() => showMenu('adduser')}
              role="button"
              tabIndex={0}
              className={`${
                menu === 'adduser' ? 'text-sky-500 dark:text-sky-500' : ''
              }  p-3 text-left font-semibold tracking-wide  text-gray-600 outline-none duration-200 ease-in hover:text-sky-500 focus:outline-none  dark:hover:text-sky-500`}
            >
              Add User
            </div>
            <div
              onClick={() => showMenu('allusers')}
              role="button"
              tabIndex={0}
              className={`${
                menu === 'allusers' ? 'text-sky-500 dark:text-sky-500' : ''
              }  p-3 text-left font-semibold tracking-wide  text-gray-600 outline-none duration-200 ease-in hover:text-sky-500 focus:outline-none  dark:hover:text-sky-500`}
            >
              View Users
            </div>
            <div
              onClick={() => showMenu('allprojects')}
              role="button"
              tabIndex={0}
              className={`${
                menu === 'allprojects' ? 'text-sky-500 dark:text-sky-500' : ''
              }  p-3 text-left font-semibold tracking-wide  text-gray-600 outline-none duration-200 ease-in hover:text-sky-500 focus:outline-none  dark:hover:text-sky-500`}
            >
              View Projects
            </div>
            <div
              onClick={() => showMenu('transactions')}
              role="button"
              tabIndex={0}
              className={`${
                menu === 'transactions' ? 'text-sky-500 dark:text-sky-500' : ''
              }  p-3 text-left font-semibold tracking-wide  text-gray-600 outline-none duration-200 ease-in hover:text-sky-500 focus:outline-none  dark:hover:text-sky-500`}
            >
              View Transactions
            </div>
            <div
              onClick={() => showMenu('payments')}
              role="button"
              tabIndex={0}
              className={`${
                menu === 'payments' ? 'text-sky-500 dark:text-sky-500' : ''
              }  p-3 text-left font-semibold tracking-wide  text-gray-600 outline-none duration-200 ease-in hover:text-sky-500 focus:outline-none  dark:hover:text-sky-500`}
            >
              View Payments
            </div>
            <br />
            <br />
            <br />
            <br />
            {/* <div
              onClick={() => showMenu('discountCoupons')}
              role="button"
              tabIndex={0}
              className={`${
                menu === 'discountCoupons'
                  ? 'text-sky-500 dark:text-sky-500'
                  : ''
              }  p-3 text-left font-semibold tracking-wide  text-gray-600 outline-none duration-200 ease-in hover:text-sky-500 focus:outline-none  dark:hover:text-sky-500`}
            >
              Discount Coupons
            </div> */}
          </>
        )}
      </section>
      <section className="w-full">
        {menu === 'stats' && <Stats />}
        {menu === 'adduser' && <CreateUser />}
        {menu === 'allusers' && <AllUsers />}
        {menu === 'allprojects' && <AllProjects />}
        {menu === 'transactions' && <Transactions />}
        {menu === 'payments' && <Payments />}
      </section>
    </div>
  );
}
