import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { SERVER_URL } from '../../common/CONFIG';
import { testDataUploadedPhotos } from '../../common/services/mocks/dataUploadedPhotos';
import { SupportingDocumentsUploadCard } from '../supporting-documents-upload-card/SupportingDocumentsUploadCard';

type Props = {
  projectId: any;
  setStatusChangedByUpload: any;
  photosAlreadyUploaded: any;
  setPhotosAlreadyUploaded: any;
};

export function SupportingDocumentsUpload(props: Props) {
  const {
    projectId,
    setStatusChangedByUpload,
    photosAlreadyUploaded,
    setPhotosAlreadyUploaded,
  } = props;

  const [OpenDropdown, setOpenDropdown] = useState(false);
  const [search, setSearch] = useState('All');
  const [imagesFromCreator, setImagesFromCreator] = useState<any>(
    photosAlreadyUploaded,
  );
  const [imagesAvailable, setImagesAvailable] = useState(0);
  const { user: currentUser } = useSelector((state: any) => state.auth);
  useEffect(() => {
    setOpenDropdown(false);
  }, [search]);

  useEffect(() => {
    setImagesFromCreator(photosAlreadyUploaded);
  }, [photosAlreadyUploaded]);

  useEffect(() => {
    setImagesAvailable(
      imagesFromCreator?.filter((photo: any) => !photo.deleted).length,
    );
  }, [imagesFromCreator]);

  const fileUpload = useRef<HTMLInputElement>(null);

  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  const [creatorPortfolioPhotos, setCreatorPortfolioPhotos] = useState<any>([
    '',
  ]);
  const [portfolioPhotosSizes, setPortfolioPhotosSizes] = useState<any>('');

  const [isUploading, setIsUploading] = useState<boolean>(false);

  async function uploadNewPhoto() {
    setIsUploading(true);

    const uploadPortfolioPhotos = new FormData();

    Object.values(creatorPortfolioPhotos).forEach((file: any) => {
      uploadPortfolioPhotos.append('multipleUploadImages', file);
    });

    uploadPortfolioPhotos.append(
      'uploadPhotosSizes',
      JSON.stringify(portfolioPhotosSizes),
    );
    uploadPortfolioPhotos.append('type', 'creator');
    uploadPortfolioPhotos.append('projectId', projectId);
    uploadPortfolioPhotos.append('userId', currentUser?.id);

    try {
      const res = await axios.post(
        `${SERVER_URL}upload-photo-buy/${currentUser?.id}`,
        uploadPortfolioPhotos,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );
      setStatusChangedByUpload('UPLOAD_PHOTOS');
      setPhotosAlreadyUploaded(res?.data);
      setIsUploading(false);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      //  console.log(err);
    }
  }

  const deletePhotoBuy = useCallback(async (photoId: number) => {
    const route = `${SERVER_URL}deletePhotoBuy`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photoId,
      })
      .then((res: any) => {
        if (res.status === 201) {
          //
        }
        return res.data;
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    uploadNewPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioPhotosSizes]);
  useEffect(() => {
    preparePhotosDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorPortfolioPhotos]);

  function preparePhotosDimensions() {
    if (creatorPortfolioPhotos) {
      const sizesTemp: any = [];

      setPortfolioPhotosSizes(sizesTemp);
    }
  }

  const handleMultipleImages = (evnt: any) => {
    const targetFiles = evnt.target.files;
    if (!targetFiles) return;
    if (targetFiles?.length !== 0) {
      const temp = [...imagesFromCreator];
      temp.push(...targetFiles);
      setImagesFromCreator(temp);
      setCreatorPortfolioPhotos(targetFiles);
    }
  };

  return (
    <div>
      <ReactTooltip
        id="ReminderChecklist"
        type="info"
        effect="solid"
        className="tooltip"
        place="left"
        multiline
        arrowColor="#54a3f5"
      >
        <p className="font-bold">Helpful Hints</p>

        <li>Upload 40+ images so brands have more to choose from</li>
        <li>Limit similar versions of the same image</li>
        <li>Create variety by changing up the angle, locations, and people</li>
        <li>Be sure to have talent permission</li>
      </ReactTooltip>
      <section className="m-auto w-10/12 ">
        <hr className="my-5 mb-20 border-[1px] font-bold" />

        <div className="mb-5 flex place-content-end content-end text-gray-500 hover:text-sky-600 dark:text-white dark:hover:text-sky-600">
          <p
            data-tip
            data-for="ReminderChecklist"
            className="hover:cursor-pointer"
          >
            Helpful Reminders{' '}
            <FontAwesomeIcon className="" icon={faQuestionCircle} />
          </p>
        </div>

        {isUploading ? (
          <div className="mt-2 flex h-80 rounded-xl border-[0.125rem] border-sky-300 bg-white transition-all duration-300 hover:cursor-pointer hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500">
            <div className="m-auto h-[300px] place-items-center content-center items-center justify-center justify-items-center place-self-center self-center justify-self-center py-28 px-[15%] text-center">
              <svg
                className="-ml-1 mr-3 h-20 w-20 animate-spin text-sky-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div
            onClick={clickFileUpload}
            role="button"
            tabIndex={0}
            className="mt-2 flex h-80 rounded-xl border-[0.125rem] border-sky-300 bg-white transition-all duration-300 hover:cursor-pointer hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500"
          >
            <div className="m-auto content-center justify-center p-10 text-center align-middle">
              <img
                src="./img/upload.png"
                alt=""
                className="inline h-12 w-12 self-center"
              />
              <h1 className="mt-5 text-2xl font-semibold text-gray-600 dark:text-white">
                Upload Project Photos
              </h1>
            </div>
          </div>
        )}
      </section>

      <input
        onChange={handleMultipleImages}
        type="file"
        ref={fileUpload}
        accept="image/*"
        multiple
        className="hidden"
      />

      <section className="m-auto mt-24 grid w-10/12 items-center justify-between align-middle md:flex">
        <div className="flex items-center align-middle">
          <img alt="icon" src="./img/photo.png" className="h-5 w-5" />
          <h1 className="ml-3 mr-8 text-lg font-semibold text-gray-600 dark:text-white">
            {imagesAvailable} Photos Uploaded
          </h1>
        </div>

        <div className="mt-5 md:absolute md:right-[8.33vw] md:mt-0">
          <div
            role="button"
            tabIndex={0}
            onClick={() => setOpenDropdown(!OpenDropdown)}
            onKeyDown={() => setOpenDropdown(!OpenDropdown)}
            className="m-auto  inline-flex w-[21.875rem] items-center justify-between rounded-xl bg-white px-8 py-5 text-center text-sm text-gray-500 duration-200 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 md:w-[10.625rem]"
          >
            {search}{' '}
            <svg
              className="ml-2 h-8 w-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          {OpenDropdown && (
            // This could be made with a "const" for the options. Then mapping it, and a onClick function to setSearch.
            // BUT the custom style was easier to make this way :
            <div
              onMouseLeave={() => setOpenDropdown(!OpenDropdown)}
              className="absolute z-30  mt-2 w-[21.875rem] list-none divide-y divide-gray-100 rounded-xl bg-white text-base shadow dark:bg-slate-800 md:w-[10.625rem]"
            >
              <span
                onClick={() => setSearch('All')}
                key="All"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in hover:cursor-pointer hover:rounded-t-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                All
              </span>

              <span
                onClick={() => setSearch('Purchased')}
                key="Purchased"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in hover:cursor-pointer hover:rounded-b-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                Purchased
              </span>
            </div>
          )}
        </div>
      </section>

      <SupportingDocumentsUploadCard
        projectId={projectId}
        data={imagesFromCreator}
        setImagesFromCreator={setImagesFromCreator}
      />
    </div>
  );
}
