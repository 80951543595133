import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
// import { ErrorBoundary } from 'common/framework';
import { Navbar, Footer, MessagesBar } from 'components';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import Admin from './pages/admin/Admin';
import Auth from './pages/auth/Auth';
import Login from './pages/brand/login/Login';
import Dashboard from './pages/brand/dashboard/Dashboard';
import Purchasedphotos from './pages/brand/purchasedphotos/Purchasedphotos';
import Photographers from './pages/brand/photographers/Photographers';
import Project from './pages/brand/project/Project';
import ShareSelects from './pages/brand/share/ShareSelects';
import Brief from './pages/brand/brief/Brief';
import ProjectDetails from './pages/creator/projectDetail/ProjectDetails';
import Profile from './pages/creator/profile/Profile';
import BrandProfile from './pages/brand/profile/BrandProfile';
import ProjectCreator from './pages/creator/project/ProjectCreator';
import ProfileOnboarding from './pages/creator/profileOnboarding/ProfileOnboarding';
import ProfileOnboardingBrand from './pages/brand/profileOnboarding/ProfileOnboardingBrand';
import FavoritePhotographers from './pages/brand/favoritePhotographers/FavoritePhotographers';
import Checkout from './pages/brand/checkout/Checkout';
import NotFound from './pages/404/NotFound';
import ForgotPassword from './pages/user/ForgotPassword';
import Welcome from './pages/user/Welcome';


import { myContext } from './components/login-detect/LoginDetect';
import { MessagesBarCreator } from './components/messages-bar-creator/MessagesBarCreator';
import { logout } from './actions/auth';
import { clearMessage } from './actions/message';
import { history } from './helpers/history';
// import AuthVerify from "./common/AuthVerify";
import EventBus from './common/EventBus';
import { SERVER_URL } from './common/CONFIG';

function App() {
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [isBrand, setIsBrand] = useState(false);

  const [isCheckingRole, setIsCheckingRole] = useState(true);
  const [isCreator, setIsCreator] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authState, setAuthState] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const [showChat, setShowChat] = useState(false);
  const [openChatShortcut, setOpenChatShortcut] = useState(false);

  const [invitations, setInvitations] = useState([]);
  const [currentInvitation, setCurrentInvitation] = useState([]);

  const { user: currentUser } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  const [numberMessages, setNumberMessages] = useState('0');

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout());
    window.location.replace('/');
  }, [dispatch]);

  const { authenticated, setAuthenticated } = useContext(myContext);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.roles.includes('ROLE_ADMIN')) {
        setIsLoggedIn(true);
        setShowAdminBoard(true);
      }

      if (currentUser.roles.includes('ROLE_BRAND')) {
        setIsLoggedIn(true);
        setIsBrand(true);
      }

      if (currentUser.roles.includes('ROLE_CREATOR')) {
        setIsLoggedIn(true);
        setIsCreator(true);
      }
    }

    setIsCheckingRole(false);

    EventBus.on('logout', () => {
      logOut();
    });
  }, [currentUser, logOut]);

  useEffect(() => {
    if (isCreator) {
      getInvitations();
    }
  }, [isBrand, isCreator]);

  useEffect(() => {
    if (currentUser?.username) {
      setShowChat(true);
    } else {
      setShowChat(false);
    }
  }, [currentUser?.username]);

  useEffect(() => {
    setCurrentInvitation([]);
    if (currentUser?.roles?.includes('ROLE_CREATOR') && invitations) {
      invitations?.forEach((element: any) => {
        if (element.status === 'ASKING') {
          setCurrentInvitation(element);
        }
      });

      if (currentInvitation && window.location.pathname !== '/') {
        setShowNotification(true);
      } else {
        setShowNotification(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations, currentUser]);

  async function getInvitations() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getInvitations/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      setInvitations(res.data);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      //  console.log(err);
    }
  }

  const authInfoTest = useMemo(
    () => ({ authenticated: authState, setAuthenticated: setAuthState }),
    [authState],
  );

  return (
    <React.StrictMode>
      <Router>
        <myContext.Provider value={authInfoTest}>
          <Navbar logOut={logOut} />
          {currentUser && showNotification && (
            <MessagesBarCreator
              invitations={currentInvitation}
              numberMessages={numberMessages}
              print
            />
          )}

          {currentUser && (
            <MessagesBar
              numberMessages={numberMessages}
              setNumberMessages={setNumberMessages}
              setOpenChatShortcut={setOpenChatShortcut}
              openChatShortcut={openChatShortcut}
            />
          )}

          <Routes>
            {currentUser && (
              <>
                {currentUser?.roles.includes('ROLE_CREATOR') && (
                  <>
                    <Route
                      path="/projectdetails"
                      element={<ProjectDetails />}
                    />
                    <Route
                      path="/profileonboarding"
                      element={<ProfileOnboarding />}
                    />
                    <Route path="/profile" element={<Profile />} />
                    <Route
                      path="/projectCreator"
                      element={
                        <ProjectCreator setInvitations={setInvitations} />
                      }
                    />
                  </>
                )}

                {currentUser?.roles.includes('ROLE_BRAND') && (
                  <>
                    <Route path="/brief" element={<Brief />} />
                    <Route path="/photographer" element={<Photographers />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="/purchasedphotos"
                      element={<Purchasedphotos />}
                    />
                    <Route path="/brand-profile" element={<BrandProfile />} />
                    <Route
                      path="/project"
                      element={
                        <Project
                          setOpenChatShortcut={setOpenChatShortcut}
                          openChatShortcut={openChatShortcut}
                        />
                      }
                    />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route
                      path="/ProfileOnboardingBrand"
                      element={<ProfileOnboardingBrand />}
                    />
                    <Route
                      path="/FavoritePhotographers"
                      element={<FavoritePhotographers />}
                    />
                  </>
                )}
              </>
            )}
            <Route path="/share" element={<ShareSelects />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/" element={<Login />} />
            {showAdminBoard && <Route path="/admin" element={<Admin />} />}

            {!isCheckingRole && <Route path="*" element={<Login />} />}
          </Routes>
          <Footer />
        </myContext.Provider>
      </Router>
    </React.StrictMode>
  );
}

export default App;
