/* eslint-disable no-console */
import {
  BriefDetails,
  ProductDetailsAndInformation,
  AdditionalRequirements,
  FavoritePhotographers,
  InspirationPhotos,
  ModalConfirm,
} from 'components';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../../../common/CONFIG';

export default function Dashboard() {
  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Brief';
  }, []);

  const [briefDetails, setBriefDetails] = useState<any>([]);

  // ######################## FOR EDIT MODE ########################

  const query = new URLSearchParams(useLocation().search);
  const id = query.get('edit');

  const [editMode, setEditMode] = useState<any>(false);
  const [isDuplicate, setIsDuplicate] = useState<any>(false);
  const [showDuplicatedMsg, setShowDuplicatedMsg] = useState<any>(false);

  const [project, setProject] = useState<any>([]);
  const [projectId, setProjectId] = useState<any>();

  useEffect(() => {
    if (id !== null) {
      setEditMode(true);
      setProjectId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async () => {
      if (editMode) {
        await getAllProjectInformation();
      } else {
        getBrandsInspirations();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (editMode) {
      setProductImages(project?.product);
      setInspirations(project?.inspirations);
      setProjectDocs(project?.documents);

      const value = [
        {
          id: 1,
          value: 'Wide',
          checked: project?.imageCropWide,
          class: 'wide',
        },
        {
          id: 2,
          value: 'Med',
          checked: project?.imageCropMed,
          class: 'med',
        },
        {
          id: 3,
          value: 'Tight',
          checked: project?.imageCropTight,
          class: 'tight',
        },
      ];

      const editInfo = {
        productName: project?.project?.productName,
        projectImage: project?.project?.projectImage,
        dueDate: project?.project?.dueDate,
        productDescription: project?.project?.productDescription,
        name: project?.project?.name,
        description: project?.project?.description,
        animals: project?.project?.animals,
        gear: project?.project?.gear,
        wardrobe: project?.project?.wardrobe,
        enviroments: project?.project?.enviroments,
        professionalModel: project?.project?.professionalModel,
        videography: project?.project?.videography,
        imageCrop: JSON.stringify(value),
      };

      setBriefDetails(editInfo);

      if (project.project?.status === 'DUPLICATE') {
        setIsDuplicate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (isDuplicate) {
      setShowDuplicatedMsg(true);

      setTimeout(() => {
        setShowDuplicatedMsg(false);
      }, 4000);
    }
  }, [isDuplicate]);

  async function getAllProjectInformation() {
    return axios
      .post(`${SERVER_URL}getAllProjectInformation/${projectId}`, {
        user: currentUser,
      })
      .then((resp) => {
        if (resp.data === 'denied') {
          navigate('/404');
        }

        if (resp.status === 201) {
          setProject(resp.data); // VER
        }
        return resp.data;
      })
      .catch(() => { });
  }

  // ######################## END EDIT MODE ########################

  const { user: currentUser } = useSelector((state: any) => state.auth);

  const [inspirations, setInspirations] = useState<any>([]);
  const [productImages, setProductImages] = useState<any[]>([]);
  const [projectDocs, setProjectDocs] = useState<any>([]);
  const [customInspirations, setCustomInspirations] = useState<any>([]);
  const [forRefresh, setForRefresh] = useState<any>();
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<any>(false);
  const [flag, setFlag] = useState<any>(false);
  const [submit, setSubmit] = useState<any>(false);

  useEffect(() => {
    if (submit) {
      const resultOfValidation = validateFields();

      if (!resultOfValidation) {
        if (submit && !editMode && !isDuplicate) {
          handleSubmitBrief();

          // setTimeout(() => {
          //   navigate('/dashboard');
          // }, 6000);
        }

        if (submit && editMode && isDuplicate) {
          handleSubmitBriefEdit(projectId);

          // setTimeout(() => {
          //   navigate('/dashboard');
          // }, 6000);
        }

        if (submit && editMode && !isDuplicate) {
          handleSubmitBriefEdit(projectId);
        }
      }
      setSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  async function handleSubmitBrief() {
    const date = Date.now();
    const decimalStr = date.toString() + Math.ceil(Math.random() * 99);
    const randomProjectId: any = Number(decimalStr);

    const projectInformation = new FormData();
    const temp = [...inspirations];
    const inpirationsUploaded: any[] = [];
    const inpirationsfromBrand: any[] = [];

    temp.forEach((element) => {
      if (!element.fromBrand) {
        inpirationsUploaded.push(element);
      }
      if (element.fromBrand) {
        inpirationsfromBrand.push(element);
      }
    });

    Object.values(inpirationsUploaded).forEach((image) => {
      projectInformation.append('uploadPhotosInspiration', image.photo);
    });
    projectInformation.append('briefDetails', JSON.stringify(briefDetails));

    projectInformation.append(
      'inspirations',
      JSON.stringify(inpirationsUploaded),
    );
    projectInformation.append(
      'inspirationsFromBrand',
      JSON.stringify(inpirationsfromBrand),
    );
    projectInformation.append('randomProjectId', randomProjectId);
    const route = `${SERVER_URL}createProject/${currentUser?.id}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, projectInformation, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          submitProductImages(randomProjectId);
        }
        return res.data;
      })
      .catch(() => { });
  }

  async function handleSubmitBriefEdit(OLD_PROJECT_ID: any) {
    const randomProjectId: any = OLD_PROJECT_ID;

    const projectInformationEdit = new FormData();
    const temp = [...inspirations];
    const inpirationsUploaded: any[] = [];
    const inpirationsfromBrand: any[] = [];

    temp.forEach((element) => {
      if (!element.fromBrand) {
        inpirationsUploaded.push(element);
      }
      if (element.fromBrand) {
        inpirationsfromBrand.push(element);
      }
    });

    Object.values(inpirationsUploaded).forEach((image) => {
      projectInformationEdit.append('uploadPhotosInspiration', image.photo);
    });
    projectInformationEdit.append('briefDetails', JSON.stringify(briefDetails));
    projectInformationEdit.append(
      'inspirations',
      JSON.stringify(inpirationsUploaded),
    );
    projectInformationEdit.append(
      'inpirationsfromBrand',
      JSON.stringify(inpirationsfromBrand),
    );
    projectInformationEdit.append('randomProjectId', randomProjectId);
    projectInformationEdit.append('isDuplicate', isDuplicate);

    // const route = `${SERVER_URL}editProject/`;
    const route = `${SERVER_URL}editProject/${currentUser?.id}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, projectInformationEdit, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          // eslint-disable-next-line no-console
          // console.log('submited !');
          submitProductImagesEdit(randomProjectId);
        }
        return res.data;
      })
      .catch(() => { });
  }

  async function submitProductImages(randomProjectId: any) {
    const projectInformation = new FormData();
    const temp = [...productImages];
    Object.values(temp).forEach((image) => {
      projectInformation.append('uploadPhotosInspiration', image);
    });
    projectInformation.append('randomProjectId', randomProjectId);
    const route = `${SERVER_URL}uploadProjectProductImages/${currentUser?.id}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, projectInformation, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          uploadMainImage(randomProjectId);
        }
        return res.data;
      })
      .catch(() => { });
  }

  async function submitProductImagesEdit(randomProjectId: any) {
    const projectInformation = new FormData();
    const temp = [...productImages];
    const productImagesAny: any = [...productImages];
    Object.values(temp).forEach((image) => {
      projectInformation.append('uploadPhotosInspiration', image);
    });
    projectInformation.append('randomProjectId', randomProjectId);
    projectInformation.append(
      'productImagesInfo',
      JSON.stringify(productImagesAny),
    );

    const route = `${SERVER_URL}uploadProjectProductImagesEdit/${currentUser?.id}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, projectInformation, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          // eslint-disable-next-line no-console
          // console.log('submited submitProductImagesEdit!');
          uploadMainImagEdit(randomProjectId);
        }
        return res.data;
      })
      .catch(() => { });
  }

  const navigate = useNavigate();

  async function submitSupportingDocs(randomProjectId: any) {
    const supportingDocs = new FormData();
    const temp = [...projectDocs];
    Object.values(temp).forEach((file) => {
      supportingDocs.append('multipleUploadFiles', file);
    });
    supportingDocs.append('randomProjectId', randomProjectId);
    const route = `${SERVER_URL}uploadSupportingProjectDoc/${currentUser?.id}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, supportingDocs, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          navigate('/dashboard');
        }
        return res.data;
      })
      .catch(() => { });
  }

  async function submitSupportingDocsEdit(randomProjectId: any) {
    const supportingDocs = new FormData();
    const temp = [...projectDocs];
    const projectDocsAny: any = [...projectDocs];
    Object.values(temp).forEach((file) => {
      supportingDocs.append('multipleUploadFiles', file);
    });
    supportingDocs.append('randomProjectId', randomProjectId);
    supportingDocs.append('supportingDocsInfo', JSON.stringify(projectDocsAny));

    if (isDuplicate) {
      supportingDocs.append('isDuplicate', isDuplicate);
    }

    const route = `${SERVER_URL}uploadSupportingProjectDocEdit/${currentUser?.id}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, supportingDocs, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          navigate('/dashboard');
        }
        return res.data;
      })
      .catch(() => { });
  }

  useEffect(() => {
    const temp = [...inspirations];
    const customInspirationsTemp: any[] = [];

    if (!forRefresh) {
      return;
    }

    getBrandsInspirations();

    temp.forEach((element) => {
      if (!element.fromBrand) {
        customInspirationsTemp.push(element);
      }
    });

    setCustomInspirations(customInspirationsTemp);
    setFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forRefresh]);

  useEffect(() => {
    checkIfThisBriefHasCustomInspirations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspirations]);

  function checkIfThisBriefHasCustomInspirations() {
    const temp: any[] = [];

    if (flag) {
      if (customInspirations.length > 0) {
        inspirations.forEach((element: any) => {
          temp.push({
            ...element,
            photoUrl: element.photo,
          });
        });

        customInspirations.forEach((element: any) => {
          temp.push(element);
        });

        setInspirations(temp);
      }
    }
    setFlag(false);
  }

  async function getBrandsInspirations() {
    let res;
    const username = currentUser?.username;
    const config = {
      method: 'get',
      url: `${SERVER_URL}getBrandsInspirationPhotosForBrief/${username}`,
      // headers: authHeader(),
    };

    try {
      res = await axios(config);
      if (res.status === 201) {
        const filteredInspirations = res.data.map((inspiration: any) => {
          const existingInspiration = inspirations.find(
            (insp: any) => insp.photo === inspiration.photo,
          );
          if (existingInspiration) {
            return {
              ...existingInspiration,
            };
          }
          return inspiration;
        });
        if (filteredInspirations.length) {
          setInspirations(filteredInspirations);
        }
      }
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  const [projectImage, setProjectImage] = useState<any | null>();

  const onChangeProjectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setProjectImage(fileList[0]);
  };

  async function uploadMainImage(randomProjectId: string) {
    const formDataImage = new FormData();
    formDataImage.append('uploadImages', projectImage);
    formDataImage.append('randomProjectId', randomProjectId);

    const route = `${SERVER_URL}uploadProjectMainPicture/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, formDataImage, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          // eslint-disable-next-line no-console
          // console.log('submited submitProductImagesEdit!');
          submitSupportingDocs(randomProjectId);
        }
        return res.data;
      })
      .catch(() => { });
  }

  async function uploadMainImagEdit(randomProjectId: string) {
    if (projectImage) {
      const formDataImage = new FormData();
      formDataImage.append('uploadImages', projectImage);
      formDataImage.append('randomProjectId', randomProjectId);

      const route = `${SERVER_URL}uploadProjectMainPicture/`;
      // eslint-disable-next-line promise/no-nesting
      await axios
        .post(route, formDataImage, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.status === 201) {
            // eslint-disable-next-line no-console
            // console.log('submited submitProductImagesEdit!');
            submitSupportingDocsEdit(randomProjectId);
          }
          return res.data;
        })
        .catch(() => { });
    } else {
      submitSupportingDocsEdit(randomProjectId);
    }
  }

  const inputProjectName: any = useRef();
  const inputProjectDescription: any = useRef();
  const dueDateInput: any = useRef();
  const imageCropRef: any = useRef();
  const noProjectImageNoEditMode: any = useRef();

  const productName: any = useRef();
  const productDesc: any = useRef();
  const productImagesRef: any = useRef();
  const creatorsRef: any = useRef();

  function validateFields() {
    let wrongValidation = false;
    const today = new Date();
    const projectName = inputProjectName.current;
    const projectDescription = inputProjectDescription.current;
    const projectDueDate = dueDateInput.current;
    const imageCrop = imageCropRef.current;
    const noImageNoEdit = noProjectImageNoEditMode.current;

    const productNameRef = productName.current;
    const productDescRef = productDesc.current;

    // project name check
    if (projectName.value.length === 0) {
      projectName?.classList.add('border-red-500', 'animate-pulse', 'border');
      wrongValidation = true;
    } else {
      projectName?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    // descript check
    if (projectDescription.value.length === 0) {
      projectDescription?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
      wrongValidation = true;
    } else {
      projectDescription?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    // due date check
    if (today >= briefDetails?.dueDate || briefDetails?.dueDate === undefined) {
      projectDueDate?.classList.add('border-red-500', 'border', 'rounded-xl');
      wrongValidation = true;
    } else {
      projectDueDate?.classList.remove(
        'border-red-500',
        'border',
        'rounded-xl',
      );
    }

    // image crop check
    if (briefDetails?.imageCrop) {
      const checkStateImageCrop = JSON.parse(briefDetails?.imageCrop);
      if (
        !checkStateImageCrop[0].checked &&
        !checkStateImageCrop[1].checked &&
        !checkStateImageCrop[2].checked &&
        !editMode
      ) {
        imageCrop?.classList.add(
          'border-red-500',
          'border',
          'rounded-xl',
          'p-5',
          'bg-red-100',
        );
        wrongValidation = true;
      } else {
        imageCrop?.classList.remove(
          'border-red-500',
          'border',
          'rounded-xl',
          'p-5',
          'bg-red-100',
        );
      }

      if (editMode) {
        if (checkStateImageCrop.every((check: any) => !check.checked)) {
          imageCrop?.classList.add(
            'border-red-500',
            'border',
            'rounded-xl',
            'p-5',
            'bg-red-100',
          );
          wrongValidation = true;
        } else {
          imageCrop?.classList.remove(
            'border-red-500',
            'border',
            'rounded-xl',
            'p-5',
            'bg-red-100',
          );
        }
      }
    } else {
      imageCrop?.classList.add(
        'border-red-500',
        'border',
        'rounded-xl',
        'p-5',
        'bg-red-100',
      );
      wrongValidation = true;
    }

    if (!editMode) {
      // descript check
      if (!projectImage) {
        noImageNoEdit?.classList.add(
          'border-red-500',
          'animate-pulse',
          'border',
        );
        wrongValidation = true;
      } else {
        noImageNoEdit?.classList.remove(
          'border-red-500',
          'animate-pulse',
          'border',
        );
      }
    }

    // product name check
    if (productNameRef.value.length === 0) {
      productNameRef?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
      wrongValidation = true;
    } else {
      productNameRef?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    // product descr check
    if (productDescRef.value.length === 0) {
      productDescRef?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
      );
      wrongValidation = true;
    } else {
      productDescRef?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
      );
    }

    // product image check
    if (productImages?.length === 0 && productImagesRef) {
      productImagesRef?.current?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
        'bg-red-100',
      );
      wrongValidation = true;
    } else {
      productImagesRef?.current?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
        'bg-red-100',
      );
    }

    // check if at least 1 creator was added
    let atLeastOneCreatorWasAdded = false;

    const checkFavCreatorsAdded = briefDetails?.favoritePhotographers;

    checkFavCreatorsAdded?.forEach((element: { added: any }) => {
      if (element?.added) {
        atLeastOneCreatorWasAdded = true;
      }
    });

    if (!atLeastOneCreatorWasAdded) {
      creatorsRef?.current?.classList.add(
        'border-red-500',
        'animate-pulse',
        'border',
        'bg-red-100',
        'py-10',
        'rounded-xl',
        'mt-3',
      );
      wrongValidation = true;
    } else {
      creatorsRef?.current?.classList.remove(
        'border-red-500',
        'animate-pulse',
        'border',
        'bg-red-100',
        'py-10',
        'rounded-xl',
        'mt-3',
      );
    }

    if (wrongValidation && atLeastOneCreatorWasAdded) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    if (wrongValidation && !atLeastOneCreatorWasAdded) {
      window.scrollTo({
        top: 2000,
        behavior: 'smooth',
      });
    }

    return wrongValidation;
  }

  function checkValidHandle() {
    const resultOfValidation = validateFields();

    if (!resultOfValidation) {
      setIsOpenConfirmation(!isOpenConfirmation);
    }
  }

  return (
    <section className="h-full min-w-[25.625rem] bg-gray-100 dark:bg-slate-900 md:max-w-full">
      {showDuplicatedMsg && (
        <section className="fixed top-[15%] left-[44%] z-[100] h-10 w-[222px] animate-pulse  rounded-3xl bg-gray-200 p-[9px] text-center text-slate-700 shadow dark:bg-slate-700 dark:text-white">
          <p>Project duplicated.</p>
        </section>
      )}
      <ModalConfirm
        submit={submit}
        setSubmit={setSubmit}
        setIsOpen={setIsOpenConfirmation}
        isOpen={isOpenConfirmation}
        data={briefDetails?.favoritePhotographers}
      />
      <BriefDetails
        noProjectImageNoEditMode={noProjectImageNoEditMode}
        imageCropRef={imageCropRef}
        dueDateInput={dueDateInput}
        inputProjectName={inputProjectName}
        inputProjectDescription={inputProjectDescription}
        briefDetails={briefDetails}
        setBriefDetails={setBriefDetails}
        editMode={editMode}
        project={project}
        onChangeProjectImage={onChangeProjectImage}
        projectImage={projectImage}
      />
      <ProductDetailsAndInformation
        productName={productName}
        productDesc={productDesc}
        productImagesRef={productImagesRef}
        briefDetails={briefDetails}
        setBriefDetails={setBriefDetails}
        setProductImages={setProductImages}
        productImages={productImages}
        editMode={editMode}
      />
      <AdditionalRequirements
        briefDetails={briefDetails}
        setBriefDetails={setBriefDetails}
        projectDocs={projectDocs}
        setProjectDocs={setProjectDocs}
        editMode={editMode}
        project={project}
      />
      <FavoritePhotographers
        creatorsRef={creatorsRef}
        briefDetails={briefDetails}
        setBriefDetails={setBriefDetails}
        setForRefresh={setForRefresh}
        editMode={editMode}
        project={project}
        isDuplicate={isDuplicate}
      />
      <InspirationPhotos
        setInspirations={setInspirations}
        inspirations={inspirations}
        editMode={editMode}
      />

      <section className="mx-auto my-20 w-10/12 bg-gray-100 text-right align-middle dark:bg-slate-900">
        <div className="float-right flex">
          {/* <Link to="/dashboard "> */}

          {editMode && !isDuplicate && (
            <div
              role="button"
              tabIndex={0}
              onClick={() => setSubmit(true)}
              className="mt-7 rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-1 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
            >
              Save Changes
            </div>
          )}
          {!editMode && !isDuplicate && (
            <div
              role="button"
              tabIndex={0}
              onClick={() => checkValidHandle()}
              className="mt-7 rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-1 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
            >
              Submit Brief
            </div>
          )}

          {editMode && isDuplicate && (
            <div
              role="button"
              tabIndex={0}
              onClick={() => checkValidHandle()}
              className="mt-7 rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-1 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
            >
              Submit Brief
            </div>
          )}

          {/* </Link> */}
          {/* <div
            role="button"
            tabIndex={0}
            className="ml-3 mt-7 rounded-3xl border-2 border-gray-300 px-5 py-1 text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400"
          >
            Save for Later
          </div> */}
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </section>
  );
}
