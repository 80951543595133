import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CartDropdownItem } from 'components/cart-dropdown-item';
import './style.scss';

type CheckOpenCart = {
  setCartOpen: (open: boolean) => void;
};

export function CartDropdown(props: CheckOpenCart) {
  const { setCartOpen } = props;
  const navigate = useNavigate();

  function handleCheckOut() {
    setCartOpen(false);
    navigate('/checkout');
  }

  return (
    <section className="absolute right-0 top-[-2px] h-[25vh] w-full rounded-b-xl bg-white p-5 shadow dark:bg-slate-800 md:w-2/12">
      {/* <div
        className="scrollbar mt-28 grid h-[75%] gap-y-5 overflow-y-auto"
        id="style-checkoutbar"
      >
        <CartDropdownItem />
        <CartDropdownItem />
        <CartDropdownItem />
        <CartDropdownItem />
        <CartDropdownItem />
        <CartDropdownItem />
        <CartDropdownItem />
      </div> */}
      <div className=" absolute bottom-0 w-[80%]">
        <div className="mt-5 flex justify-between">
          <p className="text-xl font-extrabold text-gray-700 dark:text-white">
            Total:
          </p>
          <p className="text-xl font-extrabold text-gray-700 dark:text-white">
            $1000.00
          </p>
        </div>

        <div
          onClick={handleCheckOut}
          role="button"
          tabIndex={0}
          className="brand-color my-5 block w-full rounded-full px-8 py-2 text-center text-white duration-200 ease-in hover:cursor-pointer hover:bg-sky-300"
        >
          Checkout
        </div>
      </div>
    </section>
  );
}
