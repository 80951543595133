import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ONBOARDING_FINISHED,
} from '../../../actions/types';

let user: any = localStorage.getItem('userAuthenticApp') || '';

if (user.length > 0) {
  user = JSON.parse(user);
}

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

// eslint-disable-next-line import/no-anonymous-default-export, @typescript-eslint/default-param-last, func-names
export default function (state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case ONBOARDING_FINISHED:
      return {
        ...state,
        user: {
          ...state.user,
          onboardFinish: true,
        },
      };
    default:
      return state;
  }
}
