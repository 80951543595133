export const age = [
  {
    concept: 'Child',
    selected: false,
  },
  {
    concept: 'Teen',
    selected: false,
  }, 
  {
    concept: 'Adult',
    selected: false,
  },
  {
    concept: 'Senior',
    selected: false,
  },
  {
    concept: 'N/A',
    selected: false,
  },
];

export const gender = [
  {
    concept: 'Male',
    selected: false,
  },
  {
    concept: 'Female',
    selected: false,
  },
  // {
  //   concept: "Non Binary",
  //   selected: false,
  // },
  // {
  //   concept: "Trains",
  //   selected: false,
  // },
  {
    concept: 'Other',
    selected: false,
  },
];

export const type = [
  {
    concept: 'Person',
    selected: false,
  },
  {
    concept: 'Animal',
    selected: false,
  },
];

export const modelActivities = [
  {
    concept: 'Winter',
    selected: false,
    tooltip: "skiing, snowboarding, sledding, snow shoeing..."
  },
  {
    concept: 'Coastal',
    selected: false,
    tooltip: "swimming, surfing, tide pools, sandcastles..."
  },
  {
    concept: 'Camping',
    selected: false,
    tooltip: "backcountry and car camping..."
  },
  {
    concept: 'Farm',
    selected: false,
    tooltip: "livestock, farm equipment, agriculture..."
  },
  {
    concept: 'Team Sports',
    selected: false,
    tooltip: "soccer, basketball, baseball..."
  },
  {
    concept: 'Hunting And Fishing',
    selected: false,
    tooltip: "fresh & saltwater fishing, fowl hunting, big game..."
  },
  {
    concept: 'Health',
    selected: false,
    tooltip: "running, biking, fitness, cardio..."
  },
  {
    concept: 'Physical Work',
    selected: false,
    tooltip: "construction, woodworking, industrial..."
  },
];
