import React, { useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { file } from 'jszip';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';
import { MasonryPhotographers } from '../masonry-photographers/MasonryPhotographers';
import { dataTestPhotographers } from '../../common/services/mocks/dataPhotographersProject';
import { Modal } from '../modal-photographer/ModalPhotographer';

const zip = require('jszip')();

type Props = {
  creator: any;
  setProjectCart: any;
  projectCart: any;
  projectId: any;
  project: any;
  setOpenChatShortcut: any;
  openChatShortcut: any;
};

export function ProjectDetailsPhotographers(props: Props) {
  const {
    creator,
    setProjectCart,
    projectCart,
    projectId,
    project,
    setOpenChatShortcut,
    openChatShortcut,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [OpenDropdown, setOpenDropdown] = useState(false);
  const [openPhotographer, setOpenPhotographer] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [search, setSearch] = useState('All');
  const [photoBuy, setPhotoBuy] = useState<any>([]);
  const [photosShare, setPhotosShare] = useState([]);
  const [currentFilter, setCurrentFilter]: any = useState([]);

  useEffect(() => {
    setOpenDropdown(false);

    if (!photoBuy) {
      return;
    }

    if (search === 'All') {
      setCurrentFilter(photoBuy);
    }

    if (search === 'Latest') {
      setCurrentFilter(photoBuy);
    }

    if (search === 'Favorite') {
      const temp = [];
      for (let i = 0; i < photoBuy.length; i += 1) {
        if (photoBuy[i]?.isShare === true) {
          temp.push(photoBuy[i]);
        }
      }
      setCurrentFilter(temp);
    }

    if (search === 'Purchased') {
      const temp2 = [];
      for (let i = 0; i < photoBuy.length; i += 1) {
        if (photoBuy[i]?.downloadable === true) {
          temp2.push(photoBuy[i]);
        }
      }
      setCurrentFilter(temp2);
    }
  }, [search]);

  useEffect(() => {
    setCurrentFilter(photoBuy);
  }, [photoBuy]);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setIsMobile(true);
    }
  }, []);

  const [isBrandFav, setIsBrandFav] = useState(false);
  const [hideExtraButtons, setHideExtraButtons] = useState(false);
  const [currentInspirations, setCurrentInspirations] = useState<any>([]);
  const [forRefresh, setForRefresh] = useState<any>();

  useEffect(() => {
    getPhotosUploadedForSelling(creator?.creatorInfo?.id, projectId);
  }, [creator]);

  const { user: currentUser } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  async function getPhotosUploadedForSelling(
    creatorId: any,
    projectIdProp: any,
  ) {
    const route = `${SERVER_URL}getPhotosUploadedForSelling`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        creatorId,
        projectIdProp,
        brandId: currentUser.id,
      })
      .then((res: any) => {
        if (res.status === 201) {
          // setPhotoBuy(res.data);
          setPhotosAndSharedFavs(res.data);
        }
        return res.data;
      })
      .catch(() => { });
  }


  const setPhotosAndSharedFavs = (data: any) => {
    // fors <3
    let shareFavs = project.project.shareFavorites;
    const tempImages = data;

    if (shareFavs?.length > 2) {
      shareFavs = JSON.parse(shareFavs);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < shareFavs.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let w = 0; w < data.length; w++) {
          if (data[w].id === shareFavs[i]) {
            tempImages[w].isShare = true;
          }
        }
        setPhotoBuy(tempImages);
      }

    } else {
      setPhotoBuy(data);
    }
  }

  useEffect(() => {
    let newData = [];
    const data = [...photoBuy];
    newData = data?.map((i: any) => ({ ...i, inCart: false }));
    // setPhotoBuy(newData);

    const temp: any[] = [];

    newData?.forEach((photobuy: any) => {
      let seen = false;
      projectCart?.forEach((imageInCart: any) => {
        if (photobuy.imageUrl === imageInCart.imageUrl) {
          seen = true;
        }
      });
      temp.push({
        ...photobuy,
        inCart: seen,
      });
    });

    if (projectCart.length === 0) {
      newData = data?.map((i: any) => ({ ...i, inCart: false }));
      setPhotoBuy(newData);
    } else {
      setPhotoBuy(temp);
    }
  }, [projectCart]);

  function createShare() {
    const photosToShare: any[] = [];
    photoBuy?.forEach((element: any) => {
      if (element?.isShare) {
        photosToShare.push(element);
      }
    });

    if (photosToShare && photoBuy) {
      const theProjectId = photoBuy[0]?.projectId;
      const theCreatorId = photoBuy[0]?.userId;

      let stringOfPhotos = '';

      photosToShare?.forEach((element: any) => {
        stringOfPhotos += `p${element.id}`;
      });

      const createShareLink = `/share?projectId=${theProjectId}&creatorId=${theCreatorId}&photos=${stringOfPhotos}`;

      if (stringOfPhotos?.length > 1) {
        navigate(`${createShareLink}`);
      }
    }
  }

  const downloadBoughtPhotos = () => {
    const filename = `${project.project.name}-project`;
    const urls = [];

    for (let i = 0; i < photoBuy.length; i += 1) {
      if (photoBuy[i].downloadable) {
        urls.push(
          `${BUCKET_IMAGES}original/${photoBuy[i].projectId}/${photoBuy[i].imageUrl}`,
        );
      }
    }

    const folder = zip.folder('images');
    urls.forEach((url) => {
      const blobPromise = fetch(url)
        .then((response) => {
          // eslint-disable-line
          if (response.status !== 200 && response.status !== 0) {
            return Promise.reject(new Error(response.statusText)); // eslint-disable-line
          }
          return Promise.resolve(response.blob()); // eslint-disable-line
        })
        .catch((err) => console.log('Error while getting images', err));
      const name = url.substring(url.lastIndexOf('/'));
      folder.file(name, blobPromise);
    });

    zip
      .generateAsync({ type: 'blob' })
      .then((content: any) => {
        // eslint-disable-line
        saveAs(content, `${filename}.zip`);
        return true;
      })
      .catch((err: any) => console.log('Error while generating zip file', err));
  };

  return (
    <div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={creator}
        isBrandFav={isBrandFav}
        setIsBrandFav={setIsBrandFav}
        hideExtraButtons={hideExtraButtons}
        currentInspirations={currentInspirations}
        setCurrentInspirations={setCurrentInspirations}
        setForRefresh={setForRefresh}
      />
      <section className="m-auto my-5 w-10/12 ">
        <div className="mx-auto mt-6 w-full rounded-2xl bg-white dark:bg-slate-800 ">
          <div className="m-0 h-48  w-full justify-items-center p-4 md:h-full">
            <div className="grid items-center justify-items-center md:flex md:justify-between">
              <div className="relative flex items-center">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setOpenPhotographer(!openPhotographer)}
                  onKeyDown={() => setOpenPhotographer(!openPhotographer)}
                  className="absolute -left-[3.125rem] top-[6.25rem] m-0 w-[19.75rem] rounded-xl border bg-white px-1 py-3 text-center text-gray-400 duration-200  ease-in hover:cursor-pointer hover:bg-sky-100  focus:outline-none focus-visible:ring focus-visible:ring-sky-500 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 md:relative md:left-0 md:top-0 md:mr-3 md:w-12 md:rounded-full md:border-0 md:text-left md:hover:rounded-full md:hover:pl-[0.375rem]"
                >
                  <img
                    src="./img/arrowShow.png"
                    alt=""
                    className={`${openPhotographer
                      ? 'rotate-90 duration-200 ease-in dark:invert md:ml-3'
                      : ''
                      } ml-4 inline h-4  w-3 self-center duration-200 ease-in dark:invert`}
                  />
                </div>
                <p className="text-gray-500 dark:text-white">
                  {photoBuy?.length} Photos / {creator?.creatorInfo?.nickname}
                </p>
              </div>

              <div className="absolute left-16 mt-[6.5625rem]  flex  hover:cursor-pointer hover:text-sky-400 dark:hover:text-sky-400 md:relative md:left-0 md:mt-0">
                <img
                  src="./img/downloadPurchased.png"
                  alt=""
                  className="inline h-5 w-5 self-center dark:invert md:mr-4"
                />
                {!isMobile && (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => downloadBoughtPhotos()}
                  >
                    <p className="text-gray-500 hover:text-sky-400 dark:text-white dark:hover:text-sky-400">
                      Download Purchased
                    </p>
                  </div>
                )}
              </div>
              <div className="absolute left-[8rem] mt-[6.5625rem]  flex  hover:cursor-pointer hover:text-sky-400 dark:hover:text-sky-400 md:relative md:left-0 md:mt-0">
                <img
                  src="./img/share.png"
                  alt=""
                  className="inline h-6 w-6 self-center opacity-75 dark:invert md:mr-4"
                />
                {!isMobile && (
                  <div role="button" tabIndex={0} onClick={() => createShare()}>
                    <p className="text-gray-500 hover:text-sky-400 dark:text-white dark:hover:text-sky-400">
                      Share
                    </p>
                  </div>
                )}
              </div>

              <div>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setOpenDropdown(!OpenDropdown)}
                  onKeyDown={() => setOpenDropdown(!OpenDropdown)}
                  className="absolute left-[11.875rem] mt-[1.25rem] inline-flex h-10 w-[10.625rem] items-center justify-between justify-items-center rounded-xl border bg-white px-8 py-0 text-center text-sm text-gray-500 duration-200 hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 md:relative md:left-0 md:mt-0 md:border-0 "
                >
                  {search}{' '}
                  <svg
                    className="ml-2 h-8 w-8"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>

                {OpenDropdown && (
                  <div
                    onMouseLeave={() => setOpenDropdown(!OpenDropdown)}
                    className="absolute left-[11.875rem] z-30 mt-[3.75rem] w-[10.625rem] list-none divide-y divide-gray-100 rounded-xl bg-white text-base shadow dark:bg-slate-800 md:left-auto   md:mt-2"
                  >
                    <span
                      role='button'
                      tabIndex={0}
                      onClick={() => setSearch('All')}
                      key="All"
                      className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-t-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
                    >
                      All
                    </span>
                    <span
                      role='button'
                      tabIndex={0}
                      onClick={() => setSearch('Latest')}
                      key="Latest"
                      className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
                    >
                      Latest
                    </span>
                    <span
                      role='button'
                      tabIndex={0}
                      onClick={() => setSearch('Purchased')}
                      key="Purchased"
                      className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
                    >
                      Purchased
                    </span>
                    <span
                      role='button'
                      tabIndex={0}
                      onClick={() => setSearch('Favorite')}
                      key="Favorite"

                      className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-b-xl hover:bg-sky-300 hover:text-white dark:text-white  dark:hover:bg-slate-500"
                    >
                      Favorite
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Transition
          appear
          show={openPhotographer}
          enter="transition-opacity duration-[800ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-[800ms]"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {openPhotographer && (
            <section className="h-fit items-center justify-center self-center pt-4 ">
              <div className="grid h-fit w-[21.875rem]  md:w-full">
                <div className="mt-10 w-[21.875rem] rounded-2xl bg-white p-5 dark:bg-slate-800 md:h-56 md:w-full">
                  <div className="ml-5 grid justify-between align-middle text-gray-500 dark:text-white md:flex ">
                    <p>Photographer</p>
                    <div className="">
                      <div className="absolute -mt-[1.125rem] ml-[9rem] flex md:relative md:ml-0 md:mt-0">
                        <img
                          src="./img/dashboardFrame.png"
                          alt=""
                          className="-mt-[0.125rem] mr-4 inline h-5 w-5 self-center md:mt-0"
                        />
                        <p className="mr-2 text-xs md:text-xl">
                          {project?.project?.qtyPurchased} Photos Purchased
                        </p>
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => setOpenChatShortcut(!openChatShortcut)}
                        className="absolute mt-40 w-8/12 rounded-3xl border-2 border-sky-500 bg-sky-500 px-10 py-1 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400 md:relative md:mt-7 md:w-full md:px-5"
                      >
                        Message Photographer
                      </div>
                    </div>
                  </div>

                  <section>
                    <div className="relative bottom-[4.0625rem] ml-4 grid w-80 md:flex">
                      <div>
                        <img
                          src={`${BUCKET_IMAGES}avatar/${creator?.userInfo?.username}/${creator?.creatorInfo?.avatarUrl}`}
                          alt=""
                          className="mt-20 inline-block h-[5rem] w-[5rem] rounded-full object-cover align-middle md:mt-5 "
                        />
                      </div>

                      <div className="absolute ml-32 mt-20 grid text-gray-500 dark:text-white md:relative md:ml-5 md:mt-5">
                        <p
                          className="flex w-max text-base font-bold hover:cursor-pointer hover:text-sky-300 md:text-xl"
                          onClick={() => {
                            setOpenModal(!openModal);
                          }}
                        >
                          {creator?.creatorInfo?.nickname}
                        </p>
                        <p className="text-xs md:text-base">
                          {creator?.creatorInfo?.address}
                        </p>
                        <div
                          data-tip
                          data-for="ShippingToolTip"
                          className="mt-7 rounded-3xl border-2 border-gray-300 px-3 py-2 text-center text-xs text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white"
                        >
                          Shipping Address
                        </div>
                        <ReactTooltip
                          id="ShippingToolTip"
                          type="info"
                          effect="solid"
                          className="tooltip"
                          place="right"
                          arrowColor="#54a3f5"
                        >
                          <div className="">
                            <h1 className="font-semibold">Shipping Address </h1>
                            <p>{creator?.creatorInfo?.address}</p>
                            <p>{creator?.creatorInfo?.city}</p>
                            {/* <p> Bozeman Montana 11122</p> */}
                          </div>
                        </ReactTooltip>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="mt-20 h-full items-center justify-center md:p-0 ">
                  <MasonryPhotographers
                    data={currentFilter}
                    projectCart={projectCart}
                    setProjectCart={setProjectCart}
                    photoBuy={photoBuy}
                    setPhotoBuy={setPhotoBuy}
                    projectId={projectId}
                  />
                </div>
              </div>
            </section>
          )}
        </Transition>
      </section>
    </div>
  );
}
