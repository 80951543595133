import { Disclosure, Switch } from '@headlessui/react';
import React, { useState, useRef, useEffect } from 'react';
import { USERS_IMAGES_URL } from '../../common/CONFIG';

type Props = {
  setBriefDetails: any;
  briefDetails: any;
  setProjectDocs: any;
  projectDocs: any;
  editMode: boolean;
  project: any;
};

export function AdditionalRequirements(props: Props) {
  const {
    briefDetails,
    setBriefDetails,
    setProjectDocs,
    projectDocs,
    editMode,
    project,
  } = props;

  const [enabledGear, setEnabledGear] = useState(false);
  const setEnabledGearSwitch = () => {
    setEnabledGear((value) => !value);
  };

  const [enabledAccessories, setEnabledAccessories] = useState(false);
  const setEnabledAccessoriesSwitch = () => {
    setEnabledAccessories((value) => !value);
  };

  const [enabledEnvironments, setEnabledEnvironments] = useState(false);
  const setEnabledEnvironmentsSwitch = () => {
    setEnabledEnvironments((value) => !value);
  };

  const [enabledAnimals, setEnabledAnimals] = useState(false);
  const setEnabledAnimalsSwitch = () => {
    setEnabledAnimals((value) => !value);
  };

  const [enabledModel, setEnabledModel] = useState(false);
  const setEnabledModelSwitch = () => {
    setEnabledModel((value) => !value);
  };

  const [enabledVideography, setEnabledVideography] = useState(false);
  const setEnabledVideographySwitch = () => {
    setEnabledVideography((value) => !value);
  };

  const handleMultipleImages = (evnt: any) => {
    const targetFiles = evnt.target.files;
    if (!targetFiles) return;
    if (targetFiles?.length !== 0) {
      const temp = [...projectDocs];
      temp.push(targetFiles[0]);
      setProjectDocs(temp);
    }
  };

  function handleRemoveImage(value: any) {
    const result = [...projectDocs];
    result.splice(value, 1);
    setProjectDocs(result);
  }

  useEffect(() => {
    onChangeBriefDetails('professionalModel', enabledModel);
  }, [enabledModel]);

  useEffect(() => {
    onChangeBriefDetails('videography', enabledVideography);
  }, [enabledVideography]);

  const onChangeBriefDetails = (concept: string, value: any) => {
    const tempstate = { ...briefDetails, [concept]: value };
    setBriefDetails(tempstate);
  };

  const fileUpload = useRef<HTMLInputElement>(null);

  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  useEffect(() => {
    if (editMode) {
      // // eslint-disable-next-line no-console
      //  console.log('project in edit mode', project);

      const animals = project?.project.animals;
      const gear = project?.project.gear;
      const wardrobe = project?.project.wardrobe;
      const enviroments = project?.project.enviroments;
      const professionalModel = project?.project.professionalModel;
      const videography = project?.project.videography;

      if (animals?.length > 0) {
        setEnabledAnimals(true);
      }
      if (gear?.length > 0) {
        setEnabledGear(true);
      }
      if (wardrobe?.length > 0) {
        setEnabledAccessories(true);
      }
      if (enviroments?.length > 0) {
        setEnabledEnvironments(true);
      }

      if (professionalModel) {
        setEnabledModel(true);
      }

      if (videography) {
        setEnabledVideography(true);
      }

      // // eslint-disable-next-line no-console
      //  console.log('projectDocs', projectDocs);
    }
  }, [project]);

  return (
    <section className="mx-auto w-10/12">
      <div className="w-full pt-16">
        <h1 className="mb-4 text-gray-700 dark:text-white">
          Additional Requirements
        </h1>

        <div className="mx-auto mt-6 w-full rounded-2xl bg-white  p-2 dark:bg-slate-800">
          <Disclosure as="div">
            <>
              <Disclosure.Button
                as="div"
                className="flex w-full justify-between rounded-lg bg-white text-left text-gray-400 transition-all duration-300 hover:cursor-pointer   hover:bg-sky-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 "
              >
                <div
                  onClick={setEnabledGearSwitch}
                  onKeyDown={setEnabledGearSwitch}
                  className="m-0 h-full  w-full p-4 "
                  role="button"
                  tabIndex={0}
                >
                  <span>GEAR/VEHICLES ( ATV, surfboard, tractor, etc)</span>
                </div>

                <Switch
                  checked={enabledGear}
                  onChange={setEnabledGearSwitch}
                  onClick={setEnabledGearSwitch}
                  className={`${enabledGear ? 'bg-sky-300' : 'bg-gray-300'}
          duration-400 relative mr-7 inline-flex h-[1.8125rem] w-[4.0625rem] shrink-0 cursor-pointer place-self-center rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${enabledGear ? 'translate-x-9' : 'translate-x-0'
                      }
            duration-400 pointer-events-none inline-block h-[1.5625rem] w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out dark:bg-slate-800`}
                  />
                </Switch>
              </Disclosure.Button>
              <Disclosure.Panel
                as="ul"
                className="px-4 pt-4 pb-2 text-sm text-gray-500 dark:text-white "
              >
                <textarea
                  maxLength={2000}
                  rows={3}
                  className="h-[8.75rem] w-full resize-none overflow-y-auto rounded-xl p-3 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-500 dark:placeholder:text-white"
                  placeholder="Enter Information"
                  onChange={(e) => onChangeBriefDetails('gear', e.target.value)}
                  value={briefDetails?.gear}
                />
                <span className='block pt-[9px] text-[9px] text-right'>Max 2000 chars.</span>
              </Disclosure.Panel>
            </>
          </Disclosure>
        </div>

        <div className="mx-auto mt-6 w-full rounded-2xl bg-white p-2 dark:bg-slate-800      ">
          <Disclosure as="div">
            <>
              <Disclosure.Button
                as="div"
                className="flex w-full justify-between rounded-lg bg-white text-left text-gray-400 transition-all duration-300 hover:cursor-pointer   hover:bg-sky-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500"
              >
                <div
                  onClick={setEnabledAccessoriesSwitch}
                  onKeyDown={setEnabledAccessoriesSwitch}
                  className="m-0 h-full w-full p-4"
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    WARDROBE/ACCESSORIES (Laptop, Parka, Skinny Jeans, etc)
                  </span>
                </div>

                <Switch
                  checked={enabledAccessories}
                  onChange={setEnabledAccessoriesSwitch}
                  onClick={setEnabledAccessoriesSwitch}
                  className={`${enabledAccessories ? 'bg-sky-300' : 'bg-gray-300'
                    }
          duration-400 relative mr-7 inline-flex h-[1.8125rem] w-[4.0625rem] shrink-0 cursor-pointer place-self-center rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${enabledAccessories ? 'translate-x-9' : 'translate-x-0'
                      }
            duration-400 pointer-events-none inline-block h-[1.5625rem] w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out dark:bg-slate-800`}
                  />
                </Switch>
              </Disclosure.Button>
              <Disclosure.Panel
                as="ul"
                className="px-4 pt-4 pb-2 text-sm text-gray-500 dark:text-white "
              >
                <textarea
                  maxLength={2000}
                  rows={3}
                  className="h-[8.75rem] w-full resize-none overflow-y-auto rounded-xl p-3 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-500 dark:placeholder:text-white"
                  placeholder="Enter Information"
                  onChange={(e) =>
                    onChangeBriefDetails('wardrobe', e.target.value)
                  }
                  value={briefDetails?.wardrobe}
                />
                 <span className='block pt-[9px] text-[9px] text-right'>Max 2000 chars.</span>
              </Disclosure.Panel>
            </>
          </Disclosure>
        </div>

        <div className="mx-auto mt-6 w-full rounded-2xl bg-white p-2 hover:cursor-pointer dark:bg-slate-800      ">
          <Disclosure as="div">
            <>
              <Disclosure.Button
                as="div"
                className="flex w-full justify-between rounded-lg bg-white text-left text-base text-gray-400 transition-all duration-300 hover:bg-sky-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500"
              >
                <div
                  onClick={setEnabledEnvironmentsSwitch}
                  onKeyDown={setEnabledEnvironmentsSwitch}
                  className="m-0 h-full w-full p-4 "
                  role="button"
                  tabIndex={0}
                >
                  <span>ENVIRONMENTS (Modern Interior, Rustic Barn, etc)</span>
                </div>

                <Switch
                  checked={enabledEnvironments}
                  onChange={setEnabledEnvironmentsSwitch}
                  onClick={setEnabledEnvironmentsSwitch}
                  className={`${enabledEnvironments ? 'bg-sky-300' : 'bg-gray-300'
                    }
          duration-400 relative mr-7 inline-flex h-[1.8125rem] w-[4.0625rem] shrink-0 cursor-pointer place-self-center rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${enabledEnvironments ? 'translate-x-9' : 'translate-x-0'
                      }
            duration-400 pointer-events-none inline-block h-[1.5625rem] w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out dark:bg-slate-800`}
                  />
                </Switch>
              </Disclosure.Button>
              <Disclosure.Panel
                as="ul"
                className="px-4 pt-4 pb-2 text-sm text-gray-500 dark:text-white "
              >
                <textarea
                  maxLength={2000}
                  rows={3}
                  className="h-[8.75rem] w-full resize-none overflow-y-auto rounded-xl p-3 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-500 dark:placeholder:text-white"
                  placeholder="Enter Information"
                  onChange={(e) =>
                    onChangeBriefDetails('enviroments', e.target.value)
                  }
                  value={briefDetails?.enviroments}
                />
                 <span className='block pt-[9px] text-[9px] text-right'>Max 2000 chars.</span>
              </Disclosure.Panel>
            </>
          </Disclosure>
        </div>

        <div className="mx-auto mt-6 w-full rounded-2xl bg-white p-2 hover:cursor-pointer dark:bg-slate-800">
          <Disclosure as="div">
            <>
              <Disclosure.Button
                as="div"
                className="flex w-full justify-between rounded-lg bg-white text-left text-gray-400 transition-all duration-300  hover:bg-sky-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500"
              >
                <div
                  onClick={setEnabledAnimalsSwitch}
                  onKeyDown={setEnabledAnimalsSwitch}
                  className="m-0 h-full w-full p-4"
                  role="button"
                  tabIndex={0}
                >
                  <span>ANIMALS / PETS/LIVESTOCK (Dogs, Cats, Pigs)</span>
                </div>

                <Switch
                  checked={enabledAnimals}
                  onChange={setEnabledAnimalsSwitch}
                  onClick={setEnabledAnimalsSwitch}
                  className={`${enabledAnimals ? 'bg-sky-300' : 'bg-gray-300'}
          duration-400 relative mr-7 inline-flex h-[1.8125rem] w-[4.0625rem] shrink-0 cursor-pointer place-self-center rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${enabledAnimals ? 'translate-x-9' : 'translate-x-0'
                      }
            duration-400 pointer-events-none inline-block h-[1.5625rem] w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out dark:bg-slate-800`}
                  />
                </Switch>
              </Disclosure.Button>
              <Disclosure.Panel
                as="ul"
                className="px-4 pt-4 pb-2 text-sm text-gray-500 dark:text-white "
              >
                <textarea
                  maxLength={2000}
                  rows={3}
                  className="h-[8.75rem] w-full resize-none overflow-y-auto rounded-xl p-3 focus:border-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 dark:bg-slate-500 dark:placeholder:text-white"
                  placeholder="Enter Information"
                  onChange={(e) =>
                    onChangeBriefDetails('animals', e.target.value)
                  }
                  value={briefDetails?.animals}
                />
                 <span className='block pt-[9px] text-[9px] text-right'>Max 2000 chars.</span>
              </Disclosure.Panel>
            </>
          </Disclosure>
        </div>
        <input
          onChange={handleMultipleImages}
          type="file"
          ref={fileUpload}
          accept="image/png,image/jpg,image/jpeg,application/pdf"
          multiple
          className="hidden"
        />
        <section className="mt-6">
          <div
            onClick={clickFileUpload}
            role="button"
            tabIndex={0}
            className="mt-2 flex h-60 rounded-xl border-[0.125rem] border-sky-300 bg-white transition-all duration-300 hover:cursor-pointer hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500"
          >
            <div className="m-auto content-center justify-center p-10 text-center align-middle">
              <img
                src="./img/upload.png"
                alt=""
                className="inline h-12 w-12 self-center"
              />
              <h1 className="mt-5 text-2xl font-semibold text-gray-600 dark:text-white">
                Upload Supporting Docs
              </h1>
              <p className="mt-2 text-xs font-normal text-gray-600 dark:text-white">
                Drop supporting pdf´s, jpg´s and other files here
              </p>
            </div>
          </div>

          {/* Images list ! */}
          <div className="mt-5 flex w-full ">
            {projectDocs?.map((file: any, index: number) =>
              file?.type === 'image/png' ||
                file?.type === 'image/jpeg' ||
                file?.type === 'image/jpg' ||
                file?.type === 'image/bmp' ||
                file?.fileExtension === 'png' ||
                file?.fileExtension === 'jpeg' ||
                file?.fileExtension === 'jpg' ||
                file?.fileExtension === 'bmp' ? (
                <div
                  className="relative mr-5 text-center"
                  key={`docs + ${index.toString()}`}
                >
                  <div
                    onClick={() => handleRemoveImage(index)}
                    role="button"
                    tabIndex={0}
                    className="absolute -right-[6px] -top-[6px] h-8 w-8 justify-center rounded-full bg-white pt-[3px] text-center text-xl font-semibold text-sky-500 opacity-80 transition-all duration-200 hover:bg-sky-100 hover:opacity-100 hover:shadow-md  hover:shadow-md dark:bg-slate-600"
                  >
                    X
                  </div>

                  {file?.projectId ? (
                    <>
                      <img
                        alt="img"
                        src={`${USERS_IMAGES_URL}${file?.fileUrl}`}
                        className="h-20 w-20 rounded-xl bg-contain object-cover	"
                      />
                      <p className="max-h-[20px] max-w-[5rem] overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-white">
                        {file?.fileOriginalName}
                      </p>
                    </>
                  ) : (
                    <>
                      <img
                        alt="img"
                        src={URL.createObjectURL(file)}
                        className="h-20 w-20 rounded-xl bg-contain object-cover	"
                      />
                      <p className="max-h-[20px] max-w-[5rem] overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-white">
                        {file?.name}
                      </p>
                    </>
                  )}
                </div>
              ) : (
                <div
                  className="relative mr-5 text-center"
                  key={`img + ${index.toString()}`}
                >
                  <div
                    onClick={() => handleRemoveImage(index)}
                    role="button"
                    tabIndex={0}
                    className="absolute -right-[6px] -top-[6px] h-8 w-8 justify-center rounded-full bg-white pt-[3px] text-center text-xl font-semibold text-sky-500 opacity-80 transition-all duration-200 hover:bg-sky-100 hover:opacity-100 hover:shadow-md  hover:shadow-md dark:bg-slate-600"
                  >
                    X
                  </div>

                  <div className="relative mr-6 grid h-20 w-20 touch-auto place-items-center rounded-2xl bg-sky-500 text-center hover:cursor-pointer  hover:opacity-80">
                    <img
                      src="./img/file.png"
                      alt=""
                      className="h-[54px] hover:cursor-pointer hover:opacity-80 "
                    />
                    <p className="absolute text-sm font-extrabold uppercase text-white">
                      FILE
                    </p>
                  </div>

                  <p className="max-h-[20px] max-w-[5rem] overflow-hidden text-ellipsis text-sm text-gray-500 dark:text-white">
                    {file?.name}
                  </p>
                </div>
              ),
            )}
          </div>
          <div className="flex" />
        </section>

        <div className="mx-auto mt-6 flex w-full justify-between rounded-2xl border-8 border-white bg-white p-2 text-left text-gray-400 transition-all duration-300 hover:cursor-pointer  hover:bg-sky-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75 dark:border-slate-800 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500">
          <div
            onClick={setEnabledModelSwitch}
            onKeyDown={setEnabledModelSwitch}
            className="h-full w-full px-4 py-2 "
            role="button"
            tabIndex={0}
          >
            <span>ADD PROFESSIONAL MODEL FEE + $80</span>
          </div>

          <Switch
            checked={enabledModel}
            onChange={setEnabledModelSwitch}
            onClick={setEnabledModelSwitch}
            className={`${enabledModel ? 'bg-sky-300' : 'bg-gray-300'}
          duration-400 relative mr-7 inline-flex h-[1.8125rem] w-[4.0625rem] shrink-0 cursor-pointer place-self-center rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span
              aria-hidden="true"
              className={`${enabledModel ? 'translate-x-9' : 'translate-x-0'}
            duration-400 pointer-events-none inline-block h-[1.5625rem] w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out dark:bg-slate-800`}
            />
          </Switch>
        </div>

        {/* <div className="mx-auto mt-6 flex w-full justify-between rounded-2xl border-8 border-white bg-white p-2 text-left text-gray-400 transition-all duration-300 hover:cursor-pointer   hover:bg-sky-100 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75 dark:border-slate-800 dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500">
          <div
            onClick={setEnabledVideographySwitch}
            onKeyDown={setEnabledVideographySwitch}
            className="h-full w-full px-4 py-2"
            role="button"
            tabIndex={0}
          >
            <span>ADD VIDEOGRAPHY + $500</span>
          </div>

          <Switch
            checked={enabledVideography}
            onChange={setEnabledVideographySwitch}
            onClick={setEnabledVideographySwitch}
            className={`${enabledVideography ? 'bg-sky-300' : 'bg-gray-300'}
          duration-400 relative mr-7 inline-flex h-[1.8125rem] w-[4.0625rem] shrink-0 cursor-pointer place-self-center rounded-full border-2 border-transparent transition-colors ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span
              aria-hidden="true"
              className={`${
                enabledVideography ? 'translate-x-9' : 'translate-x-0'
              }
            duration-400 pointer-events-none inline-block h-[1.5625rem] w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out dark:bg-slate-800`}
            />
          </Switch>
        </div> */}
      </div>
    </section>
  );
}
