import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Switch } from '@headlessui/react';
import { AddTalentModels, UploadPortfolio } from 'components';
import { register } from '../../actions/auth';
import CreatorFields from './CreatorFields';
import BrandFields from './BrandFields';
import {
  shotLocation,
  activityAccess,
  specializationCategories,
} from '../../components/select-category/SelectCategoryData';
import { SERVER_URL } from '../../common/CONFIG';

export default function CreateUser() {
  type State = {
    emailText: String;
    passwordText: String;
  };

  const creatorInfoExtraItems = {
    address: '',
    phone: '',
    city: '',
    zipCode: '',
    state: '',
    bio: '',
  };

  const brandInfoExtraItems = {
    title: '',
    companyName: '',
    companyWeb: '',
    companyFB: '',
    companyIG: '',
    bio: '',
  };

  const [onboarding, setOnboarding] = useState(false);
  const handleOnboarding = () => {
    setOnboarding((value) => !value);
  };

  const [enabled, setEnabled] = useState(true);
  const handleEnabled = () => {
    setEnabled((value) => !value);
  };

  // #################################################################
  // Extra stuff if user selected is creator
  const [creatorInfoExtra, setCreatorInfoExtra] = useState(
    creatorInfoExtraItems,
  );
  const [creatorTalents, setCreatorTalents] = useState<any>([]);
  const [creatorPortfolioPhotos, setCreatorPortfolioPhotos] = useState<any>([]);
  const [portfolioPhotosSizes, setPortfolioPhotosSizes] = useState<any>([]);
  const [avatarPhoto, setAvatarPhoto] = useState<any | null>('noimage');
  const [contactPhone, setContactPhone] = useState(true);
  const handleContactViaPhone = () => {
    setContactPhone((value) => !value);
  };

  const [checkedStateActivity, setCheckedStateActivity] =
    useState(activityAccess);
  const handleOnChangeActivity = (activity: string) => {
    const tempstate = [...checkedStateActivity];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedStateActivity[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedStateActivity(tempstate);
  };

  const [checkedSpecializationCategories, setCheckedSpecializationCategories] =
    useState(specializationCategories);
  const handleOnChangeSpecializationCategories = (activity: string) => {
    const tempstate = [...checkedSpecializationCategories];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedSpecializationCategories[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedSpecializationCategories(tempstate);
  };

  const [checkedShotLocation, setCheckedShotLocation] = useState(shotLocation);
  const handleOnChangeShotLocation = (activity: string) => {
    const tempstate = [...checkedShotLocation];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedShotLocation[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedShotLocation(tempstate);
  };

  const onChangeExtraCreator = (concept: string, value: string) => {
    const tempstate = { ...creatorInfoExtra, [concept]: value };
    setCreatorInfoExtra(tempstate);
  };

  const [editMode, setEditMode] = useState(false);

  // #################################################################

  // #################################################################
  // Extra stuff if user selected is brand

  const [brandInfoExtra, setBrandInfoExtra] = useState(brandInfoExtraItems);

  const onChangeExtraBrand = (concept: string, value: string) => {
    const tempstate = { ...brandInfoExtra, [concept]: value };
    setBrandInfoExtra(tempstate);
  };

  // #################################################################
  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setAvatarPhoto(fileList[0]);
  };

  const { message } = useSelector((state: any) => state.message);

  // Standard stuff for any user type
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const [roles, setRoles] = useState(['creator']);
  const [userTypeSelected, setUserTypeSelected] = useState('SELECT');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    setType(roles[0]);
  }, [roles]);

  const onPasteUsername = (e: any) => {
    const paste = (e.clipboardData || window.Clipboard).getData("text");
    if (paste.indexOf('+') > -1) {
      e.preventDefault();
    }
  }
  const onKeyDownUsername = (e: any) => {
    if (e.key === '+') {
      e.preventDefault();
      return false;
    }
    return true;
  }
  const onChangeUsername = (e: any) => {
    setUsername(e);
  };
  const onChangeEmail = (e: any) => {
    setEmail(e);
  };
  const onChangePassword = (e: any) => {
    setPassword(e);
  };
  const onChangeFirstname = (e: any) => {
    setFirstName(e);
  };
  const onChangeLastname = (e: any) => {
    setLastName(e);
  };

  useEffect(() => {
    setCheckedShotLocation(shotLocation);
    setCheckedSpecializationCategories(specializationCategories);
    setCheckedStateActivity(activityAccess);
  }, [userTypeSelected]);

  type AppDispatch = ThunkDispatch<State, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [OpenDropdown, setOpenDropdown] = useState(false);

  const handleFormSubmit = async () => {
    // e.preventDefault();

    dispatch(
      register(
        username,
        email,
        password,
        roles,
        firstName,
        lastName,
        type,
        checkedStateActivity,
        checkedShotLocation,
        checkedSpecializationCategories,
        contactPhone,
        creatorInfoExtra,
        brandInfoExtra,
        avatarPhoto.name,
        onboarding,
        enabled,
      ),
    )
      .then(() => {
        // if (avatarPhoto) {
        //   const config = {
        //     headers: {
        //       'content-type': 'multipart/form-data',
        //     },
        //   };
        //   const formData = new FormData();
        //   formData.append('uploadImages', avatarPhoto);
        //   formData.append('type', type);

        //   const route = `${SERVER_URL}upload-avatar/${username}`;
        //   // eslint-disable-next-line promise/no-nesting
        //   axios
        //     .post(route, formData, config)
        //     .then(
        //       () => null,
        //       // all good !
        //     )
        //     .catch(() => {});
        // }
        // uploadTalents();

        // uploadPortfolio();
        // navigate('/');
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return null;
      })
      .catch(() => {
        // Error ?!
      });
  };

  async function uploadTalents() {
    const uploadTalentsPhotos = new FormData();
    const temp = [...creatorTalents];
    Object.values(temp).forEach((talent) => {
      uploadTalentsPhotos.append('uploadPhotos', talent.photoTalent);
    });
    uploadTalentsPhotos.append(
      'creatorTalents',
      JSON.stringify(creatorTalents),
    );
    uploadTalentsPhotos.append('type', type);

    try {
      const res = await axios.post(
        `${SERVER_URL}upload-talents/${username}`,
        uploadTalentsPhotos,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    preparePhotosDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatorPortfolioPhotos]);

  function preparePhotosDimensions() {
    setPortfolioPhotosSizes([]);

    for (const results of creatorPortfolioPhotos) {
      const img = document.createElement('img');
      const blob = URL.createObjectURL(results);

      img.src = blob;
      img.onload = function getSizes() {
        const w = img.width;
        const h = img.height;
        const dimensions = `${w}X${h}`;
        setPortfolioPhotosSizes((prevArray: any) => [...prevArray, dimensions]);
      };
    }
  }

  async function uploadPortfolio() {
    // upload all photos

    const uploadPortfolioPhotos = new FormData();
    const temp = [...creatorPortfolioPhotos];
    Object.values(temp).forEach((element) => {
      uploadPortfolioPhotos.append('uploadPhotos', element);
    });

    uploadPortfolioPhotos.append(
      'uploadPhotosSizes',
      JSON.stringify(portfolioPhotosSizes),
    );

    uploadPortfolioPhotos.append('type', type);

    try {
      const res = await axios.post(
        `${SERVER_URL}upload-photos/${username}`,
        uploadPortfolioPhotos,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      );
      // eslint-disable-next-line no-console
      //  console.log(res);
    } catch (err: any) {
      if (err.response.status === 500) {
        // eslint-disable-next-line no-console
        //  console.log(err);
      } else {
        // eslint-disable-next-line no-console
        //  console.log(err.response.data.msg);
      }
    }
  }

  return (
    <div className="bg-gray-100 text-center text-gray-500 dark:bg-slate-900 dark:text-white">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1 className="mb-5 text-center text-2xl font-semibold text-gray-600 dark:text-white">
        Add user
      </h1>
      <p>You can add users to the database using this form.</p>
      {message && (
        <div className="text-center">
          <div className="text-gray-500" role="alert">
            {message}
          </div>
        </div>
      )}
      <br />
      <br />
      <hr className="m-auto w-6/12 border" />
      <br />
      <form onSubmit={handleFormSubmit}>
        <div className="">
          <p>Account Access Email/Username</p>
          <input
            className="mb-5 w-[20rem] rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="username"
            onPaste={(e) => onPasteUsername(e)}
            onKeyDown={(e) => onKeyDownUsername(e)}
            onChange={(e) => onChangeUsername(e.target.value)}
          />
        </div>

        <div className="">
          <p>Publicly Displayed Email</p>
          <input
            className="mb-5 w-[20rem]  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="email"
            onChange={(e) => onChangeEmail(e.target.value)}
          />
        </div>

        {/* <div className="">
          <p>Password</p>
          <input
            className="mb-5 w-[20rem]  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="password"
            name="password"
            onChange={(e) => onChangePassword(e.target.value)}
          />
        </div> */}

        <div className="">
          <p>First Name</p>
          <input
            className="mb-5 w-[20rem]  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="first_name"
            onChange={(e) => onChangeFirstname(e.target.value)}
          />
        </div>

        <div className="">
          <p>Last Name</p>
          <input
            className="mb-5 w-[20rem]  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
            type="text"
            name="last_name"
            onChange={(e) => onChangeLastname(e.target.value)}
          />
        </div>

        <br />
        <p>Enable user?</p>
        <Switch
          checked={enabled}
          onChange={handleEnabled}
          className={`${enabled ? 'bg-sky-300' : 'bg-gray-300'}
                      duration-400 relative mx-5
                      inline-flex h-[1.8125rem] w-[4.0625rem]
                      shrink-0 cursor-pointer
                      place-self-center rounded-full
                      border-2 border-transparent transition-colors
                      ease-in-out focus:outline-none focus-visible:ring-2
                      focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            aria-hidden="true"
            className={`${enabled ? 'translate-x-9' : 'translate-x-0'}
                      duration-400 pointer-events-none inline-block h-[1.5625rem]
                      w-[1.5625rem] rounded-full bg-white shadow-lg ring-0 transition ease-in-out
                      dark:bg-slate-800`}
          />
        </Switch>
        <br />

        <div
          onClick={() => setOpenDropdown(!OpenDropdown)}
          onKeyDown={() => setOpenDropdown(!OpenDropdown)}
          className="m-auto my-10 inline-flex w-[21.875rem] items-center justify-between rounded-xl border bg-white  p-3 text-center text-sm text-gray-500 duration-200 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:bg-slate-800 dark:text-white dark:hover:bg-slate-500 md:w-[8rem]"
          role="button"
          tabIndex={0}
        >
          {userTypeSelected}

          <svg
            className="ml-2 h-8 w-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          {OpenDropdown && (
            <div
              onMouseLeave={() => setOpenDropdown(!OpenDropdown)}
              className="absolute z-30  mt-40 w-[21.875rem] list-none divide-y divide-gray-100 rounded-xl bg-white text-base shadow dark:bg-slate-800 md:w-[8rem]"
            >
              <span
                onClick={() => {
                  setRoles(['creator']);
                  setUserTypeSelected('creator');
                }}
                key="All"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-t-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                Creator
              </span>
              <span
                onClick={() => {
                  setRoles(['brand']);
                  setUserTypeSelected('brand');
                }}
                key="In Progress"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                Brand
              </span>
              <span
                onClick={() => {
                  setRoles(['admin']);
                  setUserTypeSelected('admin');
                }}
                key="Completed"
                role='button'
                tabIndex={0}
                className="block px-4 py-2 text-gray-700 duration-100 ease-in   hover:cursor-pointer hover:rounded-b-xl hover:bg-sky-300 hover:text-white dark:text-white dark:hover:bg-slate-500"
              >
                Admin
              </span>
            </div>
          )}
        </div>

        <br />
        {/* <hr className="m-auto w-6/12 border" />
        <br />
        <br /> */}

        {/* {userTypeSelected === 'creator' && (
          <>
            <CreatorFields
              onChangeAvatar={onChangeAvatar}
              onChangeExtraCreator={onChangeExtraCreator}
              contactPhone={contactPhone}
              handleContactViaPhone={handleContactViaPhone}
              activityAccess={activityAccess}
              shotLocation={shotLocation}
              specializationCategories={specializationCategories}
              handleOnChangeActivity={handleOnChangeActivity}
              handleOnChangeShotLocation={handleOnChangeShotLocation}
              handleOnChangeSpecializationCategories={
                handleOnChangeSpecializationCategories
              }
              editMode={editMode}
              creatorInfoExtra={creatorInfoExtra}
            />
            <br />
            <div className="md:w-10/12 m-auto">

                <AddTalentModels
                  setCreatorTalents={setCreatorTalents}
                  creatorTalents={creatorTalents}
                  setAddNewTalent={null}
                  setEditingTalentFromProfile={false}
                />
            </div>

            <br />
            <div className="m-auto w-10/12">
              <UploadPortfolio
                setCreatorPortfolioPhotos={setCreatorPortfolioPhotos}
                creatorPortfolioPhotos={creatorPortfolioPhotos}
              />
            </div>
          </>
        )}

        {userTypeSelected === 'brand' && (
          <BrandFields
          editMode={editMode}
            onChangeExtraBrand={onChangeExtraBrand}
            onChangeAvatar={onChangeAvatar}
            brandInfoExtra={brandInfoExtra}
          />
        )} */}

        <br />
        <br />

        {userTypeSelected !== 'SELECT' && (
          <div className="">
            <div
              onClick={handleFormSubmit}
              role="button"
              tabIndex={0}
              className="brand-color font-display focus:shadow-outline m-auto w-[10rem]  rounded-2xl p-3 text-center font-semibold tracking-wide  text-white duration-200 ease-in hover:bg-sky-300 focus:outline-none dark:hover:bg-slate-500"
            >
              Create new User
            </div>
          </div>
        )}
      </form>

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
