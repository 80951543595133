import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../common/CONFIG';
import { USERS_IMAGES_URL } from '../../common/CONFIG';

type Props = {
  key: number;
  pictureUrl: string;
  projectName: string;
  totalPurchased: string;
  totalPictures: string;
  description: string;
  projectId: any;
  favoritePhotographers: string;
  getProjects: any;
  status: string;
};

export function RecentProjectCard(props: Props) {
  const { key, pictureUrl, projectName, totalPurchased, totalPictures, description, projectId, favoritePhotographers, getProjects, status } = props;

  const [deletable, setDeletable] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState();

  useEffect(() => {
    const canBeDeleted = ((!favoritePhotographers.includes("WAITING") && !favoritePhotographers.includes("ASKING") && !favoritePhotographers.includes("ACCEPTED")) || status === 'DUPLICATE');
    setDeletable(canBeDeleted);
  }, [favoritePhotographers, status]);


  const showDeleteModal = (id: any) => {
    setProjectToDelete(id);
    setShowDelete(true);
  }

  const deleteProject = async () => {

    const route = `${SERVER_URL}deleteProject/${projectToDelete}`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route)
      .then((resp) => {
        if (resp.status === 201) {
          getProjects();
          setShowDelete(false);
        }
        return resp.data;
      })
      .catch(() => { });

  }

  return (
    <>
      <Transition appear show={showDelete} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[101] overflow-y-auto"
          onClose={() => setShowDelete(!showDelete)}
        >
          <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
          <div className="px-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="mx-auto my-8 inline-block  w-full rounded-[2.5rem] bg-white p-1 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-[800px]">
                <section className="m-auto grid w-full">
                  <div className="confirm-background-small relative grid p-10 text-center ">
                    <strong className="self-center pb-10 pt-5 text-3xl font-bold text-gray-700 dark:text-white">
                      Delete project ?
                    </strong>

                    <div className="inline-flex w-full justify-center gap-6">
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() =>
                          deleteProject()
                        }
                        onKeyDown={() =>
                          deleteProject()
                        }
                        className="z-0 self-center rounded-3xl border-2 border-sky-500 bg-sky-500 px-5 py-2 text-center text-white shadow-lg shadow-sky-500/50 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:bg-sky-400 dark:hover:bg-sky-400"
                      >
                        Delete project
                      </div>
                      <div
                        onClick={() =>
                          setShowDelete(!showDelete)
                        }
                        onKeyDown={() =>
                          setShowDelete(!showDelete)
                        }
                        role="button"
                        tabIndex={0}
                        className="z-0 self-center rounded-full border-2 border-gray-300 px-16 py-2 text-gray-500 transition duration-200 ease-in hover:cursor-pointer hover:border-sky-400 hover:text-sky-400 dark:text-white dark:hover:text-sky-400"
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <div className='relative  z-[25]'>
        {deletable && (
          <div
            className="absolute top-3 right-5 z-[80] mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80"
            role="button"
            tabIndex={0}
            onClick={() => showDeleteModal(projectId)}
          >
            <img
              src="./img/deleteCircleBlue.png"
              alt=""
              className="h-10 w-10"
            />
          </div>
        )}

        <Link key={key} to={`/project?id=${projectId}`}>

          <section
            className="relative z-20 ml-[0.41vw] mb-5 h-fit  rounded-3xl  bg-white  p-5 opacity-90 duration-200 ease-in hover:cursor-pointer hover:opacity-100 hover:shadow dark:bg-slate-800 md:ml-0 md:mb-0 md:h-[52.29vh] md:w-[26.04vw]"
          >
            <img
              src={`${USERS_IMAGES_URL}${pictureUrl}`}
              alt=""
              className="w-full relative z-0 rounded-3xl object-cover  md:h-[37.35vh]"
            />
            <div className="relative mt-10 flex w-full items-center justify-between md:absolute md:bottom-4 md:mt-0">
              <h1 className="text-left text-xl font-semibold  text-gray-600 dark:text-white md:py-[4.5vh] md:text-[1vw] truncate max-w-[315px]">
                {projectName}
              </h1>
              <div className="flex">
                <img
                  alt="logo"
                  src="./img/dashboardFrame.png"
                  className="h-5 w-5 self-center md:h-[2.1vh] md:w-[1.0vw]"
                />
                <p className="ml-2 text-sm text-sky-400 md:mr-10 md:text-[0.8vw]">
                  {totalPictures} Photos
                </p>
              </div>
            </div>

            <div className="relative mt-5 flex w-full items-center justify-between md:absolute md:-bottom-4 md:mt-0">
              <h1 className="text-left text-sm text-gray-600 dark:text-white md:py-10 md:text-[0.8vw] truncate max-w-[315px]">
                {description}
              </h1>
              <div className="flex">
                <img
                  alt="logo"
                  src="./img/download.png"
                  className="h-5 w-5 self-center md:h-[2.1vh] md:w-[1.0vw]"
                />
                <p className="ml-2 text-sm text-gray-500 dark:text-white md:mr-10 md:text-[0.8vw]">
                  {totalPurchased} Purchased
                </p>
              </div>
            </div>
          </section>
        </Link>
      </div>
    </>
  );
}
