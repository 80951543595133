import React from 'react';

type PropsAction = {
  showActionInfo: boolean,
  text: string,
  loadingIcon: boolean,
};

export function ActionInformation(props: PropsAction) {
  const { showActionInfo, text, loadingIcon } = props;

  return (
    <div>
      {showActionInfo && (
        <section className=" z-[100] fixed flex items-center justify-between bottom-0 bg-sky-600 w-full px-8 py-3">
          <p className='text-white'>Please confirm your changes</p>
          <div className="animate-pulse inline-flex cursor-pointer items-center rounded-md bg-sky-500 px-8 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-sky-400">
           {loadingIcon && ( 
                    <svg
                      className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
              )}
            <p>{text}</p>
          </div>
        </section>
      )}
    </div>
  );
}
