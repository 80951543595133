import React, { useState, Fragment, useEffect } from 'react';
import { AddTalentModels, UploadPortfolio } from 'components';
import { Dialog, Transition } from '@headlessui/react';
import CreatorFields from './CreatorFields';
import BrandFields from './BrandFields';
import { BUCKET_IMAGES } from '../../common/CONFIG';
import {
  shotLocation,
  activityAccess,
  specializationCategories,
} from '../../components/select-category/SelectCategoryData';

type PropsUserToEdit = {
  userToEdit: any;
  state: boolean;
};

export default function EditUserModal(props: PropsUserToEdit) {
  const { userToEdit, state } = props;

  const [editMode, setEditMode] = useState(true);

  // #################################################################
  // Extra stuff if user selected is creator
  const [creatorInfoExtra, setCreatorInfoExtra] = useState(
    userToEdit?.creatorExtraInfo,
  );

  const [creatorTalents, setCreatorTalents] = useState<any>(
    userToEdit?.creatorTalents,
  );
  const [creatorPortfolioPhotos, setCreatorPortfolioPhotos] = useState<any>(
    userToEdit?.creatorPhotos,
  );
  const [portfolioPhotosSizes, setPortfolioPhotosSizes] = useState<any>([]);
  const [avatarPhoto, setAvatarPhoto] = useState<any | null>('noimage');
  const [contactPhone, setContactPhone] = useState(
    userToEdit?.creatorExtraInfo?.contactViaPhone,
  );
  const handleContactViaPhone = () => {
    setContactPhone((value: boolean) => !value);
  };

  const [checkedStateActivity, setCheckedStateActivity] =
    useState<any>(activityAccess);
  const handleOnChangeActivity = (activity: string) => {
    const tempstate = [...checkedStateActivity];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedStateActivity[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedStateActivity(tempstate);
  };

  const [checkedSpecializationCategories, setCheckedSpecializationCategories] =
    useState<any>(specializationCategories);
  const handleOnChangeSpecializationCategories = (activity: string) => {
    const tempstate = [...checkedSpecializationCategories];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedSpecializationCategories[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedSpecializationCategories(tempstate);
  };

  const [checkedShotLocation, setCheckedShotLocation] =
    useState<any>(shotLocation);
  const handleOnChangeShotLocation = (activity: string) => {
    const tempstate = [...checkedShotLocation];
    const index = tempstate.findIndex(
      (activities) => activities.location === activity,
    );
    const tempelement = { ...checkedShotLocation[index] };
    tempelement.selected = !tempelement.selected;
    tempstate[index] = tempelement;
    setCheckedShotLocation(tempstate);
  };

  const onChangeExtraCreator = (concept: string, value: string) => {
    const tempstate = { ...creatorInfoExtra, [concept]: value };
    setCreatorInfoExtra(tempstate);
  };

  useEffect(() => {
    const temp = [...shotLocation];

    let info = userToEdit?.creatorExtraInfo?.shotLocation;
    if (info) {
      info = JSON.parse(info);
      shotLocation?.map((obj, index) => {
        if (info.includes(obj.location)) {
          temp[index].selected = true;
        } else {
          temp[index].selected = false;
        }
        return null;
      });
    }
    setCheckedShotLocation(temp);
  }, [userToEdit?.creatorExtraInfo?.shotLocation]);

  useEffect(() => {
    const temp = [...activityAccess];

    let info = userToEdit?.creatorExtraInfo?.activityAccess;
    if (info) {
      info = JSON.parse(info);
      activityAccess?.map((obj, index) => {
        if (info.includes(obj.location)) {
          temp[index].selected = true;
        } else {
          temp[index].selected = false;
        }
        return null;
      });
    }
    setCheckedStateActivity(temp);
  }, [userToEdit?.creatorExtraInfo?.activityAccess]);

  useEffect(() => {
    const temp = [...specializationCategories];

    let info = userToEdit?.creatorExtraInfo?.specializationCategories;
    if (info) {
      info = JSON.parse(info);
      specializationCategories?.map((obj, index) => {
        if (info.includes(obj.location)) {
          temp[index].selected = true;
        } else {
          temp[index].selected = false;
        }
        return null;
      });
    }
    setCheckedSpecializationCategories(temp);
  }, [userToEdit?.creatorExtraInfo?.specializationCategories]);

  useEffect(() => {
    setCreatorInfoExtra(userToEdit?.creatorExtraInfo);
    setContactPhone(userToEdit?.creatorExtraInfo?.contactViaPhone);
  }, [
    userToEdit?.creatorExtraInfo,
    userToEdit?.creatorExtraInfo?.contactViaPhone,
  ]);

  useEffect(() => {
    if (userToEdit?.creatorTalents) {
      const temp = Array.from(userToEdit?.creatorTalents);
      setCreatorTalents(temp);
    }
  }, [userToEdit?.creatorTalents]);

  useEffect(() => {
    if (userToEdit?.creatorPhotos) {
      const temp = Array.from(userToEdit?.creatorPhotos);
      setCreatorPortfolioPhotos(temp);
    }
  }, [userToEdit?.creatorPhotos]);

  useEffect(() => {
    setBrandInfoExtra(userToEdit?.brandExtraInfo);
  }, [userToEdit?.brandExtraInfo]);

  // #################################################################

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setAvatarPhoto(fileList[0]);
  };

  const [isOpen, setIsOpen] = useState(state);

  useEffect(() => {
    setIsOpen(true);
  }, [state]);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  // BRAND STUFF !

  const [brandInfoExtra, setBrandInfoExtra] = useState(
    userToEdit?.brandExtraInfo,
  );

  const onChangeExtraBrand = (concept: string, value: string) => {
    const tempstate = { ...brandInfoExtra, [concept]: value };
    setBrandInfoExtra(tempstate);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 -z-[10]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="-z-[10] inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="-z-[10] my-8  inline-block w-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800 dark:text-white md:w-[75%]">
              <section className="m-auto w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div className="m-auto  w-11/12 ">
                  <h1 className="mb-5 text-center text-2xl font-semibold text-gray-600 dark:text-white">
                    View User
                  </h1>
                  <section className="m-auto w-11/12 items-center text-center">
                    <div className="inline-flex cursor-not-allowed  duration-200 ease-in hover:opacity-60">
                      <p className="font-semibold">Username :&nbsp;</p>
                      {userToEdit?.userInfo?.username}
                    </div>
                    <br />
                    <div className="inline-flex cursor-not-allowed  duration-200 ease-in hover:opacity-60">
                      <p className="font-semibold">Email :&nbsp;</p>
                      {userToEdit?.userInfo?.email}
                    </div>
                    <br />
                    <div className="inline-flex cursor-not-allowed  duration-200 ease-in hover:opacity-60">
                      <p className="font-semibold">Type :&nbsp;</p>
                      {userToEdit?.userInfo?.type}
                    </div>
                    <br />
                    <br />
                    <hr className="m-auto w-6/12 border" />
                    <br />
                    {userToEdit?.userInfo?.type === 'creator' && (
                      <img
                        src={`${BUCKET_IMAGES}avatar/${userToEdit.userInfo.username}/${userToEdit?.creatorExtraInfo?.avatarUrl}`}
                        alt=""
                        className="ml-[40px] inline h-60 w-60 self-center rounded-full object-cover hover:cursor-pointer hover:border hover:shadow-md md:ml-0 md:h-[20vh] md:w-[10vw]"
                      />
                    )}
                    {userToEdit?.userInfo?.type === 'brand' && (
                      <img
                        src={`${BUCKET_IMAGES}avatar/${userToEdit.userInfo.username}/${userToEdit?.brandExtraInfo?.avatarUrl}`}
                        alt=""
                        className="ml-[40px] inline h-60 w-60 self-center rounded-full object-cover hover:cursor-pointer hover:border hover:shadow-md md:ml-0 md:h-[20vh] md:w-[10vw]"
                      />
                    )}
                    <br />
                    <br />
                    <div className="">
                      <p>First Name</p>
                      <input
                        disabled
                        className="mb-5 w-[20rem]  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                        type="text"
                        name="first_name"
                        value={userToEdit?.userInfo?.first_name}
                        // onChange={(e) => onChangeFirstname(e.target.value)}
                      />
                    </div>

                    <div className="">
                      <p>Last Name</p>
                      <input
                        disabled
                        className="mb-5 w-[20rem]  rounded-2xl border border-gray-300 p-3 text-sm outline-none transition duration-300 ease-in hover:border  hover:border-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                        type="text"
                        name="last_name"
                        value={userToEdit?.userInfo?.last_name}
                        // onChange={(e) => onChangeLastname(e.target.value)}
                      />
                    </div>

                    {userToEdit?.userInfo?.type === 'creator' && (
                      <>
                        <CreatorFields
                          onChangeAvatar={onChangeAvatar}
                          onChangeExtraCreator={onChangeExtraCreator}
                          contactPhone={contactPhone}
                          handleContactViaPhone={handleContactViaPhone}
                          activityAccess={checkedStateActivity}
                          shotLocation={checkedShotLocation}
                          handleOnChangeActivity={handleOnChangeActivity}
                          handleOnChangeShotLocation={
                            handleOnChangeShotLocation
                          }
                          specializationCategories={
                            checkedSpecializationCategories
                          }
                          handleOnChangeSpecializationCategories={
                            handleOnChangeSpecializationCategories
                          }
                          editMode={editMode}
                          creatorInfoExtra={creatorInfoExtra}
                        />
                        <br />
                        <br />
                        <hr className="m-auto w-6/12 border" />
                        <br />
                        <h1 className="text-xl font-semibold ">
                          User creator talents
                        </h1>
                        <div className="grid grid-cols-5 gap-10">
                          {creatorTalents &&
                            creatorTalents?.map(
                              (element: any, index: number) =>
                                element.deleted === false && (
                                  <section
                                    className="ml-0 mb-3 h-[215px] w-[350px] px-3 md:ml-5 md:h-[22.94vh] md:w-[11.19vw] md:px-0"
                                    key={Math.floor(
                                      Math.random() * (1500 - 1) + 1,
                                    )}
                                  >
                                    <div className=" relative h-[215px] w-[350px] rounded-2xl bg-white hover:opacity-90 dark:bg-slate-800 md:h-[22.94vh] md:w-[11.19vw]">
                                      <img
                                        src={`${BUCKET_IMAGES}talent/${userToEdit?.userInfo?.username}/${element?.imageUrl}`}
                                        alt=""
                                        className="h-[215px] w-[350px] rounded-2xl object-cover hover:opacity-90 md:h-[22.94vh] md:w-full"
                                      />
                                    </div>
                                  </section>
                                ),
                            )}
                        </div>

                        <br />
                        <br />
                        <hr className="m-auto w-6/12 border" />
                        <br />
                        <h1 className="text-xl font-semibold ">
                          User creator photos
                        </h1>
                        <div className="grid grid-cols-5 gap-10">
                          {creatorPortfolioPhotos?.map(
                            (element: any, index: number) =>
                              element.deleted === false && (
                                <section
                                  className="ml-0 mb-3 h-[215px] w-[350px] px-3 md:ml-5 md:h-[22.94vh] md:w-[11.19vw] md:px-0"
                                  key={Math.floor(
                                    Math.random() * (1500 - 1) + 1,
                                  )}
                                >
                                  <div className=" relative h-[215px] w-[350px] rounded-2xl bg-white hover:opacity-90 dark:bg-slate-800 md:h-[22.94vh] md:w-[11.19vw]">
                                    <img
                                      src={`${BUCKET_IMAGES}portfolio/thumbnails/${userToEdit?.userInfo?.username}/${element?.thumbnailUrl}`}
                                      alt=""
                                      className="h-[215px] w-[350px] rounded-2xl object-cover hover:opacity-90 md:h-[22.94vh] md:w-full"
                                    />
                                  </div>
                                </section>
                              ),
                          )}
                        </div>

                        <br />
                        <br />
                      </>
                    )}

                    {userToEdit?.userInfo?.type === 'brand' && (
                      <BrandFields
                        editMode={editMode}
                        onChangeExtraBrand={onChangeExtraBrand}
                        onChangeAvatar={onChangeAvatar}
                        brandInfoExtra={brandInfoExtra}
                      />
                    )}
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
