import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import axios from 'axios';

import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';
import 'slick-carousel/slick/slick-theme.css';
import { dataTestPhotographers } from '../../common/services/mocks/dataPhotographersProject';

type Props = {
  setProjectCart: any;
  projectCart: any;
  projectId: any;
};

export function ProjectAddedToCart(props: Props) {
  const { setProjectCart, projectCart, projectId } = props;

  const [slides, setSlides] = useState<number>(5);
  useEffect(() => {
    if (window.innerWidth < 700) {
      setSlides(1);
    }
  }, []);

  async function deletFromCart(index: any) {
    const result = [...projectCart];
    const route = `${SERVER_URL}deleteFromCart`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photoId: result[index]?.id,
        projectId,
      })
      .then((res) => {
        if (res.status === 201) {
          setProjectCart(res.data);
        }
        return res.data;
      })
      .catch(() => {});
  }

  return (
    <div className="mx-auto mt-10 w-11/12 md:w-10/12 ">
      <h1 className="ml-5 text-2xl font-semibold text-gray-500 dark:text-white md:ml-0">
        {' '}
        Added to Cart{' '}
      </h1>

      {projectCart?.length === 0 && (
        <div className="m-auto my-20 w-full text-center">
          <p className="animate-pulse p-10 text-2xl font-extrabold text-gray-400 dark:text-gray-300">
            The Cart is empty
          </p>
        </div>
      )}
      {projectCart?.length > 0 && (
        <Slider
          dots
          infinite={false}
          speed={500}
          slidesToShow={slides}
          slidesToScroll={4}
          className="m-auto mb-20 mt-10 h-[21.875rem] w-[23.125rem] md:w-full"
        >
          {projectCart?.map((element: any, index: any) => (
            <section
              className="h-[21.875rem] p-2 md:w-[23.75rem] md:pr-3"
              key={`cart + ${index.toString()}`}
            >
              <div className="relative my-3 h-[18.75rem] rounded-2xl bg-white hover:opacity-90 dark:bg-slate-800">
                <img
                  src={`${BUCKET_IMAGES}watermark/${projectId}/${element?.watermarkUrl}`}
                  alt=""
                  className="h-[18.75rem] w-full rounded-2xl object-cover hover:opacity-90"
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => deletFromCart(index)}
                >
                  <img
                    src="./img/deleteCircle.png"
                    alt=""
                    className="absolute left-[19rem] top-[0.3125rem] mt-2 h-8 w-8 hover:cursor-pointer hover:opacity-80 md:left-auto md:right-[0.5625rem]"
                  />
                </div>
              </div>
            </section>
          ))}
        </Slider>
      )}
    </div>
  );
}
