/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
/* eslint-disable no-console */
import React, {
  useEffect,
  Fragment,
  useState,
  useRef,
  useCallback,
} from 'react';
import io from 'socket.io-client';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ChatMessage } from 'components/chat-message/ChatMessage';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';
import './style.scss';

const socket = io(SERVER_URL);

type Props = {
  state: boolean;
  recipients: any;
  setRecipients: any;
  unreadContacts: any;
  getRecipients: any;
};

export function ChatModal(props: Props) {
  const inputChatTypeMessage = useRef<any>('');
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [messages, setMessages] = useState<undefined | any>([]);
  const { state, recipients, setRecipients, unreadContacts, getRecipients } =
    props;
  const [typingMessage, setTypingMessage] = useState('');
  const [receiverId, setReceiverId] = useState(0);
  const [isOpen, setIsOpen] = useState(state);
  const socketRef = useRef(socket);

  useEffect(() => {
    setIsOpen(true);
  }, [state]);

  const markMessagesAsRead = useCallback(
    async (ME: any, TO: any) => {
      const route = `${SERVER_URL}markAsReaded/`;
      // eslint-disable-next-line promise/no-nesting

      await axios
        .post(route, {
          me: ME,
          to: TO,
        })
        .then((res) => {
          if (res.status === 201) {
            getRecipients();
          }
          return res.data;
        })
        .catch(() => { });
    },
    [getRecipients],
  );

  function closeModal() {
    if (receiverId !== 0) {
      markMessagesAsRead(currentUser?.id, receiverId);
    }

    setReceiverId(0);
    setIsOpen(false);
  }

  const getAllMessages = useCallback(async (sendedTo: any, me: any) => {
    const route = `${SERVER_URL}getAllMessages/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        sendedTo,
        me,
      })
      .then((resp) => {
        if (resp.status === 201) {
          setMessages(resp.data);
          scrollToBottom();
        }
        return resp.data;
      })
      .catch(() => { });
  }, []);

  const messageSent = useCallback(
    (information: any) => {
      if (information.idSender === receiverId.toString()) {
        getAllMessages(receiverId, currentUser.id);
        markMessagesAsRead(currentUser?.id, receiverId);
      } else {
        getRecipients();
      }
    },
    [
      currentUser.id,
      getAllMessages,
      getRecipients,
      markMessagesAsRead,
      receiverId,
    ],
  );

  socket.on('tellMeYourId', (asking: any) => {
    const response = {
      id: currentUser?.id,
      socket: asking,
    };

    socket.emit('thisIsMyId', response);
  });

  const sendButton = React.useRef<any>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setIsOpen(false);
    scrollToBottom();

    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        sendButton.current.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    const socketRefX = socketRef.current;
    if (receiverId !== 0) {
      socketRef.current.on('messageSent', messageSent);
      const indexCreator = recipients
        .map((object: { userId: any }) => object.userId)
        .indexOf(receiverId);
      const tempRecipents: any = [...recipients];
      tempRecipents.forEach((element: any, index: number) => {
        tempRecipents[index].selected = false;
      });
      tempRecipents[indexCreator].selected = true;
      setRecipients(tempRecipents);
    }
    return () => {
      socketRefX.off('messageSent', messageSent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverId]);

  function sendMessage() {
    if (typingMessage.length > 0 && receiverId && receiverId !== 0) {
      const message = {
        idSender: currentUser.id,
        idReceiver: receiverId,
        message: typingMessage,
      };
      socket.emit('sendMessage', message);
      inputChatTypeMessage.current.value = '';

      setTimeout(() => {
        getAllMessages(receiverId, currentUser.id);
        sendEmailNotification(message);
      }, 1000);
    }
  }

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      messagesEndRef.current.scrollIntoView();
    }
  };

  const selectUser = useCallback(
    (ID: any) => {
      markMessagesAsRead(currentUser?.id, ID);
      setReceiverId(ID);
      getAllMessages(ID, currentUser.id);
      scrollToBottom();
    },
    [currentUser.id, getAllMessages, receiverId],
  );

  const sendEmailNotification = async (message: any) => {
    const route = `${SERVER_URL}sendEmailNotification`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        message,
      })
      .then((res) => {
        if (res.status === 201) {
          //
        }
        return res.data;
      })
      .catch(() => {});
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-6/12">
              <section className="m-auto w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                    onKeyDown={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div className="z-0 mx-auto mt-5 md:w-full">
                  <section className="z-0 flex w-full gap-5">
                    <div
                      id="style-chatbar"
                      className="chat-contacts z-0 w-5/12"
                    >
                      {recipients.length > 0 ? (
                        recipients?.map((element: any, index: any) => (
                          <div
                            key={element?.username}
                            className="px-5"
                            role="button"
                            tabIndex={0}
                            onClick={() => selectUser(element?.userId)}
                          >
                            <div className="z-0 flex border-b border-b-gray-200 py-5 text-gray-600 hover:cursor-pointer hover:text-sky-500 hover:opacity-75 dark:text-white">
                              <img
                                alt="photographer"
                                src={`${BUCKET_IMAGES}avatar/${element.username}/${element?.avatarUrl}`}
                                className="h-20 w-20 rounded-full md:h-8 md:w-8"
                              />
                              {element?.nickname ? (
                                <h1
                                  className={`ml-3 self-center text-lg font-normal ${element?.userId === receiverId.toString()
                                      ? 'active'
                                      : ''
                                    }`}
                                >
                                  {element?.nickname}
                                </h1>
                              ) : (
                                <h1
                                  className={`ml-3 self-center text-lg font-normal ${element?.userId === receiverId.toString()
                                      ? 'active'
                                      : ''
                                    }`}
                                >
                                  {element?.companyName}
                                </h1>
                              )}

                              {unreadContacts
                                ?.map((contact: any) => contact.idSender)
                                .indexOf(element.userId) > -1 && (
                                  <span className="ml-3 h-2 w-2 animate-pulse self-center rounded-full border bg-green-400" />
                                )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className=" animate-pulse  pt-[110px] text-center text-gray-600 dark:text-white">
                          {currentUser?.roles?.includes('ROLE_BRAND') && (
                            <p>
                              Add Photographers to
                              <br />
                              Project Favorites
                              <br />
                              to start chating with them
                            </p>
                          )}
                          {currentUser?.roles?.includes('ROLE_CREATOR') && (
                            <p>
                              A Brand must add you
                              <br />
                              as favorite
                              <br />
                              to start chat with them.
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="z-0 m-auto w-full text-center text-gray-500 dark:text-white">
                      <div
                        id="style-msgBar"
                        className="imessage content-right grid h-96 w-full overflow-y-auto rounded border border-slate-200  p-10"
                      >
                        {receiverId !== 0 ? (
                          messages.length > 0 &&
                          messages?.map((element: any, index: any) => (
                            <ChatMessage
                              message={element}
                              myId={currentUser.id}
                            />
                          ))
                        ) : (
                          <div className="pleaseSelect mt-[150px] w-full animate-pulse place-content-center text-center text-gray-500 dark:text-white">
                            <p>Please select a recipient.</p>
                          </div>
                        )}
                        <div ref={messagesEndRef} />
                      </div>

                      {receiverId !== 0 && (
                        <div className="m-auto mt-2 flex">
                          <input
                            // disabled={isSendDisabled}
                            ref={inputChatTypeMessage}
                            className="h-10 w-full resize-none overflow-y-auto rounded-l border border-slate-200 px-3 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 dark:bg-slate-400 dark:placeholder:text-white"
                            placeholder="Enter Message"
                            onChange={(event) =>
                              setTypingMessage(event.target.value)
                            }
                          />

                          <div
                            ref={sendButton}
                            onClick={() => sendMessage()}
                            role="button"
                            tabIndex={0}
                            className="brand-color block w-4/12 rounded-r p-2 text-center text-white duration-200 ease-in hover:cursor-pointer hover:bg-sky-300"
                          >
                            Send
                            <FontAwesomeIcon
                              className="ml-3"
                              icon={faPaperPlane}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
