import React, { useState, useRef, useEffect } from 'react';

type PropsUploadPortfolio = {
  setCreatorPortfolioPhotos: any;
  creatorPortfolioPhotos: any;
  isUploading: boolean;
};

export function UploadPortfolio(props: PropsUploadPortfolio) {
  const { setCreatorPortfolioPhotos, creatorPortfolioPhotos, isUploading } =
    props;

  const onChangePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-debugger
    // debugger;
    const fileList = e.target.files;
    if (!fileList) return;
    if (fileList?.length !== 0) {
      const temp = [...creatorPortfolioPhotos];
      Array.from(fileList).forEach((file) => temp.push(file));
      setCreatorPortfolioPhotos(temp);
    }
  };

  function deletePhotoFromUpload(index: number) {
    const temp = [...creatorPortfolioPhotos];
    temp.splice(index, 1);
    setCreatorPortfolioPhotos(temp);
  }

  const fileUpload = useRef<HTMLInputElement>(null);
  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  return (
    <div>
      <div className="text-gray-500 dark:text-white">
        <h1 className="text-2xl font-semibold"> Upload Portfolio </h1>

        <p className="mt-10">
          Show A Wide Range Of Work That Highlights Your Capability And Range.
        </p>

        <input
          type="file"
          ref={fileUpload}
          accept="image/*"
          className="hidden"
          name="uploadPhotos"
          multiple
          onChange={(e) => onChangePhoto(e)}
        />

        {isUploading ? (
          <div className="m-auto flex h-[300px] place-items-center content-center items-center justify-center justify-items-center place-self-center self-center justify-self-center py-28 px-[15%] text-center">
            <svg
              className="-ml-1 mr-3 h-20 w-20 animate-spin text-sky-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>
        ) : (
          <div
            onClick={clickFileUpload}
            role="button"
            tabIndex={0}
            className="mt-5 flex h-96 rounded-xl border-[0.125rem] border-sky-300 bg-white hover:cursor-pointer  hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500"
          >
            <div className="m-auto content-center justify-center p-10 text-center align-middle">
              <img
                src="./img/upload.png"
                alt=""
                className="inline h-12 w-12 self-center"
              />
              <h1 className="mt-5 text-2xl font-semibold text-gray-600 dark:text-white">
                Upload Portfolio Photographs
              </h1>
            </div>
          </div>
        )}
      </div>
      <section className="mt-10 grid grid-cols-2 gap-4 md:grid-cols-10">
        {creatorPortfolioPhotos?.map((element: any, index: number) => (
          <div key={`porfphot + ${index.toString()}`} className="relative">
            <img
              src={URL?.createObjectURL(element)}
              alt=""
              className="h-[150px] w-[150px] rounded-2xl object-cover hover:opacity-90"
            />
            <div
              role="button"
              tabIndex={0}
              onClick={() => deletePhotoFromUpload(index)}
              className="absolute top-0 right-0 h-6 w-6 place-items-center self-center rounded-full border bg-white text-center font-extrabold text-sky-500 shadow hover:top-[-1px] hover:right-[-1px] hover:h-7 hover:w-7 hover:cursor-pointer hover:p-[2px] hover:shadow-lg"
            >
              X
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}
