import React, { useState, useEffect } from 'react';
import Masonry from 'react-responsive-masonry';
import { BUCKET_IMAGES } from '../../common/CONFIG';
import { ModalPreview } from '../modal-preview/ModalPreview';

type DataPhotographersType = {
  id: number;
  pictureName: string;
  pictureUrl: string;
  photographer: string;
  totalPurchased: string;
  totalPictures: string;
  buy: boolean;
  favorite: boolean;
  download: boolean;
  resolution: string;
  masonryHeight?: string;
  marginBottom?: string;
  refPosition?: string;
  ref: number | string;
};
type Props = {
  data: any;
};
export function MasonryPhotographersShare(props: Props) {
  let masonryCounter = 1;

  const { data } = props;

  // const arrayPropsWithHeights = [...props.data];
  // For showing smaller images in center column of masonry
  function resetMasonryCounter() {
    if (masonryCounter >= 2) {
      masonryCounter = 1; // Reset !
    }
  }
  for (let i = 0; i < data?.length; i += 1) {
    if (window.innerWidth > 700) {
      if (masonryCounter === 0 || masonryCounter === 2) {
        data[i].masonryHeight = 'h-[37.5rem]';
        data[i].refPosition = 'mt-[33.75rem]';
        masonryCounter += 1;
        resetMasonryCounter();
      } else {
        data[i].masonryHeight = 'h-[27.625rem]';
        data[i].refPosition = 'mt-96';
        masonryCounter += 1;
      }
    } else {
      data[i].masonryHeight = 'h-[27.625rem]';
      data[i].refPosition = 'mt-96';
    }
  }
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [previewUrlIndex, setPreviewUrlIndex] = useState<number>(0);

  const [cols, setCols] = useState(3);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setCols(1);
    }
  }, []);

  return (
    <div>
      {data && (
        <ModalPreview
          toggle={setIsOpenModal}
          resetIndex={setPreviewUrlIndex}
          isOpenModal={isOpenModal}
          previewUrlIndex={previewUrlIndex}
          data={data}
        />
      )}
      {data && (
        <Masonry columnsCount={cols} gutter="2rem">
          {/* <section className="grid items-center content-center grid-cols-3 mx-auto "> */}
          {data?.map((element: any, index: any) => (
            <div
              onClick={(e) => {
                setPreviewUrlIndex(index);
                setIsOpenModal(true);
              }}
              onKeyDown={(e) => {
                setPreviewUrlIndex(index);
                setIsOpenModal(true);
              }}
              key={element.id}
              role="button"
              tabIndex={0}
              className={`relative mb-6 rounded-2xl shadow hover:cursor-pointer hover:opacity-90 hover:shadow md:mb-0  ${element.masonryHeight} ${element.marginBottom} w-[22rem] md:w-[26.04vw]`}
            >
              <div
                className="absolute w-full content-center items-center self-center text-center md:w-[26.04vw] bottom-5 left-[35%]"
              >
                <p className="ml-[0.25rem] h-10 w-fit content-center items-center self-center rounded-2xl bg-white px-5 pb-[1.28vh] pt-2 text-center text-base dark:bg-slate-800 md:ml-[0.2vw] md:px-[1.04vw] md:text-[0.83vw]">
                  Ref# {element.ref}
                </p>
              </div>

              <img
                src={`${BUCKET_IMAGES}watermark/${element?.projectId}/${element?.watermarkUrl}`}
                alt=""
                className="h-full w-full rounded-2xl object-cover"
              />
            </div>
          ))}
          {/* </section> */}
        </Masonry>
      )}
    </div>
  );
}
