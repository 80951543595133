import React, { useState, useEffect } from 'react';
import { TermsModal } from '../terms-modal/TermsModal';
import { PrivacyPolicyModal } from '../privacy-policy-modal/PrivacyPolicyModal';

type PropsAgreed = {
  agreed: boolean;
  terms: any;
  privacy: any;
  setAcceptTerms: any;
  setAcceptPrivacy: any;
};

export function TermsAndConditions(props: PropsAgreed) {
  const { agreed, terms, privacy, setAcceptTerms, setAcceptPrivacy } = props;
  const [termsIsOpen, setTermsIsOpen] = useState(false);
  const [privacyIsOpen, setPrivacyIsOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(agreed);
  const [privacyAccepted, setPrivacyAccepted] = useState(agreed);
  const [minPurchaseAccepted, setMinPurchaseAccepted] = useState(agreed);

  useEffect(() => {
    setTermsAccepted(termsAccepted);
  }, [termsAccepted]);

  useEffect(() => {
    if (termsAccepted) {
      setAcceptTerms(true);
    }
  }, [termsAccepted]);

  useEffect(() => {
    if (privacyAccepted) {
      setAcceptPrivacy(true);
    }
  }, [privacyAccepted]);

  return (
    <section>
      <TermsModal
        state={termsIsOpen}
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
        agreed={agreed}
      />
      <PrivacyPolicyModal
        state={privacyIsOpen}
        privacyAccepted={privacyAccepted}
        setPrivacyAccepted={setPrivacyAccepted}
        agreed={agreed}
      />

      <div className="text-gray-600 dark:text-white">
        {agreed ? (
          <h1 className="text-2xl font-semibold"> Terms & Conditions </h1>
        ) : (
          <h1 className="text-2xl font-semibold"> Terms & Conditions </h1>
        )}
        <div className="mt-10 grid w-full md:flex">
          <div className="border-gray-300 md:w-1/2 md:border-r-2">
            <h1 ref={terms} className="text-xl font-semibold">
              Terms Of Use
            </h1>

            <div className="mt-10 ">
              <label
                htmlFor="Terms"
                className="my-1 flex items-center text-gray-700 dark:text-white md:text-[0.83vw]"
              >
                {!agreed && (
                  <input
                    id="Terms"
                    className="float-left mr-4 h-5 w-5  cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 duration-200  checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300"
                    type="checkbox"
                    value=""
                    disabled={agreed}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                    defaultChecked={termsAccepted}
                    checked={termsAccepted}
                  />
                )}
                {!agreed
                  ? 'I Agree to Terms of Use.'
                  : 'You agreed to our Terms of Use.'}
              </label>

              <div
                className="mt-10"
                onClick={() => setTermsIsOpen(!termsIsOpen)}
                onKeyDown={() => setTermsIsOpen(!termsIsOpen)}
                role="button"
                tabIndex={0}
              >
                <div className="relative grid h-[140px]  w-[110px] touch-auto place-items-center rounded-2xl bg-sky-500 text-center hover:cursor-pointer hover:opacity-80  md:mr-[1.25vw]">
                  <img
                    src="./img/file.png"
                    alt=""
                    className=" h-[90px] hover:cursor-pointer hover:opacity-80 "
                  />
                  <p className="absolute bottom-[30px] left-[30px] text-xl font-extrabold uppercase text-white">
                    PDF
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="m-auto my-10 block w-3/4 md:hidden" />
          <div className="w-full border-gray-300  md:px-[2.91vw]">
            <h1 ref={privacy} className="text-xl font-semibold ">
              Privacy Policy
            </h1>
            <div className="mt-10 ">
              <label
                htmlFor="Privacy"
                className="my-1 flex items-center text-gray-700 dark:text-white md:text-[0.83vw]"
              >
                {!agreed && (
                  <input
                    id="Privacy"
                    className="float-left mr-4 h-5 w-5  cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300"
                    type="checkbox"
                    value=""
                    disabled={agreed}
                    onChange={() => setPrivacyAccepted(!privacyAccepted)}
                    defaultChecked={privacyAccepted}
                    checked={privacyAccepted}
                  />
                )}
                {!agreed
                  ? 'I Agree to Privacy Policy.'
                  : 'You agreed to our Privacy Policy.'}
              </label>

              <div
                role="button"
                tabIndex={0}
                className="mt-10"
                onClick={() => setPrivacyIsOpen(!privacyIsOpen)}
                onKeyDown={() => setPrivacyIsOpen(!privacyIsOpen)}
              >
                <div className="relative grid h-[140px]  w-[110px] touch-auto place-items-center rounded-2xl bg-sky-500 text-center hover:cursor-pointer hover:opacity-80  md:mr-[1.25vw]">
                  <img
                    src="./img/file.png"
                    alt=""
                    className=" h-[90px] hover:cursor-pointer hover:opacity-80 "
                  />
                  <p className="absolute bottom-[30px] left-[30px] text-xl font-extrabold uppercase text-white">
                    PDF
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <hr className="m-auto my-10 block w-3/4 md:hidden" /> */}
          {/* <div className="w-full md:px-[2.91vw]">
            <h1 className="text-xl font-semibold ">
              Minimum Purchase Commitment
            </h1>
            <div className="mt-10 w-full ">
              <label
                htmlFor="min"
                className="my-1 flex items-center text-gray-700 dark:text-white"
              >
                <input
                  id="min"
                  className="float-left mr-4 h-5 w-5 cursor-pointer appearance-none rounded border border-gray-300 bg-white bg-contain bg-center bg-no-repeat opacity-75 transition duration-200 checked:border-sky-500 checked:bg-sky-300 hover:opacity-100 hover:shadow focus:outline-none dark:bg-slate-800 dark:checked:bg-sky-300"
                  type="checkbox"
                  value=""
                  disabled={agreed}
                  onChange={() => setMinPurchaseAccepted(!minPurchaseAccepted)}
                  defaultChecked={minPurchaseAccepted}
                />
                <p className="text-xs md:text-[0.83vw] md:leading-6">
                  I agree to purchase a minimum of five
                  <br />
                  images when I invited a photographer to a project.
                </p>
              </label>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
