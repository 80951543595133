import React, { useEffect, useState } from 'react';
import './style.scss';

type Props = {
  message: any;
  myId: any;
};

export function ChatMessage(props: Props) {
  const { message, myId } = props;

  const [fromThem, setFromThem] = useState(false);

  useEffect(() => {
    const myIdStringed = myId.toString();
    if (myIdStringed === message.idSender) {
      setFromThem(false);
    } else {
      setFromThem(true);
    }
  }, [message]);

  return (
    <div className="w-[100%]">
      {fromThem ? (
        <p className="from-them dark:after:bg-slate-800 float-left">{message.message} </p>
      ) : (
        <p className="from-me dark:after:bg-slate-800 float-right">{message.message} </p>
      )}
    </div>
  );
}
