import axios from "axios";
import {SERVER_URL} from "../CONFIG";

const API_URL = `${SERVER_URL  }api/auth/`;

const register = (username:any, email:any, password:any, roles:any, firstName:any, lastName:any,type:any, checkedStateActivity:any, checkedShotLocation:any, checkedSpecializationCategories:any, contactPhone:any, creatorInfoExtra:any, brandInfoExtra:any, avatarPhoto:any, onboarding:boolean, enabled:boolean) => axios.post(`${API_URL  }signup`, {
    username,
    email,
    password,
    roles,
    firstName,    
    lastName,    
    type,    
    checkedStateActivity,
    checkedShotLocation,
    checkedSpecializationCategories,
    contactPhone,
    creatorInfoExtra,
    brandInfoExtra,
    avatarPhoto,
    onboarding,
    enabled
  });

const login = (username:any, password:any) => axios
    .post(`${API_URL  }signin`, {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("userAuthenticApp", JSON.stringify(response.data));
      }

      return response.data;
    });

const logout = () => {
  localStorage.removeItem("userAuthenticApp");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  register,
  login,
  logout,
};
