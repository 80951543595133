import React, { useEffect, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import './style.scss';

type Props = {
  state: boolean;
};

export function RegionModal(props: Props) {
  const { state } = props;
  const [isOpen, setIsOpen] = useState(state);

  useEffect(() => {
    setIsOpen(true);
  }, [state]);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  const [north, setNorth] = useState(false);
  const [south, setSouth] = useState(false);
  const [midwest, setMidwest] = useState(false);
  const [west, setWest] = useState(false);
  const [northStyle, setNorthStyle] = useState('grayscale');
  const [southStyle, setSouthStyle] = useState('grayscale');
  const [midwestStyle, setMidwestStyle] = useState('grayscale');
  const [westStyle, setWestStyle] = useState('grayscale');

  function handleNorth() {
    setNorth(!north);
    if (north) {
      setNorthStyle('grayscale');
    } else {
      setNorthStyle('grayscale-0');
    }
  }

  function handleSouth() {
    setSouth(!south);
    if (south) {
      setSouthStyle('grayscale');
    } else {
      setSouthStyle('grayscale-0');
    }
  }

  function handleMidwest() {
    setMidwest(!midwest);
    if (midwest) {
      setMidwestStyle('grayscale');
    } else {
      setMidwestStyle('grayscale-0');
    }
  }

  function handleWest() {
    setWest(!west);
    if (west) {
      setWestStyle('grayscale');
    } else {
      setWestStyle('grayscale-0');
    }
  }

  //  console.log(north, south, midwest, west);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[101] overflow-y-auto"
        onClose={() => closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 -z-[1] bg-black opacity-30" />
        <div className="px-4 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800 md:w-6/12">
              <section className="m-auto w-full">
                <div className="flex justify-end">
                  <div
                    role="button"
                    tabIndex={0}
                    className="z-0 mt-1 mr-2 flex text-gray-500 hover:cursor-pointer hover:opacity-70 dark:text-white"
                    onClick={closeModal}
                    onKeyDown={closeModal}
                  >
                    <p className="mr-3 self-center text-xl">close</p>
                    <img
                      src="./img/x.png"
                      alt=""
                      className="mt-2 h-6 w-6 self-center align-middle"
                    />
                  </div>
                </div>

                <div className="mx-auto mt-10 md:w-8/12">
                  <section className="flex w-full ">
                    <div className="w-full text-center text-gray-500 dark:text-white md:w-[3/4]">
                      <h1 className="ml-5 text-xl font-semibold text-gray-900 dark:text-white">
                        Filter by region
                      </h1>

                      <div className="relative h-96">
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => handleMidwest()}
                          onKeyDown={() => handleMidwest()}
                          className={` midwest-map absolute top-[-2.39vw] left-[10.98vw] m-auto h-[40.98vh] w-[12.5vw] text-center opacity-60 duration-300 ease-in hover:animate-pulse hover:cursor-pointer hover:opacity-100 ${midwestStyle}`}
                        >
                          <h1 className="text-shadow absolute top-[27%] left-[15%] h-[17.07vh] w-[8.33vw] pt-[8.53vh] text-[1.56vw] font-semibold text-white opacity-0 duration-300 ease-in hover:opacity-100">
                            Midwest
                          </h1>
                        </div>
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => handleWest()}
                          onKeyDown={() => handleWest()}
                          className={`west-map absolute top-[-2.5vw] left-[0.62vw] h-[40.98vh] w-[11.92vw] opacity-60 duration-300 ease-in hover:animate-pulse hover:cursor-pointer hover:opacity-100 ${westStyle}`}
                        >
                          <h1 className="text-shadow absolute top-[27%] left-[15%] h-[17.07vh] w-[8.33vw] pt-[8.53vh] text-[1.56vw] font-semibold text-white opacity-0 duration-300 ease-in hover:opacity-100">
                            West
                          </h1>
                        </div>
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => handleNorth()}
                          onKeyDown={() => handleNorth()}
                          className={`duration-300hover:animate-pulse north-map absolute top-[-3.38vw] left-[22.81vw] h-[40.98vh] w-[6.92vw] opacity-60 ease-in hover:cursor-pointer hover:opacity-100 ${northStyle}`}
                        >
                          <h1 className="text-shadow absolute top-[27%] left-[15%] h-[17.07vh] w-[8.33vw] pt-[8.53vh] text-[1.56vw] font-semibold text-white opacity-0 duration-300 ease-in  hover:opacity-100">
                            North
                          </h1>
                        </div>
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => handleSouth()}
                          onKeyDown={() => handleSouth()}
                          className={`south-map absolute top-[17.82vh] left-[8.59vw] h-[40.98vh] w-[18.12vw] opacity-60 duration-300 ease-in hover:animate-pulse hover:cursor-pointer hover:opacity-100 ${southStyle} `}
                        >
                          <h1 className="text-shadow absolute top-[15%] left-[15%] h-[11.95vh] w-[13.33vw] pt-[3.41vh] text-[1.56vw] font-semibold text-white opacity-0 duration-300  ease-in hover:opacity-100	">
                            South
                          </h1>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
