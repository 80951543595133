import React, { useRef } from 'react';

type PropsBrand = {
  onChangeExtraBrand: any;
  brandInfo: any;
  onChangeAvatar: any;
  avatarPhoto: any;
  brandcompany: any;
  brandweb: any;
  brandface: any;
  brandinsta: any;
  brandbio: any;
  brandlogo: any;
};

export function CompanyInformation(props: PropsBrand) {
  const {
    onChangeExtraBrand,
    brandInfo,
    onChangeAvatar,
    avatarPhoto,
    brandcompany,
    brandweb,
    brandface,
    brandinsta,
    brandbio,
    brandlogo,
  } = props;

  const fileUpload = useRef<HTMLInputElement>(null);

  const clickFileUpload = () => {
    fileUpload!.current!.click();
  };

  return (
    <div className="text-gray-600 dark:text-white      ">
      <div className="mb-10 flex w-full place-content-between">
        <h1 className="text-2xl font-semibold"> Company Information </h1>
        <h1 className="mr-[40px] hidden text-2xl font-semibold md:block">
          {' '}
          Company Logo{' '}
        </h1>
      </div>
      <h1 className="mb-5 block text-2xl font-semibold md:hidden">
        {' '}
        Company Logo{' '}
      </h1>
      <div className="m-auto flex w-full flex-col-reverse gap-5 md:flex md:flex-row      ">
        <section className="grid h-20 w-full gap-5">
          <div className="grid gap-5 md:grid-cols-2">
            <input
              type="text"
              className="h-[70px] w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandcompany}
              placeholder="Company Name"
              value={brandInfo?.companyName}
              onChange={(e) =>
                onChangeExtraBrand('companyName', e.target.value)
              }
            />
            <input
              type="text"
              className="h-[70px] w-full rounded-2xl border border-gray-300  p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandweb}
              placeholder="Company Website"
              value={brandInfo?.companyWeb}
              onChange={(e) => onChangeExtraBrand('companyWeb', e.target.value)}
            />
            <input
              type="text"
              className="h-[70px] w-full rounded-2xl border border-gray-300 p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandface}
              placeholder="Facebook"
              value={brandInfo?.companyFB}
              onChange={(e) => onChangeExtraBrand('companyFB', e.target.value)}
            />
            <input
              type="text"
              className="h-[70px] w-full rounded-2xl border border-gray-300  p-6 text-sm outline-none transition duration-500 ease-in hover:border hover:border-sky-500   dark:bg-slate-400 dark:placeholder:text-white"
              ref={brandinsta}
              placeholder="Instagram"
              value={brandInfo?.companyIG}
              onChange={(e) => onChangeExtraBrand('companyIG', e.target.value)}
            />
          </div>
        </section>
        <input
          onChange={(e) => onChangeAvatar(e)}
          type="file"
          ref={fileUpload}
          accept="image/*"
          className="hidden"
        />
        <div className="mx-auto grid place-content-center md:flex">
          <div
            onClick={clickFileUpload}
            role="button"
            ref={brandlogo}
            tabIndex={0}
            className="flex h-60 w-60 place-content-end content-end rounded-full border-[0.125rem] border-dashed border-gray-300 bg-white text-right hover:cursor-pointer hover:border-sky-400  hover:bg-sky-50 hover:opacity-80  dark:bg-slate-800 dark:hover:bg-slate-500 md:float-right"
          >
            {avatarPhoto ? (
              <img
                src={URL.createObjectURL(avatarPhoto)}
                alt=""
                className="h-60 w-60 rounded-full bg-white  object-cover text-right hover:cursor-pointer  hover:border-sky-400 hover:bg-sky-50  hover:opacity-80 dark:bg-slate-800 dark:hover:bg-slate-500"
              />
            ) : (
              <div className="m-auto content-center justify-center p-10 text-center align-middle">
                <img
                  src="./img/upload.png"
                  alt=""
                  className="inline h-9 w-9 self-center"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <textarea
        rows={3}
        className="mt-[285px] min-h-[250px] w-full resize-none overflow-y-auto rounded-xl border border-gray-300 p-4 transition duration-500 ease-in  focus:border-sky-500 focus:outline-none focus:ring-sky-500 dark:bg-slate-400  dark:placeholder:text-white md:mt-5"
        placeholder="Company Bio"
        value={brandInfo?.bio}
        ref={brandbio}
        onChange={(e) => onChangeExtraBrand('bio', e.target.value)}
        maxLength={2000}
      />
    </div>
  );
}
