import React, { useState, useEffect } from 'react';
import Masonry from 'react-responsive-masonry';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BUCKET_IMAGES, SERVER_URL } from '../../common/CONFIG';

type DataPhotographersType = {
  id: number | string;
  pictureName: string;
  pictureUrl: string;
  photographer: string;
  totalPurchased: string;
  totalPictures: string;
  buy: boolean;
  favorite: boolean;
  download: boolean;
  resolution: string;
  masonryHeight?: string;
  marginBottom?: string;
  refPosition?: string;
  ref?: number | string;
  notes?: string;
  cartAdded?: boolean;
  cartBought?: boolean;
  documentName?: string;
  extension?: string;
  type?: string;
};
type Props = {
  // data: DataPhotographersType[];
  data: any[];
  currentInspirations: any;
  setCurrentInspirations: any;
  setForRefresh: any;
  creatorUsername: any;
};

export function MasonryPhotographersProfile(props: Props) {
  const {
    data,
    currentInspirations,
    setCurrentInspirations,
    setForRefresh,
    creatorUsername,
  } = props;
  const { user: currentUser } = useSelector((state: any) => state.auth);
  const [photos, setPhotos] = useState<any>(['']);
  const [cols, setCols] = useState(3);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setCols(1);
    }

    let masonryCounter = 1;

    for (let i = 0; i < data?.length; i += 1) {
      if (window.innerWidth > 700) {
        if (masonryCounter === 0 || masonryCounter === 2) {
          data[i].masonryHeight = 'h-[37.5rem]';
          // eslint-disable-next-line react-hooks/exhaustive-deps
          masonryCounter += 1;
          if (masonryCounter >= 2) {
            masonryCounter = 1; // Reset !
          }
        } else {
          data[i].masonryHeight = 'h-[27.625rem]';
          masonryCounter += 1;
        }
      } else {
        data[i].masonryHeight = 'h-[27.625rem]';
      }
    }

    setPhotos(data);
  }, [data]);

  async function addInspiration(photoId: number) {
    const temp: any = [...currentInspirations];

    temp.push(photoId);

    const route = `${SERVER_URL}addCreatorPhotoAsInspiration/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photo: photoId,
        brand: currentUser.id,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setCurrentInspirations(temp);
      })
      .catch(() => {});
    refresh(photoId);
  }

  async function deleteInspiration(photoId: number) {
    const temp: any = [...currentInspirations];
    const tempInspi: any = [];

    temp.forEach((element: any) => {
      if (element !== photoId) {
        tempInspi.push(element);
      }
    });

    const route = `${SERVER_URL}deleteCreatorPhotoAsInspiration/`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        photo: photoId,
        brand: currentUser.id,
      })
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setCurrentInspirations(tempInspi);
      })
      .catch(() => {});
    refresh(-photoId);
  }

  // for BRIEF!
  function refresh(photoId: number) {
    setForRefresh(photoId);
  }

  return (
    <Masonry columnsCount={cols} gutter="2.625rem">
      {/* <section className="grid items-center content-center grid-cols-3 mx-auto "> */}
      {photos?.map((element: any, index: any) => (
        <div
          key={`photos + ${index.toString()}`}
          className={`mb-6 rounded-2xl hover:opacity-90 md:mb-0 md:shadow ${element.masonryHeight} ${element.marginBottom}  w-[31.25rem] md:w-[26.04vw]`}
        >
          <div
            className={`absolute grid w-[32.5rem] grid-cols-2 place-content-between p-2 md:w-[45.83vw]  ${element.masonryHeight}`}
          >
            <div />

            {currentInspirations.includes(element.id) ? (
              <div
                role="button"
                tabIndex={0}
                onClick={() => deleteInspiration(element.id)}
              >
                <img
                  src="./img/starFillCircle.png"
                  alt=""
                  className="mt-2 h-10 w-10 hover:cursor-pointer hover:opacity-80 md:h-[3vh] md:w-[3vh]"
                />
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => addInspiration(element.id)}
              >
                <img
                  src="./img/starEmptyCircle.png"
                  alt=""
                  className="mt-2 h-10 w-10 hover:cursor-pointer  hover:opacity-80 md:h-[3vh] md:w-[3vh]"
                />
              </div>
            )}
          </div>

          <img
            src={`${BUCKET_IMAGES}portfolio/thumbnails/${creatorUsername}/${element?.thumbnailUrl}`}
            alt=""
            className="h-full w-[20rem] rounded-2xl object-cover hover:opacity-90 md:w-full"
          />
        </div>
      ))}
      {/* </section> */}
    </Masonry>
  );
}
