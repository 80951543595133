import React, { useEffect, useState } from 'react';
import { MasonryPhotographersShare } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL, BUCKET_IMAGES } from '../../../common/CONFIG';

export default function ShareSelects() {
  const [shareInfo, setShareInfo] = useState<any>([]);
  const query = new URLSearchParams(useLocation().search);
  const projectId = query.get('projectId');
  const creatorId = query.get('creatorId');
  const photos = query.get('photos');
  const navigate = useNavigate();

  async function getInfoOfTheShare() {
    if (!projectId || !creatorId || !photos) {
      navigate('/');
    }

    const route = `${SERVER_URL}getInfoOfTheShare`;
    // eslint-disable-next-line promise/no-nesting
    await axios
      .post(route, {
        creatorId,
        projectId,
        photos,
      })
      .then((res: any) => {
        if (res.status === 201) {
          setShareInfo(res.data);
        }
        return res.data;
      })
      .catch(() => {});
  }

  // Auto scroll!
  useEffect(() => {
    window.scrollTo(0, 0);
    getInfoOfTheShare();
  }, []);

  useEffect(() => {
    document.title = 'Authentic | Share';
  }, []);

  return (
    <section className="w-full bg-gray-100 text-gray-500 dark:bg-slate-900 dark:text-white      ">
      <div className="m-auto w-10/12 py-20">
        <section className="grid justify-between md:flex">
          <div className="text-gray-500 dark:text-white">
            <p className="mt-0 text-sm ">STATUS: {shareInfo?.projectStatus}</p>
            <h1 className="text-3xl font-bold ">{shareInfo?.projectName}</h1>
            <h1 className="mt-6 text-lg font-semibold ">
              Shared By: {shareInfo?.sharedBy}
            </h1>
          </div>
          <div className="mt-5 md:mt-0">
            <div className="flex flex-row-reverse md:flex-row">
              <div className="mt-5 ml-5 grid md:mr-7 md:ml-0 md:mt-0">
                <p className="mt-0 text-sm ">Photographer</p>
                <h1 className="mt-2 text-2xl font-semibold ">
                  {shareInfo?.creatorName}
                </h1>
                <p className="mt-0 text-sm ">{shareInfo?.creatorCity}</p>
                <p className="mt-2 text-sm md:mt-6 ">
                  {shareInfo?.numberPhotos} Photos
                </p>
              </div>
              <img
                src={`${BUCKET_IMAGES}avatar/${shareInfo?.username}/${shareInfo?.avatarUrl}`}
                alt=""
                className="mt-4 inline-block h-[6.875rem] w-[6.875rem] rounded-full object-cover align-middle "
              />
            </div>
          </div>
        </section>

        <div className="my-20">
          <MasonryPhotographersShare data={shareInfo?.photos} />
        </div>
      </div>
    </section>
  );
}
